import React from "react";
import styled from "styled-components";
import { PayNowOrderPayment } from "../../../../types/PayNowOrderPayment";
import { Span } from "../../../Span";
import { Flex } from "../../../Flex";
import {
  formattedQuoteExpiry,
  setFormattedTotalPrice,
} from "../PayNowDetailsUtil/PayNowDetailsUtil";

interface Props {
  orderPayment: PayNowOrderPayment;
}

const LabelWrapper = styled(Span)`
  color: theme.colors.neutrals.dn40;
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;

export const PayNowReviewQuoteDetails: React.FC<Props> = ({ orderPayment }) => {
  const { siteAddress, jobCategory, serviceType, totalPrice, quoteExpiry } =
    orderPayment;

  const orderNumberText = "Quote for order number " + orderPayment.orderNumber;

  return (
    <Flex flexDirection="column">
      <Span
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mr={3}
        mb={5}
        alignSelf="flex-start"
        data-testid="pay-now-quote-number"
      >
        {orderNumberText}
      </Span>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Address
        </LabelWrapper>
        <ValueWrapper
          data-testid="pay-now-quote-address"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {siteAddress}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Job type
        </LabelWrapper>
        <ValueWrapper
          data-testid="pay-now-quote-job-type"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {jobCategory}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={5}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Service type
        </LabelWrapper>
        <ValueWrapper
          data-testid="pay-now-quote-service-type"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {serviceType}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}
        >
          Total (incl. GST)
        </LabelWrapper>
        <ValueWrapper
          data-testid="pay-now-quote-total-price"
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {setFormattedTotalPrice(totalPrice)}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row">
        <LabelWrapper
          fontSize={1}
          lineHeight={1}
          flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}
          data-testid="pay-now-quote-expiry-date"
        >
          {formattedQuoteExpiry(quoteExpiry)}
        </LabelWrapper>
      </Flex>
    </Flex>
  );
};
