import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../Button";
import env from "../../environment";

interface Props {
  title: string;
  url?: string;
}

export const LoginButton: React.FC<Props> = ({ title, url }) => {
  const { loginWithRedirect } = useAuth0();

  const handleOnClick = () => {
    loginWithRedirect({ redirectUri: url });
  };

  return <Button onClick={handleOnClick}>{title}</Button>;
};

LoginButton.defaultProps = {
  title: "Sign in",
  url: env.appBaseUri,
};
