import {
  LiveningIcpRoles,
  NewConnectionRoles,
  Roles,
} from "../../../types/ContactDetailRoles";

export const newConnectionRolesSelected = (
  contactDetailsRoles: NewConnectionRoles,
): boolean => {
  return (
    contactDetailsRoles.siteAccessContactRole ||
    contactDetailsRoles.quotePayerRole ||
    contactDetailsRoles.customerRole ||
    contactDetailsRoles.consultantRole
  );
};

export const liveningIcpRolesSelectedInAllContactDetails = (
  contactDetailsRoles: LiveningIcpRoles[],
) => {
  return contactDetailsRoles
    .filter((roles, index) => index !== 0)
    .every(
      (roles) =>
        roles.powerRetailerAccountHolderRole || roles.siteAccessContactRole,
    );
};

//new connection and icp request use
export const siteAccessContactRoleSelectedInAllContactDetails = (
  contactDetailsRoles: Roles[],
): boolean => {
  return contactDetailsRoles.some((roles) => roles.siteAccessContactRole);
};

export const powerRetailerAccountHolderRoleSelectedInAllContactDetails = (
  contactDetailsRoles: LiveningIcpRoles[],
): boolean => {
  return contactDetailsRoles.some(
    (roles) => roles.powerRetailerAccountHolderRole,
  );
};

export const newConnectionRolesSelectedInAllContactDetails = (
  contactDetailsRoles: NewConnectionRoles[],
) => {
  return contactDetailsRoles
    .filter((roles, index) => index !== 0)
    .every((roles) => {
      return newConnectionRolesSelected(roles);
    });
};

export const customerRoleSelectedInAllContactDetails = (
  contactDetailsRoles: NewConnectionRoles[],
): boolean => {
  return contactDetailsRoles.some((roles) => roles.customerRole);
};

export const quotePayerRoleSelectedInAllContactDetails = (
  contactDetailsRoles: NewConnectionRoles[],
): boolean => {
  return contactDetailsRoles.some((roles) => roles.quotePayerRole);
};

export const consultantRoleSelectedInAllContactDetails = (
  contactDetailsRoles: NewConnectionRoles[],
): boolean => {
  return contactDetailsRoles.some((roles) => roles.consultantRole);
};
