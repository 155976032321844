import { useEffect, useState } from "react";
import { EMPTY_UNSUBMITTED_ORDERS_RESPONSE } from "../../constants/Orders";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import env from "../../environment";
import { getUnSubmittedOrderList } from "../../services/OrderService";
import { UnsubmittedOrdersResponse } from "../../types/Order";

const PAGE_INDEX = 0;
const RECORDS_THRESHOLD = env.ordersApiSearchThreshold;

export const useGetUnsubmittedOrderList = (
  retryCount: number,
  recordsPerPage?: number,
  sortOrder?: string,
) => {
  const [unSubmittedOrders, setUnSubmittedOrders] =
    useState<UnsubmittedOrdersResponse>(EMPTY_UNSUBMITTED_ORDERS_RESPONSE);

  const [apiError, setApiError] = useState(false);

  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;

  useEffect(() => {
    const retrieveOrderList = async () => {
      try {
        const unSubmittedOrders = await getUnSubmittedOrderList(
          RECORDS_THRESHOLD,
          PAGE_INDEX,
          sortOrder,
          accessToken,
        );

        setUnSubmittedOrders(unSubmittedOrders);
        setApiError(false);
      } catch {
        setApiError(true);
      }
    };

    if (accessToken) {
      retrieveOrderList();
    }
  }, [retryCount, recordsPerPage, sortOrder, accessToken]);

  return { ...unSubmittedOrders, apiError: apiError };
};
