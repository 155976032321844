import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { submitNewConnectionOrder } from "../../services/SubmitOrderService";
import { NewConnectionFormValues } from "../../types/NewConnectionFormValues";
import { NewConnectionForm } from "../NewConnectionForm";
import { NewConnectionProgressStepper } from "../NewConnectionProgressStepper";
import {
  getGaSubmitFailedEvent,
  getGaSubmitSuccessEvent,
} from "../../utils/ga/GaNewConnectionEvents";
import { EMPTY_UNSUBMITTED_ORDER } from "../../constants/Orders";
import {
  deleteUnSubmittedOrder,
  putUnSubmittedOrder,
} from "../../services/OrderService";
import { FloatingBanner } from "../FloatingBanner";
import { BasicNotificationBannerProps } from "../BasicNotificationBanner/BasicNotificationBanner";
import { addUnsubmittedOrderId } from "../AddDraftOrderIdUtil/AddDraftOrderIdUtil";
import lodash from "lodash";
import { EMPTY_NEW_CONNECTION_ORDER } from "../../constants/NewConnectionOrder";
import { setRequesterDetailsNewConnection } from "../SetRequesterFormDetailsUtil/SetRequesterFormDetailsUtil";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { sendGAEvent } from "../../utils/ga/GaEventSender";
import { useInvalidateOrderList } from "../../hooks/cachedQueries/useInvalidateOrderList/useInvalidateOrderList";
import { getGaSaveDraftFailedEvent, getGaSaveDraftSuccessEvent } from "../../utils/ga/GaNewConnectionEvents";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 25vw auto 10vw;
  }
`;

const WrappedNotificationBanner = styled.div`
  position: fixed;
  left: 2.2vh;
  bottom: 14.7vh;
`;

interface NotificationBannerProps extends BasicNotificationBannerProps {
  show: boolean;
}

export const NewConnection: React.FC = () => {
  const location = useLocation();
  const { state } = location;

  const {
    authenticatedUserContext: {
      isLoggedIn,
      userAuth0Details,
      userBasicDetails,
    },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;
  const { invalidateOrdersCache } = useInvalidateOrderList();

  const formValues: NewConnectionFormValues = lodash.isEmpty(state)
    ? lodash.cloneDeep(EMPTY_NEW_CONNECTION_ORDER)
    : state.newConnectionOrder;
  formValues.isUserLoggedIn = isLoggedIn;

  const initialState: NotificationBannerProps = {
    show: false,
    title: "",
    message: "",
    variant: "success",
    icon: "check_circle",
  };

  const [saveOrderResetBanner, setSaveOrderResetBanner] =
    useState(initialState);

  const [unsubmittedOrderId, setUnsubmittedOrderId] = useState(
    lodash.isEmpty(state) ? "" : state.orderId,
  );

  const [activeStep, setActiveStep] = useState(2);
  const [orderDetails, setOrderDetails] = useState<
    NewConnectionFormValues | undefined
  >(undefined);

  const handleStepChange = (activeStep: number) => {
    setActiveStep(activeStep);
  };

  const handleSubmit = async (values: NewConnectionFormValues) => {
    try {
      values.contactId = userBasicDetails?.contactId;

      await submitNewConnectionOrder(values, accessToken);
      await invalidateOrdersCache();
      await deleteUnSubmittedOrder(unsubmittedOrderId, accessToken);

      setOrderDetails(values);
      sendGAEvent(getGaSubmitSuccessEvent());
    } catch (err: any) {
      setActiveStep(4);
      sendGAEvent(getGaSubmitFailedEvent());
    }
  };

  const handleSave = async (values: NewConnectionFormValues) => {
    let newConnectionOrderSaved;
    try {
      const draftOrder = lodash.cloneDeep(EMPTY_UNSUBMITTED_ORDER);
      draftOrder.orderType = "NewConnectionOrder";
      draftOrder.newConnectionOrder = values;

      addUnsubmittedOrderId(unsubmittedOrderId, draftOrder);

      const result = await putUnSubmittedOrder(draftOrder, accessToken);
      setUnsubmittedOrderId(result.orderId);

      newConnectionOrderSaved = true;
      sendGAEvent(getGaSaveDraftSuccessEvent());
    } catch (error) {
      newConnectionOrderSaved = false;
      sendGAEvent(getGaSaveDraftFailedEvent());
    }

    setSaveOrderResetBanner({
      show: true,
      title: newConnectionOrderSaved
        ? "Order saved"
        : "Oops, looks like something went wrong",
      message: newConnectionOrderSaved
        ? "Your order has been successfully saved and can be found in your Draft orders list"
        : "Please wait a few minutes and try again. If the problem persists, contact us at info@vector.co.nz.",
      variant: newConnectionOrderSaved ? "success" : "error",
      icon: newConnectionOrderSaved ? "check_circle" : "warning",
    });
  };

  const handleOnSaveBannerClose = () => {
    setSaveOrderResetBanner({
      show: false,
      title: saveOrderResetBanner.title,
      message: saveOrderResetBanner.message,
      variant: saveOrderResetBanner.variant,
      icon: saveOrderResetBanner.icon,
    });
  };

  return (
    <>
      <NewConnectionProgressStepper currentStep={activeStep} />
      <WrappedNotificationBanner>
        <FloatingBanner
          title={saveOrderResetBanner.title}
          message={saveOrderResetBanner.message}
          variant={saveOrderResetBanner.variant}
          icon={saveOrderResetBanner.icon}
          visible={saveOrderResetBanner.show}
          onBannerClose={handleOnSaveBannerClose}
        />
      </WrappedNotificationBanner>
      <GridWrapper mx={[3, 3, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          {!orderDetails ? (
            <NewConnectionForm
              initialValues={setRequesterDetailsNewConnection(
                formValues,
                userBasicDetails,
              )}
              onSubmit={handleSubmit}
              onStepChange={handleStepChange}
              activeStep={activeStep}
              handleSave={handleSave}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/thank-you/newConnection",
                state: orderDetails,
              }}
            />
          )}
        </FlexWrapper>
      </GridWrapper>
    </>
  );
};
