import React from "react";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { ContactDetailFields } from "../ContactDetailsForm/ContactDetailFields";
import { Span } from "../Span";
import { Flex } from "../Flex";
import { FormikRadioButton } from "../FormikRadioButton";
import { ErrorMessage, Field } from "formik";

interface Props {
  hasLandOwner?: string;
  focusable?: boolean;
}

export const LandOwnerContactFields: React.FC<Props> = ({
  hasLandOwner,
  focusable,
}) => {
  return (
    <Flex mt={5} flexDirection="column">
      <Span
        display="inline-block"
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mb={4}
      >
        Do you have an land owner?
      </Span>
      <Flex mb={4}>
        <Field
          magnitude="small"
          label="Same as requester"
          id="yes requester"
          name="hasLandOwner"
          value="yes requester"
          currentValue={hasLandOwner}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mr={[2, 2, 3]}
          component={FormikRadioButton}
          useTimeout
        />
        <Field
          magnitude="small"
          label="Same as customer"
          id="yes customer"
          name="hasLandOwner"
          value="yes customer"
          currentValue={hasLandOwner}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={[2, 2, 3]}
          component={FormikRadioButton}
          useTimeout
        />
      </Flex>
      <Flex flexDirection="column" mb={4}>
        <Flex width="100%">
          <Field
            magnitude="small"
            label="New contact"
            id="yes land owner"
            name="hasLandOwner"
            value="yes"
            hasElectrician={hasLandOwner}
            currentValue={hasLandOwner}
            flexGrow={0.5}
            flexBasis={0}
            minWidth={0}
            mr={[2, 2, 3]}
            component={FormikRadioButton}
            useTimeout
          />
          <Field
            magnitude="small"
            label="Not applicable"
            id="no land owner"
            name="hasLandOwner"
            value="no"
            currentValue={hasLandOwner}
            flexGrow={0.5}
            flexBasis={0}
            minWidth={0}
            ml={[2, 2, 3]}
            component={FormikRadioButton}
            useTimeout
          />
        </Flex>
        <ErrorMessage
          name="hasLandOwner"
          className="field-error"
          component={FormInputErrorMessage}
        />
      </Flex>

      {hasLandOwner === "yes" && (
        <Flex flexDirection="column">
          <ContactDetailFields
            focusable={focusable}
            idPrefix="optionalContactDetails"
            namePrefix="optionalContactDetails"
            optionalAlternatePhone
            optionalBusinessName
            optionalEmail
          />
        </Flex>
      )}
    </Flex>
  );
};
