import React from "react";
import { Title } from "../../../Title";
import { Box } from "../../../Box";
import { Flex } from "../../../Flex";
import { FaqTypes } from "../../../../constants/FaqValues";
import { Accordion } from "../../../Accordion";

interface Props {
  faqs: FaqTypes[];
}

export const FaqAccordionGroup: React.FC<Props> = ({ faqs }) => {
  return (
    <Box>
      {faqs.map((category) => (
        <Flex flexDirection="column" pt={2} key={category.category}>
          <Title fontSize={6} fontWeight="medium" key={category.category}>
            {category.category}
          </Title>

          {category.qa.map((faq) => (
            <Box key={faq.question} pb={2}>
              <Accordion title={faq.question} content={faq.answer} />
            </Box>
          ))}
        </Flex>
      ))}
    </Box>
  );
};
