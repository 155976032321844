import { useEffect } from "react";

type Hook = (title?: string) => void;

export const useDocumentTitle: Hook = (title) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
};
