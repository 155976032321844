export const shouldCompleteProfile = (
  isLoading: boolean,
  isLoggedIn: boolean,
  hasSiebelAccount?: boolean,
) => {
  if (hasSiebelAccount === undefined) {
    return false;
  }

  return !isLoading && isLoggedIn && !hasSiebelAccount;
};
