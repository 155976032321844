import { AxiosRequestConfig } from "axios";

export const includeAccessToken = (
  config: AxiosRequestConfig,
  accessToken?: string,
) => ({
  ...config.headers,
  ...(accessToken && {
    "access-token": `${accessToken}`,
  }),
});
