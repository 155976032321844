export type ReactQueryKeys =
  | "orders"
  | "serviceProviders"
  | "livening-inspectors"
  | "power-retailers"
  | "accountDetails"
  | "accountVerification"
  | "basicAccountDetails"
  | "fullAccountDetails";

export const accountVerificationKey: ReactQueryKeys[] = [
  "accountDetails",
  "accountVerification",
];

export const basicAccountDetailsKey: ReactQueryKeys[] = [
  "accountDetails",
  "basicAccountDetails",
];

export const fullAccountDetailsKey: ReactQueryKeys[] = [
  "accountDetails",
  "fullAccountDetails",
];
