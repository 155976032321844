import React from "react";
import { Flex } from "../Flex";
import { ErrorMessage, Field } from "formik";
import { FormikInputText } from "../FormikInputText";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { Box } from "../Box";
import { Link } from "../Link";
import { Icon } from "../Icon";

interface Props {
  focusable?: boolean;
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  disabled?: boolean;
}

export const ManualBillingAddressFields: React.FC<Props> = ({
  focusable,
  fieldIdPrefix,
  fieldNamePrefix,
  disabled,
}) => {
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={["column", "column", "column", "row", "row"]}
        mb={[3, 3, 3, 3, 4]}
      >
        <Flex
          flexDirection="column"
          flexGrow={1}
          flexBasis={0}
          minWidth={0}
          mr={[0, 0, 0, 3, 3]}
        >
          <Field
            id={`${fieldIdPrefix}.manualBillingAddress.addressString`}
            name={`${fieldNamePrefix}.manualBillingAddress.addressString`}
            label="Street address / PO Box or Private Bag"
            placeholder="e.g. 109 Sample Street / PO Box 99882 / Private Bag 123"
            focusable={focusable}
            component={FormikInputText}
            disabled={disabled}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.manualBillingAddress.addressString`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={["column", "column", "column", "column", "row"]}
        mb={[3, 3, 4]}
      >
        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mb={[3, 3, 3, 3, 0]}
          mr={[0, 0, 0, 0, 2]}
        >
          <Field
            id={`${fieldIdPrefix}.manualBillingAddress.suburb`}
            name={`${fieldNamePrefix}.manualBillingAddress.suburb`}
            label="Suburb"
            placeholder="e.g. Glen Eden"
            focusable={focusable}
            optional
            component={FormikInputText}
            disabled={disabled}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.manualBillingAddress.suburb`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex flexDirection="column" flexGrow={0.5} flexBasis={0} minWidth={0}>
          <Field
            id={`${fieldIdPrefix}.manualBillingAddress.city`}
            name={`${fieldNamePrefix}.manualBillingAddress.city`}
            label="City"
            placeholder="e.g. Auckland"
            focusable={focusable}
            component={FormikInputText}
            disabled={disabled}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.manualBillingAddress.city`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex
          flexDirection={["column", "column", "row"]}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={[0, 0, 0, 0, 2]}
        >
          <Flex
            flexDirection="column"
            flexBasis={["auto", 0]}
            flexGrow={[1, 0.5]}
            minWidth={["auto", 0]}
          >
            <Field
              id={`${fieldIdPrefix}.manualBillingAddress.postcode`}
              name={`${fieldNamePrefix}.manualBillingAddress.postcode`}
              label="Postcode"
              placeholder="e.g. 1010"
              focusable={focusable}
              component={FormikInputText}
              disabled={disabled}
            />
            <ErrorMessage
              name={`${fieldNamePrefix}.manualBillingAddress.postcode`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>

          <Box
            ml={[0, 3]}
            mt={4}
            flexGrow={[1, 0.5]}
            flexBasis={["auto", 0]}
            minWidth={["auto", 0]}
          >
            <Link href="https://www.nzpost.co.nz/tools/address-postcode-finder">
              Postcode Finder
              <Icon icon="open_in_new" />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
