import React from "react";
import { Flex } from "../Flex";
import { Title } from "../Title";
import { Link } from "../Link";
import { Icon } from "../Icon";
import { Button } from "../Button";
import { Span } from "../Span";
import { Box } from "../Box";

interface Props {
  heading?: string;
  subHeading?: string;
  message?: string;
  contactEmailAddress?: string;
  showBackButton?: boolean;
  showTryAgainButton?: boolean;
  onBackClick?: () => void;
  onTryAgainClick?: () => void;
  tryAgainLoading?: boolean;
}

export const SomethingWentWrong: React.FC<Props> = ({
  heading,
  subHeading,
  message,
  contactEmailAddress,
  showBackButton,
  showTryAgainButton,
  onBackClick,
  onTryAgainClick,
  tryAgainLoading,
}) => {
  const supportEmail = contactEmailAddress || "info@vector.co.nz";

  return (
    <Flex flexDirection="column" textAlign="center" alignItems="center" mt={7}>
      <Flex
        borderRadius="50%"
        backgroundColor="neutrals.ln20"
        height={17}
        width={17}
        textAlign="center"
        mb={4}
      >
        <Icon
          margin="auto"
          icon="sentiment_dissatisfied"
          color="neutrals.mn80"
          fontSize={8}
        />
      </Flex>

      <Title as="h1" lineHeight={7} fontWeight="medium" mb={4}>
        {heading}
      </Title>

      <Flex flexDirection="column">
        <Box mb={5}>
          <Flex flexDirection="column">
            <Span data-testid="sub-heading" mb={1}>
              {subHeading}
            </Span>
            <Span data-testid="message">
              {message} or alternatively, if the problem persists,{" "}
              <Link href={`mailto:${supportEmail}`}>email us.</Link>
            </Span>
          </Flex>
        </Box>

        {(showBackButton || showTryAgainButton) && (
          <Flex justifyContent="center">
            {showBackButton && (
              <Button
                data-testid="back-button"
                variant={!showTryAgainButton ? "primary" : "secondary"}
                onClick={onBackClick}
              >
                Back
              </Button>
            )}

            {showTryAgainButton && (
              <Button
                data-testid="try-again-button"
                type="submit"
                variant={!showBackButton ? "secondary" : "primary"}
                loading={tryAgainLoading}
                disabled={tryAgainLoading}
                onClick={onTryAgainClick}
                ml={showBackButton ? 5 : 0}
              >
                Try again
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

SomethingWentWrong.defaultProps = {
  heading: "Something went wrong",
  subHeading: "It looks like there was an error.",
};
