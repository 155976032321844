import React, { useState } from "react";
import { OrderDetailsListNavigationMenu } from "../../components/OrderDetailsList/_/OrderDetailsListNavigationMenu";
import { Page } from "../../components/Page";
import { OrderDetails } from "../../components/OrderDetails";
import { FloatingBanner } from "../../components/FloatingBanner";
import styled from "styled-components";
import { BasicNotificationBannerProps } from "../../components/BasicNotificationBanner/BasicNotificationBanner";

export interface OrderDetailsProps {
  tabTitle: string;
}

interface NotificationBannerProps extends BasicNotificationBannerProps {
  show: boolean;
}

const WrappedNotificationBanner = styled.div`
  position: fixed;
  left: 2.2vh;
  bottom: 14.7vh;
`;

export const OrderDetailsPage: React.FC<OrderDetailsProps> = ({ tabTitle }) => {
  const [notificationBannerContent, setNotificationBannerContent] =
    useState<NotificationBannerProps>({
      show: false,
      title: "",
      message: "",
      variant: "success",
      icon: "check_circle",
    });

  const handleCancelOrder = async (cancelResponse: boolean) => {
    setNotificationBannerContent({
      show: true,
      title: cancelResponse
        ? "Cancel order request successful"
        : "Something went wrong",
      message: cancelResponse
        ? "Your request to cancel your order has been successfully sent."
        : "There was an error sending your cancellation request. Please try again in a few minutes.",
      variant: cancelResponse ? "success" : "error",
      icon: cancelResponse ? "check_circle" : "warning",
    });
  };

  const handleCancelOrderBannerClose = () => {
    setNotificationBannerContent({
      ...notificationBannerContent,
      show: false,
    });
  };

  return (
    <Page
      tabTitle={tabTitle}
      navigationMenuContents={() => <OrderDetailsListNavigationMenu />}
      hasMobileNavMenu
    >
      <WrappedNotificationBanner>
        <FloatingBanner
          title={notificationBannerContent.title}
          message={notificationBannerContent.message}
          variant={notificationBannerContent.variant}
          icon={notificationBannerContent.icon}
          visible={notificationBannerContent.show}
          onBannerClose={handleCancelOrderBannerClose}
        />
      </WrappedNotificationBanner>

      <OrderDetails handleOnCancelOrderNotification={handleCancelOrder} />
    </Page>
  );
};
