import React from "react";
import { useUserAuthenticationExecutor } from "../../hooks/useUserAuthenticationExecutor/useUserAuthenticationExecutor";
import { UserAuthentication } from "../../types/UserAuthentication";
import { UserAuthenticationAction } from "../../types/UserAuthenticationAction";

const AuthenticatedUserContext = React.createContext<
  | {
      authenticatedUserContext: UserAuthentication;
      setAuthenticatedUserContext: React.Dispatch<UserAuthenticationAction>;
    }
  | undefined
>(undefined);

const UserAuthenticationProvider: React.FC = ({ children }) => {
  const { authenticatedUserContext, setAuthenticatedUserContext } =
    useUserAuthenticationExecutor();

  return (
    <AuthenticatedUserContext.Provider
      value={{
        authenticatedUserContext,
        setAuthenticatedUserContext,
      }}
    >
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

const useUserAuthentication = () => {
  const context = React.useContext(AuthenticatedUserContext);

  if (context === undefined) {
    throw new Error(
      "useUserAuthentication should be called within UserAuthenticationProvider",
    );
  }

  return context;
};

export { UserAuthenticationProvider, useUserAuthentication };
