import React, { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { Flex } from "../Flex";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { FormikAddressSearchField } from "../FormikAddressSearchField";
import { FormikInputText } from "../FormikInputText";
import { FormikActionLink } from "../FormikActionLink";
import { SiteAddressInformation } from "../../types/SiteAddressInformation";
import { StreetAddressFields } from "../StreetAddressFields";
import {
  checkIsManualAddress,
  checkIsSearchedAddress,
} from "../ManualAddressHelperUtil/ManualAddressHelperUtil";

interface Props {
  siteAddressInformation: SiteAddressInformation;
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  focusable?: boolean;
  validateField: (field: string) => void;
  showUnit: boolean;
  showAdditional?: boolean;
}

export const StreetNumberAndName: React.FC<Props> = ({
  siteAddressInformation,
  fieldIdPrefix,
  fieldNamePrefix,
  focusable,
  validateField,
  showUnit,
  showAdditional,
}) => {
  const [isManualAddress, setIsManualAddress] = useState(false);

  const formattedFieldIdPrefix = `${fieldIdPrefix}.addressInformation`;
  const formattedFieldNamePrefix = `${fieldNamePrefix}.addressInformation`;

  const handleActionLinkClick = () => {
    // Manually trigger Formik field validation for street address because action link has no blur
    validateField(`${fieldNamePrefix}.addressInformation.streetAddress`);

    // Toggle manuallyEnteredAddress in Formik values to manage field validation
    siteAddressInformation.manuallyEnteredAddress =
      !siteAddressInformation.manuallyEnteredAddress;

    setIsManualAddress((prevState) => !prevState);
  };

  useEffect(() => {
    if (siteAddressInformation.manuallyEnteredAddress) {
      setIsManualAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //the address is manual entered
    if (
      checkIsManualAddress(
        isManualAddress,
        siteAddressInformation.manuallyEnteredAddress,
      )
    ) {
      siteAddressInformation.streetAddress = "";
      siteAddressInformation.streetAddressId = "";
    }

    //the address is searched
    if (
      checkIsSearchedAddress(
        isManualAddress,
        siteAddressInformation.manuallyEnteredAddress,
      )
    ) {
      if (showUnit) {
        siteAddressInformation.manualStreetAddress.unit = "";
      }

      siteAddressInformation.manualStreetAddress.streetNumber = "";
      siteAddressInformation.manualStreetAddress.streetName = "";
      siteAddressInformation.manualStreetAddress.suburb = "";
      siteAddressInformation.manualStreetAddress.postcode = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualAddress]);

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mb={2}>
        <Field
          id={`${formattedFieldIdPrefix}.streetAddress`}
          name={`${formattedFieldNamePrefix}.streetAddress`}
          nameId={`${formattedFieldNamePrefix}.streetAddressId`}
          label="Street number and name"
          visibleItems={10}
          placeholder="e.g. 101 Carlton Gore Road, Newmarket, Auckland"
          noResultsMessage={
            "We couldn’t find a matching address. " +
            "Please continue using the manual address fields."
          }
          disabled={isManualAddress}
          focusable={focusable}
          value={isManualAddress ? "" : siteAddressInformation.streetAddress}
          component={FormikAddressSearchField}
        />
        <ErrorMessage
          name={`${formattedFieldNamePrefix}.streetAddress`}
          className="field-error"
          component={FormInputErrorMessage}
        />
      </Flex>

      <Field
        id={`${formattedFieldIdPrefix}.manuallyEnteredAddress`}
        name={`${formattedFieldNamePrefix}.manuallyEnteredAddress`}
        iconLeft={` ${!isManualAddress ? "add_circle" : "remove_circle"}`}
        label={`${
          !isManualAddress
            ? "Add address manually"
            : "Remove manually entered address"
        }`}
        alignSelf="flex-start"
        value={`${!isManualAddress ? "manual" : ""}`}
        mt={2}
        mb={[3, 3, 3, 3, 4]}
        onClick={handleActionLinkClick}
        component={FormikActionLink}
      />

      {isManualAddress && (
        <StreetAddressFields
          fieldIdPrefix={`${formattedFieldIdPrefix}.manualStreetAddress`}
          fieldNamePrefix={`${formattedFieldNamePrefix}.manualStreetAddress`}
          showUnit={showUnit}
        />
      )}

      {showAdditional && (
        <Flex flexDirection="column" mb={4}>
          <Field
            id={`${formattedFieldIdPrefix}.additionalAddressInformation`}
            name={`${formattedFieldNamePrefix}.additionalAddressInformation`}
            label="Additional address information"
            placeholder="e.g. ABC Building"
            optional
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${formattedFieldNamePrefix}.additionalAddressInformation`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      )}
    </Flex>
  );
};

StreetNumberAndName.defaultProps = {
  focusable: true,
  showAdditional: true,
};
