/***
 * Filter an array to return only the items with property keys all found in filters array (for object arrays)
 * or are equal to values in filters array (for number and string arrays)
 * @param array
 * @param filters
 * @param predicate - optional comparison function to use instead of the default filtering used on object arrays
 */
export const filterWithMultipleValues = <T extends object | number | string>(
  array: T[] | undefined,
  filters: string[] | number[],
  predicate?: (item: T, filter: string) => boolean,
): T[] => {
  if (!Array.isArray(array) || !array.length) {
    return [];
  }

  if (!Array.isArray(filters) || !filters.length) {
    return array;
  }

  const stringFilters = filters.map((item) => String(item));

  return array.filter((item) => {
    if (typeof item === "object") {
      return stringFilters.every((filter) =>
        predicate
          ? predicate(item, filter)
          : Object.keys(item).includes(filter),
      );
    }

    return stringFilters.includes(String(item));
  });
};
