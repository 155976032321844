import React from "react";
import { ServiceTypeForm } from "../ServiceTypeForm";
import { Flex } from "../Flex";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { ServiceTypeProgressStepper } from "../ServiceTypeProgressStepper";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

export const ServiceType: React.FC = () => {
  return (
    <>
      <ServiceTypeProgressStepper currentStep={0} />
      <GridWrapper mx={[2, 2, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          <ServiceTypeForm id="service-type-form" />
        </FlexWrapper>
      </GridWrapper>
    </>
  );
};
