const UserAuthenticationActionType = {
  Error: "ERROR",
  LogInStarted: "LOG_IN_STARTED",
  LogInStopped: "LOG_IN_STOPPED",
  LogInCompleted: "LOG_IN_COMPLETED",
  Auth0DetailsRetrieved: "AUTH0_DETAILS_RETRIEVED",
  SiebelAccountVerified: "SIEBEL_ACCOUNT_VERIFIED",
  BasicDetailsRetrieved: "BASIC_DETAILS_RETRIEVED",
  BasicDetailsUpdated: "BASIC_DETAILS_UPDATED",
} as const;

Object.freeze(UserAuthenticationActionType);

export default UserAuthenticationActionType;
