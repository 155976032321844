import { FileScanResponse } from "../types/FileScanResponse";
import { SCAN_TIMEOUT_MILLISECONDS } from "./FileUploadService";

const ONE_SECOND = 1000;

export const getDelayInterval = (executionCount: number) => {
  const delayMilliseconds = Math.pow(2, executionCount) * 100;
  const delayOffsetMilliseconds =
    ONE_SECOND + delayMilliseconds * 0.2 * Math.random();

  return delayMilliseconds + delayOffsetMilliseconds;
};

export const calculateRemainingTimeoutInterval = (
  delay: number,
  timeout?: number,
  lastRequestTime?: number,
): number => {
  if (timeout && lastRequestTime) {
    const lastRequestDuration = Date.now() - lastRequestTime;
    return Math.max(timeout - lastRequestDuration - delay, 1);
  }

  return SCAN_TIMEOUT_MILLISECONDS;
};

export const shouldScanResultRetry = (response: FileScanResponse): boolean => {
  if (response && response.scanResult === "pending") {
    return true;
  }

  return false;
};
