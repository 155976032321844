import React from "react";
import styled from "styled-components";
import { LiveningIcpFormValues } from "../../../../types/LiveningIcpFormValues";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { Title } from "../../../Title";
import { ReviewFormLiveningIcpContactDetailsWithTitle } from "./_/ReviewFormLiveningIcpContactDetailsWithTitle";
import { ReviewFormSection } from "./_/ReviewFormSection";
import { ReviewFormMeteredSiteDetails } from "./_/ReviewFormMeteredSiteDetails";
import { ReviewFormUnmeteredSiteDetails } from "./_/ReviewFormUnmeteredSiteDetails";
import { SquareCheckbox } from "../../../SquareCheckbox";
import {
  getGaEditContactDetailsEvent,
  getGaEditInspectorRetailerEvent,
  getGaEditOrderDetailsEvent,
  getGaEditSiteDetailsEvent,
} from "../../../../utils/ga/GaLiveningIcpEvents";
import { ReviewFormOptionalContactDetails } from "../../../ReviewFormOptionalContactDetails";
import { Link } from "../../../Link";
import { sendGAEvent } from "../../../../utils/ga/GaEventSender";

interface Props {
  values: LiveningIcpFormValues;
  onEdit: (elementId: string) => void;
  selectedTermsConditions: boolean;
  onSelectTermsConditions: () => void;
}

const LabelWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.dn40};
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;

const ICP_ORDER_DETAILS_SECTION = "livening-icp-order-details-section";
const ICP_SITE_DETAILS_SECTION = "livening-icp-site-details-section";
const ICP_CONTACT_DETAILS_SECTION = "livening-icp-contact-details-section";
const ICP_INSPECTOR_RETAILER_SECTION =
  "livening-icp-inspector-retailer-section";

export const LiveningIcpReviewForm: React.FC<Props> = ({
  values,
  onEdit,
  selectedTermsConditions,
  onSelectTermsConditions,
}) => {
  const handleOnEdit = (elementId: string) => () => {
    if (elementId === ICP_ORDER_DETAILS_SECTION) {
      sendGAEvent(getGaEditOrderDetailsEvent());
    }

    if (elementId === ICP_SITE_DETAILS_SECTION) {
      sendGAEvent(getGaEditSiteDetailsEvent());
    }

    if (elementId === ICP_CONTACT_DETAILS_SECTION) {
      sendGAEvent(getGaEditContactDetailsEvent());
    }

    if (elementId === ICP_INSPECTOR_RETAILER_SECTION) {
      sendGAEvent(getGaEditInspectorRetailerEvent());
    }

    onEdit(elementId);
  };

  const handleOnSelectTermsConditions = () => {
    onSelectTermsConditions();
  };

  return (
    <Flex flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="medium" mb={5}>
        {"Review & Submit"}
      </Title>

      <ReviewFormSection
        id="review-form-order-details-section"
        title="Order details"
        data-testid="review-section-order-details"
        onEditClick={handleOnEdit(ICP_ORDER_DETAILS_SECTION)}
      >
        <Flex flexDirection="row" mb={3}>
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
            Type of ICP
          </LabelWrapper>
          <ValueWrapper
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
          >
            {values.icpType}
          </ValueWrapper>
        </Flex>

        <Flex flexDirection="row">
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
            SR/Project number
          </LabelWrapper>
          <ValueWrapper
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
          >
            {values.projectNumber ? values.projectNumber : "N/A"}
          </ValueWrapper>
        </Flex>
      </ReviewFormSection>

      <ReviewFormSection
        id="review-form-site-details-section"
        title="Site details"
        data-testid="review-section-site-details"
        onEditClick={handleOnEdit(ICP_SITE_DETAILS_SECTION)}
      >
        {values.icpType === "metered ICP" && (
          <ReviewFormMeteredSiteDetails sites={values.metered.sites} />
        )}
        {values.icpType === "unmetered ICP" && (
          <ReviewFormUnmeteredSiteDetails unmetered={values.unmetered} />
        )}
      </ReviewFormSection>

      <ReviewFormSection
        id="review-form-inspector-and-retailer-details-section"
        title="Livening electrician &#38; retailer"
        data-testid="review-section-electrician-retailer-details"
        onEditClick={handleOnEdit(ICP_INSPECTOR_RETAILER_SECTION)}
      >
        <Flex flexDirection="row" mb={3}>
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
            Vector approved livening electrician
          </LabelWrapper>
          <ValueWrapper
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
          >
            {values.liveningInspector}
          </ValueWrapper>
        </Flex>

        <Flex flexDirection="row">
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
            Power retailer
          </LabelWrapper>
          <ValueWrapper
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
          >
            {values.powerRetailer}
          </ValueWrapper>
        </Flex>
      </ReviewFormSection>

      <ReviewFormSection
        id="review-form-contact-details-section"
        title="Contact details"
        data-testid="review-section-contact-details"
        onEditClick={handleOnEdit(ICP_CONTACT_DETAILS_SECTION)}
      >
        {values.contactDetails.map((contactDetails, index) => {
          return (
            <ReviewFormLiveningIcpContactDetailsWithTitle
              key={index}
              contactDetails={contactDetails}
              contactDetailsIndex={index}
              hasElectrician={values.hasElectrician}
            />
          );
        })}

        {(values.hasElectrician === "no" ||
          values.hasElectrician === "yes") && (
          <ReviewFormOptionalContactDetails
            contactDetails={values.optionalContactDetails}
            hasOptional={values.hasElectrician}
            title={"Electrician"}
          />
        )}
      </ReviewFormSection>

      <SquareCheckbox
        id="terms-conditions"
        label={() => (
          <Span>
            {"I have read and understand Vector's"}{" "}
            <Link href="https://www.vector.co.nz/privacy-policy">
              privacy policy
            </Link>
          </Span>
        )}
        name="terms-conditions"
        onChange={handleOnSelectTermsConditions}
        checked={selectedTermsConditions}
        value={selectedTermsConditions}
        mr={3}
      />
    </Flex>
  );
};
