import React, { useState } from "react";
import { useDocumentTitle } from "../../hooks/documentFunction/useDocumentTitle";
import { MainTopBarContainer } from "../MainTopBar/MainTopBarContainer";
import { NavigationMenu } from "../NavigationMenu";

interface Props {
  tabTitle?: string;
  showUserLogo?: boolean;
  hasMobileNavMenu?: boolean;
  hasTopBar?: boolean;
  navigationMenuContents?: () => void;
}

export const Page: React.FC<Props> = ({
  children,
  tabTitle,
  showUserLogo,
  hasMobileNavMenu,
  hasTopBar,
  navigationMenuContents,
}) => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  const handleMobileNavMenuClick = () => {
    setShowMobileNav((prevState) => !prevState);
  };

  return (
    <>
      {useDocumentTitle(tabTitle)}

      {hasTopBar && (
        <MainTopBarContainer
          onMobileNavMenuClick={handleMobileNavMenuClick}
          hasMobileNavMenu={hasMobileNavMenu}
          showUserLogo={showUserLogo}
          showMobileNav={showMobileNav}
        />
      )}

      {navigationMenuContents && (
        <NavigationMenu
          menuContents={() => navigationMenuContents()}
          showMobileNav={showMobileNav}
        />
      )}

      {!showMobileNav && <> {children} </>}
    </>
  );
};

Page.defaultProps = {
  showUserLogo: true,
  hasMobileNavMenu: false,
  hasTopBar: true,
};
