import * as yup from "yup";
import {
  ALTERNATE_PHONE_REG_EXP,
  MOBILE_REG_EXP,
} from "../../constants/ContactNumbersRegex";
import {
  ONLY_LETTERS_AND_SPACE_REG_EXP,
  ONLY_NUMBERS_REG_EXP,
} from "../../constants/FormRegex";
import { noWhitespaceValidation } from "../../utils/noWhitespaceValidation/noWhitespaceValidation";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noWhitespace",
  noWhitespaceValidation,
);

export const CompleteProfileDetailsFormSchema = yup.object({
  profile: yup.object().shape({
    type: yup.string().required("Account type is required"),
    isInvitedUser: yup.string(),
    firstName: yup
      .string()
      .noWhitespace("First name is invalid")
      .max(50, "Maximum length is 50")
      .required("First name is required"),
    lastName: yup
      .string()
      .noWhitespace("Last name is invalid")
      .max(50, "Maximum length is 50")
      .required("Last name is required"),
    mobile: yup
      .string()
      .noWhitespace("Enter a valid phone number")
      .matches(MOBILE_REG_EXP, "Enter a valid phone number")
      .required("Mobile is required"),
    alternatePhone: yup
      .string()
      .noWhitespace("Enter a valid alternate phone number")
      .matches(ALTERNATE_PHONE_REG_EXP, "Enter a valid alternate phone number"),
    businessName: yup.string().when("type", {
      is: (type: string) => type === "business",
      then: yup
        .string()
        .noWhitespace("Business name is invalid")
        .max(100, "Maximum length is 100 characters")
        .required("Business name is required"),
      otherwise: yup.string().max(100, "Maximum length is 100 characters"),
    }),
    companyNzbn: yup.string().when(["type", "isInvitedUser"], {
      is: (type: string, isInvitedUser: string) =>
        type === "business" && isInvitedUser === "false",
      then: yup
        .string()
        .matches(ONLY_NUMBERS_REG_EXP, "NZBN can only contain numbers")
        .min(13, "Your NZBN should contain 13 numbers, please update")
        .required("NZBN is required"),
    }),
    billingAddressId: yup.string().nullable(),
    billingAddressDisplay: yup
      .string()
      .when(["type", "isInvitedUser", "manuallyEnteredBillingAddress"], {
        is: (
          type: string,
          isInvitedUser: string,
          manuallyEnteredBillingAddress: boolean,
        ) =>
          (type === "personal" && !manuallyEnteredBillingAddress) ||
          (type === "business" &&
            !manuallyEnteredBillingAddress &&
            isInvitedUser === "false" &&
            !manuallyEnteredBillingAddress),
        then: yup.string().test(
          "is-valid-address",
          "Billing address is invalid",

          function (value) {
            const { from } = this as yup.TestContext & {
              /* eslint-disable  @typescript-eslint/no-explicit-any */
              from: any;
            };

            if (!value) {
              return false;
            }

            const formValues = from[0];

            if (formValues) {
              const billingAddressId = from[0].value.billingAddressId as string;
              return billingAddressId !== undefined && billingAddressId !== "";
            }

            return false;
          },
        ),
      }),
    manuallyEnteredBillingAddress: yup.boolean(),
    manualBillingAddress: yup.object().when("manuallyEnteredBillingAddress", {
      is: true,
      then: yup.object({
        addressString: yup
          .string()
          .noWhitespace("Street address / PO Box or Private Bag is invalid")
          .required("Street address / PO Box or Private Bag is required")
          .max(100, "Maximum length is 100")
          .min(5, "Street address / PO Box or Private Bag is invalid"),
        suburb: yup
          .string()
          .noWhitespace("Suburb is invalid")
          .max(50, "Maximum length is 50")
          .matches(
            ONLY_LETTERS_AND_SPACE_REG_EXP,
            "Suburb can only contain letters",
          ),
        city: yup
          .string()
          .noWhitespace("City is invalid")
          .required("City is required")
          .max(50, "Maximum length is 50")
          .matches(
            ONLY_LETTERS_AND_SPACE_REG_EXP,
            "City can only contain letters",
          ),
        postcode: yup
          .string()
          .noWhitespace("Postcode is invalid")
          .required("Postcode is required")
          .matches(ONLY_NUMBERS_REG_EXP, "Postcode can only contain numbers")
          .length(4, "Postcode should contain 4 numbers, please update"),
      }),
    }),
  }),
});
