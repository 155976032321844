import React, { useState } from "react";
import styled from "styled-components";
import { NewConnectionFormValues } from "../../types/NewConnectionFormValues";
import { IconServiceType } from "../IconServiceType";
import { Form, Formik, FormikHelpers } from "formik";
import { space, SpaceProps } from "styled-system";
import { Button } from "../Button";
import { Flex } from "../Flex";
import {
  handleScrollToId,
  scrollToTop,
} from "../../utils/handleScroll/HandleScroll";
import { NewConnectionOrderDetailsForm } from "./_/NewConnectionOrderDetailsForm";
import { NewConnectionReviewForm } from "./_/NewConnectionReviewForm";
import { NEW_CONNECTION_FORM_STEPS } from "../NewConnectionProgressStepper/NewConnectionProgressStepper";
import { mediaQueries } from "../../theme";
import { shouldGoToReview } from "./_/NewConnectionFormUtil/NewConnectionFormUtil";
import { NewConnectionOrderDetailsFormSchema } from "./NewConnectionOrderDetailsFormSchema";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { SomethingWentWrong } from "../SomethingWentWrong/SomethingWentWrong";

interface Props {
  onSubmit: (values: NewConnectionFormValues) => void;
  onStepChange: (activeStep: number) => void;
  activeStep: number;
  initialValues: NewConnectionFormValues;
  handleSave: (values: NewConnectionFormValues) => void;
}

const validationSchemas = [
  undefined,
  NewConnectionOrderDetailsFormSchema,
  undefined,
];

const FormWrapper = styled(Form)<SpaceProps>`
  ${space}
`;

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

export const NewConnectionForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onStepChange,
  activeStep,
  handleSave,
}) => {
  const [selectedTermsConditions, setSelectedTermsConditions] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const {
    authenticatedUserContext: { isLoggedIn },
  } = useUserAuthentication();

  const isLastStep = activeStep === NEW_CONNECTION_FORM_STEPS.length;
  const isSubmissionErrorStep =
    activeStep === NEW_CONNECTION_FORM_STEPS.length + 1;

  const submitForm = (values: NewConnectionFormValues) => {
    setShowLoading(true);
    onSubmit(values);

    setTimeout(() => {
      setShowLoading(false);
    }, 3000);
  };

  const handleOnSubmit = (
    values: NewConnectionFormValues,
    actions: FormikHelpers<NewConnectionFormValues>,
  ) => {
    let nextStep = 1;

    if (isLastStep || isSubmissionErrorStep) {
      submitForm(values);
    } else {
      // Reset touched fields to false
      // Formik automatically sets all fields as touched onSubmit
      // This falsy triggers error states for fields
      actions.setTouched({
        serviceType: false,
        projectType: false,
        subdivisionType: false,
        developmentDescription: false,
        site: {
          siteType: false,
          lotInformation: {
            lotNumber: false,
            dpNumber: false,
            streetNumber: false,
            streetName: false,
            suburb: false,
            postcode: false,
          },
          addressInformation: {
            streetAddress: false,
            additionalAddressInformation: false,
          },
          lotCount: false,
          lots: [
            {
              fuseAmp: false,
              phase: false,
              type: false,
            },
          ],
          additionalSiteRequirements: false,
        },
        sitePlans: [
          {
            fileName: false,
            md5: false,
          },
        ],
        contactDetails: [
          {
            firstName: false,
            lastName: false,
            email: false,
            mobile: false,
            alternatePhone: false,
            businessName: false,
            businessNumber: false,
            billingAddressDisplay: false,
            billingAddressId: false,
            manuallyEnteredBillingAddress: false,
            contactHasRole: false,
            manualBillingAddress: {
              addressString: false,
              city: false,
              suburb: false,
              postcode: false,
            },
            roles: {
              siteAccessContactRole: false,
              quotePayerRole: false,
            },
            workContactPreferences: {
              email: false,
              sms: false,
            },
            quoteContactPreferences: {
              email: false,
              sms: false,
            },
          },
        ],
        hasElectrician: false,
        hasLandOwner: false,
        optionalContactDetails: {
          firstName: false,
          lastName: false,
          email: false,
          mobile: false,
          alternatePhone: false,
          businessName: false,
          workContactPreferences: {
            email: false,
            sms: false,
          },
        },
        isComplexConnection: false,
        siteAccessContactRoleChecked: false,
        quotePayerRoleChecked: false,
        customerRoleChecked: false,
        consultantRoleChecked: false,
      });

      if (shouldGoToReview(values)) {
        nextStep = activeStep + 1;
        onStepChange(nextStep);

        // Scroll to top when user navigates back and forth on form
        scrollToTop();
      } else {
        setShowLoading(true);
      }
    }
  };

  const handleBack = () => {
    const nextStep = activeStep - 1;
    onStepChange(nextStep);
  };

  const handleReviewEdit = (elementId: string) => {
    const nextStep = activeStep - 1;
    onStepChange(nextStep);
    handleScrollToId(elementId);
  };

  const onSelectTermsConditions = () => {
    setSelectedTermsConditions((prevState) => !prevState);
  };

  // Will be covered by acceptance tests
  /* istanbul ignore next */
  const handleOnFilesStillUploading = (filesStillUploading: boolean) => {
    if (!filesStillUploading) {
      setShowLoading(false);
    }
  };

  return (
    <>
      {activeStep < 4 && (
        <IconServiceType
          title="Electricity connection"
          icon="flash_on"
          mt={[5, 5, 5, 5, 7]}
        />
      )}

      <Formik
        validateOnChange={false}
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        initialValues={initialValues!}
        validationSchema={validationSchemas[activeStep - 1]}
        onSubmit={handleOnSubmit}
      >
        {({ values, validateField }) => (
          <FormWrapper mb={8}>
            {activeStep === 2 && (
              <NewConnectionOrderDetailsForm
                id="new-connection-order-details-form"
                values={values}
                validateField={validateField}
                onFilesStillUploading={handleOnFilesStillUploading}
              />
            )}

            {activeStep === 3 && (
              <NewConnectionReviewForm
                values={values}
                onEdit={handleReviewEdit}
                selectedTermsConditions={selectedTermsConditions}
                onSelectTermsConditions={onSelectTermsConditions}
              />
            )}

            <Flex
              justifyContent={activeStep > 2 ? "space-between" : "flex-end"}
              mt={5}
            >
              {activeStep === 3 && (
                <Button
                  variant="secondary"
                  alignSelf="flex-start"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}

              {activeStep < 4 && (
                <Button
                  variant="secondary"
                  alignSelf="flex-start"
                  marginLeft="auto"
                  disabled={!isLoggedIn}
                  onClick={() => handleSave(values)}
                  mr="3"
                  px="6"
                  py="3"
                  data-testid={"save-button"}
                >
                  Save
                </Button>
              )}

              {activeStep < 4 && (
                <Button
                  type="submit"
                  disabled={
                    (isLastStep && !selectedTermsConditions) || showLoading
                  }
                  alignSelf="flex-end"
                  loading={showLoading}
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
              )}
            </Flex>

            {activeStep === 4 && (
              <FlexWrapper flexDirection="column">
                <SomethingWentWrong
                  subHeading="It looks like there was an error submitting your order."
                  message="Please try again in a few minutes"
                  onBackClick={handleBack}
                  onTryAgainClick={() => submitForm(values)}
                  tryAgainLoading={showLoading}
                  showTryAgainButton
                  showBackButton
                />
              </FlexWrapper>
            )}
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
