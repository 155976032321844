import React from "react";
import { ActionLink } from "../ActionLink";
import styled from "styled-components";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";
import { useHistory } from "react-router-dom";
import { useFormBackButtonText } from "../FormBackButtonUtil/FormBackButtonUtil";

const FlexWrapperTwo = styled(Flex)`
  display: flex;
  ${mediaQueries.extraLargeDevice} {
    position: fixed;
    z-index: 9999;
  }
`;

export const ProgressStepperBackButton: React.FC = () => {
  const [backLink, backText] = useFormBackButtonText();
  const history = useHistory();

  const handleBackClick = () => {
    history.push(`${backLink}`);
  };

  return (
    <FlexWrapperTwo pl={5} pt={5}>
      <ActionLink
        iconLeft={"keyboard_backspace"}
        onClick={handleBackClick}
        id={"back-button"}
        variant={"secondary"}
      >
        {backText}
      </ActionLink>
    </FlexWrapperTwo>
  );
};
