import React from "react";
import { MainTopBar } from "./MainTopBar";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { getUserInitials } from "../../utils/userDetailsHelper/userDetailsHelper";
import styled from "styled-components";
import { mediaQueries } from "../../theme";

interface Props {
  showUserLogo?: boolean;
  hasMobileNavMenu?: boolean;
  onMobileNavMenuClick?: () => void;
  showMobileNav?: boolean;
  userLogoDisabled?: boolean;
}

const Spacer = styled.div`
  ${mediaQueries.largeDevice} {
    padding-bottom: 5em;
  }
`;

export const MainTopBarContainer: React.FC<Props> = ({
  showUserLogo,
  hasMobileNavMenu,
  onMobileNavMenuClick,
  showMobileNav,
  userLogoDisabled,
}) => {
  const {
    authenticatedUserContext: { userBasicDetails },
  } = useUserAuthentication();

  let userInitials;
  let userFullName;
  let userEmail;

  if (
    userBasicDetails &&
    userBasicDetails.firstName &&
    userBasicDetails.lastName
  ) {
    const { firstName, lastName } = userBasicDetails;

    userInitials = getUserInitials(firstName, lastName);
    userFullName = "";
    userEmail = "";
  } else {
    userInitials = "G";
    userFullName = "Guest";
    userEmail = "Limited features";

    userLogoDisabled = true;
  }

  return (
    <Spacer>
      <MainTopBar
        userInitials={userInitials.toUpperCase()}
        userFullName={userFullName}
        userEmail={userEmail}
        showUserLogo={showUserLogo}
        hasMobileNavMenu={hasMobileNavMenu}
        onMobileNavMenuClick={onMobileNavMenuClick}
        showMobileNav={showMobileNav}
        userLogoDisabled={userLogoDisabled}
      />
    </Spacer>
  );
};

MainTopBarContainer.defaultProps = {
  showUserLogo: true,
  showMobileNav: false,
  userLogoDisabled: false,
};
