import React from "react";
import styled from "styled-components";
import { ProgressSteps } from "../../types/ProgressSteps";
import { DesktopProgressBar } from "../DesktopProgressBar";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";

const FlexWrapper = styled(Flex)`
  display: none;
  transform: translate(0, -35%);
  ${mediaQueries.extraLargeDevice} {
    display: flex;
  }
`;

export const ProgressStepperDesktop: React.FC<ProgressSteps> = ({
  ...props
}) => {
  return (
    <FlexWrapper
      position="fixed"
      top="40%"
      border="none"
      borderRight="2px solid"
      borderRightColor="neutrals.ln30"
      height="100%"
      width="max-content"
      minWidth="240px"
      pr={4}
    >
      <DesktopProgressBar {...props} />
    </FlexWrapper>
  );
};
