import React from "react";
import styled from "styled-components";
import { ProgressSteps } from "../../types/ProgressSteps";
import { MobileProgressBar } from "../MobileProgressBar";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";

const ShowOnMobile = styled(Flex)`
  display: flex;
  ${mediaQueries.extraLargeDevice} {
    display: none;
  }
`;

export const ProgressStepperMobile: React.FC<ProgressSteps> = ({
  ...props
}) => {
  return (
    <ShowOnMobile mt={3} ml={3}>
      <MobileProgressBar {...props} />
    </ShowOnMobile>
  );
};
