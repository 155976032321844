import { ErrorMessage, Field, FieldArrayRenderProps } from "formik";
import React, { useEffect, useState } from "react";
import { NewConnectionContactDetailsValues } from "../../../../types/NewConnectionContactDetailsValues";
import { ContactDetailFields } from "../../../ContactDetailsForm/ContactDetailFields";
import { ContactDetailsContactPreferences } from "../../../ContactDetailsForm/ContactDetailsContactPreferences";
import { getNewConnectionRolesTitle } from "../../../ContactDetailsRolesTitle/ContactDetailsRolesTitle";
import { Flex } from "../../../Flex";
import { IconPopupTip } from "../../../IconPopupTip";
import { Line } from "../../../Line";
import { Span } from "../../../Span";
import { NewConnectionRoleSelector } from "../NewConnectionRoleSelector";
import { ActionButton } from "../../../ActionButton";
import { MOBILE_REG_EXP } from "../../../../constants/ContactNumbersRegex";
import { getOptionalContactDetailsFormFields } from "../NewConnectionOptionalFormFieldsUtil/NewConnectionOptionalFormFieldsUtil";
import {
  HasElectricianTypes,
  HasLandOwnerTypes,
} from "../../../../constants/HasOptionalRoleValues";
import { isRequesterAndLoggedIn } from "../../../FormContactDetailsFieldsUtil/FormContactDetailsFieldsUtil";
import { FormikInputText } from "../../../FormikInputText";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import {
  checkIsManualAddress,
  checkIsSearchedAddress,
} from "../../../ManualAddressHelperUtil/ManualAddressHelperUtil";
import { BillingAddressSection } from "../../../BillingAddressSection";

interface Props {
  newConnectionType: NewConnectionType;
  contactDetails: NewConnectionContactDetailsValues[];
  contactDetailsIndex: number;
  roleSelectorPosition?: "top" | "bottom";
  fieldsVisible?: boolean;
  focusable?: boolean;
  hasElectrician: HasElectricianTypes;
  hasLandOwner: HasLandOwnerTypes;
  index: number;
  showRemoveButton?: boolean;
  arrayHelpers?: FieldArrayRenderProps;
  validateField: (field: string) => void;
}

export const NewConnectionContactDetailFields: React.FC<Props> = ({
  newConnectionType,
  contactDetails,
  contactDetailsIndex,
  roleSelectorPosition,
  focusable,
  hasElectrician,
  hasLandOwner,
  index,
  showRemoveButton,
  arrayHelpers,
  validateField,
}) => {
  const {
    authenticatedUserContext: { isLoggedIn, userBasicDetails },
  } = useUserAuthentication();
  const { userType } = userBasicDetails;

  const [isManualAddress, setIsManualAddress] = useState(false);

  const currentContactDetails = contactDetails[contactDetailsIndex];
  const currentContactDetailsRoles = currentContactDetails.roles;

  const allContactDetailsRoles = contactDetails.map((details) => details.roles);

  if (newConnectionType === "simple") {
    if (contactDetailsIndex === 0) {
      currentContactDetailsRoles.requester = true;
    }

    if (currentContactDetailsRoles.requester) {
      currentContactDetails.workContactPreferences.email = true;
    }

    if (currentContactDetailsRoles.quotePayerRole) {
      currentContactDetails.quoteContactPreferences.email = true;
    }
  }

  const optionalContactDetailsFields = getOptionalContactDetailsFormFields(
    currentContactDetailsRoles,
    contactDetailsIndex,
  );

  const showPostalAddress = currentContactDetailsRoles.quotePayerRole;

  const showWorkUpdateContactPreferences =
    currentContactDetailsRoles.requester ||
    currentContactDetailsRoles.siteAccessContactRole ||
    currentContactDetailsRoles.quotePayerRole;

  const showQuoteUpdateContactPreferences =
    currentContactDetailsRoles.quotePayerRole;

  const showContactPreferencesSelector =
    showWorkUpdateContactPreferences || showQuoteUpdateContactPreferences;

  const showBusinessNumber = !(
    currentContactDetailsRoles.consultantRole || userType === "personal"
  );
  const showBusinessName = userType === "business";

  const shouldDisableWorkSmsPreference =
    !currentContactDetails.mobile ||
    !MOBILE_REG_EXP.test(currentContactDetails.mobile);

  const shouldDisableQuoteSmsPreference =
    !currentContactDetails.mobile ||
    !MOBILE_REG_EXP.test(currentContactDetails.mobile);

  const shouldDisableWorkEmailPreference =
    currentContactDetailsRoles.requester || !currentContactDetails.email;

  const title = getNewConnectionRolesTitle(
    currentContactDetailsRoles,
    contactDetailsIndex === 0,
    hasElectrician,
    hasLandOwner,
  );

  const shouldDisableContactDetails = isRequesterAndLoggedIn(
    isLoggedIn,
    contactDetailsIndex,
  );

  useEffect(() => {
    if (currentContactDetails.manuallyEnteredBillingAddress) {
      setIsManualAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //the address is manual entered
    if (
      checkIsManualAddress(
        isManualAddress,
        currentContactDetails.manuallyEnteredBillingAddress,
      )
    ) {
      currentContactDetails.billingAddressDisplay = "";
      currentContactDetails.billingAddressId = "";
    }

    //the address is searched
    if (
      checkIsSearchedAddress(
        isManualAddress,
        currentContactDetails.manuallyEnteredBillingAddress,
      )
    ) {
      currentContactDetails.manualBillingAddress.addressString = "";
      currentContactDetails.manualBillingAddress.city = "";
      currentContactDetails.manualBillingAddress.suburb = "";
      currentContactDetails.manualBillingAddress.postcode = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualAddress]);

  const handleContactClearClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    contactIndex: number,
    arrayHelpers?: FieldArrayRenderProps,
  ) => {
    arrayHelpers && arrayHelpers.remove(contactIndex);
  };

  const handleActionLinkClick = () => {
    // Manually trigger Formik field validation for street address because action link has no blur
    validateField(
      `contactDetails[${contactDetailsIndex}].billingAddressDisplay`,
    );

    // Toggle manuallyEnteredAddress in Formik values to manage field validation
    currentContactDetails.manuallyEnteredBillingAddress =
      !currentContactDetails.manuallyEnteredBillingAddress;

    setIsManualAddress((prevState) => !prevState);
  };

  return (
    <Flex flexDirection="column">
      <Flex
        alignItems="center"
        mb={4}
        justifyContent={[
          "space-between",
          "space-between",
          "space-between",
          "flex-start",
        ]}
      >
        <Span fontWeight="bold">{title}</Span>

        {showRemoveButton && (
          <ActionButton
            data-testid="remove-contact-button"
            onClick={(e) => handleContactClearClick(e, index, arrayHelpers)}
            icon="clear"
            ml={2}
          />
        )}
      </Flex>
      {/* Role selector (top)  */}
      {roleSelectorPosition === "top" && (
        <NewConnectionRoleSelector
          contactDetailsRoles={allContactDetailsRoles}
          contactDetailsIndex={contactDetailsIndex}
          siteAccessContactRole={newConnectionType === "simple"}
          quotePayerRole={newConnectionType === "simple"}
          customerRole={newConnectionType === "complex"}
          consultantRole={newConnectionType === "complex"}
          newConnectionType={newConnectionType}
          mb={4}
        />
      )}

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        data-testid="contact-details-fields-wrapper"
      >
        {/*{section}*/}
        <ContactDetailFields
          focusable={focusable}
          idPrefix={`contactDetails[${contactDetailsIndex}]`}
          namePrefix={`contactDetails[${contactDetailsIndex}]`}
          optionalFirstName={optionalContactDetailsFields.optionalFirstName}
          optionalLastName={optionalContactDetailsFields.optionalLastName}
          optionalEmail={optionalContactDetailsFields.optionalEmail}
          optionalMobile={optionalContactDetailsFields.optionalMobile}
          optionalBusinessName={
            optionalContactDetailsFields.optionalBusinessName
          }
          disabledFirstName={shouldDisableContactDetails}
          disabledLastName={shouldDisableContactDetails}
          disabledEmail={shouldDisableContactDetails}
          disabledMobile={shouldDisableContactDetails}
          disabledAlternatePhone={shouldDisableContactDetails}
          disabledBusinessName={shouldDisableContactDetails}
          showBusinessName={showBusinessName}
          optionalAlternatePhone
        />

        {/* Business number  */}
        {showBusinessNumber && (
          <Flex flexDirection="column" mb={4}>
            <Field
              id={`contactDetails[${contactDetailsIndex}].businessNumber`}
              name={`contactDetails[${contactDetailsIndex}].businessNumber`}
              label="NZBN (New Zealand Business Number)"
              flexGrow={1}
              focusable={focusable}
              optional
              maxLength={13}
              component={FormikInputText}
              disabled={shouldDisableContactDetails}
            />
            <ErrorMessage
              name={`contactDetails[${contactDetailsIndex}].businessNumber`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>
        )}

        {/* NZ postal address  */}
        {showPostalAddress && (
          <BillingAddressSection
            fieldIdPrefix={`contactDetails[${contactDetailsIndex}]`}
            fieldNamePrefix={`contactDetails[${contactDetailsIndex}]`}
            shouldDisableContactDetails={shouldDisableContactDetails}
            isManualAddress={isManualAddress}
            validateField={validateField}
            handleActionLinkClick={handleActionLinkClick}
          />
        )}

        {/* Role selector (bottom)  */}
        {roleSelectorPosition === "bottom" && (
          <NewConnectionRoleSelector
            contactDetailsRoles={allContactDetailsRoles}
            contactDetailsIndex={contactDetailsIndex}
            siteAccessContactRole={newConnectionType === "simple"}
            quotePayerRole={newConnectionType === "simple"}
            customerRole={newConnectionType === "complex"}
            consultantRole={newConnectionType === "complex"}
            newConnectionType={newConnectionType}
            mb={5}
          />
        )}

        {/* Contact preferences selector */}
        {showContactPreferencesSelector && (
          <Flex flexDirection="column">
            <Flex flexDirection="row" mb={3}>
              <Span fontSize={2} lineHeight={2} mr={3}>
                {"Different roles might have different contact preferences. " +
                  "Identify whether they want to be notified via email and/or SMS"}
              </Span>
              <IconPopupTip icon="help">
                Select contact preferences
              </IconPopupTip>
            </Flex>

            {showQuoteUpdateContactPreferences && (
              <ContactDetailsContactPreferences
                id={`contactDetails[${contactDetailsIndex}].quoteContactPreferences`}
                name={`contactDetails[${contactDetailsIndex}].quoteContactPreferences`}
                title="Quote update:"
                emailLabel="Email"
                smsLabel="SMS"
                emailDisabled
                smsDisabled={shouldDisableQuoteSmsPreference}
                focusable={focusable}
                mb={5}
              />
            )}

            {showWorkUpdateContactPreferences && (
              <ContactDetailsContactPreferences
                id={`contactDetails[${contactDetailsIndex}].workContactPreferences`}
                name={`contactDetails[${contactDetailsIndex}].workContactPreferences`}
                title="Work update:"
                emailLabel="Email"
                smsLabel="SMS"
                emailDisabled={shouldDisableWorkEmailPreference}
                smsDisabled={shouldDisableWorkSmsPreference}
                focusable={focusable}
              />
            )}
          </Flex>
        )}
      </Flex>

      <Line
        width="100%"
        my={5}
        zIndex={0}
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
      />
    </Flex>
  );
};

NewConnectionContactDetailFields.defaultProps = {
  roleSelectorPosition: "top",
  fieldsVisible: false,
  focusable: true,
};
