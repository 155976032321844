export const mapStringToOrderStatus = (status: string): OrderProgressStatus => {
  switch (status.trim().toLowerCase()) {
    case "in progress":
      return "in progress";
    case "on hold":
      return "on hold";
    case "draft":
      return "draft";
    case "cancelled":
      return "cancelled";
    case "completed":
      return "completed";
    default:
      return "in progress";
  }
};
