import { ColorProps, FlexboxProps, LayoutProps } from "styled-system";
import { FieldProps, getIn } from "formik";
import React, { TextareaHTMLAttributes } from "react";
import { TextBox } from "../TextBox";
import { TextBoxProps } from "../TextBox/TextBox";

export const FormikTextBox: React.FC<
  FieldProps &
    FlexboxProps &
    TextBoxProps &
    ColorProps &
    LayoutProps &
    TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <TextBox {...field} error={touch && error ? error : false} {...props} />
  );
};
