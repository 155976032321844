import * as yup from "yup";
import {
  ALTERNATE_PHONE_REG_EXP,
  MOBILE_REG_EXP,
} from "../../constants/ContactNumbersRegex";
import { ONLY_NUMBERS_REG_EXP } from "../../constants/FormRegex";
import { noWhitespaceValidation } from "../../utils/noWhitespaceValidation/noWhitespaceValidation";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noWhitespace",
  noWhitespaceValidation,
);

export const AccountDetailsFormSchema = yup.object({
  profile: yup.object().shape({
    type: yup.string().required("Account type is required"),
    firstName: yup
      .string()
      .noWhitespace("First name is invalid")
      .max(50, "Maximum length is 50")
      .required("First name is required"),
    lastName: yup
      .string()
      .noWhitespace("Last name is invalid")
      .max(50, "Maximum length is 50")
      .required("Last name is required"),
    mobile: yup
      .string()
      .matches(MOBILE_REG_EXP, "Enter a valid phone number")
      .required("Mobile is required"),
    alternatePhone: yup
      .string()
      .matches(ALTERNATE_PHONE_REG_EXP, "Enter a valid alternate phone number"),
    businessName: yup.string().when("type", {
      is: (type: string) => type === "business",
      then: yup
        .string()
        .noWhitespace("Business name is invalid")
        .max(100, "Maximum length is 100 characters")
        .required("Business name is required"),
      otherwise: yup
        .string()
        .noWhitespace("Business name is invalid")
        .max(100, "Maximum length is 100 characters"),
    }),
    companyNzbn: yup.string().when("type", {
      is: (type: string) => type === "business",
      then: yup
        .string()
        .matches(ONLY_NUMBERS_REG_EXP, "NZBN can only contain numbers")
        .min(13, "Your NZBN should contain 13 numbers, please update"),
    }),
    billingAddressDisplay: yup.string(),
  }),
});
