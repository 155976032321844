import React from "react";
import { HelpCentrePageContent } from "../../types/HelpCentrePageContent";
import { HelpCentrePage } from "./HelpCentrePage";

export const EMPTY_HELP_CENTRE_PAGE_CONTENT: HelpCentrePageContent = {
  tabTitle: "",
};

export const HelpCentrePageContainer: React.FC = () => {
  return <HelpCentrePage {...EMPTY_HELP_CENTRE_PAGE_CONTENT} />;
};
