import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

interface Props {
  icon: string; //https://fonts.google.com/icons search for rounded
}

const Component = styled.span<
  ColorProps &
    BorderProps &
    PositionProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    HTMLAttributes<HTMLSpanElement>
>`
  ${border}
  ${color}
  ${position}
  ${space}
  ${typography}
  ${layout}
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
`;

export const Icon: React.FC<
  Props &
    BorderProps &
    ColorProps &
    PositionProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    HTMLAttributes<HTMLSpanElement>
> = ({ icon, ...props }) => <Component {...props}>{icon}</Component>;

Icon.defaultProps = {
  color: "primary",
  fontFamily: "Material Icons",
  fontSize: 5,
  lineHeight: "body",
  verticalAlign: "middle",
  fontWeight: 400,
};
