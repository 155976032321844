import React from "react";
import styled from "styled-components";
import {
  getAddressInformation,
  getSiteLotInformation,
} from "../FormattedAddressUtil/FormattedAddressUtil";
import { Flex } from "../../../../../Flex";
import { Line } from "../../../../../Line";
import { Span } from "../../../../../Span";
import { ReviewFormDesktopMeteredUnits } from "../ReviewFormDesktopMeteredUnits";
import { LiveningIcpSite } from "../../../../../../types/LiveningIcpSite";
import { ReviewFormMobileMeteredUnits } from "../ReviewFormMobileMeteredUnits";
import { Box } from "../../../../../Box";
import { ReviewUploadsFormMeteredDetails } from "../ReviewUploadsFormMeteredDetails";

interface Props {
  sites: LiveningIcpSite[];
}

const SiteWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const ReviewFormMeteredSiteDetails: React.FC<Props> = ({ sites }) => {
  return (
    <>
      {sites.map((site, index) => {
        const formattedAddress =
          site.siteType === "lotAndDpNumber"
            ? getSiteLotInformation(site.lotInformation)
            : site.siteType === "streetNumberAndName"
            ? getAddressInformation(site.addressInformation)
            : "N/A";

        return (
          <Box key={index}>
            <SiteWrapper
              key={index}
              flexDirection="column"
              borderRadius="radio"
              padding={[3, 3, 3, 4]}
              mb={4}
            >
              <Span
                data-testid={`review-form-site-${index + 1}-title`}
                fontSize={4}
                lineHeight={4}
                fontWeight="medium"
              >{`Site ${index + 1}`}</Span>

              <Line
                width="100%"
                border="1px solid"
                borderColor="neutrals.ln30"
                bg="neutrals.ln30"
                opacity="1"
                height={0}
                mt={[3, 3, 3, 4]}
                mb={[2, 2, 2, 5]}
              />

              <Span
                data-testid={`review-form-site-${index + 1}-streetaddress`}
                fontWeight="medium"
                lineHeight={3}
                mb={[3, 3, 3, 5]}
              >
                {formattedAddress}
              </Span>

              {site.uploadProofOfAddresses.length !== 0 && (
                <ReviewUploadsFormMeteredDetails
                  uploads={site.uploadProofOfAddresses}
                  uploadTitle="Proof of address"
                  data-testid={`review-upload-proof-of-address-site-${
                    index + 1
                  }`}
                />
              )}

              {site.unitType === "upload" && (
                <ReviewUploadsFormMeteredDetails
                  uploads={site.uploadUnits}
                  uploadTitle="Fuse and phase information"
                  data-testid={`review-upload-units-site-${index + 1}`}
                />
              )}

              {site.unitType === "manual" && (
                <>
                  <ReviewFormDesktopMeteredUnits units={site.manualUnits} />
                  <ReviewFormMobileMeteredUnits units={site.manualUnits} />
                </>
              )}
            </SiteWrapper>
            {/*)}*/}
          </Box>
        );
      })}
    </>
  );
};
