import React from "react";
import { Flex } from "../Flex";
import { Title } from "../Title";
import { Span } from "../Span";
import { Link } from "../Link";
import { Icon } from "../Icon";
import { Box } from "../Box";

interface Props {
  title: string;
  description: string;
  helpItemsWithoutLinks?: string[];
  helpItemsWithLinks?: HelpItemLink[];
}

interface HelpItemLink {
  url: string;
  description: string;
}

export const CantFindWhatYouAreLookingFor: React.FC<Props> = ({
  title,
  description,
  helpItemsWithoutLinks,
  helpItemsWithLinks,
}) => {
  return (
    <Flex flexDirection="column" bg="hydro10" borderRadius="radio" p={4} mb={7}>
      <Title mt={0} fontSize={5} lineHeight={5} fontWeight="medium">
        {title}
      </Title>

      <Span
        fontSize={2}
        lineHeight={2}
        fontWeight="medium"
        my={1}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <Flex mt={2} flexDirection="column">
        {helpItemsWithoutLinks &&
          helpItemsWithoutLinks.map((value, index) => (
            <Box my={1} key={index}>
              <Span lineHeight={2}>{value}</Span>
            </Box>
          ))}

        {helpItemsWithLinks &&
          helpItemsWithLinks.map((value, index) => (
            <Box my={1} key={index}>
              <Link href={value.url}>
                {value.description}{" "}
                <Icon fontSize={2} lineHeight={2} icon="open_in_new" />
              </Link>
            </Box>
          ))}
      </Flex>
    </Flex>
  );
};
