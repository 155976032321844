import React from "react";
import { Title } from "../../../Title";
import { Line } from "../../../Line";
import { Flex } from "../../../Flex";
import { Button } from "../../../Button";
import { Span } from "../../../Span";
import { useHistory } from "react-router-dom";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

export const PaymentSuccess: React.FC = () => {
  const history = useHistory();

  const {
    authenticatedUserContext: { isLoggedIn },
  } = useUserAuthentication();
  const buttonText = isLoggedIn ? "Go to Orders" : "Return to vector.co.nz";

  const handleOnGoToClick = () => {
    if (isLoggedIn) {
      history.push("/order-details-list");
    } else {
      window.location.replace("https://www.vector.co.nz/");
    }
  };

  return (
    <>
      <Title as="h1" mb={5} lineHeight={6} fontWeight="500">
        Payment Confirmed
      </Title>

      <Span mb={1}>Thank you for your payment.</Span>
      <Span>We will continue working on your order.</Span>

      {!isLoggedIn && (
        <Span mt={4}>
          Please close this page or click on the below button to return to
          vector.co.nz.
        </Span>
      )}

      <Line
        width="100%"
        my={5}
        zIndex={0}
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
      />

      <Flex>
        <Button onClick={handleOnGoToClick}>{buttonText}</Button>
      </Flex>
    </>
  );
};
