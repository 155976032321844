import React from "react";
import styled from "styled-components";
import { Box } from "../../../Box";
import { Flex } from "../../../Flex";
import { Line } from "../../../Line";
import { Span } from "../../../Span";

interface Props {
  siteIndex?: number;
  siteAddress: string;
  jobCategory: string;
}

const LabelWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;

export const NewConnectionThankYouOrder: React.FC<Props> = ({
  siteIndex,
  siteAddress,
  jobCategory,
}) => {
  return (
    <Box key={siteIndex}>
      <Flex flexDirection="row" my={3}>
        <LabelWrapper
          data-testid={
            siteIndex
              ? `thank-you-site-${siteIndex}-sitenumber`
              : "thank-you-site-sitenumber"
          }
          lineHeight={2}
          color="neutrals.dn40"
          flexGrow={[0.5, 0.5, 0.5, 0.37, 0.37]}
        >
          {siteIndex ? `Site ${siteIndex}` : "Site"}
        </LabelWrapper>

        <ValueWrapper
          data-testid={
            siteIndex
              ? `thank-you-site-${siteIndex}-streetaddress`
              : "thank-you-site-streetaddress"
          }
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.63, 0.63]}
        >
          {siteAddress}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.37, 0.37]}>
          Type of service
        </LabelWrapper>

        <ValueWrapper
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.63, 0.63]}
          data-testid={
            siteIndex
              ? `thank-you-site-${siteIndex}-jobCategory`
              : "thank-you-site-jobCategory"
          }
        >
          {jobCategory}
        </ValueWrapper>
      </Flex>

      <Line
        width="100%"
        mt={3}
        zIndex={0}
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
      />
    </Box>
  );
};
