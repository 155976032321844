import React from "react";
import { UnmeteredFeatureType } from "../../../../constants/Unmetered";
import { Flex } from "../../../Flex";
import { AvailableHours } from "../AvailableHours";
import { FeatureType } from "../FeatureType";
import { Quantity } from "../Quantity";
import { Wattage } from "../Wattage";

interface Props {
  featureType: UnmeteredFeatureType;
}

export const FeatureTypeNonOtherLayout: React.FC<Props> = ({ featureType }) => {
  return (
    <Flex
      flexDirection={["column", "column", "column", "row"]}
      alignItems={"flex-start"}
    >
      <FeatureType />
      <Quantity />
      <Wattage featureType={featureType} />
      <AvailableHours featureType={featureType} />
    </Flex>
  );
};
