import React, { InputHTMLAttributes, RefObject } from "react";
import styled, { css } from "styled-components";
import { Flex } from "../Flex";
import { FlexGrowProps, SpaceProps, variant } from "styled-system";
import { Label } from "../Label";
import { Text } from "../Text";

export interface RadioButtonDescriptiveProps {
  label: string;
  description: string;
  currentValue?: string | number;
}

const FlexContainer = styled(Flex)(
  {
    boxSizing: "border-box",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  variant({
    scale: "radioButtonDescriptive",
    variants: {
      primary: {
        borderRadius: "radio",
        border: "2px solid",
        borderColor: "neutrals.ln40",
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
        },
        "&:hover": {
          bg: "hydro10",
        },
      },
    },
  }),
);

const LabelWrapper = styled(Label)`
  cursor: pointer;
`;

const TextWrapper = styled(Text)`
  cursor: pointer;
  margin-block-start: 0;
  margin-block-end: 0;
`;

const Input = styled.input`
  display: none;
`;

const Container = styled(FlexContainer)`
  border-color: ${({ checked, theme }) => checked && theme.colors.hydroDark};
  background-color: ${({ checked, theme }) => checked && theme.colors.hydro10};
  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: none;
      pointer-events: none;
      background-color: ${theme.colors.neutrals.ln20};
      & > ${LabelWrapper} {
        cursor: none;
        pointer-events: none;
        color: ${theme.colors.neutrals.mn40};
      }
      & > ${TextWrapper} {
        cursor: none;
        pointer-events: none;
        color: ${theme.colors.neutrals.mn40};
      }
    `}
`;

export const RadioButtonDescriptive: React.FC<
  RadioButtonDescriptiveProps &
    FlexGrowProps &
    SpaceProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({
  id,
  label,
  description,
  currentValue,
  value,
  disabled,
  tabIndex,
  flexGrow,
  ml,
  mr,
  mb,
  ...props
}) => {
  const inputRef: RefObject<HTMLInputElement> = React.useRef(null);
  const checked = currentValue === value;

  const handleOnClick = () => {
    /* istanbul ignore next */
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  };

  return (
    <Container
      variant="primary"
      checked={checked}
      onClick={handleOnClick}
      disabled={disabled}
      tabIndex={tabIndex}
      flexGrow={flexGrow}
      aria-labelledby={id}
      p={4}
      ml={ml}
      mr={mr}
      mb={mb}
    >
      <Input
        {...props}
        id={id}
        value={value}
        checked={checked}
        aria-checked={checked}
        ref={inputRef}
        disabled={disabled}
        type="radio"
      />
      <LabelWrapper
        fontSize={4}
        lineHeight={4}
        id={id}
        htmlFor={id}
        mb={description ? 3 : 0}
        textAlign="left"
      >
        {label}
      </LabelWrapper>
      <TextWrapper
        fontSize={1}
        lineHeight={1}
        textAlign="left"
        color="neutrals.dn40"
      >
        {description}
      </TextWrapper>
    </Container>
  );
};

RadioButtonDescriptive.defaultProps = {
  disabled: false,
};
