import React, { useEffect, useReducer } from "react";
import styled from "styled-components";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { SpaceProps } from "styled-system";
import {
  getFileNameWithoutExtension,
  getFileExtension,
} from "../FileUtil/FileUtil";
import {
  getFileScanResult,
  uploadFile,
} from "../../../../services/FileUploadService";
import { UploadFileStatus } from "../UploadFileStatus";
import { reducer } from "../UploadReducer/UploadReducer";
import { FILE_NAME_REG_EXP } from "../../../../constants/FileUploadNameRegex";

interface Props {
  id: string;
  file: CssFile;
  onDelete: (file: CssFile) => void;
  onUploadScanComplete: (file: CssFile) => void;
  attachmentType: string;
}

const FlexWrapper = styled(Flex)<{ error: boolean }>`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};

  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.urgentRed : theme.colors.neutrals.ln30};
`;

const FileNameWrapper = styled(Span)`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileExtensionWrapper = styled(Span)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.neutrals.mn80};
  border-radius: 3px;
`;

export const UploadFileItem: React.FC<Props & SpaceProps> = ({
  id,
  file,
  onDelete,
  onUploadScanComplete,
  mt,
  attachmentType,
}) => {
  const [uploadState, dispatch] = useReducer(reducer, {
    status: "",
    md5: "",
  });

  const uploadFileAsync = async (file: CssFile) => {
    let dispatchType: UploadActionType = "UPLOAD_FAILED";
    let dispatchPayload: UploadActionPayload = { md5: "" };

    try {
      const response = await uploadFile(file, attachmentType);

      dispatchType =
        response && response.name && response.md5
          ? "GET_SCAN_RESULT"
          : "UPLOAD_FAILED";

      if (response && response.name && response.md5) {
        file.uploaded = true;
        file.md5 = response.md5;
        dispatchPayload = { md5: response.md5 };
      }
    } catch (error: any) {
      file.uploaded = false;
      dispatchType = (getFileNameWithoutExtension(file.name).match(FILE_NAME_REG_EXP)) ? "UPLOAD_FAILED" : "UPLOAD_NAME_FAILED";
    }

    dispatch({
      type: dispatchType,
      payload: dispatchPayload,
    });
  };

  const getFileScanResultAsync = async (md5: string) => {
    const dispatchPayload: UploadActionPayload = { md5 };
    let dispatchType: UploadActionType = "SCAN_FAILED";

    try {
      const response = await getFileScanResult(md5, attachmentType);

      dispatchType =
        response && response.scanResult === "ok" ? "COMPLETE" : "SCAN_FAILED";

      if (response.scanResult === "ok") {
        file.scanned = true;
        onUploadScanComplete(file);
      }
    } catch (error: any) {
      file.scanned = false;
      dispatchType = "SCAN_FAILED";
    }

    dispatch({
      type: dispatchType,
      payload: dispatchPayload,
    });
  };

  const handleOnDelete = () => {
    onDelete(file);
  };

  const handleRetry = () => {
    dispatch({
      type: "UPLOAD",
    });

    uploadFileAsync(file);
  };

  // Check file scan result based on upload/scan status
  useEffect(() => {
    if (!file.scanned && uploadState.status === "SCANNING" && uploadState.md5) {
      getFileScanResultAsync(uploadState.md5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadState]);

  // Upload file on component mount
  useEffect(() => {
    if (!file.uploaded) {
      dispatch({
        type: "UPLOAD",
      });

      uploadFileAsync(file);
    }

    if (file.scanned) {
      dispatch({
        type: "COMPLETE",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" id={id}>
      <FlexWrapper
        flexDirection={
          uploadState.status === "COMPLETE"
            ? "row"
            : ["column", "column", "row"]
        }
        alignItems={["flex-start", "flex-start", "center"]}
        padding={3}
        mt={mt}
        error={
          uploadState.status === "UPLOAD_FAILED" ||
          uploadState.status === "SCAN_FAILED" ||
          uploadState.status === "UPLOAD_NAME_FAILED"
        }
      >
        <Flex>
          <FileNameWrapper
            data-testid="upload-item-file-name"
            lineHeight={2}
            mr={3}
            maxWidth={["100px", "100px", "300px", "fit-content"]}
          >
            {getFileNameWithoutExtension(file.name)}
          </FileNameWrapper>

          <FileExtensionWrapper
            data-testid="upload-item-file-type"
            lineHeight={2}
            px={1}
            mr={[0, 0, 7]}
          >
            {getFileExtension(file.name)}
          </FileExtensionWrapper>
        </Flex>

        <UploadFileStatus
          mt={[2, 2, 0]}
          ml={uploadState.status === "COMPLETE" ? "auto" : [0, 0, "auto"]}
          uploadStatus={uploadState.status}
          onDelete={handleOnDelete}
          onRetry={handleRetry}
        />
      </FlexWrapper>

      {uploadState.status === "SCAN_FAILED" && (
        <Span color="urgentRed" fontSize={1} lineHeight={1} ml={1} mt={1}>
          Try uploading a new file
        </Span>
      )}
    </Flex>
  );
};
