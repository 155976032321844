import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

export const useFormBackButtonText = (): string[] => {
  const {
    authenticatedUserContext: { isLoggedIn },
  } = useUserAuthentication();

  if (isLoggedIn) {
    return ["/order-details-list", "Back to my orders"];
  }

  return ["/service-type", "Back to new order"];
};
