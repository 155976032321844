import React from "react";
import styled from "styled-components";
import { ContactPreferences } from "../../types/ContactDetailsValues";
import { getContactPreferencesDisplay } from "../ContactPreferencesUtil/ContactPreferencesUtil";
import { Flex } from "../Flex";
import { Span } from "../Span";
import { UserLogo } from "../UserLogo";
import { getUserInitialsWithMultipleNames } from "../../utils/userDetailsHelper/userDetailsHelper";

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  alternatePhone?: string;
  businessName?: string;
  businessNumber?: string;
  billingAddress?: string;
  workContactPreferences?: ContactPreferences;
  quoteContactPreferences?: ContactPreferences;
}

const SpanWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.mn80};
`;

export const ContactDetailsDisplay: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  mobile,
  alternatePhone,
  businessName,
  businessNumber,
  billingAddress,
  workContactPreferences,
  quoteContactPreferences,
}) => {
  const initials = getUserInitialsWithMultipleNames(firstName, lastName);
  const fullName = `${firstName ?? ""} ${lastName ?? ""}`;
  const workContactPreferencesDisplay = getContactPreferencesDisplay(
    "site",
    workContactPreferences,
  );
  const quoteContactPreferencesDisplay = getContactPreferencesDisplay(
    "quote",
    quoteContactPreferences,
  );

  /* istanbul ignore next */
  const hasContactDetails =
    mobile ||
    alternatePhone ||
    businessName ||
    businessNumber ||
    billingAddress ||
    workContactPreferencesDisplay ||
    quoteContactPreferencesDisplay;

  return (
    <Flex flexDirection="column" mb={5}>
      <Flex mb={/* istanbul ignore next */ hasContactDetails ? 3 : 0}>
        <UserLogo
          userInitials={initials}
          userFullName={fullName}
          userEmail={email}
        />
      </Flex>

      {mobile && (
        <SpanWrapper
          lineHeight={2}
          mb={2}
          ml={6}
          pl={2}
          data-testid="contact-details-mobile"
        >
          {mobile}
        </SpanWrapper>
      )}

      {alternatePhone && (
        <SpanWrapper
          lineHeight={2}
          mb={2}
          ml={6}
          pl={2}
          data-testid="contact-details-alternate-phone"
        >
          {alternatePhone}
        </SpanWrapper>
      )}

      {businessName && (
        <SpanWrapper
          lineHeight={2}
          mb={2}
          ml={6}
          pl={2}
          data-testid="contact-details-business-name"
        >
          {businessName}
        </SpanWrapper>
      )}

      {businessNumber && (
        <SpanWrapper
          lineHeight={2}
          mb={2}
          ml={6}
          pl={2}
          data-testid="contact-details-business-number"
        >
          {`NZBN: ${businessNumber}`}
        </SpanWrapper>
      )}

      {billingAddress && (
        <SpanWrapper
          lineHeight={2}
          mb={2}
          ml={6}
          pl={2}
          data-testid="contact-details-billing-address"
        >
          {billingAddress}
        </SpanWrapper>
      )}

      {workContactPreferencesDisplay && (
        <SpanWrapper
          lineHeight={2}
          ml={6}
          pl={2}
          data-testid="contact-details-work-preferences"
        >
          {workContactPreferencesDisplay}
        </SpanWrapper>
      )}

      {quoteContactPreferencesDisplay && (
        <SpanWrapper
          lineHeight={2}
          ml={6}
          pl={2}
          data-testid="contact-details-quote-preferences"
        >
          {quoteContactPreferencesDisplay}
        </SpanWrapper>
      )}
    </Flex>
  );
};
