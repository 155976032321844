interface SessionStorageFunctions {
  getItem<T extends object>(key: SessionStorageKey): T | null;
  removeItem(key: SessionStorageKey): void;
}

export type SessionStorageKey = "ecssQueryCache";

export const sessionStorageWrapper: SessionStorageFunctions = {
  getItem<T>(key: SessionStorageKey): T | null {
    const value = sessionStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value) as T;
      } catch (error) {
        return value as unknown as T;
      }
    }

    return null;
  },

  removeItem(key: SessionStorageKey): void {
    sessionStorage.removeItem(key);
  },
};
