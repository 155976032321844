import { User } from "@auth0/auth0-react";
import moment from "moment/moment";

const AUTH0_META_DATA_RULE_NAMESPACE = "https://orders.vector.co.nz";

const getMetaDataValue = (value: string, user?: User): string => {
  if (!user) {
    return "";
  }

  return user[value] || "";
};

export const getAuthId = (user?: User): string => {
  return getMetaDataValue(`${AUTH0_META_DATA_RULE_NAMESPACE}/auth0Id`, user);
};

export const getPasswordChangeDate = (user?: User): string => {
  const passwordChangeDate = getMetaDataValue(
    `${AUTH0_META_DATA_RULE_NAMESPACE}/lastPasswordReset`,
    user,
  );

  return (
    passwordChangeDate && moment.utc(passwordChangeDate).format("DD MMMM YYYY")
  );
};

export const getCreatedDate = (user?: User): string => {
  const createdAt = getMetaDataValue(
    `${AUTH0_META_DATA_RULE_NAMESPACE}/createdAt`,
    user,
  );

  return createdAt && moment.utc(createdAt).format("DD MMMM YYYY");
};
