import { UnsubmittedOrder } from "../../types/Order";

export const addUnsubmittedOrderId = (
  unsubmittedOrderId: string,
  draftOrder: UnsubmittedOrder,
) => {
  if (unsubmittedOrderId) {
    draftOrder.orderId = unsubmittedOrderId;
  }
};
