import React from "react";
import { LoginPage } from "./LoginPage";
import { useParams } from "react-router-dom";

type LoginOptionParams = { orderType: string };

export const LoginPageContainer: React.FC = () => {
  const { orderType } = useParams<LoginOptionParams>();

  return <LoginPage orderType={orderType} />;
};
