import React, { useState } from "react";
import styled from "styled-components";
import { Title } from "../Title";
import { Flex } from "../Flex";
import { Box } from "../Box";
import { Icon } from "../Icon";
import { theme } from "../../theme";
import { Span } from "../Span";

interface Props {
  title: string;
  content: string;
}

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;

export const Accordion: React.FC<Props> = ({ title, content }) => {
  const [show, setShow] = useState<boolean>(false);

  const onClick = () => setShow((prevState) => !prevState);

  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderRadius="12px"
      borderColor={theme.colors.neutrals.ln40}
      px={3}
      flexDirection="column"
    >
      <Flex flexDirection="column" width="100%">
        <StyledFlex
          alignItems="center"
          justifyContent="space-between"
          onClick={onClick}
          data-testid="accordionOnClick"
        >
          <Box pr={3}>
            <Title as="h4" data-testid="faqQuestion">
              {title}
            </Title>
          </Box>
          <Icon
            icon={show ? "remove" : "add"}
            fontWeight={500}
            color={theme.colors.neutrals.dn40}
          />
        </StyledFlex>

        <Span
          data-testid="faqAnswer"
          flexDirection="column"
          display={show ? "inline-block" : "none"}
          mb={3}
          fontSize={1}
          lineHeight={1}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Flex>
    </Flex>
  );
};
