import React from "react";
import { Span } from "../Span";
import { Flex } from "../Flex";
import { ErrorMessage, Field } from "formik";
import { FormikInputText } from "../FormikInputText";
import { Unmetered } from "../../types/Unmetered";
import { FeatureTypeNonOtherLayout } from "./_/FeatureTypeNonOtherLayout";
import { FeatureTypeOtherLayout } from "./_/FeatureTypeOtherLayout";

import { StreetNumberAndName } from "../StreetNumberAndName";
import { FormInputErrorMessage } from "../FormInputErrorMessage";

interface Props {
  unmetered: Unmetered;
  validateField: (field: string) => void;
}

export const UnmeteredIcp: React.FC<Props> = ({ unmetered, validateField }) => {
  const fieldIdPrefix = "unmetered";
  const fieldNamePrefix = "unmetered";

  return (
    <>
      <StreetNumberAndName
        fieldIdPrefix={fieldIdPrefix}
        fieldNamePrefix={fieldNamePrefix}
        siteAddressInformation={unmetered.addressInformation}
        validateField={validateField}
        showUnit={false}
        showAdditional={false}
      />

      <Flex flexDirection="column" mb={7}>
        <Span
          display="inline-block"
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          mb={3}
        >
          Additional information
        </Span>
        <Field
          id={`${fieldIdPrefix}.addressInformation.additionalAddressInformation`}
          name={`${fieldNamePrefix}.addressInformation.additionalAddressInformation`}
          label="Additional address information"
          message="If this ICP is not exactly at the above address, please provide additional information about the site location."
          optional
          component={FormikInputText}
        />
        <ErrorMessage
          name={`${fieldIdPrefix}.addressInformation.additionalAddressInformation`}
          className="field-error"
          component={FormInputErrorMessage}
        />
      </Flex>

      <Flex mb={7} flexDirection="column" flexGrow={1}>
        <Span
          display="inline-block"
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          mb={3}
        >
          Unmetered ICP information
        </Span>

        {unmetered.featureType === "Other" && (
          <FeatureTypeOtherLayout featureType={unmetered.featureType} />
        )}

        {unmetered.featureType !== "Other" && (
          <FeatureTypeNonOtherLayout featureType={unmetered.featureType} />
        )}
      </Flex>
    </>
  );
};
