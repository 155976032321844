import React from "react";
import styled from "styled-components";
import { Flex } from "../../../Flex";
import { TableHead } from "../../../TableHead";
import { layout, LayoutProps } from "styled-system";
import { UnsubmittedOrder } from "../../../../types/Order";
import { UnsubmittedOrderRow } from "../UnsubmittedOrderRow";
import { deleteModalAction } from "../../../../constants/DeleteReducerAction";

interface Props {
  className: string;
  headers: { Header: string }[];
  orders: UnsubmittedOrder[];
  userFullName: string;
  onDelete: (action: deleteModalAction) => void;
}

const TableWrapper = styled.table<LayoutProps>`
  ${layout}
  border-collapse: collapse;
`;

const TableHeadWrapper = styled(TableHead)`
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const UnsubmittedOrdersTable: React.FC<Props> = ({ className, headers, orders, userFullName, onDelete }) => {
  return (
    <Flex flexDirection="column" className={className} mb={5}>
      <TableWrapper>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <TableHeadWrapper
                key={index}
                data-testid="unsubmitted-order-header"
                pl={index === 0 ? 2 : 0}
                py={3}
                textAlign="left"
                backgroundColor="neutrals.ln10"
                borderTop="1px solid"
                borderBottom="1px solid"
                color="neutrals.mn80"
                fontWeight="medium"
                fontSize={0}
                lineHeight={0}
              >
                {header.Header}
              </TableHeadWrapper>
            ))}

            <TableHeadWrapper
              pr={2}
              py={3}
              textAlign="left"
              backgroundColor="neutrals.ln10"
              borderTop="1px solid"
              borderBottom="1px solid"
              color="neutrals.mn80"
              fontWeight="medium"
              fontSize={0}
              lineHeight={0}
            >
              {/* Empty by design for controls */}
            </TableHeadWrapper>
          </tr>
        </thead>

        <tbody>
          {orders.map((order, index) => (
            <UnsubmittedOrderRow key={index} index={index} order={order} userFullName={userFullName} onDelete={onDelete} />
          ))}
        </tbody>
      </TableWrapper>
    </Flex>
  );
};
