import React from "react";
import styled from "styled-components";
import { Flex } from "../../../../../Flex";
import { ReviewFormDesktopLots } from "../ReviewFormDesktopLots";
import { ReviewFormMobileLots } from "../ReviewFormMobileLots";
// eslint-disable-next-line max-len
import {
  getAddressInformation,
  getSiteLotInformation,
} from "../../../../../LiveningIcpForm/_/LiveningIcpReviewForm/_/FormattedAddressUtil/FormattedAddressUtil";
import { Span } from "../../../../../Span";
import {
  getFileExtension,
  getFileNameWithoutExtension,
} from "../../../../../FileUpload/_/FileUtil/FileUtil";
import { NewConnectionFormValues } from "../../../../../../types/NewConnectionFormValues";
import { isComplexConnection } from "../../../../../NewConnectionComplexUtil/NewConnectionComplexUtil";

interface Props {
  values: NewConnectionFormValues;
}

const SiteWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const LabelWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.dn40};
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;

const UploadUnitWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const FileTypeWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.neutrals.mn80};
  text-transform: uppercase;
`;

export const ReviewFormSiteDetails: React.FC<Props> = ({ values }) => {
  const isComplex = isComplexConnection(values.site);
  const hasMoreThanTenLots = values.site.lotCount === "More than 10";

  return (
    <>
      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
          Address
        </LabelWrapper>

        <ValueWrapper
          data-testid="review-form-site-details-address"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
        >
          {values.site.siteType === "lotAndDpNumber"
            ? getSiteLotInformation(values.site.lotInformation)
            : values.site.siteType === "streetNumberAndName"
            ? getAddressInformation(values.site.addressInformation)
            : "N/A"}
        </ValueWrapper>
      </Flex>

      {values.site.addressInformation && (
        <Flex flexDirection="row" mb={3}>
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
            Additional address information
          </LabelWrapper>

          <ValueWrapper
            data-testid="review-form-site-details-address-additional"
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
          >
            {values.site.addressInformation.additionalAddressInformation}
          </ValueWrapper>
        </Flex>
      )}

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
          Number of lots
        </LabelWrapper>

        <ValueWrapper
          data-testid="review-form-site-details-lot-count"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
        >
          {values.site.lotCount}
        </ValueWrapper>
      </Flex>

      {!isComplex && (
        <Flex flexDirection="row" mb={3}>
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
            Additional site requirements
          </LabelWrapper>

          <ValueWrapper
            data-testid="review-form-site-details-additional-site-requirements"
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
          >
            {values.site.additionalSiteRequirements}
          </ValueWrapper>
        </Flex>
      )}

      {isComplex && (
        <>
          <Flex flexDirection="row" mb={3}>
            <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
              Service type
            </LabelWrapper>

            <ValueWrapper
              data-testid="review-form-site-details-service-type"
              lineHeight={2}
              ml={3}
              flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
            >
              {values.serviceType}
            </ValueWrapper>
          </Flex>

          <Flex flexDirection="row" mb={3}>
            <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
              Project type
            </LabelWrapper>

            <ValueWrapper
              data-testid="review-form-site-details-project-type"
              lineHeight={2}
              ml={3}
              flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
            >
              {values.projectType}
            </ValueWrapper>
          </Flex>

          {values.projectType === "subdivision" && (
            <Flex flexDirection="row" mb={3}>
              <LabelWrapper
                lineHeight={2}
                flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}
              >
                Project requirement
              </LabelWrapper>

              <ValueWrapper
                data-testid="review-form-site-details-subdivision-type"
                lineHeight={2}
                ml={3}
                flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
              >
                {values.subdivisionType}
              </ValueWrapper>
            </Flex>
          )}
          <Flex flexDirection="row" mb={3}>
            <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
              Description
            </LabelWrapper>

            <ValueWrapper
              data-testid="review-form-site-details-development-description"
              lineHeight={2}
              ml={3}
              flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
            >
              {values.developmentDescription}
            </ValueWrapper>
          </Flex>
        </>
      )}

      <Flex flexDirection="row" mb={7}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.35]}>
          Site plan
        </LabelWrapper>

        <ValueWrapper
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.65]}
        >
          {values.sitePlans.map((unit, index) => {
            return (
              <UploadUnitWrapper
                key={index}
                mb={3}
                borderRadius="radio"
                padding={[2, 2, 2, 3]}
              >
                <Span
                  data-testid={`review-form-site-${index + 1}-filename`}
                  fontSize={2}
                  lineHeight={2}
                >
                  {getFileNameWithoutExtension(unit.fileName)}
                </Span>

                <FileTypeWrapper
                  data-testid={`review-form-site-${index + 1}-filetype`}
                  ml={3}
                  padding={1}
                  borderRadius="fileType"
                  fontSize={1}
                  lineHeight={0}
                >
                  {getFileExtension(unit.fileName)}
                </FileTypeWrapper>
              </UploadUnitWrapper>
            );
          })}
        </ValueWrapper>
      </Flex>

      {!hasMoreThanTenLots && (
        <SiteWrapper
          flexDirection="column"
          borderRadius="radio"
          padding={[3, 3, 3, 4]}
          mb={4}
        >
          <ReviewFormDesktopLots lots={values.site.lots} />
          <ReviewFormMobileLots lots={values.site.lots} />
        </SiteWrapper>
      )}
    </>
  );
};
