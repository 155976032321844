import React from "react";
import { ContactDetailsValues } from "../../types/ContactDetailsValues";
import { MOBILE_REG_EXP } from "../../constants/ContactNumbersRegex";
import { ContactDetailsContactPreferences } from "../ContactDetailsForm/ContactDetailsContactPreferences";
import { IconPopupTip } from "../IconPopupTip";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { ContactDetailFields } from "../ContactDetailsForm/ContactDetailFields";
import { Span } from "../Span";
import { Flex } from "../Flex";
import { FormikRadioButton } from "../FormikRadioButton";
import { ErrorMessage, Field } from "formik";

interface Props {
  hasElectrician?: string;
  focusable?: boolean;
  optionalContactDetails: ContactDetailsValues;
}

export const ElectricianContactFields: React.FC<Props> = ({
  hasElectrician,
  focusable,
  optionalContactDetails,
}) => {
  const shouldDisableWorkSmsPreference =
    !optionalContactDetails.mobile ||
    !MOBILE_REG_EXP.test(optionalContactDetails.mobile);

  const shouldDisableWorkEmailPreference = optionalContactDetails.email === "";

  // Fail-safe to ensure the SMS is not checked when it is disabled
  if (shouldDisableWorkEmailPreference) {
    optionalContactDetails.workContactPreferences.email = false;
  }

  return (
    <Flex mt={5} flexDirection="column">
      <Span
        display="inline-block"
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mb={4}
      >
        Do you have an electrician?
      </Span>
      <Flex mb={4}>
        <Field
          magnitude="small"
          label="Same as requester"
          id="yes requester"
          name="hasElectrician"
          value="yes requester"
          currentValue={hasElectrician}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mr={[2, 2, 3]}
          component={FormikRadioButton}
          useTimeout
        />
        <Field
          magnitude="small"
          label="Same as site access contact"
          id="yes site access contact"
          name="hasElectrician"
          value="yes site access contact"
          currentValue={hasElectrician}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={[2, 2, 3]}
          component={FormikRadioButton}
          useTimeout
        />
      </Flex>
      <Flex flexDirection="column" mb={4}>
        <Flex width="100%">
          <Field
            magnitude="small"
            label="New contact"
            id="yes Electrician"
            name="hasElectrician"
            value="yes"
            hasElectrician={hasElectrician}
            currentValue={hasElectrician}
            flexGrow={0.5}
            flexBasis={0}
            minWidth={0}
            mr={[2, 2, 3]}
            component={FormikRadioButton}
            useTimeout
          />
          <Field
            magnitude="small"
            id="no Electrician"
            name="hasElectrician"
            label="Not applicable"
            value="no"
            currentValue={hasElectrician}
            flexGrow={0.5}
            flexBasis={0}
            minWidth={0}
            ml={[2, 2, 3]}
            component={FormikRadioButton}
            useTimeout
          />
        </Flex>
        <ErrorMessage
          name="hasElectrician"
          className="field-error"
          component={FormInputErrorMessage}
        />
      </Flex>

      {hasElectrician === "yes" && (
        <Flex flexDirection="column">
          <ContactDetailFields
            focusable={focusable}
            idPrefix="optionalContactDetails"
            namePrefix="optionalContactDetails"
            optionalEmail
            optionalBusinessName
            optionalAlternatePhone
          />

          <Flex
            flexDirection="row"
            mb={3}
            justifyContent={[
              "space-between",
              "space-between",
              "space-between",
              "flex-start",
            ]}
          >
            <Span fontSize={2} lineHeight={2} mr={3}>
              {"Select contact preferences"}
            </Span>
            <IconPopupTip icon="help" ml={["auto", "auto", 3]}>
              Identify whether they want to be notified via email and/or SMS.
            </IconPopupTip>
          </Flex>

          <ContactDetailsContactPreferences
            id="optionalContactDetails.workContactPreferences"
            name="optionalContactDetails.workContactPreferences"
            title="Work update:"
            emailLabel="Email"
            smsLabel="SMS"
            emailDisabled={shouldDisableWorkEmailPreference}
            smsDisabled={shouldDisableWorkSmsPreference}
            focusable={focusable}
          />
        </Flex>
      )}
    </Flex>
  );
};
