import { NewConnectionFormValues } from "../types/NewConnectionFormValues";
import { EMPTY_NEW_CONNECTION_CONTACT_DETAILS_VALUES } from "./ContactDetailsValues";
import { EMPTY_NEW_CONNECTION_SITES } from "./Sites";
import { EMPTY_CONTACT_DETAILS_VALUES } from "./EmptyContactDetailsValues";

export const EMPTY_NEW_CONNECTION_ORDER: NewConnectionFormValues = {
  isUserLoggedIn: false,
  site: EMPTY_NEW_CONNECTION_SITES,
  serviceType: "",
  projectType: "",
  subdivisionType: "",
  developmentDescription: "",
  filesUploadStatus: "",
  sitePlans: [],
  contactDetails: [EMPTY_NEW_CONNECTION_CONTACT_DETAILS_VALUES],
  optionalContactDetails: EMPTY_CONTACT_DETAILS_VALUES,
  hasElectrician: "",
  hasLandOwner: "",
  siteAccessContactRoleChecked: false,
  quotePayerRoleChecked: false,
  customerRoleChecked: false,
  consultantRoleChecked: false,
  isComplexConnection: false,
};
