import React from "react";
import { ServiceType } from "../../components/ServiceType";
import { Page } from "../../components/Page";

export interface ServiceTypeProps {
  tabTitle: string;
}

export const ServiceTypePage: React.FC<ServiceTypeProps> = ({ tabTitle }) => {
  return (
    <Page tabTitle={tabTitle}>
      <ServiceType />
    </Page>
  );
};
