import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { NewConnectionFormValues } from "../../../../types/NewConnectionFormValues";
import {
  getGaDetailServiceEvent,
  getGaIndicativeServiceEvent,
  getGaPosProjectEvent,
  getGaPosSubdivisionEvent,
  getGaReticSubdivisionEvent,
  getGaSubdivisionProjectEvent,
} from "../../../../utils/ga/GaNewConnectionEvents";
import { handleScrollToName } from "../../../../utils/handleScroll/HandleScroll";
import { BasicNotificationBanner } from "../../../BasicNotificationBanner";
import { Box } from "../../../Box";
import { getFirstErrorByPosition } from "../../../CheckErrorsUtil/CheckErrorsUtil";
import { Flex } from "../../../Flex";
import { FormikRadioButton } from "../../../FormikRadioButton";
import { FormikTextBox } from "../../../FormikTextBox";
import { IconPopupTip } from "../../../IconPopupTip";
import { Line } from "../../../Line";
import { isComplexConnection } from "../../../NewConnectionComplexUtil/NewConnectionComplexUtil";
import { Span } from "../../../Span";
import { Title } from "../../../Title";
import { NewConnectionContactDetailsForm } from "../NewConnectionContactDetailsForm";
import { NewConnectionSiteInformationForm } from "../NewConnectionSiteInformationForm";
import { SitePlanUpload } from "../SitePlanUpload";
import {
  consultantRoleSelectedInAllContactDetails,
  customerRoleSelectedInAllContactDetails,
  quotePayerRoleSelectedInAllContactDetails,
  siteAccessContactRoleSelectedInAllContactDetails,
} from "../../../ContactDetailsForm/ContactDetailsRoleChecker/ContactDetailsRoleChecker";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";

interface Props {
  id: string;
  values: NewConnectionFormValues;
  validateField: (field: string) => void;
  onFilesStillUploading?: (filesStillUploading: boolean) => void;
}

export const NewConnectionOrderDetailsForm: React.FC<Props> = ({
  id,
  values,
  validateField,
  onFilesStillUploading,
}) => {
  const { errors, isSubmitting } = useFormikContext<NewConnectionFormValues>();

  useEffect(() => {
    if (values.isComplexConnection) {
      values.contactDetails.forEach((contactDetail) => {
        contactDetail.roles.siteAccessContactRole = false;
        contactDetail.roles.quotePayerRole = false;
        contactDetail.billingAddress = "";
        contactDetail.billingAddressId = "";
        contactDetail.billingAddressDisplay = "";
      });

      values.hasElectrician = "";
    } else {
      values.contactDetails.forEach((contactDetail) => {
        contactDetail.roles.consultantRole = false;
        contactDetail.roles.customerRole = false;
      });

      values.hasLandOwner = "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isComplexConnection]);

  useEffect(() => {
    const allContactDetailsRoles = values.contactDetails.map(
      (details) => details.roles,
    );

    values.siteAccessContactRoleChecked =
      siteAccessContactRoleSelectedInAllContactDetails(allContactDetailsRoles);
    values.quotePayerRoleChecked = quotePayerRoleSelectedInAllContactDetails(
      allContactDetailsRoles,
    );
    values.customerRoleChecked = customerRoleSelectedInAllContactDetails(
      allContactDetailsRoles,
    );
    values.consultantRoleChecked = consultantRoleSelectedInAllContactDetails(
      allContactDetailsRoles,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.contactDetails, values.isComplexConnection]);

  useEffect(() => {
    const isComplex = isComplexConnection(values.site);
    values.isComplexConnection = isComplex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.site]);

  // Scroll to first validation error (if any)
  useEffect(() => {
    if (isSubmitting) {
      const errorFieldName = getFirstErrorByPosition(errors);
      if (errorFieldName) {
        handleScrollToName(errorFieldName);
      }
    }
  }, [isSubmitting, errors]);

  // Check if files are still uploading
  useEffect(() => {
    if (onFilesStillUploading) {
      const areFilesStillUploading = values.filesUploadStatus === "UPLOADING";
      onFilesStillUploading(areFilesStillUploading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.filesUploadStatus]);

  const complexConnection = isComplexConnection(values.site);

  const handleUploadPlanStatusChange = (
    filesUploadStatus: FileSelectUploadStatus,
  ) => {
    values.filesUploadStatus = filesUploadStatus;
  };

  return (
    <Flex id={id} flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="medium" mb={5}>
        Order details
      </Title>

      <Box id="new-connection-order-details-section">
        <Flex
          justifyContent={[
            "space-between",
            "space-between",
            "space-between",
            "flex-start",
          ]}
          mt={[2, 2, 2, 0, 0]}
          mb={3}
        >
          <Span
            display="inline-block"
            fontSize={4}
            lineHeight={4}
            fontWeight="medium"
          >
            Site addresses phase and fuse information
          </Span>
        </Flex>

        <Flex flexDirection="column">
          <BasicNotificationBanner
            message={
              "You must consult your electrician to confirm the phase and fuse size you will need to power your home."
            }
            variant="default"
            icon="info"
            mb={3}
          />

          <NewConnectionSiteInformationForm
            id="new-connection-site-details-section"
            site={values.site}
            validateField={validateField}
          />
        </Flex>
      </Box>

      {complexConnection && (
        <>
          <Flex flexDirection="column" mb={7}>
            <Span
              display="inline-block"
              fontSize={4}
              lineHeight={4}
              fontWeight="medium"
              mb={3}
            >
              Which service do you need?
            </Span>

            <Flex>
              <Field
                magnitude="medium"
                id="service-type-indicative"
                name="serviceType"
                label="Indicative cost (No fee)"
                value="indicative cost"
                currentValue={values.serviceType}
                flexGrow={0.5}
                flexBasis={0}
                minWidth={0}
                mr={[2, 2, 3]}
                gaEvent={getGaIndicativeServiceEvent()}
                component={FormikRadioButton}
              />
              <Field
                magnitude="medium"
                id="service-type-detailed"
                name="serviceType"
                label="Detailed design and price (A design fee will apply)"
                value="design and price"
                currentValue={values.serviceType}
                flexGrow={0.5}
                flexBasis={0}
                minWidth={0}
                mr={[2, 2, 3]}
                gaEvent={getGaDetailServiceEvent()}
                component={FormikRadioButton}
              />
            </Flex>
          </Flex>

          <Flex flexDirection="column" mb={7}>
            <Flex
              justifyContent={[
                "space-between",
                "space-between",
                "space-between",
                "flex-start",
              ]}
              mt={[2, 2, 2, 0, 0]}
              mb={3}
            >
              <Span
                display="inline-block"
                fontSize={4}
                lineHeight={4}
                fontWeight="medium"
              >
                What type of project is this?
              </Span>

              <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
                Subdivision is the process of dividing land into smaller parts
                (lots) where developers can request Vector to provide a
                connection at the boundary of each new lot (full reticulation)
                or provide a point of supply in the berm at the entrance to the
                development and the developer can install private reticulation
                within the development boundaries to provide a connection at
                each new lot. Point of supply is usually on a property boundary
                where Vector`s network connects to a property`s private service
                line.
              </IconPopupTip>
            </Flex>

            <Flex>
              <Field
                magnitude="medium"
                id="project-type-subdivision"
                name="projectType"
                label="Subdivision"
                value="subdivision"
                currentValue={values.projectType}
                flexGrow={0.5}
                flexBasis={0}
                minWidth={0}
                mr={[2, 2, 3]}
                gaEvent={getGaSubdivisionProjectEvent()}
                component={FormikRadioButton}
              />
              <Field
                magnitude="medium"
                id="project-type-detailed"
                name="projectType"
                label="Point of supply"
                value="point of supply"
                currentValue={values.projectType}
                flexGrow={0.5}
                flexBasis={0}
                minWidth={0}
                mr={[2, 2, 3]}
                gaEvent={getGaPosProjectEvent()}
                component={FormikRadioButton}
              />
            </Flex>
          </Flex>

          {values.projectType === "subdivision" && (
            <Flex flexDirection="column" mb={7}>
              <Flex
                justifyContent={[
                  "space-between",
                  "space-between",
                  "space-between",
                  "flex-start",
                ]}
                mt={[2, 2, 2, 0, 0]}
                mb={3}
              >
                <Span
                  display="inline-block"
                  fontSize={4}
                  lineHeight={4}
                  fontWeight="medium"
                >
                  What do you require?
                </Span>
              </Flex>

              <Flex>
                <Field
                  magnitude="medium"
                  id="subdivision-type-boundary"
                  name="subdivisionType"
                  label="Point of the supply at the boundary of subdivision"
                  value="boundary"
                  currentValue={values.subdivisionType}
                  flexGrow={0.5}
                  flexBasis={0}
                  minWidth={0}
                  mr={3}
                  gaEvent={getGaPosSubdivisionEvent()}
                  component={FormikRadioButton}
                />
                <Field
                  magnitude="medium"
                  id="subdivision-type-full-reticulation"
                  name="subdivisionType"
                  label="Full reticulation"
                  value="full reticulation"
                  currentValue={values.subdivisionType}
                  flexGrow={0.5}
                  flexBasis={0}
                  minWidth={0}
                  ml={2}
                  gaEvent={getGaReticSubdivisionEvent()}
                  component={FormikRadioButton}
                />
              </Flex>
            </Flex>
          )}

          <Flex flexDirection="column" mb={7}>
            <Span
              display="inline-block"
              fontSize={4}
              lineHeight={4}
              fontWeight="medium"
              mb={3}
            >
              Give us a description of the development
            </Span>

            <Field
              id="development-description"
              name="developmentDescription"
              label="Description"
              placeholder="e.g. Existing site will be partially demolished and new apartment blocks will be installed in their place. The estimated new load will be 3-4MVA"
              rows={5}
              mr={3}
              component={FormikTextBox}
            />

            <ErrorMessage
              name="developmentDescription"
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>
        </>
      )}

      <Box>
        <SitePlanUpload onStatusChange={handleUploadPlanStatusChange} />

        <Line
          width="100%"
          border="1px solid"
          borderColor="neutrals.ln30"
          bg="neutrals.ln30"
          opacity="1"
          height={0}
          my={7}
        />
      </Box>

      <NewConnectionContactDetailsForm
        id="new-connection-contact-details-section"
        contactDetails={values.contactDetails}
        newConnectionType={complexConnection ? "complex" : "simple"}
        hasElectrician={values.hasElectrician}
        hasLandOwner={values.hasLandOwner}
        optionalContactDetails={values.optionalContactDetails}
        validateField={validateField}
      />
    </Flex>
  );
};
