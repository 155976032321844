import { Field, ErrorMessage } from "formik";
import React from "react";
import { UnmeteredFeatureType } from "../../../../constants/Unmetered";
import { Flex } from "../../../Flex";
import { FormikInputText } from "../../../FormikInputText";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";

interface Props {
  featureType: UnmeteredFeatureType;
}

export const Wattage: React.FC<Props> = ({ featureType }) => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={featureType !== "Other" ? 0.2 : 0.3}
      flexBasis={0}
      minWidth={["100%", "100%", "100%", 0]}
      mr={[0, 0, 0, 3]}
      mb={[3, 3, 3, 0]}
    >
      <Field
        id="unmetered.wattage"
        name="unmetered.wattage"
        label="Wattage"
        placeholder=""
        component={FormikInputText}
      />
      <ErrorMessage
        name="unmetered.wattage"
        className="field-error"
        component={FormInputErrorMessage}
      />
    </Flex>
  );
};
