import React from "react";
import { Span } from "../Span";
import { Flex } from "../Flex";
import { ReviewFormContactDetails } from "../ReviewFormContactDetails";
import { ContactDetailsValues } from "../../types/ContactDetailsValues";
import { SpaceProps } from "styled-system";

interface Props {
  contactDetails: ContactDetailsValues;
  hasOptional: "no" | "yes";
  title: string;
}

export const ReviewFormOptionalContactDetails: React.FC<Props & SpaceProps> = ({
  contactDetails,
  hasOptional,
  title,
  mb,
}) => {
  return (
    <Flex flexDirection="column" mb={mb}>
      <Flex flexDirection="column">
        <Span lineHeight={2} fontWeight="bold" mb={3}>
          {title}
        </Span>

        {hasOptional === "no" && (
          <Flex id={"test-id-no-content"}>No contact</Flex>
        )}

        {hasOptional === "yes" && (
          <ReviewFormContactDetails contactDetails={contactDetails} />
        )}
      </Flex>
    </Flex>
  );
};
