import React, { InputHTMLAttributes } from "react";
import { FieldProps, getIn } from "formik";
import { LayoutProps, SpaceProps } from "styled-system";
import { Counter } from "../Counter";
import { CounterProps } from "../Counter/Counter";

export const FormikCounter: React.FC<
  CounterProps &
    FieldProps &
    LayoutProps &
    SpaceProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  const handleOnChange = (count: number) => {
    form.setFieldValue(field.name, count);
  };

  const handleOnBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  return (
    <Counter
      {...field}
      {...props}
      error={touch && error ? error : false}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};
