import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

export const TableData = styled.td<
  ColorProps & SpaceProps & TypographyProps & LayoutProps & BorderProps
>`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${border}
`;
