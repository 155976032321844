import React, { useState } from "react";
import { SomethingWentWrong } from "../SomethingWentWrong/SomethingWentWrong";

interface Props {
  onRetry?: () => void;
}

export const OrderListError: React.FC<Props> = ({ onRetry }) => {
  const [showLoading, setShowLoading] = useState(false);

  const handleOnTryAgainClick = () => {
    onRetry && onRetry();
    setShowLoading(true);

    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  };

  return (
    <SomethingWentWrong
      subHeading="It looks like there was an error retrieving your orders."
      message="Please try again in a few minutes"
      onTryAgainClick={handleOnTryAgainClick}
      tryAgainLoading={showLoading}
      showTryAgainButton
    />
  );
};
