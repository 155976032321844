import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Tooltip } from "../Tooltip";
import { Icon } from "../Icon";
import { Flex } from "../Flex";
import {
  color,
  ColorProps,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  TypographyProps,
} from "styled-system";

interface Props {
  icon: string;
}

const TooltipWrapper = styled(Tooltip)<LayoutProps & SpaceProps>`
  ${layout}
  ${space}
  &:hover {
    opacity: 1;
    visibility: visible;
  }
  opacity: 0;
  transition: 0;
  visibility: hidden;
  position: absolute;
`;

const IconWrapper = styled(Icon)<Props & ColorProps>`
  ${color}
  &:hover + ${TooltipWrapper} {
    transition-delay: 0.2s;
    opacity: 1;
    visibility: visible;
  }
`;

export const IconTooltip: React.FC<
  Props &
    LayoutProps &
    FlexboxProps &
    SpaceProps &
    ColorProps &
    TypographyProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({ id, icon, color, width, mr, fontSize, alignItems, children }) => {
  return (
    <Flex flexDirection="column" alignItems={alignItems} mr={mr}>
      <IconWrapper
        icon={icon}
        aria-labelledby={id}
        fontSize={fontSize}
        color={color}
      />
      <TooltipWrapper id={id} width={width} mt={4}>
        {children}
      </TooltipWrapper>
    </Flex>
  );
};

IconTooltip.defaultProps = {
  icon: "",
};
