import { apiClient } from "../client/ApiClient";
import { InspectorRetailer } from "../types/InspectorRetailer";

const getInspectorRetailers = async (
  path: string,
): Promise<InspectorRetailer[]> => {
  return await apiClient
    .get<InspectorRetailer[]>(path)
    .then((response) => response.data);
};

export default getInspectorRetailers;
