import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { LiveningIcpThankYou } from "../../components/LiveningIcpThankYou";
import { NewConnectionThankYou } from "../../components/NewConnectionThankYou";
import { LiveningIcpFormValues } from "../../types/LiveningIcpFormValues";
import { NewConnectionFormValues } from "../../types/NewConnectionFormValues";

type ThankYouParams = { pageType: string };

export const ThankYouPage: React.FC = () => {
  const { pageType } = useParams<ThankYouParams>();

  const location = useLocation<
    LiveningIcpFormValues | NewConnectionFormValues
  >();

  return (
    <>
      {pageType === "liveningIcp" && (
        <LiveningIcpThankYou values={location.state} />
      )}

      {pageType === "newConnection" && (
        <NewConnectionThankYou values={location.state} />
      )}
    </>
  );
};
