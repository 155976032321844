import React from "react";
import styled from "styled-components";
import lodash from "lodash";
import { Span } from "../Span";
import { color, ColorProps } from "styled-system";
import { Box } from "../Box";

interface Props {
  color: string;
  status: OrderProgressStatus;
  fontSize?: number;
}

const Circle = styled(Span)`
  ${color}
  height: 12px;
  width: 12px;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

export const OrderStatusIcon: React.FC<Props & ColorProps> = ({
  color,
  status,
  fontSize,
}) => {
  return (
    <Box>
      <Circle color={color} />
      <Span data-testid="order-status" fontSize={fontSize}>
        {status ? lodash.capitalize(status) : "In progress"}
      </Span>
    </Box>
  );
};

OrderStatusIcon.defaultProps = {
  fontSize: 1,
};
