import { OrderDetailsType } from "../types/OrderDetails";
import env from "../environment";
import {
  LiveningIcpTypes,
  NewConnectionTypes,
  OrderDetailMainTypes,
  OrderDetailSubTypes,
} from "../types/OrderJobTypes";

export const EMPTY_ORDER_DETAILS: OrderDetailsType = {
  id: "",
  serviceRequestNumber: "",
  duePayments: [],
  orderStatus: {
    progressStatus: "in progress",
    percentageComplete: "0",
    currentActivity: "",
    currentActivityStartDate: new Date(),
    currentActivityEndDate: new Date(),
    nextActivity: "",
    cancelReason: "",
    infoMessage: "",
    alertMessage: "",
    actionMessage: "",
  },
  siteDetails: {
    jobType: "livening icp",
    address: "",
    typeDetailed: "livening icp",
    liveningIcpSiteDetails: {
      icpType: "metered",
      additionalInfo: "",
      projectNumber: "",
      liveningInspector: "",
      powerRetailer: "",
      featureType: "",
      quantity: "",
      wattage: "",
      availableHours: "",
      units: [],
    },
    newConnectionSiteDetails: {
      lotCount: 0,
      serviceType: "",
      projectType: "",
      subdivisionType: "",
      description: "",
      sitePlans: [],
      lots: [],
    },
  },
  contactDetails: [],
  documents: [],
};

export const COMPLETION_INFO_DATA_MAP: Record<
  OrderDetailSubTypes,
  | {
      estimatedCompletion: string;
      infoLink: string;
      infoLinkText: string;
      fileName: string;
    }
  | undefined
> = {
  "livening icp": {
    estimatedCompletion:
      "We are unable to tell you when your site can be livened, as the majority of the work is organised by your nominated electricity retailer.",
    infoLink: `${env.orderInfoLinkBaseUrl}service-information-livening-(icp-request).pdf`,
    infoLinkText: "ICP request (livening) information sheet.",
    fileName: "Service information - Livening (ICP request)",
  },
  "simple standard connection": {
    estimatedCompletion:
      "We aim to complete a standard simple connection within 6 to 8 working weeks from the date we receive payment of the quote.",
    infoLink: `${env.orderInfoLinkBaseUrl}service-information-standard-electricity-connection.pdf`,
    infoLinkText:
      "New simple electricity connection (standard) information sheet.",
    fileName: "Service information - Standard electricity connection",
  },
  "simple non standard connection": {
    estimatedCompletion:
      "We aim to complete a non-standard simple connection within 6 to 8 working weeks after receiving payment of the detailed quote.",
    infoLink: `${env.orderInfoLinkBaseUrl}service-information-non-standard-electricity-connection.pdf`,
    infoLinkText:
      "New simple electricity connection (non-standard) information sheet.",
    fileName: "Service information - Non standard electricity connection",
  },
  "undefined connection": undefined,
};

export const ORDER_DETAILS_HEADING_MAP: Record<OrderDetailMainTypes, string> = {
  "livening icp": "ICP Request (Livening)",
  "new connection": "Simple electricity connection",
};

export const NEW_CONNECTION_SUB_HEADING_MAP: Record<
  NewConnectionTypes,
  string
> = {
  "undefined connection": "Simple electricity connection",
  "simple standard connection": "Simple electricity connection (Standard)",
  "simple non standard connection":
    "Simple electricity connection (Non-standard)",
};

export const LIVENING_ICP_SUB_HEADING_MAP: Record<LiveningIcpTypes, string> = {
  metered: "Metered ICP",
  unmetered: "Unmetered ICP",
};
