import React from "react";
import { UnsubmittedOrder } from "../../../../types/Order";
import { Flex } from "../../../Flex";
import styled from "styled-components";
import { Span } from "../../../Span";
import { OrderStatusIcon } from "../../../OrderStatusIcon";
import { theme } from "../../../../theme";
import { ActionLink } from "../../../ActionLink";
import {
  getExpiryDaysStringUtil,
  getOrderTypeUtil,
  getUnsubmittedAddressUtil,
  isDeleteOrderButton,
} from "../UnsubmittedOrdersUtil/UnsubmittedOrdersUtil";
import { deleteModalAction } from "../../../../constants/DeleteReducerAction";
import { useHistory } from "react-router-dom";

interface Props {
  order: UnsubmittedOrder;
  userFullName: string;
  onDelete: (action: deleteModalAction) => void;
}

const CardRow = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CardHeader = styled(Span)`
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  color: ${({ theme }) => theme.colors.neutrals.mn80};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const CardData = styled(Span)`
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.nightSky};
  text-align: right;
`;

const FlexWrapper = styled(Flex)`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydroDark};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
    outline: none;
  }
`;

const ActionLinkWrapper = styled(ActionLink)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

export const UnsubmittedCard: React.FC<Props> = ({
  order,
  userFullName,
  onDelete,
}) => {
  const days = getExpiryDaysStringUtil(order.expiryDay);
  const address = getUnsubmittedAddressUtil(order);
  const orderType = getOrderTypeUtil(order.orderType);

  const handleDelete = () => onDelete({ type: "Select", payload: order });

  const history = useHistory();

  const handleRowOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const isDelete = isDeleteOrderButton(event);

    if (!isDelete) {
      if (order.orderType === "LiveningIcpOrder") {
        history.push("/icp-request", order);
      } else {
        history.push("/new-connection", order);
      }
    }
  };

  return (
    <FlexWrapper
      flexDirection="column"
      p={4}
      borderRadius="notificationBanner"
      border="1px solid"
      borderColor="neutrals.ln30"
      marginBottom={3}
      padding="10px"
      onClick={handleRowOnClick}
    >
      <CardRow>
        <Span data-testid="order-row-site" fontSize={2} lineHeight={2}>
          {address}
        </Span>
      </CardRow>

      <Flex marginBottom={3} alignItems="center">
        <OrderStatusIcon color={theme.colors.orangeAlert} status={"draft"} />
      </Flex>

      <CardRow>
        <CardHeader data-testid="order-header-job-type">Job Type</CardHeader>
        <CardData data-testid="order-row-order-type">{orderType}</CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-created-by">
          Created by
        </CardHeader>
        <CardData data-testid="order-row-created-by">{userFullName}</CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-created-date">
          Creation date
        </CardHeader>
        <CardData data-testid="order-row-created-date">
          {order.createdDate}
        </CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-expires-date">
          Expires in
        </CardHeader>
        <CardData data-testid="order-row-expires-date">{days}</CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-delete">
          <ActionLinkWrapper
            data-testid="order-delete-button"
            onClick={handleDelete}
          >
            Delete
          </ActionLinkWrapper>
        </CardHeader>
      </CardRow>
    </FlexWrapper>
  );
};
