import env from "../environment";
import { auth0Client } from "../client/Auth0Client";
import { PasswordReset } from "../types/PasswordReset";

const AUTH0_APP_CONNECTION = "Username-Password-Authentication";

const sendPasswordResetEmail = async (email: string): Promise<boolean> => {
  const request: PasswordReset = {
    /* eslint-disable  @typescript-eslint/camelcase */
    client_id: env.auth0ClientId,
    connection: AUTH0_APP_CONNECTION,
    email,
  };

  return await auth0Client
    .post<boolean>("dbconnections/change_password", request)
    .then((response) => response.status === 200);
};

export default sendPasswordResetEmail;
