import {
  border,
  BorderProps,
  color,
  ColorProps,
  FlexboxProps,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import React, { InputHTMLAttributes, ReactElement } from "react";
import * as CSS from "csstype";
import { Flex } from "../Flex";
import { Label } from "../Label";
import { Span } from "../Span";
import styled from "styled-components";
import { IconPopupTip } from "../IconPopupTip";

export interface InputTextProps {
  label?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  displayLabel?: ResponsiveValue<CSS.Property.Display, any>;
  message?: ReactElement | string;
  error?: boolean;
  optional?: boolean;
  focusable?: boolean;
  tooltip?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  displayTooltip?: ResponsiveValue<CSS.Property.Display, any>;
}

const FlexWrapper = styled(Flex)`
  box-sizing: border-box;
`;

const LabelWrapper = styled(Label)<
  InputTextProps & ColorProps & InputHTMLAttributes<HTMLInputElement>
>`
  color: ${({ disabled, theme }) => disabled && theme.colors.disabledColor};
`;

const InputWrapper = styled.input<
  InputTextProps &
    BorderProps &
    ColorProps &
    SpaceProps &
    TypographyProps &
    InputHTMLAttributes<HTMLInputElement>
>`
  ${border}
  ${layout}
  ${color}
  ${space}
  ${typography}
  outline: none;
  border-color: ${({ error, theme }) => error && theme.colors.danger};
  box-sizing: border-box;
  &:focus {
    border-color: ${({ theme }) => theme.colors.hydro};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.disabledColor};
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    border-color: ${({ theme }) => theme.colors.disabledBorder};
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.disabledColor};
  }
`;

const SpanWrapper = styled(Span)<
  InputTextProps & ColorProps & InputHTMLAttributes<HTMLInputElement>
>`
  color: ${({ theme }) => theme.colors.neutrals.mn60};
  color: ${({ error, theme }) => error && theme.colors.danger};
`;

export const InputText: React.FC<
  InputTextProps &
    BorderProps &
    ColorProps &
    FlexboxProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({
  id,
  label,
  displayLabel,
  error,
  disabled,
  message,
  optional,
  width,
  mb,
  mt,
  focusable,
  tooltip,
  displayTooltip,
  tabIndex,
  color,
  minWidth,
  flexBasis,
  flexGrow,
  ...props
}) => {
  return (
    <FlexWrapper
      flexDirection="column"
      flexGrow={flexGrow}
      flexBasis={flexBasis}
      minWidth={minWidth}
      width={width}
      mt={mt}
      mb={mb}
    >
      <Flex pl={2} pr={2} mb={1} justifyContent="space-between">
        {label && (
          <>
            <LabelWrapper
              htmlFor={id}
              fontSize="body"
              lineHeight="body"
              disabled={disabled}
              color={color}
              display={displayLabel}
              data-testid="input-text-label"
            >
              {label}
            </LabelWrapper>

            {tooltip && (
              <IconPopupTip
                display={displayTooltip}
                icon="help"
                ml={1}
                data-testid="input-text-popuptip"
              >
                {tooltip}
              </IconPopupTip>
            )}
          </>
        )}

        {optional && (
          <SpanWrapper
            ml="auto"
            mr={2}
            fontSize={1}
            lineHeight={1}
            data-testid="input-text-optional"
          >
            Optional
          </SpanWrapper>
        )}
      </Flex>

      <InputWrapper
        id={id}
        error={error}
        disabled={disabled}
        border="2px solid"
        borderRadius="input"
        borderColor="disabledBackground"
        fontFamily="inherit"
        autoComplete="new-password"
        pl={3}
        py={3}
        fontSize={2}
        lineHeight={2}
        width={width}
        {...props}
        type="text"
        tabIndex={focusable ? tabIndex : -1}
        data-testid="input-text-input"
      />
      {/*HACK - refactor message box to prevent duplication - duplicates is to pass coverage test. */}
      {error && (
        <SpanWrapper
          error={error}
          height={0}
          mx={0}
          fontSize={0}
          lineHeight={0}
          data-testid="error-wrapper"
        />
      )}
      {!error && message && (
        <SpanWrapper
          error={error}
          height={6}
          mx={2}
          fontSize={1}
          lineHeight={1}
          data-testid="input-text-message"
        >
          {message}
        </SpanWrapper>
      )}
    </FlexWrapper>
  );
};

InputText.defaultProps = {
  error: false,
  disabled: false,
  optional: false,
  focusable: true,
  displayLabel: "inline-block",
  displayTooltip: "flex",
};
