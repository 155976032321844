import React from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { Title } from "../Title";
import { OrderProgressSection } from "../OrderProgressSection";
import { OrderSiteDetails } from "./_/OrderSiteDetails";
import { OrderContactDetails } from "./_/OrderContactDetails";
import { OrderDetailsType } from "../../types/OrderDetails";
import { OrderDetailsGetSupport } from "./_/OrderDetailsGetSupport";
import {
  isOrderCancelledOrComplete,
  isOrderCurrentActivityReviewCancellation,
} from "../OrderDetailsList/_/OrderStatusUtil/OrderStatusUtil";
import { Span } from "../Span";
import { getOrderDetailsHeading } from "./helpers/OrderDetailsHelpers";
import { OrderDetailCancelModal } from "./_/OrderDetailCancelModal";

interface Props {
  orderDetails: OrderDetailsType;
  error?: boolean;
  orderId: string;
  handleOnCancelOrderNotification: (cancelResponse: boolean) => void;
}

const FlexWrapper = styled(Flex)`
  ${mediaQueries.largeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.largeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

export const OrderDetails: React.FC<Props> = ({
  orderDetails,
  orderId,
  handleOnCancelOrderNotification,
}) => {
  const {
    orderStatus,
    serviceRequestNumber,
    siteDetails,
    contactDetails,
    duePayments,
    documents,
  } = orderDetails;
  const { jobType, typeDetailed: orderDetailType } = siteDetails;
  const [duePayment] = duePayments;
  const heading = getOrderDetailsHeading(jobType);
  const isCancelModalVisible =
    !isOrderCancelledOrComplete(orderStatus.progressStatus) &&
    !isOrderCurrentActivityReviewCancellation(orderStatus.currentActivity);

  return (
    <GridWrapper mx={[2, 2, 4, 4, 0]}>
      <FlexWrapper flexDirection="column">
        <Flex alignItems="center" justifyContent={"space-between"}>
          <Title as="h1" lineHeight={6} fontWeight="500">
            {heading}
          </Title>

          {isCancelModalVisible && (
            <OrderDetailCancelModal
              orderStatus={orderStatus.progressStatus}
              orderId={orderId}
              handleOnCancelOrderNotification={handleOnCancelOrderNotification}
            />
          )}
        </Flex>

        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Span
            lineHeight={2}
            flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}
            fontWeight={500}
            mb={2}
          >
            {serviceRequestNumber}
          </Span>
        </Flex>

        <OrderProgressSection
          orderStatus={orderStatus}
          duePayment={duePayment}
          orderDetailType={orderDetailType}
        />

        <OrderSiteDetails siteDetails={siteDetails} documents={documents} />

        <OrderContactDetails contactDetails={contactDetails} />
        {!isOrderCancelledOrComplete(orderStatus.progressStatus) && (
          <OrderDetailsGetSupport />
        )}
      </FlexWrapper>
    </GridWrapper>
  );
};
