import React from "react";
import { AccountSecurityDetails } from "./AccountSecurityDetails";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

interface Props {
  onResetPasswordClick: (email: string) => void;
}

export const AccountSecurityDetailsContainer: React.FC<Props> = ({
  onResetPasswordClick,
}) => {
  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();
  const { email, passwordChangeDate, createdDate } = userAuth0Details;

  return (
    <AccountSecurityDetails
      email={email}
      passwordChangeDate={passwordChangeDate}
      createdDate={createdDate}
      onResetPasswordClick={onResetPasswordClick}
    />
  );
};
