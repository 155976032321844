import React from "react";
import { PaymentResultPage } from "./PaymentResultPage";
import { NotFound } from "../NotFound";
import { useSessionIdQuery } from "../../hooks/sessionIdFunction/useSessionIdQuery";

export const PaymentResultPageContainer: React.FC = () => {
  const sessionId = useSessionIdQuery();

  return (
    <>
      {sessionId && <PaymentResultPage />}

      {!sessionId && <NotFound />}
    </>
  );
};
