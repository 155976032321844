import React from "react";
import { NewConnection } from "../../components/NewConnection";
import { Page } from "../../components/Page";

export interface NewConnectionProps {
  tabTitle: string;
}

export const NewConnectionPage: React.FC<NewConnectionProps> = ({
  tabTitle,
}) => {
  return (
    <Page tabTitle={tabTitle}>
      <NewConnection />
    </Page>
  );
};
