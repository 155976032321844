import { Order } from "../../types/Order";

export const useGetRequesterList = (orderList: Order[]) => {
  const requesterList = orderList
    .map((order) => order.requester)
    .filter((value, index, self) => self.indexOf(value) === index);

  requesterList.unshift("All");
  return requesterList;
};
