import React from "react";
import { ActionLink } from "../../ActionLink";

interface Props {
  anyRolesSelected: boolean;
  remainingSelectableRoles: number;
  actionLinkRoleTitle: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tabIndex?: number;
  focusable?: boolean;
}

export const ContactDetailsFormAddContact: React.FC<Props> = ({
  anyRolesSelected,
  remainingSelectableRoles,
  onClick,
  focusable,
  tabIndex,
  actionLinkRoleTitle,
}) => {
  return (
    <ActionLink
      iconLeft="add_circle"
      onClick={onClick}
      disabled={!anyRolesSelected || remainingSelectableRoles === 0}
      tabIndex={focusable ? tabIndex : -1}
    >
      {actionLinkRoleTitle}
    </ActionLink>
  );
};

ContactDetailsFormAddContact.defaultProps = {
  tabIndex: 0,
  focusable: true,
};
