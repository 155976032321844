import React from "react";
import { useLocation } from "react-router-dom";

export const useSessionIdQuery = () => {
  const { search } = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search],
  );

  return searchParams.get("sessionId");
};
