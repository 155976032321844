import { Field, ErrorMessage } from "formik";
import React from "react";
import { Flex } from "../../../Flex";
import { FormikCounter } from "../../../FormikCounter";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";

export const Quantity: React.FC = () => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={0.3}
      flexBasis={0}
      minWidth={["100%", "100%", "100%", 0]}
      mr={[0, 0, 0, 3]}
      mb={[3, 3, 3, 0]}
    >
      <Field
        id="unmetered.quantity"
        name="unmetered.quantity"
        min={1}
        max={10}
        value={1}
        label="Quantity"
        component={FormikCounter}
      />
      <ErrorMessage
        name="unmetered.quantity"
        className="field-error"
        component={FormInputErrorMessage}
      />
    </Flex>
  );
};
