import { sessionStorageWrapper } from "../../../utils/sessionStorageWrapper/sessionStorageWrapper";
import { PersistedClient } from "@tanstack/react-query-persist-client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { retrieveUser } from "../../../services/UsersService";
import {
  hoursToMinutes,
  minutesToMs,
} from "../../../utils/numberComputationHelper/numberComputationHelper";
import { filterWithMultipleValues } from "../../../utils/arrayHelper/arrayHelper";
import {
  fullAccountDetailsKey,
  ReactQueryKeys,
} from "../../../constants/ReactQueryKeys";
import { UserFullDetails } from "../../../types/UserFullDetails";

export const useGetFullUserDetails = (
  auth0AccessToken: string,
  email: string,
  hasSiebelAccount?: boolean,
) => {
  const queryKey: ReactQueryKeys = "accountDetails";

  const ecssQueryCache =
    sessionStorageWrapper.getItem<PersistedClient>("ecssQueryCache");

  const [firstQuery] = filterWithMultipleValues(
    ecssQueryCache?.clientState?.queries,
    [...fullAccountDetailsKey, email],
    (query, filter) =>
      query.queryKey.includes(filter) && !query.state.isInvalidated,
  );

  const cachedFullDetails = firstQuery?.state?.data as
    | UserFullDetails
    | undefined;

  // override default staleTime and cacheTime in this query
  // - https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydefaults
  const minutes = hoursToMinutes(24);
  const queryClient = useQueryClient();
  queryClient.setQueryDefaults([queryKey], {
    staleTime: minutesToMs(minutes),
    cacheTime: minutesToMs(minutes),
  });

  /***
   * useQuery docs - https://tanstack.com/query/v4/docs/react/reference/useQuery
   */
  const { data: userFullDetails, isError: apiError } =
    useQuery<UserFullDetails>({
      queryKey: [...fullAccountDetailsKey, email, auth0AccessToken],
      queryFn: () => retrieveUser(auth0AccessToken),
      enabled: !!auth0AccessToken && !!hasSiebelAccount && !cachedFullDetails,
    });

  return { userFullDetails: cachedFullDetails ?? userFullDetails, apiError };
};
