export const getUserInitials = (firstName: string, lastName: string) =>
  `${firstName ? firstName.substring(0, 1) : ""}${
    lastName ? lastName.substring(0, 1) : ""
  }`;

/***
 * Get initials from first and last name, supports space separated names to cater for
 * names combined in one field as they came from Siebel - will only return first two initials
 * e.g. John Doe => JD
 * e.g. Jack Ryan Smith => JR
 *
 * @param firstName
 * @param lastName
 * @returns initials, empty string if no name provided
 */
export const getUserInitialsWithMultipleNames = (
  firstName: string,
  lastName: string,
) =>
  `${
    firstName
      ? firstName
          .split(" ")
          .slice(0, 2)
          .map((name) => name.substring(0, 1))
          .join("")
      : ""
  }${
    lastName
      ? lastName
          .split(" ")
          .slice(0, 2)
          .map((name) => name.substring(0, 1))
          .join("")
      : ""
  }`;
