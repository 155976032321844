import React from "react";
import {
  AccountSettingsPage,
  AccountSettingsPageProps,
} from "./AccountSettingsPage";

export const DEFAULT_ACCOUNT_SETTINGS_TYPE: AccountSettingsPageProps = {
  tabTitle: "",
};

export const AccountSettingsPageContainer: React.FC = () => {
  return <AccountSettingsPage {...DEFAULT_ACCOUNT_SETTINGS_TYPE} />;
};
