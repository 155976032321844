import { UserAuthentication } from "../../types/UserAuthentication";
import { UserAuthenticationAction } from "../../types/UserAuthenticationAction";
import UserAuthenticationActionType from "../../types/UserAuthenticationActionType";

export const authenticatedUserReducer = (
  state: UserAuthentication,
  action: UserAuthenticationAction,
): UserAuthentication => {
  const { type, payload } = action;

  switch (type) {
    case UserAuthenticationActionType.Error: {
      const { hasError } = payload;

      return {
        ...state,
        hasError,
        isLoading: false,
      };
    }

    case UserAuthenticationActionType.LogInStopped: {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        isGuest: true,
      };
    }

    case UserAuthenticationActionType.LogInCompleted: {
      return {
        ...state,
        isLoggedIn: true,
        isGuest: false,
      };
    }

    case UserAuthenticationActionType.Auth0DetailsRetrieved: {
      const { userAuth0Details } = payload;

      return {
        ...state,
        userAuth0Details,
      };
    }

    case UserAuthenticationActionType.SiebelAccountVerified: {
      const { isLoading, hasSiebelAccount } = payload;

      return {
        ...state,
        isLoading,
        hasSiebelAccount,
      };
    }

    case UserAuthenticationActionType.BasicDetailsRetrieved: {
      const { userBasicDetails } = payload;

      return {
        ...state,
        userBasicDetails,
        isLoading: false,
      };
    }

    case UserAuthenticationActionType.BasicDetailsUpdated: {
      const { userBasicDetails } = payload;
      return {
        ...state,
        userBasicDetails: {
          ...state.userBasicDetails,
          ...userBasicDetails,
        },
      };
    }

    default:
      return state;
  }
};
