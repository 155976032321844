import React from "react";
import { Order } from "../../../../types/Order";
import { Flex } from "../../../Flex";
import styled from "styled-components";
import { Span } from "../../../Span";
import { OrderStatusIcon } from "../../../OrderStatusIcon";
import { getStatusColor } from "../OrderStatusUtil/OrderStatusUtil";
import { useGoToOrderDetails } from "../../../OrderDetails/helpers/OrderDetailsHelpers";
import { mapStringToOrderStatus } from "../../../../utils/orderStatusMapper/orderStatusMapper";

interface Props {
  type: OrdersFilterStatus;
  order: Order;
}

const CardRow = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 8px;
`;

const CardHeader = styled(Span)`
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  color: ${({ theme }) => theme.colors.neutrals.mn80};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

const CardData = styled(Span)`
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.nightSky};
  text-align: right;
`;

const FlexWrapper = styled(Flex)`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydroDark};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
    outline: none;
  }
`;

export const Card: React.FC<Props> = ({ type, order }) => {
  const goToOrderDetails = useGoToOrderDetails(order);

  const orderStatus = mapStringToOrderStatus(order.jobStatus);

  return (
    <FlexWrapper
      flexDirection="column"
      p={4}
      borderRadius="notificationBanner"
      border="1px solid"
      borderColor="neutrals.ln30"
      marginBottom={3}
      padding="10px"
      onClick={goToOrderDetails}
    >
      <CardRow>
        <Span data-testid="order-row-site" fontSize={2} lineHeight={2}>
          {order.address}
        </Span>
      </CardRow>

      <Flex marginBottom={3} alignItems="center">
        {orderStatus !== "" && (
          <OrderStatusIcon
            color={getStatusColor(orderStatus)}
            status={orderStatus}
          />
        )}
      </Flex>

      <CardRow>
        <CardHeader data-testid="order-header-request-date">
          Requested date
        </CardHeader>

        <CardData data-testid="order-row-request-date">
          {order.requestDate}
        </CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-job-type">Job Type</CardHeader>
        <CardData data-testid="order-row-job-type">{order.jobType}</CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-requested-by">
          Requested by
        </CardHeader>
        <CardData data-testid="order-row-requested-by">
          {order.requester}
        </CardData>
      </CardRow>

      <CardRow>
        <CardHeader data-testid="order-header-order-number">
          Order no.
        </CardHeader>
        <CardData data-testid="order-row-order-number">
          {order.orderNumber}
        </CardData>
      </CardRow>

      {type === "open" && (
        <CardRow>
          <CardHeader data-testid="order-header-current-activity">
            Current activity
          </CardHeader>
          <CardData data-testid="order-row-current-activity">
            {order.currentActivity}
          </CardData>
        </CardRow>
      )}

      {type === "closed" && (
        <CardRow>
          <CardHeader data-testid="order-header-reason">Reason</CardHeader>
          <CardData data-testid="order-row-reason">{order.reason}</CardData>
        </CardRow>
      )}
    </FlexWrapper>
  );
};
