import React from "react";
import styled from "styled-components";
import { PayNowReviewQuoteDetails } from "../PayNowReviewQuoteDetails";
import { PayNowReviewThingsToKnow } from "../PayNowReviewThingsToKnow";
import { Box } from "../../../Box";
import { PayNowOrderPayment } from "../../../../types/PayNowOrderPayment";
import { Flex } from "../../../Flex";
import { PaymentPageInformationValues } from "../../../../constants/PaymentInformationValues";

interface Props {
  id: string;
  orderPayment: PayNowOrderPayment;
}

const PayNowReviewQuoteWrapper = styled(Box)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const PayNowReviewQuote: React.FC<Props> = ({ id, orderPayment }) => {
  return (
    <Flex flexDirection="column" id={id}>
      <PayNowReviewQuoteWrapper p={4} borderRadius="radio">
        <PayNowReviewQuoteDetails orderPayment={orderPayment} />
      </PayNowReviewQuoteWrapper>
      <PayNowReviewThingsToKnow
        title={PaymentPageInformationValues.title}
        answer={PaymentPageInformationValues.answer}
      />
    </Flex>
  );
};
