import React from "react";
import styled, { keyframes } from "styled-components";
import { color, ColorProps, space, SpaceProps } from "styled-system";

const rotate = keyframes`
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(720deg);
  }
`;

const Box = styled.div<SpaceProps>`
  ${space}
  vertical-align: middle;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
`;

/* eslint-disable  @typescript-eslint/no-explicit-any */
const Circle = styled.div<any>`
  ${color}
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.25);
  backface-visibility: hidden;
  transform-origin: 0 0;

  div {
    box-sizing: border-box !important;
  }

  > div {
    position: absolute;
    width: 82px;
    height: 82px;
    top: 9px;
    left: 9px;
    border-radius: 50%;
    border: 10px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: ${rotate} 3s linear infinite;
  }

  > div:nth-child(2) {
    border-color: transparent;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: rotate(45deg);

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        top: -10px;
        left: 26px;
        background: currentColor;
        border-radius: 50%;
        box-shadow: 0 72px 0 0 currentColor;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        left: -10px;
        top: 26px;
        background: currentColor;
        border-radius: 50%;
      }

      &:after {
        left: -10px;
        top: 26px;
        box-shadow: 72px 0 0 0 currentColor;
      }
    }
  }
`;

export const Spinner: React.FC<SpaceProps & ColorProps> = ({
  color,
  ...props
}) => {
  return (
    <Box data-testid="spinner" {...props}>
      <Circle color={color}>
        <div />
        <div>
          <div />
        </div>
      </Circle>
    </Box>
  );
};

Spinner.defaultProps = {
  bg: "transparent",
  color: "primary",
};
