import React from "react";
import { FieldProps, getIn } from "formik";
import { AddressSearchField } from "../AddressSearchField";
import { AddressSearchFieldProps } from "../AddressSearchField/AddressSearchFieldContainer";
import { FlexGrowProps } from "styled-system";

interface Props {
  nameId: string;
  isPostalAddress: boolean;
}

export const FormikAddressSearchField: React.FC<
  Props & FieldProps & AddressSearchFieldProps & FlexGrowProps
> = ({ field, form, nameId, isPostalAddress, ...props }) => {
  const handleOnChange = (value?: string | null | undefined) => {
    form.setFieldValue(field.name, value);
    form.setFieldValue(nameId, "");
  };

  const handleOnSelect = (
    value?: string | null | undefined,
    valueId?: string | null,
  ) => {
    form.setFieldValue(field.name, value);
    form.setFieldValue(nameId, valueId);

    setTimeout(() => {
      form.validateField(field.name);
    }, 200);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <AddressSearchField
      {...field}
      error={touch && error ? error : false}
      {...props}
      onSelect={handleOnSelect}
      onChange={handleOnChange}
      isPostalAddress={isPostalAddress}
    />
  );
};
