import React from "react";
import { ServiceTypeFormValues } from "../../types/ServiceTypeFormValues";
import { Span } from "../Span";
import { Flex } from "../Flex";
import { Box } from "../Box";
import { Field } from "formik";
import { FormikRadioButtonDescriptive } from "../FormikRadioButtonDescriptive";
import { CantFindWhatYouAreLookingFor } from "../CantFindWhatYouAreLookingFor";

interface Props {
  values: ServiceTypeFormValues;
  onServiceTypeSelected: () => void;
}

export const ServiceTypeGas: React.FC<Props> = ({
  values,
  onServiceTypeSelected,
}) => {
  const handleOnServiceTypeSelected = () => {
    onServiceTypeSelected();
  };

  return (
    <>
      <Span
        display="inline-block"
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mt={7}
        mb={5}
      >
        Select a service type.
      </Span>

      <Flex flexDirection="column" justifyContent="space-between">
        <Box mb={3}>
          <Field
            id="newResidentialConnection"
            name="gasServiceType"
            label="New residential connection"
            value="newResidentialConnection"
            description="If there is no piped gas connected to the residential property, get an estimate on how much
            it will cost to connect and order a new connection."
            currentValue={values.gasServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <Box mb={3}>
          <Field
            id="gasDisconnection"
            name="gasServiceType"
            label="Disconnection (Permanent)"
            value="gasDisconnection"
            description="If you need a temporary or permanent gas disconnection, please contact your gas retailer.
            This form is only to be used if you do not know the gas retailer for the site."
            currentValue={values.gasServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <Box mb={7}>
          <Field
            id="referenceMaps"
            name="gasServiceType"
            label="Reference maps"
            value="referenceMaps"
            description={
              "Request reference maps indicating the approximate location of our underground electricity," +
              "gas and fibre cables and pipes."
            }
            currentValue={values.gasServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <CantFindWhatYouAreLookingFor serviceType={values.service} />
      </Flex>
    </>
  );
};
