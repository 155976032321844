import React, { useState } from "react";
import { Field, FieldArrayRenderProps } from "formik";
import { ActionButton } from "../ActionButton";
import { FormikCounter } from "../FormikCounter";
import { Modal } from "../Modal";
import { ManualUnitValues } from "../../types/ManualUnitValues";
import { EMPTY_MANUAL_UNIT_VALUES } from "../../constants/ManualUnitValues";

interface Props {
  units: ManualUnitValues[];
  index: number;
  arrayHelpers: FieldArrayRenderProps;
}

export const UnitCopy: React.FC<Props> = ({ units, index, arrayHelpers }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOnCancel = () => {
    setShowModal(false);
  };

  const handleOnConfirm = () => {
    const copiedUnit = units[index];

    for (let i = 0; i < arrayHelpers.form.values.unitCopyCount; i++) {
      arrayHelpers.push({
        unitNumber: EMPTY_MANUAL_UNIT_VALUES.unitNumber,
        type: copiedUnit.type,
        phase: copiedUnit.phase,
        fuseAmp: copiedUnit.fuseAmp,
        supply: copiedUnit.supply,
      });
    }

    setShowModal(false);
  };

  const areUnitsPresent = (): boolean => {
    return units[index].unitNumber === "";
  };

  return (
    <>
      <ActionButton
        icon="content_copy"
        onClick={handleShowModal}
        disabled={areUnitsPresent()}
      />

      <Modal
        title="Duplicate site details"
        showModal={showModal}
        cancelText="Cancel"
        confirmText="Apply"
        cancelButtonVariant="ghost"
        onCloseModal={handleCloseModal}
        onCancel={handleOnCancel}
        onConfirm={handleOnConfirm}
      >
        <Field
          id="unitCopyCount"
          name="unitCopyCount"
          value={1}
          mr="auto"
          component={FormikCounter}
        />
      </Modal>
    </>
  );
};
