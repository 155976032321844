import React, { useEffect } from "react";
import { Flex } from "../Flex";
import { Button } from "../Button";
import { Span } from "../Span";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { usePaginationFunctions } from "../../hooks/usePaginationFunctions/usePaginationFunctions";

interface Props {
  totalPages: number;
  pageIndex: number;
  canPreviousPage: boolean;
  previousPage: () => void;
  canNextPage: boolean;
  nextPage: () => void;
  goToPage: (pageIndex: number) => void;
  requesterFilter?: string;
  searchFilter?: string;
}

const DesktopButton = styled(Button)`
  display: none;

  ${mediaQueries.largeDevice} {
    display: inline-block;
  }
`;

const MobileButton = styled(Button)`
  display: inline-block;

  ${mediaQueries.largeDevice} {
    display: none;
  }
`;

export const Pagination: React.FC<Props> = ({
  totalPages,
  pageIndex,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  goToPage,
  requesterFilter,
  searchFilter,
}) => {
  const {
    currentPageGroup,
    pages,
    getRealPageIndex,
    goToPageIndex,
    goToFirstPage,
    goToLastPage,
  } = usePaginationFunctions(5, totalPages);

  const firstPageClickHandler = () => {
    goToFirstPage();
    goToPage(0);
  };

  const lastPageClickHandler = () => {
    goToLastPage();
    goToPage(totalPages - 1);
  };

  const previousPageClickHandler = () => {
    goToPageIndex(pageIndex - 1);
    previousPage();
  };

  const nextPageClickHandler = () => {
    goToPageIndex(pageIndex + 1);
    nextPage();
  };

  const pageIndexClickHandler = (renderedPageNumber: number) => {
    goToPageIndex(pageIndex);
    const realPageIndex = getRealPageIndex(
      currentPageGroup,
      renderedPageNumber,
    );
    goToPage(realPageIndex);
  };

  useEffect(() => {
    goToFirstPage();
  }, [requesterFilter, searchFilter, goToFirstPage]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      mx="auto"
      mt="auto"
      pb={6}
    >
      <DesktopButton
        data-testid="pagination-first-desktop-button"
        onClick={firstPageClickHandler}
        disabled={!canPreviousPage}
        iconLeft="keyboard_double_arrow_left"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={7}
        px={2}
      >
        First
      </DesktopButton>
      <DesktopButton
        data-testid="pagination-previous-desktop-button"
        onClick={previousPageClickHandler}
        disabled={!canPreviousPage}
        iconLeft="arrow_back_ios"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={3}
        px={2}
      >
        Prev
      </DesktopButton>

      <MobileButton
        data-testid="pagination-first-mobile-button"
        onClick={firstPageClickHandler}
        disabled={!canPreviousPage}
        iconLeft="keyboard_double_arrow_left"
        variant="ghost"
        fontWeight="regular"
        magnitude="small"
        iconSize={7}
        px={0}
      />
      <MobileButton
        data-testid="pagination-previous-mobile-button"
        onClick={previousPageClickHandler}
        disabled={!canPreviousPage}
        iconLeft="arrow_back_ios"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={3}
        px={1}
      />

      {pages.map((_number, index) => {
        const computedIndex = getRealPageIndex(currentPageGroup, index);
        return (
          <Flex
            key={index}
            borderRadius="input"
            justifyContent="center"
            alignItems="center"
            backgroundColor={
              pageIndex === computedIndex ? "neutrals.ln40" : "neutrals.ln0"
            }
            width={10}
            height={10}
            mx={2}
          >
            <Span
              style={{ cursor: "pointer" }}
              onClick={() => pageIndexClickHandler(index)}
              color="neutrals.dn40"
              data-testid={`pagination-page-${index + 1}`}
            >
              {computedIndex + 1}
            </Span>
          </Flex>
        );
      })}

      <MobileButton
        data-testid="pagination-next-mobile-button"
        onClick={nextPageClickHandler}
        disabled={!canNextPage}
        iconRight="arrow_forward_ios"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={3}
        px={1}
      />
      <MobileButton
        data-testid="pagination-last-mobile-button"
        onClick={lastPageClickHandler}
        disabled={!canNextPage}
        iconRight="keyboard_double_arrow_right"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={7}
        px={0}
      />

      <DesktopButton
        data-testid="pagination-next-desktop-button"
        onClick={nextPageClickHandler}
        disabled={!canNextPage}
        iconRight="arrow_forward_ios"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={3}
        px={2}
      >
        Next
      </DesktopButton>
      <DesktopButton
        data-testid="pagination-last-desktop-button"
        onClick={lastPageClickHandler}
        disabled={!canNextPage}
        iconRight="keyboard_double_arrow_right"
        variant="ghost"
        fontWeight="medium"
        magnitude="small"
        iconSize={7}
        px={2}
      >
        Last
      </DesktopButton>
    </Flex>
  );
};
