import { apiClient } from "../client/ApiClient";
import { ExtendedAxiosRequestConfig } from "../types/ExtendedAxiosRequestConfig";
import { ExtendedAxiosResponse } from "../types/ExtendedAxiosResponse";
import { FileScanResponse } from "../types/FileScanResponse";
import { UploadFileResponse } from "../types/UploadFileResponse";
import {
  calculateRemainingTimeoutInterval,
  getDelayInterval,
  shouldScanResultRetry,
} from "./FileUploadServiceUtil";

export const SCAN_TIMEOUT_MILLISECONDS = 600000; //10mins

export const uploadFile = async (
  file: CssFile,
  attachmentType: string,
): Promise<UploadFileResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  return await apiClient
    .post(`file-upload/${attachmentType}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

// Using a variation of the retry logic/mechanism found in https://github.com/softonic/axios-retry
// At the time of writing axios-retry doesn't allow for custom 200 retry logic (only errors)
const getFileScanResultAsync = async (
  md5: string,
  attachmentType: string,
): Promise<FileScanResponse> => {
  let executionCount = 1;

  apiClient.defaults.timeout = SCAN_TIMEOUT_MILLISECONDS;

  apiClient.interceptors.request.use((config: ExtendedAxiosRequestConfig) => {
    config.lastRequestTime = Date.now();
    return config;
  });

  apiClient.interceptors.response.use(
    (response: ExtendedAxiosResponse<FileScanResponse>) => {
      const { data, config } = response;

      /* istanbul ignore next */
      if (!config) {
        return Promise.reject(response);
      }

      const shouldRetry = shouldScanResultRetry(data);
      if (!shouldRetry) {
        return Promise.resolve(response);
      }

      executionCount = executionCount + 1;
      config.executionCount = executionCount;

      const delayInterval = getDelayInterval(executionCount);
      config.timeout = calculateRemainingTimeoutInterval(
        delayInterval,
        config.timeout,
        config.lastRequestTime,
      );

      config.transformRequest = [(data) => data];

      return new Promise((resolve) =>
        setTimeout(() => {
          resolve(apiClient.request(config));
        }, delayInterval),
      );
    },
  );

  return await apiClient
    .get<FileScanResponse>(`file-scan-result\\${md5}`, {
      params: { attachmentType },
    })
    .then((response) => {
      return response.data;
    })
    .then((response) => {
      return response;
    });
};

export const getFileScanResult = async (
  md5: string,
  attachmentType: string,
): Promise<FileScanResponse> => {
  return getFileScanResultAsync(md5, attachmentType);
};
