export const FileTypeValues: FileType[] = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "application/acad",
  "application/x-acad",
  "application/autocad_dwg",
  "image/x-dwg",
  "application/dwg",
  "application/x-dwg",
  "application/x-autocad",
  "image/vnd.dwg",
  "drawing/dw",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
];
