import React from "react";
import { SpaceProps } from "styled-system";
import { Flex } from "../Flex";
import { Loading } from "../Loading";
import { VectorLogo } from "../VectorLogo";

interface Props {
  text?: string;
  showVectorLogo?: boolean;
}

export const CenteredLoading: React.FC<Props & SpaceProps> = ({
  text,
  showVectorLogo,
  mt,
}) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      align-content="center"
      height="100vh"
      mt={mt}
    >
      <Flex flexDirection="column" alignItems="center" align-content="center">
        {showVectorLogo && (
          <VectorLogo
            height={120}
            width={120}
            my={3}
            src={"/assets/images/vector-logo-desktop.svg"}
          />
        )}
        <Loading text={text} />
      </Flex>
    </Flex>
  );
};

CenteredLoading.defaultProps = {
  text: "Loading",
  showVectorLogo: false,
};
