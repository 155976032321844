import React from "react";
import { getNewConnectionRolesTitle } from "../../../../../ContactDetailsRolesTitle/ContactDetailsRolesTitle";
import { Flex } from "../../../../../Flex";
import { Span } from "../../../../../Span";
import { ReviewFormContactDetails } from "../../../../../ReviewFormContactDetails";
import { NewConnectionContactDetailsValues } from "../../../../../../types/NewConnectionContactDetailsValues";
import {
  HasElectricianTypes,
  HasLandOwnerTypes,
} from "../../../../../../constants/HasOptionalRoleValues";

interface Props {
  contactDetails: NewConnectionContactDetailsValues;
  contactDetailsIndex: number;
  hasElectrician: HasElectricianTypes;
  hasLandOwner: HasLandOwnerTypes;
}

export const ReviewFormNewConnectionContactDetailsWithTitle: React.FC<Props> =
  ({ contactDetails, contactDetailsIndex, hasElectrician, hasLandOwner }) => {
    return (
      <Flex flexDirection="column">
        <Span lineHeight={2} fontWeight="bold" mb={3}>
          {getNewConnectionRolesTitle(
            contactDetails.roles,
            contactDetailsIndex === 0,
            hasElectrician,
            hasLandOwner,
          )}
        </Span>

        <ReviewFormContactDetails contactDetails={contactDetails} />
      </Flex>
    );
  };
