import React, { useEffect, useState } from "react";
import { PayNowOrderPayment } from "../../../../types/PayNowOrderPayment";
import { Flex } from "../../../Flex";
import { Title } from "../../../Title";
import { ActionLink } from "../../../ActionLink";
import { Button } from "../../../Button";
import { SquareCheckbox } from "../../../SquareCheckbox";
import { Span } from "../../../Span";
import { ContactDetailFields } from "../../../ContactDetailsForm/ContactDetailFields";
import { Box } from "../../../Box";
import { BillingAddressSection } from "../../../BillingAddressSection";
import {
  checkIsManualAddress,
  checkIsSearchedAddress,
} from "../../../ManualAddressHelperUtil/ManualAddressHelperUtil";

interface Props {
  onEdit: () => void;
  editMode: boolean;
  selectedAcknowledged: boolean;
  onSelectAcknowledged: () => void;
  handleSubmit: (values: PayNowOrderPayment) => void;
  handleCancel: () => void;
  validateField: (field: string) => void;
  values: PayNowOrderPayment;
  initialValues: PayNowOrderPayment;
}

export const PayNowBillingDetailsForm: React.FC<Props> = ({
  onEdit,
  editMode,
  selectedAcknowledged,
  onSelectAcknowledged,
  handleCancel,
  validateField,
  values,
  initialValues,
}) => {
  const prefix = "billingDetails";
  const billingDetails = values.billingDetails;

  const [isManualAddress, setIsManualAddress] = useState(false);

  useEffect(() => {
    if (values.billingDetails.manuallyEnteredBillingAddress) {
      setIsManualAddress(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      values.billingDetails.billingAddressDisplay !==
      initialValues.billingDetails.billingAddressDisplay
    ) {
      values.billingDetails.addressId = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.billingDetails.billingAddressDisplay]);

  useEffect(() => {
    // Toggle manuallyEnteredAddress in Formik values to manage field validation
    billingDetails.manuallyEnteredBillingAddress = isManualAddress;

    //the address is manual entered
    if (
      checkIsManualAddress(
        isManualAddress,
        billingDetails.manuallyEnteredBillingAddress,
      )
    ) {
      billingDetails.billingAddressDisplay = "";
      billingDetails.billingAddressId = "";
      billingDetails.addressId = "";
    }

    //the address is searched
    if (
      checkIsSearchedAddress(
        isManualAddress,
        billingDetails.manuallyEnteredBillingAddress,
      )
    ) {
      billingDetails.manualBillingAddress.addressString = "";
      billingDetails.manualBillingAddress.city = "";
      billingDetails.manualBillingAddress.suburb = "";
      billingDetails.manualBillingAddress.postcode = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualAddress]);

  const handleActionLinkClick = () => {
    // Manually trigger Formik field validation for street address because action link has no blur
    validateField("billingDetails.billingAddressDisplay");

    setIsManualAddress((prevState) => !prevState);
  };

  const handleHideManualWhenCancel = () => {
    setIsManualAddress(false);
    handleCancel();
  };

  return (
    <Flex flexDirection="column">
      <Box>
        <Flex justifyContent="space-between">
          <Title as="h1" lineHeight={7} fontWeight="medium" my={5}>
            Billing Details
          </Title>

          <ActionLink
            iconLeft="edit"
            alignSelf="flex-end"
            onClick={onEdit}
            py={5}
            mb={2}
          >
            Edit
          </ActionLink>
        </Flex>

        <Flex flexDirection="column">
          <ContactDetailFields
            focusable={true}
            idPrefix={prefix}
            namePrefix={prefix}
            optionalBusinessName={true}
            showPhone={false}
            disabledFirstName={!editMode}
            disabledLastName={!editMode}
            disabledEmail={!editMode}
            disabledBusinessName={!editMode}
          />

          <BillingAddressSection
            fieldIdPrefix={prefix}
            fieldNamePrefix={prefix}
            shouldDisableContactDetails={!editMode}
            isManualAddress={isManualAddress}
            validateField={validateField}
            handleActionLinkClick={handleActionLinkClick}
          />
        </Flex>

        {editMode && (
          <Flex justifyContent="flex-end">
            <Button
              variant="ghost"
              alignSelf="flex-end"
              onClick={handleHideManualWhenCancel}
              mr="3"
              px="6"
              py="3"
              data-testid="cancel-button"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="secondary"
              alignSelf="flex-end"
              px="6"
              py="3"
              data-testid="save-button"
            >
              Save
            </Button>
          </Flex>
        )}
      </Box>

      <Flex mt={7}>
        <SquareCheckbox
          id="terms-conditions"
          label={
            /* istanbul ignore next */ () => (
              <Span>
                I acknowledge that the billing details above are correct
              </Span>
            )
          }
          name="billing-details-acknowledge"
          onChange={onSelectAcknowledged}
          checked={selectedAcknowledged}
          value={selectedAcknowledged}
          disabled={editMode}
          mr={3}
          data-testid="billing-details-acknowledge"
        />
      </Flex>
    </Flex>
  );
};
