import React, { ChangeEvent, InputHTMLAttributes } from "react";
import { RadioButton } from "../RadioButton";
import { FieldProps, getIn } from "formik";
import { RadioButtonProps } from "../RadioButton/RadioButton";
import { FlexboxProps, SpaceProps, TypographyProps } from "styled-system";
import { GAEvent, sendGAEvent } from "../../utils/ga/GaEventSender";

interface Props {
  gaEvent?: GAEvent;
  useTimeout?: boolean;
  alignText?: string;
}

export const FormikRadioButton: React.FC<
  FieldProps &
    FlexboxProps &
    RadioButtonProps &
    SpaceProps &
    TypographyProps &
    InputHTMLAttributes<HTMLInputElement> &
    Props
> = ({ field, form, gaEvent, useTimeout, alignText, ...props }) => {
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (gaEvent) {
      sendGAEvent(gaEvent);
    }

    field.onChange(evt);

    if (useTimeout) {
      setTimeout(() => {
        form.validateField(field.name);
      }, 200);
    } else {
      form.validateField(field.name);
    }
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <RadioButton
      {...field}
      error={touch && error ? error : false}
      {...props}
      onChange={handleChange}
      alignText={alignText}
    />
  );
};

FormikRadioButton.defaultProps = {
  useTimeout: false,
};
