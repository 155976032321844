import React from "react";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Span } from "../Span";
import { Button } from "../Button";
import { useHistory } from "react-router-dom";

export const OrderListEmpty: React.FC = () => {
  const history = useHistory();

  const handleNewOrderClick = () => {
    history.push("/service-type");
  };

  return (
    <Flex flexDirection="column" textAlign="center" alignItems="center" mt={7}>
      <Flex
        borderRadius="50%"
        backgroundColor="neutrals.ln20"
        height={17}
        width={17}
        textAlign="center"
        mb={4}
      >
        <Icon
          margin="auto"
          icon="add_circle"
          color="neutrals.mn80"
          fontSize={8}
        />
      </Flex>
      <Span mb={4} fontSize={4} lineHeight={4}>
        You don’t have any order yet
      </Span>
      <Button
        data-testid="new-order-button"
        alignSelf="flex-end"
        variant="secondary"
        magnitude="medium"
        onClick={handleNewOrderClick}
      >
        Start new order
      </Button>
    </Flex>
  );
};
