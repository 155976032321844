import React from "react";
import {
  OrderDetailsListPage,
  OrderDetailsListProps,
} from "./OrderDetailsListPage";

export const DEFAULT_ORDER_DETAILS_LIST_CONTENT: OrderDetailsListProps = {
  tabTitle: "",
};

export const OrderDetailsListPageContainer: React.FC = () => {
  return <OrderDetailsListPage {...DEFAULT_ORDER_DETAILS_LIST_CONTENT} />;
};
