import { ManualStreetAddress } from "../../../../types/ManualStreetAddress";
import { SiteAddressInformation } from "../../../../types/SiteAddressInformation";
import { SiteLotInformation } from "../../../../types/SiteLotInformation";

const getDraftManualAddress = (
  manualStreetAddress: ManualStreetAddress,
): string => {
  const addressArray = [];

  if (manualStreetAddress.unit) {
    addressArray.push(`Unit ${manualStreetAddress.unit}`);
  }

  if (manualStreetAddress.streetNumber && manualStreetAddress.streetName) {
    addressArray.push(
      `${manualStreetAddress.streetNumber} ${manualStreetAddress.streetName}`,
    );
  } else if (manualStreetAddress.streetNumber) {
    addressArray.push(`${manualStreetAddress.streetNumber}`);
  } else if (manualStreetAddress.streetName) {
    addressArray.push(`${manualStreetAddress.streetName}`);
  }

  if (manualStreetAddress.suburb) {
    addressArray.push(`${manualStreetAddress.suburb}`);
  }
  if (manualStreetAddress.postcode) {
    addressArray.push(`${manualStreetAddress.postcode}`);
  }

  return addressArray.join(", ");
};

export const getDraftSiteLotInformation = (
  siteLotInformation: SiteLotInformation,
): string => {
  const addressArray = [];

  if (siteLotInformation.lotNumber) {
    addressArray.push(`Lot ${siteLotInformation.lotNumber}`);
  }

  if (siteLotInformation.dpNumber) {
    addressArray.push(`DP ${siteLotInformation.dpNumber}`);
  }

  if (siteLotInformation.streetName) {
    addressArray.push(
      `${
        siteLotInformation.streetNumber
          ? siteLotInformation.streetNumber +
            " " +
            siteLotInformation.streetName
          : siteLotInformation.streetName
      }`,
    );
  }

  if (siteLotInformation.suburb) {
    addressArray.push(`${siteLotInformation.suburb}`);
  }

  if (siteLotInformation.postcode) {
    addressArray.push(`${siteLotInformation.postcode}`);
  }

  return addressArray.join(", ");
};

export const getDraftAddressInformation = (
  siteAddressInformation: SiteAddressInformation,
): string => {
  if (siteAddressInformation.manuallyEnteredAddress) {
    return getDraftManualAddress(siteAddressInformation.manualStreetAddress);
  }

  return siteAddressInformation.streetAddress;
};
