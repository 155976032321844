import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { DropZoneFileUpload } from "./_/DropZoneFileUpload/DropZoneFileUpload";
import { Flex } from "../Flex";
import { UploadFileItem } from "./_/UploadFileItem";
import { UploadUnitValues } from "../../types/UploadUnitValues";
import {
  mockArrayBuffer,
  mockSlice,
  mockStream,
  mockText,
} from "./_/FileUtil/FileUtil";
import {
  getFileSelectUploadStatus,
  getFileNameFromPath,
} from "../FileUploadUtil/FileUploadUtil";

export interface FileUploadProps {
  value: UploadUnitValues[];
  acceptFileTypes: FileType[];
  allowMultiple?: boolean;
  error?: boolean;
  errorMessage?: string;
  onChange?: (units: UploadUnitValues[]) => void;
  onBlur?: () => void;
  onStatusChange: (filesUploadStatus: FileSelectUploadStatus) => void;
  attachmentType: string;
}

const MAX_UPLOAD_FILES = 5;
const MAX_FILE_SIZE = 20971520;

export const FileUploadContainer: React.FC<
  FileUploadProps &
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "value" | "onBlur" | "onChange" | "onSelect"
    >
> = ({ id, value, onChange, onStatusChange, attachmentType, ...props }) => {
  const [selectedFiles, setSelectedFiles] = useState<CssFile[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadUnitValues[]>([]);

  const handleSelectedFiles = (selectedFiles: CssFile[]) => {
    const transformedFiles: CssFile[] = selectedFiles.map((selectedFile) => {
      const fileBlob = selectedFile.slice(
        0,
        selectedFile.size,
        selectedFile.type,
      );
      const transformedName = getFileNameFromPath(selectedFile.name);
      const newFile = new File([fileBlob], transformedName, {
        type: selectedFile.type,
        lastModified: selectedFile.lastModified,
      }) as CssFile;

      newFile.attachmentType = attachmentType;

      return newFile;
    });
    setSelectedFiles((prevState) => [...prevState, ...transformedFiles]);
  };

  const handleOnUploadScanComplete = (file: CssFile) => {
    const convertedFile = {
      md5: file.md5,
      fileName: file.name,
      attachmentType: attachmentType,
    };

    setUploadedFiles((prevState) => [...prevState, convertedFile]);
  };

  const handleFileDelete = (file: CssFile) => {
    const updatedUploadFiles = selectedFiles.filter(
      (selectedFile: CssFile) => selectedFile.name !== file.name,
    );

    const updatedUnits = value.filter(
      (unit: UploadUnitValues) => unit.fileName !== file.name,
    );

    setSelectedFiles([...updatedUploadFiles]);
    setUploadedFiles([...updatedUnits]);
  };

  // If the selected files are being set by way of value
  useEffect(() => {
    if (value.length > 0) {
      const mappedSelectedFiles = value.map((uploadedUnit) => {
        return {
          md5: uploadedUnit.md5,
          name: uploadedUnit.fileName,
          attachmentType: attachmentType,
          uploaded: true,
          scanned: true,
          lastModified: 0,
          webkitRelativePath: "",
          size: 0,
          type: "",
          arrayBuffer: mockArrayBuffer,
          slice: mockSlice,
          stream: mockStream,
          text: mockText,
        };
      });

      setSelectedFiles(() => [...mappedSelectedFiles]);

      mappedSelectedFiles.forEach((selectedFile) => {
        handleOnUploadScanComplete(selectedFile);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filesUploadStatus = getFileSelectUploadStatus(
      selectedFiles,
      uploadedFiles,
    );

    onStatusChange && onStatusChange(filesUploadStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles, uploadedFiles]);

  useEffect(() => {
    onChange && onChange(uploadedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  return (
    <Flex id={id} flexDirection="column">
      <DropZoneFileUpload
        {...props}
        maxSizeInBytes={MAX_FILE_SIZE}
        maxFiles={MAX_UPLOAD_FILES - selectedFiles.length}
        disabled={selectedFiles.length >= MAX_UPLOAD_FILES}
        rejectedFileNames={selectedFiles.map(
          (selectedFile) => selectedFile.name,
        )}
        onFilesSelected={handleSelectedFiles}
      />

      {selectedFiles.map((file: CssFile, index: number) => (
        <UploadFileItem
          key={`${index}_${file.name}`}
          id={`upload_file_item_${index}`}
          mt={3}
          file={file}
          onDelete={handleFileDelete}
          onUploadScanComplete={handleOnUploadScanComplete}
          attachmentType={attachmentType}
        />
      ))}
    </Flex>
  );
};

FileUploadContainer.defaultProps = {
  value: [],
};
