import React from "react";
import styled from "styled-components";
import { Flex } from "../../components/Flex";
import { Title } from "../../components/Title";
import { Box } from "../../components/Box";
import { Span } from "../../components/Span";
import { VectorLogo } from "../../components/VectorLogo";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Div = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

export const MaintenancePage: React.FC<Props> = () => {
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        mt={7}
      >
        <VectorLogo
          height={150}
          width={150}
          my={3}
          src={"/assets/images/vector-logo-desktop.svg"}
        />

        <Title as="h1" lineHeight={7} fontWeight="medium" mb={4}>
          Maintenance
        </Title>

        <Flex flexDirection="column">
          <Box mb={5}>
            <Flex flexDirection="column">
              <Span data-testid="sub-heading" mb={1}>
                We apologise that the system will be unavailable until 11am
                today because of planned maintenance work.
              </Span>

              <Span data-testid="message">
                Thank you for your patience and cooperation.
              </Span>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
