import React from "react";
import { UnsubmittedOrder } from "../../../../types/Order";
import { Flex } from "../../../Flex";
import { UnsubmittedCard } from "../UnsubmittedCard";
import { deleteModalAction } from "../../../../constants/DeleteReducerAction";

interface Props {
  className: string;
  orders: UnsubmittedOrder[];
  userFullName: string;
  onDelete: (order: deleteModalAction) => void;
}

export const UnsubmittedOrdersCards: React.FC<Props> = ({ className, orders, userFullName, onDelete }) => {
  return (
    <Flex flexDirection="column" height="100%" className={className}>
      {orders.map((order, index) => (
        <UnsubmittedCard key={index} order={order} userFullName={userFullName} onDelete={onDelete} />
      ))}
    </Flex>
  );
};
