import React from "react";
import { NewConnectionFormValues } from "../../../../types/NewConnectionFormValues";
import { Title } from "../../../Title";
import { ReviewFormSection } from "../../../LiveningIcpForm/_/LiveningIcpReviewForm/_/ReviewFormSection";
import { Flex } from "../../../Flex";
import { ReviewFormSiteDetails } from "./_/ReviewFormSiteDetails";
import { SquareCheckbox } from "../../../SquareCheckbox";
import {
  getGaEditContactDetailsEvent,
  getGaEditSiteDetailsEvent,
} from "../../../../utils/ga/GaNewConnectionEvents";
import { ReviewFormOptionalContactDetails } from "../../../ReviewFormOptionalContactDetails";
import { ReviewFormNewConnectionContactDetailsWithTitle } from "./_/ReviewFormNewConnectionContactDetailsWithTitle";
import { Span } from "../../../Span";
import { Link } from "../../../Link";
import { sendGAEvent } from "../../../../utils/ga/GaEventSender";

interface Props {
  values: NewConnectionFormValues;
  onEdit: (elementId: string) => void;
  selectedTermsConditions: boolean;
  onSelectTermsConditions: () => void;
}

const NEW_CONNECTION_ORDER_DETAILS_SECTION =
  "new-connection-order-details-section";
const NEW_CONNECTION_CONTACT_DETAILS_SECTION =
  "new-connection-contact-details-section";

export const NewConnectionReviewForm: React.FC<Props> = ({
  values,
  onEdit,
  selectedTermsConditions,
  onSelectTermsConditions,
}) => {
  const handleOnEdit = (elementId: string) => () => {
    if (elementId === NEW_CONNECTION_ORDER_DETAILS_SECTION) {
      sendGAEvent(getGaEditSiteDetailsEvent());
    }

    if (elementId === NEW_CONNECTION_CONTACT_DETAILS_SECTION) {
      sendGAEvent(getGaEditContactDetailsEvent());
    }

    onEdit(elementId);
  };

  return (
    <Flex flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="medium" mb={5}>
        {"Review & Submit"}
      </Title>

      <ReviewFormSection
        id="review-form-site-details-section"
        title="Site details"
        data-testid="review-section-site-details"
        onEditClick={handleOnEdit(NEW_CONNECTION_ORDER_DETAILS_SECTION)}
      >
        <ReviewFormSiteDetails values={values} />
      </ReviewFormSection>

      <ReviewFormSection
        id="review-form-contact-details-section"
        title="Contact details"
        data-testid="review-section-contact-details"
        onEditClick={handleOnEdit(NEW_CONNECTION_CONTACT_DETAILS_SECTION)}
      >
        {values.contactDetails.map((contactDetails, index) => {
          return (
            <ReviewFormNewConnectionContactDetailsWithTitle
              key={index}
              contactDetails={contactDetails}
              contactDetailsIndex={index}
              hasElectrician={values.hasElectrician}
              hasLandOwner={values.hasLandOwner}
            />
          );
        })}

        {(values.hasElectrician === "no" ||
          values.hasElectrician === "yes") && (
          <ReviewFormOptionalContactDetails
            contactDetails={values.optionalContactDetails}
            hasOptional={values.hasElectrician}
            title="Electrician"
            mb={5}
          />
        )}

        {(values.hasLandOwner === "no" || values.hasLandOwner === "yes") && (
          <ReviewFormOptionalContactDetails
            contactDetails={values.optionalContactDetails}
            hasOptional={values.hasLandOwner}
            title="Land Owner"
            mb={0}
          />
        )}
      </ReviewFormSection>

      <SquareCheckbox
        id="terms-conditions"
        label={() => (
          <Span>
            {"I have read and understand Vector's"}{" "}
            <Link href="https://www.vector.co.nz/privacy-policy">
              privacy policy
            </Link>
          </Span>
        )}
        name="terms-conditions"
        onChange={onSelectTermsConditions}
        checked={selectedTermsConditions}
        value={selectedTermsConditions}
        mr={3}
      />
    </Flex>
  );
};
