import React from "react";
import { ProgressSteps } from "../../types/ProgressSteps";
import { ProgressStepperMobile } from "../ProgressStepperMobile";
import { ProgressStepperDesktop } from "../ProgressStepperDesktop";
import { Flex } from "../Flex";
import { ProgressStepperBackButton } from "../ProgressStepperBackButton";

export const ProgressStepper: React.FC<ProgressSteps> = ({ ...props }) => {
  return (
    <Flex flexDirection="column">
      <ProgressStepperBackButton />

      <ProgressStepperDesktop {...props} />
      <ProgressStepperMobile {...props} />
    </Flex>
  );
};
