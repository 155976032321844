import React from "react";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { Page } from "../../components/Page";
import { OrderDetailsListNavigationMenu } from "../../components/OrderDetailsList/_/OrderDetailsListNavigationMenu";
import { PaymentResult } from "../../components/PaymentResult";

export const PaymentResultPage: React.FC = () => {
	const {
		authenticatedUserContext: { isLoggedIn },
	} = useUserAuthentication();

  return (
    <Page
      navigationMenuContents={
        isLoggedIn ? () => <OrderDetailsListNavigationMenu /> : undefined
      }
      hasMobileNavMenu={isLoggedIn}
    >
      <PaymentResult />
    </Page>
  );
};
