import axios from "axios";
import env from "../environment";
import { ExtendedAxiosRequestConfig } from "../types/ExtendedAxiosRequestConfig";

export const apiClientConfig: ExtendedAxiosRequestConfig = {
  baseURL: env.apiBaseUrl,
  headers: {
    apikey: env.apikey,
    "Content-Type": "application/json",
    Accept: "application/json",
    "referrer-policy": "strict-origin-when-cross-origin",
  },
};

export const apiClient = axios.create(apiClientConfig);
