import { Field } from "formik";
import React from "react";
import { SpaceProps } from "styled-system";
import { Flex } from "../../Flex";
import { FormikCheckbox } from "../../FormikCheckbox";
import { Span } from "../../Span";

interface Props {
  id: string;
  name: string;
  title: string;
  emailLabel: string;
  smsLabel: string;
  emailDisabled?: boolean;
  smsDisabled?: boolean;
  focusable?: boolean;
}

export const ContactDetailsContactPreferences: React.FC<Props & SpaceProps> = ({
  id,
  name,
  title,
  emailLabel,
  smsLabel,
  focusable,
  emailDisabled,
  smsDisabled,
  mb,
}) => {
  return (
    <>
      <Span mb={2}>{title}</Span>
      <Flex flexDirection="row" mb={mb}>
        <Field
          id={`${id}.email`}
          name={`${name}.email`}
          label={emailLabel}
          focusable={focusable}
          disabled={emailDisabled}
          component={FormikCheckbox}
        />

        <Field
          id={`${id}.sms`}
          name={`${name}.sms`}
          label={smsLabel}
          focusable={focusable}
          ml={2}
          disabled={smsDisabled}
          component={FormikCheckbox}
        />
      </Flex>
    </>
  );
};

ContactDetailsContactPreferences.defaultProps = {
  focusable: true,
  emailDisabled: false,
  smsDisabled: false,
};
