import React, { AnchorHTMLAttributes } from "react";
import styled from "styled-components";
import {
  color as c,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system";

interface Props {
  variant?: "primary" | "secondary" | "tertiary";
}

const Href = styled.a<
  ColorProps &
    TypographyProps &
    SpaceProps &
    AnchorHTMLAttributes<HTMLAnchorElement>
>`
  ${c}
  ${typography}
  ${space}
  outline: none;
  text-decoration: none;
  cursor: pointer;
  width: max-content;
`;

const Component = styled(Href).attrs((props) => ({
  className: props.className,
}))(
  variant({
    scale: "link",
    variants: {
      primary: {
        color: "primary",
        lineHeight: "body",
        px: 0,
        fontWeight: "medium",
        ">:only-child": {
          color: "primary",
        },
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
        },
        "&.styled-link:hover": {
          borderBottom: "1px solid",
          borderBottomColor: "primary",
        },
        "&:active": {
          color: "hydroDarker",
          borderBottom: "1px solid",
          borderBottomColor: "hydroDarker",
          ">:only-child": {
            color: "hydroDarker",
          },
        },
        "&:visited": {
          color: "hydroDarker",
          borderBottomColor: "hydroDarker",
          ">:only-child": {
            color: "hydroDarker",
          },
        },
        "&:disabled": {
          color: "neutrals.mn40",
          ">:only-child": {
            color: "neutrals.mn40",
          },
        },
      },
      secondary: {
        color: "solarLight",
        lineHeight: "body",
        px: 0,
        fontWeight: "medium",
        ">:only-child": {
          color: "solarLight",
        },
        "&:focus": {
          borderColor: "solarLight",
          boxShadow: "inputFocusLight",
        },
        "&.styled-link:hover": {
          borderBottom: "1px solid",
          borderBottomColor: "solarLight",
        },
        "&:active": {
          color: "solarLight",
          borderBottom: "1px solid",
          borderBottomColor: "solarLight",
          ">:only-child": {
            color: "solarLight",
          },
        },
        "&:visited": {
          color: "solarLight",
          borderBottomColor: "solarLight",
          ">:only-child": {
            color: "solarLight",
          },
        },
        "&:disabled": {
          color: "neutrals.mn40",
          ">:only-child": {
            color: "neutrals.mn40",
          },
        },
      },
      tertiary: {
        color: "neutrals.ln0",
        lineHeight: "body",
        px: 0,
        fontWeight: "medium",
        borderBottom: "1px solid",
        borderBottomColor: "neutrals.ln0",
        ">:only-child": {
          color: "neutrals.ln0",
        },
        "&:focus": {
          borderColor: "neutrals.ln0",
          boxShadow: "inputFocusWhite",
        },
        "&.styled-link:hover": {
          borderBottom: "1px solid",
          borderBottomColor: "neutrals.ln0",
        },
        "&:active": {
          color: "neutrals.ln0",
          borderBottom: "1px solid",
          borderBottomColor: "neutrals.ln0",
          ">:only-child": {
            color: "neutrals.ln0",
          },
        },
        "&:visited": {
          color: "neutrals.ln0",
          borderBottomColor: "neutrals.ln0",
          ">:only-child": {
            color: "neutrals.ln0",
          },
        },
        "&:disabled": {
          color: "neutrals.mn40",
          ">:only-child": {
            color: "neutrals.mn40",
          },
        },
      },
    },
  }),
);

export const Link: React.FC<
  Props &
    ColorProps &
    TypographyProps &
    SpaceProps &
    AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, ...props }) => (
  <Component className="styled-link" {...props}>
    {children}
  </Component>
);

Link.defaultProps = {
  variant: "primary",
  rel: "noopener noreferrer",
  target: "_blank",
};
