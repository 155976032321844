import axios, { AxiosRequestConfig } from "axios";
import env from "../environment";

const config: AxiosRequestConfig = {
  baseURL: env.auth0ApiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const auth0Client = axios.create(config);
