import { UserAuth0Details } from "../../types/UserAuth0Details";
import { UserBasicDetails } from "../../types/UserBasicDetails";

export const isUserBasicDetailsEmpty = (userBasicDetails: UserBasicDetails) => {
  return (
    !userBasicDetails.contactId ||
    !userBasicDetails.accountId ||
    !userBasicDetails.firstName ||
    !userBasicDetails.lastName ||
    !userBasicDetails.email
  );
};

export const isUserAuth0DetailsEmpty = (userAuth0Details: UserAuth0Details) => {
  return (
    !userAuth0Details.auth0Id ||
    !userAuth0Details.email ||
    !userAuth0Details.createdDate
  );
};
