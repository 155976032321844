import React, { InputHTMLAttributes } from "react";
import { FieldProps } from "formik";
import { FlexGrowProps, SpaceProps } from "styled-system";
import { RadioButtonDescriptive } from "../RadioButtonDescriptive";
import { RadioButtonDescriptiveProps } from "../RadioButtonDescriptive/RadioButtonDescriptive";


export const FormikRadioButtonDescriptive: React.FC<  FieldProps &
    FlexGrowProps &
    RadioButtonDescriptiveProps &
    SpaceProps &
    InputHTMLAttributes<HTMLInputElement>> = ({ field, ...props }) => {
  return <RadioButtonDescriptive  {...field} {...props} />;
};
