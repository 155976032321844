import React from "react";
import { Flex } from "../Flex";
import { Spinner } from "../Spinner";
import { Title } from "../Title";

interface Props {
  text?: string;
}

export const Loading: React.FC<Props> = ({ text }) => {
  return (
    <Flex justifyContent="space-around" alignItems="center">
      <Title as="h2" color="black">
        {text}
      </Title>
      <Spinner color="primary" ml={2} />
    </Flex>
  );
};

Loading.defaultProps = {
  text: "Loading",
};
