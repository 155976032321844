import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { PayNowForm } from "../PayNowForm";
import { PayNowProgressStepper } from "../PayNowProgressStepper";
import { PayNowOrderPayment } from "../../types/PayNowOrderPayment";
import { EMPTY_PAY_NOW_ORDER_PAYMENT } from "../../constants/PayNowOrderPaymentValues";
import lodash from "lodash";
import { setPayNowFormDetails } from "../PayNowForm/_/PayNowDetailsUtil/PayNowDetailsUtil";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 25vw auto 10vw;
  }
`;

interface Props {
  paymentId: string;
  initialValues: PayNowOrderPayment;
  onSubmit: (values: PayNowOrderPayment) => void;
}

export const PayNow: React.FC<Props> = ({
  paymentId,
  initialValues,
  onSubmit,
}) => {
  const formValues: PayNowOrderPayment = lodash.cloneDeep(
    EMPTY_PAY_NOW_ORDER_PAYMENT,
  );
  const [activeStep, setActiveStep] = useState(1);

  const handleStepChange = (activeStep: number) => {
    setActiveStep(activeStep);
  };

  return (
    <>
      <PayNowProgressStepper currentStep={activeStep} />
      <GridWrapper mx={[3, 3, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          <PayNowForm
            initialValues={setPayNowFormDetails(formValues, initialValues)}
            onSubmit={onSubmit}
            onStepChange={handleStepChange}
            paymentId={paymentId}
            activeStep={activeStep}
          />
        </FlexWrapper>
      </GridWrapper>
    </>
  );
};
