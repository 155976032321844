import { Field } from "formik";
import React from "react";
import { ServiceTypeFormValues } from "../../types/ServiceTypeFormValues";
import { Box } from "../Box";
import { CantFindWhatYouAreLookingFor } from "../CantFindWhatYouAreLookingFor";
import { Flex } from "../Flex";
import { FormikRadioButtonDescriptive } from "../FormikRadioButtonDescriptive";
import { Span } from "../Span";

interface Props {
  values: ServiceTypeFormValues;
  onServiceTypeSelected: () => void;
}

export const ServiceTypeConsentApprovals: React.FC<Props> = ({
  values,
  onServiceTypeSelected,
}) => {
  const handleOnServiceTypeSelected = () => {
    onServiceTypeSelected();
  };

  return (
    <>
      <Span
        display="inline-block"
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mt={7}
        mb={5}
      >
        Select a service type.
      </Span>

      <Flex flexDirection="column" justifyContent="space-between">
        <Box mb={7}>
          <Field
            id="highLoadPermit"
            name="consentApprovalsType"
            label="High load permit"
            value="highLoadPermit"
            description="You need a permit or an escort from us when transporting loads over 4.3 metres high through Auckland."
            currentValue={values.consentApprovalsType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <CantFindWhatYouAreLookingFor serviceType={values.service} />
      </Flex>
    </>
  );
};
