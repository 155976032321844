import React from "react";
import { SpaceProps } from "styled-system";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { MobileProgressStepButton } from "../MobileProgressStepButton";
import { ProgressSteps } from "../../../../types/ProgressSteps";

export const MobileProgressStep: React.FC<ProgressSteps & SpaceProps> = ({
  steps,
  currentStep,
  ...props
}) => {
  const stepsCount = steps.length;
  const stepText = steps[currentStep - 1];
  const stepSubText =
    currentStep < steps.length ? "Next: " + steps[currentStep] : "";

  return (
    <>
      {currentStep <= stepsCount && (
        <Flex flexDirection="row" {...props}>
          <MobileProgressStepButton>{`${currentStep} of ${stepsCount}`}</MobileProgressStepButton>

          <Flex flexDirection="column" ml={2}>
            <Span
              id="mobile-progress-step-text"
              color="nightSky"
              fontSize={1}
              lineHeight={2}
            >
              {stepText}
            </Span>

            {stepSubText && (
              <Span
                id="mobile-progress-step-subtext"
                color="neutrals.mn80"
                fontSize={0}
                lineHeight={0}
              >
                {stepSubText}
              </Span>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};
