/***
 * Searches each array value's object properties (or properties provided in searchKeys), or the values in the array if items is
 * not an object[]
 * @param items - object/string/number []
 * @param searchKeys - object properties to search
 */
export const usePredictiveSearch = <T extends object | number | string>(
  items: T[],
  searchKeys?: Array<keyof T>,
) => {
  const normalize = (value: T[keyof T] | string | number) =>
    String(value).replace(/\s+/g, " ").trim().toLowerCase();

  const search = (filter: string): T[] =>
    (items || []).filter((item) =>
      typeof item === "object"
        ? (searchKeys ?? Object.keys(item)).some((key) =>
            normalize(item[key as keyof T]).includes(normalize(filter)),
          )
        : normalize(String(item)).includes(normalize(filter)),
    );

  return { search };
};
