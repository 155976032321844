import { NewConnectionRoles } from "../../../../types/ContactDetailRoles";
import {
  customerRoleSelectedInAllContactDetails,
  consultantRoleSelectedInAllContactDetails,
} from "../../../ContactDetailsForm/ContactDetailsRoleChecker/ContactDetailsRoleChecker";

export const getComplexAddContactErrorMsg = (
  newConnectionType: NewConnectionType,
  newConnectionRoles: NewConnectionRoles[],
) => {
  if (newConnectionType !== "complex") {
    return "";
  }

  const customerRoleSelected =
    customerRoleSelectedInAllContactDetails(newConnectionRoles);
  const consultantRoleSelected =
    consultantRoleSelectedInAllContactDetails(newConnectionRoles);

  if (!customerRoleSelected && !consultantRoleSelected) {
    return "";
  } else if (!customerRoleSelected) {
    return "contactDetails[0].roles.customerRole";
  } else if (!consultantRoleSelected) {
    return "contactDetails[0].roles.consultantRole";
  }

  return "";
};
