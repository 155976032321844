import { useQueryInvalidator } from "../useQueryInvalidator/useQueryInvalidator";
import { OrdersResponse } from "../../../types/Order";

export const useInvalidateOrderList = () => {
  const { invalidateQuery } = useQueryInvalidator();

  const invalidateOrdersCache = async () => {
    //a bit of timeout to give time for siebel to get updated
    await new Promise<void>((resolve) =>
      setTimeout(() => {
        invalidateQuery<OrdersResponse>("orders");
        resolve();
      }, 1500),
    );
  };

  return { invalidateOrdersCache };
};
