import React from "react";
import { Box } from "../../../Box";
import { Flex } from "../../../Flex";
import { Icon } from "../../../Icon";
import { Link } from "../../../Link";
import { Span } from "../../../Span";
import { Title } from "../../../Title";

export const FaqGetSupport: React.FC = () => {
  return (
    <Flex flexDirection="column" bg="hydro10" borderRadius="radio" p={4} my={7}>
      <Title mt={0} fontSize={5} lineHeight={5} fontWeight="medium">
        Get support
      </Title>

      <Span fontSize={2} lineHeight={2} fontWeight="medium" my={2}>
        If you need any help, you can contact us directly via email or call us
        at <a href="tel:+64508832867">0508 832 867</a>.
      </Span>

      <Box
        mt={2}
        px={3}
        py={1}
        backgroundColor="hydro10"
        borderRadius="4px"
        width="fit-content"
      >
        <Link href="mailto: info@vector.co.nz">
          <Icon mr={2} fontSize={2} lineHeight={2} icon="mail" />
          <Span ml={1}>Email us</Span>
        </Link>
      </Box>
    </Flex>
  );
};
