import React from "react";
import { Flex } from "../../../Flex";
import { Button } from "../../../Button";
import { Icon } from "../../../Icon";
import { Span } from "../../../Span";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Line } from "../../../Line";
import { Box } from "../../../Box";
import { SpaceProps, space } from "styled-system";
import { Link } from "../../../Link";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

const StyledNavLink = styled(NavLink)<SpaceProps>`
  ${space}

  display: block;
  text-decoration: none;
  border-radius: 6px;

  &.active {
    background-color: ${(props) => props.theme.colors.neutrals.ln30};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }
`;

const FeedbackLink = styled(Link)`
  &:hover {
    border: none;
  }
`;

export const OrderDetailsListNavigationMenu: React.FC = () => {
  const history = useHistory();
  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();
  const { auth0Id } = userAuth0Details;

  let feedbackUrl = "https://survey.vector.co.nz/jfe/form/SV_0HXtq1qeG8zfWRM";
  if (auth0Id) {
    feedbackUrl += `?user=${auth0Id.replace("auth0|", "")}`;
  }

  const handleOnClick = () => {
    history.push("/service-type");
  };

  return (
    <Flex
      flexDirection="column"
      height="90vh"
      width={["100%", "100%", "262px"]}
    >
      <Flex mt={3} px={3} flexDirection="column">
        <Button
          iconLeft="add_circle"
          magnitude="large"
          lineHeight="22px"
          fontWeight="medium"
          onClick={handleOnClick}
        >
          New Order
        </Button>

        <Flex flexDirection="column" mt={5}>
          <Flex alignItems="center" p="12px" mb={2}>
            <Box ml="12px">
              <Icon icon="description" color="neutrals.mn80" />
            </Box>

            <Span
              fontSize={2}
              lineHeight={2}
              fontWeight="medium"
              ml={3}
              color="nightSky"
            >
              Orders
            </Span>
          </Flex>

          <Box mb={1} ml={6}>
            <StyledNavLink
              exact
              activeClassName="active"
              to="/order-details-list/open-orders"
              px={2}
              py={1}
              ml={2}
            >
              <Span
                color="nightSky"
                fontSize={1}
                lineHeight={4}
                fontWeight="medium"
              >
                Open orders
              </Span>
            </StyledNavLink>
          </Box>

          <Box mb={1} ml={6}>
            <StyledNavLink
              exact
              activeClassName="active"
              to="/order-details-list/closed-orders"
              px={2}
              py={1}
              ml={2}
            >
              <Span
                color="nightSky"
                fontSize={1}
                lineHeight={4}
                fontWeight="medium"
              >
                Closed orders
              </Span>
            </StyledNavLink>
          </Box>

          <Box mb={1} ml={6}>
            <StyledNavLink
              exact
              activeClassName="active"
              to="/order-details-list/draft-orders"
              px={2}
              py={1}
              ml={2}
            >
              <Span
                color="nightSky"
                fontSize={1}
                lineHeight={4}
                fontWeight="medium"
              >
                Draft orders
              </Span>
            </StyledNavLink>
          </Box>
        </Flex>
      </Flex>

      <Flex px={4} mt="auto" mb={[2, 2, 5]} flexDirection="column">
        <Line
          width="100%"
          zIndex={0}
          border="1px solid"
          borderColor="neutrals.ln30"
          bg="neutrals.ln30"
          opacity="1"
          height={0}
        />

        <Flex alignItems="center" mt={3} mb={2}>
          <FeedbackLink
            href={feedbackUrl}
            target="_blank"
            data-testid="feedback-link"
          >
            <Icon icon="chat" color="neutrals.mn80" />
            <Span
              fontSize={1}
              lineHeight={2}
              fontWeight="medium"
              ml={2}
              color="nightSky"
            >
              Feedback
            </Span>
          </FeedbackLink>
        </Flex>
      </Flex>
    </Flex>
  );
};
