import React from "react";
import { CantFindWhatYouAreLookingFor } from "./CantFindWhatYouAreLookingFor";
import { ServiceTypeFormValues } from "../../types/ServiceTypeFormValues";

interface Props {
  serviceType: ServiceTypeFormValues["service"];
}

const ELECTRICITY_SERVICE_TYPE = {
  title: "Can't order online what you need?",
  description:
    "Please contact us (<a href='tel:0508 832 867'>0508 832 867</a>) if you need help for:",
  helpItemsWithLinks: [
    {
      url: "https://www.vector.co.nz/personal/electricity/connection-change/vector-equipment",
      description: "Relocation or removal of Vector equipment",
    },
    {
      url: "https://www.vector.co.nz/personal/electricity/connection-change/upgrades-downgrades",
      description: "Upgrade or downgrade",
    },
    {
      url: "https://www.vector.co.nz/personal/electricity/connection-change/vector-equipment",
      description: "Undergrounding overhead assets",
    },
    {
      url: "https://www.vector.co.nz/personal/help-safety/near-our-network/temporary-service-line-disconnection",
      description: "Disconnection of private service lines (Temporary)",
    },
    {
      url: "https://www.vector.co.nz/personal/help-safety/near-our-network/temp-changes-Vector",
      description:
        "Disconnection, re-routing or dropping of Vector's network cables (Temporary)",
    },
    {
      url: "https://www.vector.co.nz/personal/electricity/capacity-check",
      description: "Capacity checks",
    },
    {
      url: "https://www.vector.co.nz/personal/electricity/confirmation-of-supply",
      description: "Confirmation of electricity supply",
    },
    {
      url: "https://www.vector.co.nz/personal/outages-faults/hot-water",
      description: "Report a hot water outage",
    },
    {
      url: "https://www.vector.co.nz/personal/outages-faults/streetlights",
      description: "Report a streetlight fault",
    },
    {
      url: "https://www.vector.co.nz/personal/outages-faults/trees",
      description: "Report a tree related fault",
    },
    {
      url: "https://www.vector.co.nz/personal/outages-faults/vector-equipment",
      description: "Report a Vector equipment fault",
    },
    {
      url: "https://www.vector.co.nz/personal/outages-faults/graffiti",
      description: "Report graffiti",
    },
  ],
};

const GAS_SERVICE_TYPE = {
  title: "Can't order online what you need?",
  description:
    "Please contact us (<a href='tel:0508 832 867'>0508 832 867</a>) if you need help for:",
  helpItemsWithoutLinks: ["Commercial gas connections", "Gas subdivisions"],
  helpItemsWithLinks: [
    {
      url: "https://www.vector.co.nz/personal/gas/gas-main-relocation",
      description: "Gas mains relocation",
    },
    {
      url: "https://www.vector.co.nz/personal/outages-faults/gas",
      description: "Gas leak or fault",
    },
  ],
};

const CONSENT_APPROVALS_SERVICE_TYPE = {
  title: "Can't find the right consent or approval?",
  description:
    "Please contact us (<a href='tel:0508 832 867'>0508 832 867</a>) if you need help for:",
  helpItemsWithLinks: [
    {
      url: "https://www.vector.co.nz/personal/help-safety/near-our-network/close-approach-consents",
      description: "Close approach consents",
    },
    {
      url: "https://www.vector.co.nz/personal/help-safety/near-our-network/artwork-on-our-equipment",
      description: "Approval of artwork on Vector equipment",
    },
    {
      url: "https://www.vector.co.nz/personal/electricity/distributed-generation",
      description:
        "Connect distributed generation (solar, battery, wind) to Vector's network",
    },
  ],
};

export const CantFindWhatYouAreLookingForContainer: React.FC<Props> = ({
  serviceType,
}) => {
  return (
    <>
      {serviceType === "electricity" && (
        <CantFindWhatYouAreLookingFor {...ELECTRICITY_SERVICE_TYPE} />
      )}

      {serviceType === "gas" && (
        <CantFindWhatYouAreLookingFor {...GAS_SERVICE_TYPE} />
      )}

      {serviceType === "consent_approvals" && (
        <CantFindWhatYouAreLookingFor {...CONSENT_APPROVALS_SERVICE_TYPE} />
      )}
    </>
  );
};
