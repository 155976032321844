import React from "react";
import styled from "styled-components";
import { Flex } from "../../../../../Flex";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { mediaQueries } from "../../../../../../theme";
import { Span } from "../../../../../Span";
import { NewConnectionLotValues } from "../../../../../../types/NewConnectionLotValues";

interface Props {
  lots: NewConnectionLotValues[];
}

const Table = styled.table<LayoutProps>`
  ${layout}
  text-align: left;
  display: table;

  ${mediaQueries.mediumDevice} {
    display: none;
  }
`;

const TableCell = styled.td<SpaceProps>`
  ${space}
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const LabelWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.mn80};
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;

export const ReviewFormMobileLots: React.FC<Props> = ({ lots }) => {
  return (
    <Table>
      <thead></thead>
      <tbody>
        {lots.map((lot, index) => {
          return (
            <tr data-testid={`mobile-lot-row-${index + 1}`} key={index}>
              <TableCell pt={2} pb={3}>
                <Flex flexDirection="row" mb={2}>
                  <LabelWrapper lineHeight={2} flexGrow={0.5}>
                    Supply Type
                  </LabelWrapper>
                  <ValueWrapper
                    data-testid={`lot-${index + 1}-type`}
                    lineHeight={2}
                    flexGrow={0.5}
                  >
                    {lot.type}
                  </ValueWrapper>
                </Flex>
                <Flex flexDirection="row" mb={2}>
                  <LabelWrapper lineHeight={2} flexGrow={0.5}>
                    Phase
                  </LabelWrapper>
                  <ValueWrapper
                    data-testid={`lot-${index + 1}-phase`}
                    lineHeight={2}
                    flexGrow={0.5}
                  >
                    {lot.phase}
                  </ValueWrapper>
                </Flex>
                <Flex flexDirection="row" mb={2}>
                  <LabelWrapper lineHeight={2} flexGrow={0.5}>
                    Fuse (Amp)
                  </LabelWrapper>
                  <ValueWrapper
                    data-testid={`lot-${index + 1}-fuse`}
                    lineHeight={2}
                    flexGrow={0.5}
                  >
                    {lot.fuseAmp}
                  </ValueWrapper>
                </Flex>
              </TableCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
