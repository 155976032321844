import React from "react";
import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import {
  PersistQueryClientProvider,
  removeOldestQuery,
} from "@tanstack/react-query-persist-client";
import { SessionStorageKey } from "../../utils/sessionStorageWrapper/sessionStorageWrapper";
import { minutesToMs } from "../../utils/numberComputationHelper/numberComputationHelper";

interface Props {
  queryCacheKey?: SessionStorageKey;
  cacheMinutes?: number;
  staleMinutes?: number;
}

/***
 * Created to have default settings to the PersistQueryClientProvider. It setups a query cache saved
 * in client-side storage
 *
 * @param children
 * @param queryCacheKey - use to identify the cache in client-side storage
 * @param cacheMinutes - time before data is removed from client-side storage
 * @param staleMinutes - time before data is considered stale, (stale data triggers background network calls in some cases)
 * @constructor
 */
export const PersistedQueryProvider: React.FC<Props> = ({
  children,
  queryCacheKey = "ecssQueryCache",
  cacheMinutes,
  staleMinutes,
}) => {
  const cacheAge = minutesToMs(cacheMinutes ?? 5);
  const staleAge = minutesToMs(staleMinutes ?? 5);

  // default config for all queries
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: cacheAge,
        staleTime: staleAge,
        retry: 2,
      },
    },
  });

  const persister = createSyncStoragePersister({
    storage: window.sessionStorage,
    key: queryCacheKey,
    retry: removeOldestQuery,
  });

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister, maxAge: cacheAge }}
    >
      {children}
    </PersistQueryClientProvider>
  );
};
