import { Field, ErrorMessage } from "formik";
import React from "react";
import {
  UnmeteredAvailableHourValues,
  UnmeteredFeatureType,
} from "../../../../constants/Unmetered";
import { Flex } from "../../../Flex";
import { FormikDropdown } from "../../../FormikDropdown";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";

interface Props {
  featureType: UnmeteredFeatureType;
}

export const AvailableHours: React.FC<Props> = ({ featureType }) => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={featureType !== "Other" ? 0.2 : 0.4}
      flexBasis={0}
      minWidth={["100%", "100%", "100%", 0]}
    >
      <Field
        id="unmetered.availableHours"
        name="unmetered.availableHours"
        items={UnmeteredAvailableHourValues}
        visibleItems={2}
        label="Available hours"
        placeholder=""
        component={FormikDropdown}
      />
      <ErrorMessage
        name="unmetered.availableHours"
        className="field-error"
        component={FormInputErrorMessage}
      />
    </Flex>
  );
};
