import React from "react";
import { SpaceProps } from "styled-system";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Span } from "../Span";

interface Props {
  icon: string;
  title: string;
}

export const IconServiceType: React.FC<Props & SpaceProps> = ({
  icon,
  title,
  mt,
}) => {
  return (
    <Flex alignItems="center" mt={mt}>
      <Icon
        icon={icon}
        py={1}
        px={1}
        mr={3}
        borderRadius="6px"
        color="hydro"
        backgroundColor="hydro25"
      />
      <Span color="neutrals.mn80">{title}</Span>
    </Flex>
  );
};

IconServiceType.defaultProps = {
  title: "",
};
