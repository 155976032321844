import React from "react";
import styled from "styled-components";
import {
  variant,
  border,
  color,
  borderColor,
  BorderProps,
} from "styled-system";

interface Props {
  variant?: "primary" | "secondary";
}

const Div = styled.div`
  ${border}
  ${color}
  ${borderColor}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  position: static;
  width: fit-content;
  height: 40px;

  box-sizing: border-box;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
`;

const BadgeVariant = styled(Div)(
  variant({
    scale: "badge",
    variants: {
      primary: {
        bg: "neutrals.ln0",
        borderColor: "neutrals.ln40",
        color: "baseColors.nightSky",
      },
      secondary: {
        bg: "hydro10",
        borderColor: "hydro25",
        color: "primary",
      },
    },
  }),
);

export const Badge: React.FC<Props & BorderProps> = ({
  children,
  ...props
}) => {
  return <BadgeVariant {...props}>{children}</BadgeVariant>;
};

Badge.defaultProps = {
  variant: "primary",
};
