import { ContactDetailsValues } from "../types/ContactDetailsValues";

export const EMPTY_CONTACT_DETAILS_VALUES: ContactDetailsValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  alternatePhone: "",
  businessName: "",
  workContactPreferences: {
    email: false,
    sms: false,
  },
};
