import { PayNowOrderPayment } from "../types/PayNowOrderPayment";
import { PAYMENT_METHOD_CREDIT_CARD } from "./PaymentMethods";

export const EMPTY_PAY_NOW_ORDER_PAYMENT: PayNowOrderPayment = {
  orderId: "",
  orderNumber: "",
  siteAddress: "",
  siteSuburb: "",
  siteCity: "",
  jobCategory: "",
  serviceType: "",
  totalPrice: 0,
  quoteExpiry: "",
  status: "",
  requestKey: "",
  requestEntity: "",
  paidDate: "",
  billingDetails: {
    businessName: "",
    billingAddressDisplay: "",
    billingAddressId: "",
    manuallyEnteredBillingAddress: false,
    manualBillingAddress: {
      addressString: "",
      suburb: "",
      city: "",
      postcode: "",
    },
    addressId: "",
    customerFullName: "",
    firstName: "",
    lastName: "",
    alternatePhone: "",
    mobile: "",
    email: "",
  },
  paymentMethod: PAYMENT_METHOD_CREDIT_CARD,
};
