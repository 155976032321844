import { CompleteProfileAction } from "../../types/CompleteProfileAction";
import CompleteProfileActionType from "../../types/CompleteProfileActionType";
import { CompleteProfileState } from "../../types/CompleteProfileState";

export const completeProfileReducer = (
  state: CompleteProfileState,
  action: CompleteProfileAction,
): CompleteProfileState => {
  const { type, payload } = action;

  switch (type) {
    case CompleteProfileActionType.Error: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }

    case CompleteProfileActionType.UserCreateStarted: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CompleteProfileActionType.UserCreateStatusChanged: {
      const { userCreated } = payload;

      return {
        userCreated,
        isLoading: false,
        hasError: !userCreated,
      };
    }

    default: {
      return state;
    }
  }
};
