import { HasElectricianTypes } from "../../constants/HasOptionalRoleValues";
import {
  LiveningIcpRoles,
  NewConnectionRoles,
} from "../../types/ContactDetailRoles";
import { OrderDetailsRole } from "../../types/OrderDetailsRole";

const addDelimiter = (value: string, roleName: string): string => {
  return `${value ? ` / ${roleName}` : `${roleName}`}`;
};

export const getLiveningIcpRolesTitle = (
  roles: LiveningIcpRoles,
  isRequester: boolean,
  hasElectrician: HasElectricianTypes,
) => {
  let title = "";

  if (isRequester) {
    title += "Requester";
  }

  if (roles.siteAccessContactRole) {
    title += addDelimiter(title, "Site access contact");
  }
  if (roles.powerRetailerAccountHolderRole) {
    title += addDelimiter(title, "Power retailer account holder");
  }
  if (
    hasElectrician === "yes site access contact" &&
    roles.siteAccessContactRole
  ) {
    title += addDelimiter(title, "Electrician");
  }
  if (hasElectrician === "yes requester" && isRequester) {
    title += addDelimiter(title, "Electrician");
  }

  // Placeholder if no role has been selected and not the Requester
  if (!title) {
    title = "New contact";
  }

  return title;
};

export const getNewConnectionRolesTitle = (
  roles: NewConnectionRoles,
  isRequester: boolean,
  //temp optional till handle review form for new connection
  hasElectrician?: HasElectricianTypes,
  hasLandOwner?: string,
) => {
  let title = "";

  if (isRequester) {
    title += "Requester";
  }

  if (roles.siteAccessContactRole) {
    title += addDelimiter(title, "Site access contact");
  }
  if (roles.quotePayerRole) {
    title += addDelimiter(title, "Quote payer");
  }
  if (roles.customerRole) {
    title += addDelimiter(title, "Customer");
  }
  if (roles.consultantRole) {
    title += addDelimiter(title, "Consultant");
  }

  if (
    hasElectrician === "yes site access contact" &&
    roles.siteAccessContactRole
  ) {
    title += addDelimiter(title, "Electrician");
  }
  if (hasElectrician === "yes requester" && isRequester) {
    title += addDelimiter(title, "Electrician");
  }

  if (hasLandOwner === "yes customer" && roles.customerRole) {
    title += addDelimiter(title, "Land owner");
  }
  if (hasLandOwner === "yes requester" && isRequester) {
    title += addDelimiter(title, "Land owner");
  }

  // Placeholder if no role has been selected and not the Requester
  if (!title) {
    title = "New contact";
  }

  return title;
};

export const getOrderDetailsRolesTitle = (roles: OrderDetailsRole) => {
  let title = "";

  const {
    requester,
    siteAccessContact,
    powerRetailerAccountHolder,
    quotePayer,
    customer,
    consultant,
    electrician,
    landOwner,
  } = roles;

  if (requester) {
    title += "Requester";
  }

  if (siteAccessContact) {
    title += addDelimiter(title, "Site access contact");
  }
  if (powerRetailerAccountHolder) {
    title += addDelimiter(title, "Power retailer account holder");
  }
  if (electrician) {
    title += addDelimiter(title, "Electrician");
  }
  if (quotePayer) {
    title += addDelimiter(title, "Quote payer");
  }
  if (customer) {
    title += addDelimiter(title, "Customer");
  }
  if (consultant) {
    title += addDelimiter(title, "Consultant");
  }
  if (landOwner) {
    title += addDelimiter(title, "Land owner");
  }

  return title;
};
