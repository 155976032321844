import React from "react";
import styled from "styled-components";
import { OrderDetailsLiveningIcpSiteDetails } from "../../../../types/OrderDetailsSiteDetails";
import { Flex } from "../../../Flex";
import { LabelValuePair } from "../../../LabelValuePair";
import { ReviewFormDesktopMeteredUnits } from "../../../LiveningIcpForm/_/LiveningIcpReviewForm/_/ReviewFormDesktopMeteredUnits";
import { ReviewFormMobileMeteredUnits } from "../../../LiveningIcpForm/_/LiveningIcpReviewForm/_/ReviewFormMobileMeteredUnits";
import { Span } from "../../../Span";
import { getLiveningIcpSubHeading } from "../../helpers/OrderDetailsHelpers";
import { Line } from "../../../Line";
import lodash from "lodash";
import { OrderDocument } from "../../../../types/OrderDocument";
import { OrderDocuments } from "../OrderDocument";

interface Props {
  address: string;
  liveningIcpSiteDetails: OrderDetailsLiveningIcpSiteDetails;
  documents: OrderDocument[];
}

const UnitsWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const OrderLiveningIcpSiteDetails: React.FC<Props> = ({
  address,
  liveningIcpSiteDetails,
  documents,
}) => {
  const {
    icpType,
    projectNumber,
    liveningInspector,
    powerRetailer,
    featureType,
    quantity,
    wattage,
    availableHours,
    units,
  } = liveningIcpSiteDetails;

  const meteredJob = icpType === "metered";
  const subHeading = getLiveningIcpSubHeading(icpType);

  const renderMeteredUnits = () => {
    if (meteredJob && units.length) {
      const transformedUnits = units.map((unit) => ({
        ...unit,
        type: lodash.capitalize(unit.type),
        supply: lodash.capitalize(unit.supply),
      }));

      return (
        <UnitsWrapper
          flexDirection="column"
          borderRadius="radio"
          padding={[3, 3, 3, 4]}
          mt={2}
        >
          <ReviewFormDesktopMeteredUnits units={transformedUnits} />
          <ReviewFormMobileMeteredUnits units={transformedUnits} />
        </UnitsWrapper>
      );
    }
  };

  return (
    <Flex flexDirection="column">
      <Span
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mb={5}
        alignSelf="flex-start"
      >
        Site details
      </Span>
      <LabelValuePair label="Type of ICP" value={subHeading} id="type-of-icp" />
      <LabelValuePair
        label="Reference number"
        value={projectNumber || "N/A"}
        id="reference-number"
      />
      {meteredJob && units.length > 0 && (
        <LabelValuePair
          label="Number of ICP requests"
          value={units.length}
          id="number-of-icp-requests"
        />
      )}
      <LabelValuePair label="Address" value={address} id="address" />

      {renderMeteredUnits()}

      {!meteredJob && (
        <>
          <LabelValuePair
            label="Feature type"
            value={featureType}
            id="feature-type"
          />
          <LabelValuePair label="Quantity" value={quantity} id="quantity" />
          <LabelValuePair label="Wattage" value={`${wattage}W`} id="wattage" />
          <LabelValuePair
            label="Available hours"
            value={availableHours}
            id="available-hours"
          />
        </>
      )}
      <Line
        width="100%"
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
        my={7}
      />

      <OrderDocuments documents={documents} />

      <Span
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mb={5}
        alignSelf="flex-start"
      >
        Livening electrician & retailer
      </Span>
      <LabelValuePair
        label="Livening inspector"
        value={liveningInspector}
        id="livening-inspector"
      />
      <LabelValuePair
        label="Power retailer"
        value={powerRetailer}
        id="power-retailer"
      />

      <Line
        width="100%"
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
        my={7}
      />
    </Flex>
  );
};
