import React from "react";
import { ErrorMessage, Field } from "formik";
import { Flex } from "../Flex";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { FormikAddressSearchField } from "../FormikAddressSearchField";

interface Props {
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  validateField: (field: string) => void;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  infoMessage?: string | (() => void);
}

export const PostalAddress: React.FC<Props> = ({
  fieldIdPrefix,
  fieldNamePrefix,
  label,
  infoMessage,
  disabled,
}) => {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mb={4}>
        <Field
          id={`${fieldIdPrefix}.billingAddressDisplay`}
          name={`${fieldNamePrefix}.billingAddressDisplay`}
          nameId={`${fieldNamePrefix}.billingAddressId`}
          label={label}
          visibleItems={10}
          placeholder={"e.g. 101 Carlton Gore Road, Newmarket, Auckland"}
          noResultsMessage={
            "We couldn’t find a matching address, please enter a valid postal address."
          }
          component={FormikAddressSearchField}
          isPostalAddress
          infoMessage={infoMessage}
          disabled={disabled}
        />
        <ErrorMessage
          name={`${fieldNamePrefix}.billingAddressDisplay`}
          className="field-error"
          component={FormInputErrorMessage}
        />
      </Flex>
    </Flex>
  );
};

PostalAddress.defaultProps = {
  disabled: false,
  label: "NZ Billing Address",
};
