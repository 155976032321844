import React from "react";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";

interface Props {
  title: string;
  answer: string;
}

export const PayNowReviewThingsToKnow: React.FC<Props> = ({
  title,
  answer,
}) => {
  return (
    <Flex flexDirection="column" mb={4}>
      <Span
        fontSize={2}
        lineHeight={2}
        fontWeight="heavy"
        mr={3}
        mt={5}
        mb={5}
        alignSelf="flex-start"
        data-testid="paymentInformationTitle"
      >
        {title}
      </Span>

      <Span
        data-testid="paymentInformationAnswer"
        flexDirection="column"
        mb={3}
        fontSize={1}
        lineHeight={1}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </Flex>
  );
};
