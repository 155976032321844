import React, { useReducer, useState } from "react";
import styled from "styled-components";
import { OrderDocument } from "../../../../types/OrderDocument";
import {
  getFileExtension,
  getFileNameWithoutExtension,
} from "../../../FileUpload/_/FileUtil/FileUtil";
import { Span } from "../../../Span";
import { ActionLink } from "../../../ActionLink";
import { downloadFile } from "../../../../services/FileDownloadService";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { Flex } from "../../../Flex";
import { Spinner } from "../../../Spinner";
import {
  fileDownloadFailure,
  fileDownloadSuccess,
} from "../../../../constants/SnackbarMessages";
import { FloatingBanner } from "../../../FloatingBanner";
import { BasicNotificationBannerProps } from "../../../BasicNotificationBanner/BasicNotificationBanner";

interface Props {
  document: OrderDocument;
}

interface DownloadNotificationState extends BasicNotificationBannerProps {
  show: boolean;
}

const FileNameWrapper = styled(Span)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FileExtensionWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.neutrals.mn80};
  border-radius: 3px;
`;

const WrappedNotificationBanner = styled.div`
  position: fixed;
  left: 2.2vh;
  bottom: 14.7vh;
`;

export const OrderDocumentItem: React.FC<Props> = ({ document }) => {
  const fileDownloadInitState: DownloadNotificationState = {
    show: false,
    title: "",
    message: "",
    variant: "success",
    icon: "check_circle",
  };
  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;
  const fileName = getFileNameWithoutExtension(document.fileName);
  const fileExtension = getFileExtension(document.fileName);

  const [isDownloading, toggleDownloading] = useReducer(
    (downloading) => !downloading,
    false,
  );
  const [fileDownloadState, setFileDownloadState] = useState(
    fileDownloadInitState,
  );

  const resetBannerState = () => setFileDownloadState(fileDownloadInitState);

  const handleFileDownload = async () => {
    toggleDownloading();
    const downloaded = await downloadFile(accessToken, document);

    setFileDownloadState({
      show: true,
      title: downloaded ? fileDownloadSuccess.title : fileDownloadFailure.title,
      message: downloaded
        ? `${fileName} ${fileDownloadSuccess.message}`
        : `${fileName} ${fileDownloadFailure.message}`,
      variant: downloaded ? "success" : "error",
      icon: downloaded ? "check_circle" : "warning",
    });

    toggleDownloading();
  };

  return (
    <Flex py={1}>
      <FileNameWrapper
        lineHeight={2}
        mr={3}
        maxWidth={["100px", "100px", "300px", "fit-content"]}
      >
        {fileName}
      </FileNameWrapper>

      {fileExtension && (
        <FileExtensionWrapper
          lineHeight={2}
          px={1}
          mr={[0, 0, 7]}
          data-testid="file-extension-wrapper"
        >
          {fileExtension}
        </FileExtensionWrapper>
      )}
      <ActionLink
        ml={"auto"}
        onClick={handleFileDownload}
        data-testid={"download-doc-link"}
      >
        {isDownloading ? <Spinner /> : "Download"}
      </ActionLink>
      <WrappedNotificationBanner>
        <FloatingBanner
          title={fileDownloadState.title}
          message={fileDownloadState.message}
          variant={fileDownloadState.variant}
          icon={fileDownloadState.icon}
          visible={fileDownloadState.show}
          onBannerClose={resetBannerState}
        />
      </WrappedNotificationBanner>
    </Flex>
  );
};
