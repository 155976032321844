import { createGaEvent } from "./GaEventFactory";
import { GAEvent } from "./GaEventSender";


export const getGaBillingSavedSuccessEvent = (): GAEvent => {
  return createGaEvent("edit_billing_details", "billing_save_successful");
};

export const getGaBillingSavedFailedEvent = (): GAEvent => {
  return createGaEvent("edit_billing_details", "billing_save_failed");
};
