import React from "react";
import { Flex } from "../Flex";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { PaymentSuccess } from "./_/PaymentSuccess";
import { PaymentFailure } from "./_/PaymentFailure";
import { useParams } from "react-router-dom";

type PaymentPageParams = { pageType: string };

const FlexWrapper = styled(Flex)`
  ${mediaQueries.largeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.largeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

export const PaymentResult: React.FC = () => {
  const { pageType } = useParams<PaymentPageParams>();

  return (
    <>
      <GridWrapper mx={[3, 3, 4, 4, 0]} my={6}>
        <FlexWrapper flexDirection="column">
          {pageType === "success" && <PaymentSuccess />}

          {pageType === "failure" && <PaymentFailure />}
        </FlexWrapper>
      </GridWrapper>
    </>
  );
};
