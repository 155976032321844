import React from "react";
import { Form, Formik } from "formik";
import { CompleteProfileFormValues } from "../../types/CompleteProfileFormValues";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { EMPTY_COMPLETE_PROFILE_VALUES } from "../../constants/CompleteProfileDetails";
import lodash from "lodash";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { CompleteProfileDetailsFormSchema } from "./CompleteProfileDetailsFormSchema";
import { CompleteProfileDetailsForm } from "../CompleteProfileDetailsForm";

interface Props {
  onSubmit: (values: CompleteProfileFormValues) => void;
  initialValues?: CompleteProfileFormValues;
}

const validationSchema = CompleteProfileDetailsFormSchema;

const FormWrapper = styled(Form)<SpaceProps>`
  ${space}
`;

export const CompleteProfileForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const handleSubmit = (values: CompleteProfileFormValues) => {
    onSubmit(values);
  };

  return (
    <Formik
      validateOnChange={false}
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      initialValues={initialValues!}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, validateField }) => (
        <FormWrapper mb={8} mx={[3, 3, 8, "20%"]}>
          <CompleteProfileDetailsForm
            values={values}
            validateField={validateField}
          />

          <Flex justifyContent="flex-end" mt={5}>
            <Button type="submit" alignSelf="flex-end">
              Submit
            </Button>
          </Flex>
        </FormWrapper>
      )}
    </Formik>
  );
};

CompleteProfileForm.defaultProps = {
  initialValues: lodash.cloneDeep(EMPTY_COMPLETE_PROFILE_VALUES),
};
