import React from "react";
import { FieldProps, getIn } from "formik";
import { LiveningInspectorFieldProps } from "../InspectorRetailerDropdownField/InspectorRetailerDropdownFieldContainer";
import { FlexGrowProps } from "styled-system";
import { InspectorRetailerDropdownFieldContainer } from "../InspectorRetailerDropdownField";

interface Props {
  nameId: string;
}

export const FormikInspectorRetailerField: React.FC<
  Props & FieldProps & LiveningInspectorFieldProps & FlexGrowProps
> = ({ field, form, nameId, ...props }) => {
  const handleOnSelect = (
    selected?: string | null | undefined,
    selectedId?: string | null | undefined,
  ) => {
    form.setFieldValue(field.name, selected);
    form.setFieldValue(nameId, selectedId);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <InspectorRetailerDropdownFieldContainer
      {...field}
      error={touch && error ? error : false}
      {...props}
      onSelect={handleOnSelect}
    />
  );
};
