import { apiClient } from "../client/ApiClient";
import { Address } from "../types/Address";

const lookupAddress = async (
  address: string,
  isPostalAddress: boolean,
): Promise<Address[]> => {
  return await apiClient
    .get<Address[]>("address", {
      params: {
        q: address,
        isPostalAddress: isPostalAddress,
      },
    })
    .then((response) => response.data);
};

export default lookupAddress;
