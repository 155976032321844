import { apiClient } from "../client/ApiClient";
import { WindcavePaymentSession } from "../types/WindcavePaymentSession";
import { WindcavePaymentSessionConfig } from "../types/WindcavePaymentSessionConfig";

const configureSession = async (
  request: WindcavePaymentSessionConfig,
): Promise<WindcavePaymentSession> => {
  return await apiClient
    .post<WindcavePaymentSession>("payments/session", request)
    .then((response) => response.data);
};

export default configureSession;
