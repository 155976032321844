import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useReducer, useRef } from "react";
import { EMPTY_USER_AUTH0_DETAILS } from "../../constants/UserAuth0Details";
import { EMPTY_USER_BASIC_DETAILS } from "../../constants/UserBasicDetails";
import { authenticatedUserReducer } from "../../reducers/UserAuthenticationReducer/UserAuthenticationReducer";
import UserAuthenticationActionType from "../../types/UserAuthenticationActionType";
import { isUserAuth0DetailsEmpty } from "../../utils/userDetailsValidator/UserDetailsValidator";
import { getUserAuth0DetailsWrapper } from "../../utils/getUserAuth0DetailsWrapper/getUserAuth0DetailsWrapper";
import { useVerifySiebelAccount } from "../cachedQueries/userAccount/useVerifySiebelAccount";
import { useGetBasicUserDetails } from "../cachedQueries/userAccount/useGetBasicUserDetails";

export const useUserAuthenticationExecutor = () => {
  const {
    isLoading: auth0Loading,
    isAuthenticated: auth0LoggedIn,
    getAccessTokenSilently,
    user: auth0User,
  } = useAuth0();

  const [authenticatedUserContext, setAuthenticatedUserContext] = useReducer(
    authenticatedUserReducer,
    {
      isGuest: true,
      isLoading: true,
      isLoggedIn: false,
      userAuth0Details: EMPTY_USER_AUTH0_DETAILS,
      userBasicDetails: EMPTY_USER_BASIC_DETAILS,
      hasError: false,
      hasSiebelAccount: undefined,
    },
  );

  const { isLoggedIn, userAuth0Details, hasSiebelAccount } =
    authenticatedUserContext;
  const { siebelVerificationStatus, apiError: siebelVerificationError } =
    useVerifySiebelAccount(
      userAuth0Details.accessToken,
      userAuth0Details.email,
    );

  const userDetailsInitRef = useRef(false);
  const { current: userDetailsInitialized } = userDetailsInitRef;
  const { userBasicDetails } = useGetBasicUserDetails(
    userAuth0Details.accessToken,
    userAuth0Details.email,
    hasSiebelAccount,
  );

  // Auth0 login
  useEffect(() => {
    if (auth0LoggedIn) {
      setAuthenticatedUserContext({
        type: UserAuthenticationActionType.LogInCompleted,
        payload: {},
      });
    }

    if (!auth0Loading && !auth0LoggedIn) {
      setAuthenticatedUserContext({
        type: UserAuthenticationActionType.LogInStopped,
        payload: {},
      });
    }
  }, [auth0Loading, auth0LoggedIn, setAuthenticatedUserContext]);

  // Auth0 full details
  useEffect(() => {
    (async () => {
      let userAuth0Details = EMPTY_USER_AUTH0_DETAILS;
      if (isLoggedIn) {
        userAuth0Details = await getUserAuth0DetailsWrapper(
          isLoggedIn,
          auth0User,
          getAccessTokenSilently,
        );
      }

      if (!isUserAuth0DetailsEmpty(userAuth0Details)) {
        setAuthenticatedUserContext({
          type: UserAuthenticationActionType.Auth0DetailsRetrieved,
          payload: { userAuth0Details },
        });
      }
    })();
  }, [isLoggedIn, auth0User, getAccessTokenSilently]);

  // Backend account verification
  useEffect(() => {
    if (siebelVerificationStatus !== undefined) {
      const accountVerified = siebelVerificationStatus === 204;

      setAuthenticatedUserContext({
        type: UserAuthenticationActionType.SiebelAccountVerified,
        payload: {
          isLoading: accountVerified && !userDetailsInitialized,
          hasSiebelAccount: accountVerified,
        },
      });
    }

    if (siebelVerificationError) {
      setAuthenticatedUserContext({
        type: UserAuthenticationActionType.Error,
        payload: {
          hasError: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siebelVerificationStatus, siebelVerificationError]);

  // Backend user details
  useEffect(() => {
    if (userDetailsInitialized) {
      return;
    }

    if (userBasicDetails) {
      userDetailsInitRef.current = true;
      setAuthenticatedUserContext({
        type: UserAuthenticationActionType.BasicDetailsRetrieved,
        payload: { userBasicDetails },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBasicDetails]);

  return {
    authenticatedUserContext,
    setAuthenticatedUserContext,
  };
};
