import { apiClient, apiClientConfig } from "../client/ApiClient";
import { LiveningIcpFormValues } from "../types/LiveningIcpFormValues";
import { NewConnectionFormValues } from "../types/NewConnectionFormValues";
import { includeAccessToken } from "../client/AxiosConfigObjectUtil";

export const submitLiveningIcpOrder = async (
  value: LiveningIcpFormValues,
  auth0AccessToken?: string,
): Promise<void> => {
  return await apiClient
    .post("livening-icp", value, {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => response.data);
};

export const submitNewConnectionOrder = async (
  value: NewConnectionFormValues,
  auth0AccessToken?: string,
): Promise<void> => {
  return await apiClient
    .post("new-connection", value, {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => response.data);
};
