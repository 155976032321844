import React from "react";
import { SomethingWentWrong } from "../../../SomethingWentWrong/SomethingWentWrong";

export const PaymentFailure: React.FC = () => {
  return (
    <SomethingWentWrong
      subHeading="It looks like there was an error during payment."
      message="Please try again in a few minutes"
    />
  );
};
