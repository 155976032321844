import { NewConnectionRoles } from "../../../../types/ContactDetailRoles";

export const getOptionalContactDetailsFormFields = (
  role: NewConnectionRoles,
  index: number,
): OptionalContactDetailsFormFields => {
  let optionalFirstName = true;
  let optionalLastName = true;
  let optionalEmail = true;
  let optionalMobile = true;
  let optionalAlternatePhone = true;

  if (
    index === 0 ||
    (!role.siteAccessContactRole &&
      !role.quotePayerRole &&
      !role.consultantRole &&
      !role.customerRole) ||
    role.quotePayerRole
  ) {
    return {
      optionalFirstName: false,
      optionalLastName: false,
      optionalEmail: false,
      optionalMobile: false,
      optionalAlternatePhone: false,
      optionalBusinessName: true,
    };
  }

  if (role.consultantRole && index !== 0) {
    optionalFirstName = false;
    optionalLastName = false;
  } else if (role.customerRole && index !== 0) {
    optionalFirstName = false;
    optionalLastName = false;
    optionalEmail = false;
  }
  if (role.siteAccessContactRole && index !== 0) {
    optionalFirstName = false;
    optionalLastName = false;
    optionalMobile = false;
    optionalAlternatePhone = false;
  }

  return {
    optionalFirstName,
    optionalLastName,
    optionalEmail,
    optionalMobile,
    optionalAlternatePhone,
    optionalBusinessName: true,
  };
};
