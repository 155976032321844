import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import "./index.scss";
import "./utils/ga/GaEventSender";
import env from "./environment";
import TagManager from "react-gtm-module";

Modal.setAppElement("#root");

const tagManagerArgs = {
  gtmId: env.googleTagManagerId,
  auth: env.googleTagManagerAuthKey,
  preview: env.googleTagManagerEnvId,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
