import { useEffect, useState } from "react";
import configureSession from "../../services/WindcaveService";
import { PaymentBillingDetails } from "../../types/PaymentBillingDetails";
import { useLocation } from "react-router-dom";
import { WindcavePaymentSessionConfig } from "../../types/WindcavePaymentSessionConfig";

export const useWindcaveSession = () => {
  const [redirectUrl, setRedirectUrl] = useState("");
  const [apiError, setApiError] = useState(false);
  const { state } = useLocation<PaymentBillingDetails>();
  const {
    userId,
    orderId,
    paymentId,
    srNumber,
    customerName,
    amount,
    paymentMethod,
  } = state;

  useEffect(() => {
    const configurePaymentSessionAsync = async () => {
      const request: WindcavePaymentSessionConfig = {
        userId,
        orderId,
        paymentId,
        srNumber,
        customerName,
        amount,
        paymentMethod,
      };

      try {
        const paymentSession = await configureSession(request);

        if (paymentSession && paymentSession.sessionUrl) {
          setRedirectUrl(paymentSession.sessionUrl);
        } else {
          setRedirectUrl("/");
        }
      } catch (error) {
        setApiError(true);
      }
    };

    if (orderId && amount) {
      configurePaymentSessionAsync();
    } else {
      setRedirectUrl("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return { redirectUrl, apiError };
};
