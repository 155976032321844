import { ErrorMessage, Field } from "formik";
import React from "react";
import { LiveningIcpRoles } from "../../../../types/ContactDetailRoles";
import { Flex } from "../../../Flex";
import { FormikCheckbox } from "../../../FormikCheckbox";
import { liveningIcpRoleDisabler } from "../../../ContactDetailsForm/ContactDetailsRoleDisabler/ContactDetailsRoleDisabler";
import { SpaceProps } from "styled-system";
import { IconPopupTip } from "../../../IconPopupTip";
import { Span } from "../../../Span";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import {
  showLiveningRoleRequiredErrorMessage
} from "../../../ContactDetailsForm/ContactDetailsShowRoleRequiredUtil/ContactDetailsShowRoleRequiredUtil";

interface Props {
  contactDetailsIndex: number;
  contactDetailsRoles: LiveningIcpRoles[];
}

export const LiveningIcpRoleSelector: React.FC<Props & SpaceProps> = ({
  contactDetailsIndex,
  contactDetailsRoles,
  mb,
}) => {
  const disabledRoles = liveningIcpRoleDisabler(
    contactDetailsRoles,
    contactDetailsIndex,
  );

  const roleRequiredRoleErrorMessage = showLiveningRoleRequiredErrorMessage(
    contactDetailsRoles[contactDetailsIndex]);

  return (
    <Flex flexDirection="column" mb={mb}>
      <Flex
        mb={3}
        justifyContent={[
          "space-between",
          "space-between",
          "space-between",
          "flex-start",
        ]}
      >
        <Span fontSize={2} lineHeight={2} mr={3}>
          Select what other role(s) this contact has.
        </Span>
        <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
          {"Vector requires the contact details of all people / roles, " +
            "as they need to contact them at different times during the job. " +
            "Sometimes one person fulfils several roles eg the requestor and the electrician might be the same person"}
        </IconPopupTip>
      </Flex>

      <Flex flexDirection={["column", "column", "row"]} width="100%">
        <Flex flexDirection="column">
          <Field
            id={`contactDetails[${contactDetailsIndex}].roles.siteAccessContactRole`}
            name={`contactDetails[${contactDetailsIndex}].roles.siteAccessContactRole`}
            label="Site access contact"
            disabled={disabledRoles.siteAccessContactRole}
            my={[1, 1, 0]}
            mx={[0, 0, 1]}
            component={FormikCheckbox}
          />

          {!disabledRoles.siteAccessContactRole && (
            <ErrorMessage
              name={`contactDetails[${contactDetailsIndex}].roles.siteAccessContactRole`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          )}

          {!roleRequiredRoleErrorMessage && (
          <ErrorMessage
            name={`contactDetails[${contactDetailsIndex}].contactHasRole`}
            className="field-error"
            component={FormInputErrorMessage}
          />
          )}
        </Flex>

        <Flex flexDirection="column">
          <Field
            id={`contactDetails[${contactDetailsIndex}].roles.powerRetailerAccountHolderRole`}
            name={`contactDetails[${contactDetailsIndex}].roles.powerRetailerAccountHolderRole`}
            label="Power retailer account holder"
            disabled={disabledRoles.powerRetailerAccountHolderRole}
            my={[1, 1, 0]}
            mx={[0, 0, 1]}
            component={FormikCheckbox}
          />

          {!disabledRoles.powerRetailerAccountHolderRole && (
            <ErrorMessage
              name={`contactDetails[${contactDetailsIndex}].roles.powerRetailerAccountHolderRole`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          )}

        </Flex>
      </Flex>
    </Flex>
  );
};
