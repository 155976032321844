import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

export const Span = styled.span<
  ColorProps &
    FlexboxProps &
    SpaceProps &
    TypographyProps &
    LayoutProps &
    BorderProps &
    PositionProps
>`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${border}
  ${flexbox}
  ${position}
  
  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      border-bottom: 1px solid;
      border-bottom-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
