import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ActionLink } from "../ActionLink";
import env from "../../environment";

interface Props {
  title: string;
  url?: string;
}

export const LoginActionLink: React.FC<Props> = ({ title, url }) => {
  const { loginWithRedirect } = useAuth0();

  const handleOnClick = () => {
    loginWithRedirect({ redirectUri: url });
  };

  return <ActionLink onClick={handleOnClick}>{title}</ActionLink>;
};

LoginActionLink.defaultProps = {
  title: "Sign in",
  url: env.appBaseUri,
};
