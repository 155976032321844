import React, { ReactElement } from "react";
import { ErrorMessage, Field } from "formik";
import { FormikInputText } from "../../FormikInputText";
import { Flex } from "../../Flex";
import { SpaceProps } from "styled-system";
import { FormInputErrorMessage } from "../../FormInputErrorMessage";

interface Props {
  focusable?: boolean;
  idPrefix: string;
  namePrefix: string;
  optionalFirstName?: boolean;
  optionalLastName?: boolean;
  optionalEmail?: boolean;
  optionalMobile?: boolean;
  optionalAlternatePhone?: boolean;
  optionalBusinessName?: boolean;
  showEmail?: boolean;
  showPhone?: boolean;
  showBusinessName?: boolean;
  disabledFirstName?: boolean;
  disabledLastName?: boolean;
  disabledEmail?: boolean;
  disabledMobile?: boolean;
  disabledAlternatePhone?: boolean;
  disabledBusinessName?: boolean;
  businessNameInfoMessage?: ReactElement | string;
}

export const ContactDetailFields: React.FC<Props & SpaceProps> = ({
  focusable,
  idPrefix,
  namePrefix,
  optionalFirstName,
  optionalLastName,
  optionalEmail,
  optionalMobile,
  optionalAlternatePhone,
  optionalBusinessName,
  showEmail,
  showPhone,
  showBusinessName,
  disabledFirstName,
  disabledLastName,
  disabledEmail,
  disabledMobile,
  disabledAlternatePhone,
  disabledBusinessName,
  businessNameInfoMessage,
}) => {
  return (
    <Flex flexDirection="column">
      {/* First name / Last name */}
      <Flex flexDirection={["column", "column", "row"]} mb={4}>
        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mr={[0, 0, 2]}
          mb={[4, 4, 0]}
        >
          <Field
            id={`${idPrefix}.firstName`}
            name={`${namePrefix}.firstName`}
            label="First name"
            flexGrow={1}
            focusable={focusable}
            optional={optionalFirstName}
            disabled={disabledFirstName}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${namePrefix}.firstName`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={[0, 0, 2]}
        >
          <Field
            id={`${idPrefix}.lastName`}
            name={`${namePrefix}.lastName`}
            label="Last name"
            flexGrow={1}
            focusable={focusable}
            optional={optionalLastName}
            disabled={disabledLastName}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${namePrefix}.lastName`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Flex>

      {/* Email  */}
      {showEmail && (
        <Flex flexDirection="column" mb={4}>
          <Field
            id={`${idPrefix}.email`}
            name={`${namePrefix}.email`}
            label="Email"
            flexGrow={1}
            focusable={focusable}
            optional={optionalEmail}
            disabled={disabledEmail}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${namePrefix}.email`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      )}

      {/* Mobile / Alternate Phone */}
      {showPhone && (
        <Flex flexDirection={["column", "column", "row"]} mb={4}>
          <Flex
            flexDirection="column"
            flexGrow={[1, 1, 0.5]}
            flexBasis={["auto", "auto", 0]}
            minWidth={["auto", "auto", 0]}
            mr={[0, 0, 2]}
            mb={[4, 4, 0]}
          >
            <Field
              id={`${idPrefix}.mobile`}
              name={`${namePrefix}.mobile`}
              label="Mobile"
              placeholder="Enter mobile number"
              flexGrow={1}
              focusable={focusable}
              optional={optionalMobile}
              disabled={disabledMobile}
              component={FormikInputText}
            />
            <ErrorMessage
              name={`${namePrefix}.mobile`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>

          <Flex
            flexDirection="column"
            flexGrow={[1, 1, 0.5]}
            flexBasis={["auto", "auto", 0]}
            minWidth={["auto", "auto", 0]}
            ml={[0, 0, 2]}
          >
            <Field
              id={`${idPrefix}.alternatePhone`}
              name={`${namePrefix}.alternatePhone`}
              label="Alternate phone"
              placeholder="Enter alternate number"
              flexGrow={1}
              focusable={focusable}
              disabled={disabledAlternatePhone}
              optional={optionalAlternatePhone}
              component={FormikInputText}
            />
            <ErrorMessage
              name={`${namePrefix}.alternatePhone`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>
        </Flex>
      )}

      {/* Business name  */}
      {showBusinessName && (
        <Flex flexDirection="column" mb={4}>
          <Field
            id={`${idPrefix}.businessName`}
            name={`${namePrefix}.businessName`}
            label="Business name"
            flexGrow={1}
            focusable={focusable}
            optional={optionalBusinessName}
            disabled={disabledBusinessName}
            component={FormikInputText}
            message={businessNameInfoMessage}
          />
          <ErrorMessage
            name={`${namePrefix}.businessName`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      )}
    </Flex>
  );
};

ContactDetailFields.defaultProps = {
  focusable: true,
  optionalFirstName: false,
  optionalLastName: false,
  optionalEmail: false,
  optionalMobile: false,
  optionalAlternatePhone: false,
  optionalBusinessName: false,
  showEmail: true,
  showPhone: true,
  showBusinessName: true,
  disabledFirstName: false,
  disabledLastName: false,
  disabledEmail: false,
  disabledMobile: false,
  disabledAlternatePhone: false,
  disabledBusinessName: false,
};
