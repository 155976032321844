import React from "react";
import { Order } from "../../../../types/Order";
import { Flex } from "../../../Flex";
import { Card } from "../Card";

interface Props {
  className: string;
  type: OrdersFilterStatus;
  orders: Order[];
}

export const OrdersCards: React.FC<Props> = ({ className, type, orders }) => {
  return (
    <Flex flexDirection="column" height="100%" className={className}>
      {orders.map((order, index) => (
        <Card key={index} type={type} order={order} />
      ))}
    </Flex>
  );
};
