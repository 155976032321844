import React from "react";
import { Title } from "../../../Title";
import { UNSUBMITTED_ORDERS_TABLE_COLUMNS } from "../../../../constants/Orders";
import { Flex } from "../../../Flex";
import { UnsubmittedOrdersContainer } from "../UnsubmittedOrdersContainer/UnsubmittedOrdersContainer";
import { BasicNotificationBanner } from "../../../BasicNotificationBanner";
import { Box } from "../../../Box";

export const UnsubmittedOrders: React.FC = () => {
  return (
    <Flex height="100%" width="100%" flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="medium">
        Draft Orders
      </Title>

      <Box mb={3}>
        <BasicNotificationBanner
          message={
            "Please note that draft orders will be automatically discarded 30 days after creation."
          }
          data-testid="banner-id"
          variant="default"
          icon="info"
          mb={3}
        />
      </Box>

      <UnsubmittedOrdersContainer columns={UNSUBMITTED_ORDERS_TABLE_COLUMNS} />
    </Flex>
  );
};
