import { LiveningIcpSite } from "../types/LiveningIcpSite";
import { NewConnectionSite } from "../types/NewConnectionSite";
import { EMPTY_NEW_CONNECTION_LOT_VALUES } from "./NewConnectionLotValues";
import { EMPTY_MANUAL_UNIT_VALUES } from "./ManualUnitValues";
import { SiteAddressInformation } from "../types/SiteAddressInformation";

export const EMPTY_ADDRESS_INFORMATION: SiteAddressInformation = {
  streetAddress: "",
  streetAddressId: "",
  manuallyEnteredAddress: false,
  manualStreetAddress: {
    streetNumber: "",
    streetName: "",
    suburb: "",
    unit: "",
    postcode: "",
  },
  additionalAddressInformation: "",
};

export const EMPTY_LIVENING_ICP_SITES: LiveningIcpSite = {
  siteType: "",
  lotInformation: {
    lotNumber: "",
    dpNumber: "",
    streetNumber: "",
    streetName: "",
    suburb: "",
    postcode: "",
  },
  addressInformation: {
    streetAddress: "",
    streetAddressId: "",
    manuallyEnteredAddress: false,
    manualStreetAddress: {
      streetNumber: "",
      streetName: "",
      suburb: "",
      unit: "",
      postcode: "",
    },
    additionalAddressInformation: "",
  },
  unitType: "",
  manualUnits: [EMPTY_MANUAL_UNIT_VALUES],
  uploadProofOfAddresses: [],
  uploadUnits: [],
  filesUploadStatus: "",
};

export const EMPTY_NEW_CONNECTION_SITES: NewConnectionSite = {
  siteType: "",
  lotInformation: {
    lotNumber: "",
    dpNumber: "",
    streetNumber: "",
    streetName: "",
    suburb: "",
    postcode: "",
  },
  addressInformation: {
    streetAddress: "",
    streetAddressId: "",
    manuallyEnteredAddress: false,
    manualStreetAddress: {
      streetNumber: "",
      streetName: "",
      suburb: "",
      unit: "",
      postcode: "",
    },
    additionalAddressInformation: "",
  },
  lotCount: "1",
  lots: [EMPTY_NEW_CONNECTION_LOT_VALUES],
  additionalSiteRequirements: "",
};
