import { useLocation } from "react-router-dom";
import { useState } from "react";

/***
 * Wrapper to native api - https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * T - type with valid query param names as properties
 *
 * @param queryParam - query param name
 */
export const useQueryParams = <T>(queryParam: Extract<keyof T, string>) => {
  const location = useLocation();
  const [queryParams] = useState(new URLSearchParams(location.search));

  const getByName = (name: Extract<keyof T, string>) =>
    queryParams.get(name) ?? "";

  return getByName(queryParam);
};
