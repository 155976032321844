import React from "react";
import { Route } from "react-router-dom";
import {
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from "@auth0/auth0-react";
import { CenteredLoading } from "../CenteredLoading";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const ProtectedRoute = (props: any) => {
  const { component, ...args } = props;

  /* istanbul ignore next */
  const handleOnRedirecting = () => <CenteredLoading />;

  const options: WithAuthenticationRequiredOptions = {
    onRedirecting: handleOnRedirecting,
  };

  return (
    <Route
      component={withAuthenticationRequired(component, options)}
      {...args}
    />
  );
};
