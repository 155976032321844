import { Field } from "formik";
import React from "react";
import { Flex } from "../Flex";
import { FormikFileUpload } from "../FormikFileUpload";
import { Span } from "../Span";

interface Props {
  siteIndex: number;
}

export const ProofOfAddressUpload: React.FC<Props> = ({ siteIndex }) => {
  return (
    <Flex flexDirection="column">
      <Span lineHeight={2} mb={3} fontWeight={"bold"}>
        Proof of address / site plan
      </Span>

      <Span lineHeight={2} mb={3}>
        Please upload proof that this address exists (e.g. council confirmation
        letter). Max. 5 files, pdf, jpg, gif, png or tiff format.
      </Span>

      <Field
        id={`metered.sites[${siteIndex}].uploadProofOfAddresses`}
        name={`metered.sites[${siteIndex}].uploadProofOfAddresses`}
        acceptFileTypes={[
          "application/pdf",
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "image/tiff",
        ]}
        attachmentType={"Proof of address"}
        component={FormikFileUpload}
      />
    </Flex>
  );
};
