import React, { ChangeEvent, InputHTMLAttributes } from "react";
import { Checkbox } from "../Checkbox";
import { FieldProps, getIn } from "formik";
import { CheckboxProps } from "../Checkbox/Checkbox";
import { SpaceProps } from "styled-system";

export const FormikCheckbox: React.FC<
  FieldProps &
    CheckboxProps &
    SpaceProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({ field, form, ...props }) => {
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    field.onChange(evt);
    form.validateField(field.name);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <Checkbox
      {...field}
      {...props}
      error={touch && error ? error : false}
      onChange={handleChange}
    />
  );
};
