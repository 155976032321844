import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";


const ButtonWrapper = styled.button`
  ${border}
  ${color}
  ${layout}
  ${space}
  ${size}
  ${typography}
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  font-family: inherit;
  border-style: solid;
  border-width: 2px;

  &:disabled {
    pointer-events: none;
  }
`;

const StyledButton = styled(ButtonWrapper)<
    BorderProps &
    ColorProps &
    LayoutProps &
    SizeProps &
    SpaceProps &
    TypographyProps &
    ButtonHTMLAttributes<HTMLButtonElement>
>`
  background-color: ${({ theme }) => theme.colors.hydroDark};
  color: white;
  border-color: ${({ theme }) => theme.colors.hydroDark};

  >:only-child: {
    color: white;
  }
`;

export const MobileProgressStepButton: React.FC<SpaceProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledButton
      borderRadius="button"
      fontSize={1}
      lineHeight={2}
      fontWeight="bold"
      px={3}
      py={2}
      minWidth={16}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

MobileProgressStepButton.defaultProps = {};
