import React, { ButtonHTMLAttributes } from "react";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { Button } from "../Button";
import { SpaceProps } from "styled-system";
import { Flex } from "../Flex";

export interface ActionableNotificationBannerProps {
  title?: string;
  message: string | (() => void);
  variant: NotificationBannerVariant;
  icon: NotificationBannerIcon;
  buttonText: string;
  buttonMarginLeft?: number | string;
}

export const ActionableNotificationBanner: React.FC<
  ActionableNotificationBannerProps &
    SpaceProps &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  title,
  message,
  variant,
  icon,
  buttonText,
  onClick,
  mb,
  buttonMarginLeft,
}) => {
  return (
    <BasicNotificationBanner
      title={title}
      message={message}
      variant={variant}
      icon={icon}
      mb={mb}
      width="100%"
      actionButton={
        <Flex data-testid="action-button-wrapper" ml={buttonMarginLeft}>
          <Button
            variant="primary"
            magnitude="small"
            height={9}
            width={16}
            px="2px"
            mt={[2, 0]}
            ml={[1, 3]}
            mb={2}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Flex>
      }
    />
  );
};
