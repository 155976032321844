import React from "react";
import { DuePayment } from "../../../../types/DuePayment";
import { BasicNotificationBanner } from "../../../BasicNotificationBanner";
import { Span } from "../../../Span";
import { isPaymentDue } from "../OrderProgressSectionUtil/OrderProgressSectionUtil";
import { OrderStatus } from "../../../../types/OrderStatus";
import { useHistory } from "react-router-dom";
import { ActionableNotificationBanner } from "../../../ActionableNotificationBanner";

interface Props {
  orderStatus: OrderStatus;
  duePayment: DuePayment;
}

export const StatusActionMessage: React.FC<Props> = ({
  orderStatus,
  duePayment,
}) => {
  const history = useHistory();

  const { actionMessage } = orderStatus;

  const paymentIsDue = isPaymentDue(duePayment);

  const handleOnPayNowClick = () => {
    const { paymentId } = duePayment;

    if (paymentId) {
      history.push(`/pay-now/${paymentId}`);
    }
  };

  return (
    <>
      {actionMessage && !paymentIsDue && (
        <BasicNotificationBanner
          message={() => (
            <Span dangerouslySetInnerHTML={{ __html: actionMessage }} />
          )}
          variant="warning"
          icon="warning"
          mb={3}
          data-testid="basic-action-message"
        />
      )}

      {actionMessage && paymentIsDue && (
        <ActionableNotificationBanner
          data-testid="action-message"
          message={() => (
            <Span dangerouslySetInnerHTML={{ __html: actionMessage }} />
          )}
          buttonText="Pay now"
          variant="warning"
          icon="warning"
          onClick={handleOnPayNowClick}
          mb={3}
          buttonMarginLeft="auto"
        />
      )}
    </>
  );
};
