import React from "react";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import { Icon } from "../../../Icon";
import { Span } from "../../../Span";
import { DesktopProgressStepButton } from "../DesktopProgressStepButton";

interface Props {
  stepNumber: number;
  stepText: string;
  current?: boolean;
  completed?: boolean;
  completedIcon?: string;
}

const StyledStepItem = styled.li`
  display: inline-block;
  white-space: nowrap;
`;

const StyledStepText = styled(Span)`
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
`;

export const DesktopProgressStep: React.FC<Props &
                                            SpaceProps> = ({
    stepNumber,
    stepText,
    current,
    completed,
    completedIcon,
    ...props
}) => {
  return (
    <StyledStepItem {...props}>
        <DesktopProgressStepButton
            variant={current ? "current" : completed ? "completed" : "disabled"}
        >
            {completed && completedIcon ? (
                <Icon icon={completedIcon} />
            ) : (
                stepNumber
            )}
        </DesktopProgressStepButton>

        <StyledStepText
            color={current ? "nightSky" : "neutrals.mn80"}
            fontSize={4}
            lineHeight={4}
            ml={3}
            pt={1}
            display="inline-block"
        >
            {stepText}
        </StyledStepText>
    </StyledStepItem>
  );
};

DesktopProgressStep.defaultProps = {
  current: false,
  completed: false,
  completedIcon: "done",
};
