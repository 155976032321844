import { CompleteProfileFormValues } from "../types/CompleteProfileFormValues";

export const EMPTY_COMPLETE_PROFILE_VALUES: CompleteProfileFormValues = {
  profile: {
    firstName: "",
    lastName: "",
    mobile: "",
    alternatePhone: "",
    businessName: "",
    id: "",
    auth0Id: "",
    type: "personal",
    isInvitedUser: "false",
    billingAddressDisplay: "",
    billingAddressId: "",
    manuallyEnteredBillingAddress: false,
    manualBillingAddress: {
      addressString: "",
      suburb: "",
      city: "",
      postcode: "",
    },
    companyNzbn: "",
    email: "",
  },
};
