import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "../Button";
import { LiveningIcpFormValues } from "../../types/LiveningIcpFormValues";
import { LiveningIcpOrderDetailsForm } from "./_/LiveningIcpOrderDetailsForm";
import { LiveningIcpReviewForm } from "./_/LiveningIcpReviewForm";
import { Flex } from "../Flex";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { LIVENING_ICP_FORM_STEPS } from "../LiveningIcpProgressStepper/LiveningIcpProgressStepper";
import {
  handleScrollToId,
  scrollToTop,
} from "../../utils/handleScroll/HandleScroll";
import { IconServiceType } from "../IconServiceType";
import { getGaIcpTypeEvent } from "../../utils/ga/GaLiveningIcpEvents";
import { shouldGoToReview } from "./_/LiveningIcpFormUtil/LiveningIcpFormUtil";
import { LiveningIcpOrderDetailsFormSchema } from "./LiveningIcpOrderDetailsFormSchema";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { SomethingWentWrong } from "../SomethingWentWrong/SomethingWentWrong";
import { sendGAEvent } from "../../utils/ga/GaEventSender";

interface Props {
  onSubmit: (values: LiveningIcpFormValues) => void;
  onStepChange: (activeStep: number) => void;
  activeStep: number;
  initialValues: LiveningIcpFormValues;
  handleSave: (values: LiveningIcpFormValues) => void;
}

const validationSchemas = [
  undefined,
  LiveningIcpOrderDetailsFormSchema,
  undefined,
];

const FormWrapper = styled(Form)<SpaceProps>`
  ${space}
`;

export const LiveningIcpForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onStepChange,
  activeStep,
  handleSave,
}) => {
  const [selectedTermsConditions, setSelectedTermsConditions] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const {
    authenticatedUserContext: { isLoggedIn },
  } = useUserAuthentication();

  const isLastStep = activeStep === LIVENING_ICP_FORM_STEPS.length;
  const isSubmissionErrorStep =
    activeStep === LIVENING_ICP_FORM_STEPS.length + 1;

  const submitForm = (values: LiveningIcpFormValues) => {
    setShowLoading(true);
    onSubmit(values);

    setTimeout(() => {
      setShowLoading(false);
    }, 3000);
  };

  const handleOnSubmit = (
    values: LiveningIcpFormValues,
    actions: FormikHelpers<LiveningIcpFormValues>,
  ) => {
    let nextStep = 1;

    if (isLastStep || isSubmissionErrorStep) {
      submitForm(values);
    } else {
      sendGAEvent(getGaIcpTypeEvent(values.icpType));

      // Reset touched fields to false
      // Formik automatically sets all fields as touched onSubmit
      // This falsy triggers error states for fields
      actions.setTouched({
        icpType: false,
        metered: {
          sites: [
            {
              siteType: false,
              addressInformation: {
                streetAddress: false,
                manualStreetAddress: {
                  streetNumber: false,
                  streetName: false,
                  suburb: false,
                  postcode: false,
                },
                additionalAddressInformation: false,
              },
              unitType: false,
              manualUnits: [
                {
                  unitNumber: false,
                  type: false,
                  phase: false,
                  fuseAmp: false,
                  supply: false,
                },
              ],
            },
          ],
        },
        unmetered: {
          addressInformation: {
            streetAddress: false,
            manualStreetAddress: {
              streetNumber: false,
              streetName: false,
              suburb: false,
              postcode: false,
            },
            additionalAddressInformation: false,
          },
          featureType: false,
          featureTypeOther: false,
          quantity: false,
          wattage: false,
          availableHours: false,
        },
        siteAccessContactRoleChecked: false,
        powerRetailerAccountHolderRoleChecked: false,
        hasElectrician: false,
        liveningInspector: false,
        powerRetailer: false,
        optionalContactDetails: {
          firstName: false,
          lastName: false,
          email: false,
          mobile: false,
          alternatePhone: false,
          businessName: false,
          workContactPreferences: {
            email: false,
            sms: false,
          },
        },
        contactDetails: [
          {
            firstName: false,
            lastName: false,
            email: false,
            mobile: false,
            alternatePhone: false,
            businessName: false,
            contactHasRole: false,
            roles: {
              siteAccessContactRole: false,
              powerRetailerAccountHolderRole: false,
            },
            workContactPreferences: {
              email: false,
              sms: false,
            },
          },
        ],
      });

      if (shouldGoToReview(values)) {
        nextStep = activeStep + 1;
        onStepChange(nextStep);

        // Scroll to top when user navigates back and forth on form
        scrollToTop();
      } else {
        setShowLoading(true);
      }
    }
  };

  const handleBack = () => {
    const nextStep = activeStep - 1;
    onStepChange(nextStep);
  };

  const handleReviewEdit = (elementId: string) => {
    const nextStep = activeStep - 1;
    onStepChange(nextStep);
    handleScrollToId(elementId);
  };

  const handleOnSelectTermsConditions = () => {
    setSelectedTermsConditions((prevState) => !prevState);
  };

  const handleOnFilesStillUploading = (filesStillUploading: boolean) => {
    if (!filesStillUploading) {
      setShowLoading(false);
    }
  };

  return (
    <>
      {activeStep < 4 && (
        <IconServiceType
          title="Livening (ICP number)"
          icon="flash_on"
          mt={[5, 5, 5, 5, 7]}
        />
      )}

      <Formik
        validateOnChange={false}
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        initialValues={initialValues!}
        validationSchema={validationSchemas[activeStep - 1]}
        onSubmit={handleOnSubmit}
      >
        {({ values, validateField }) => (
          <FormWrapper mb={8}>
            {activeStep === 2 && (
              <LiveningIcpOrderDetailsForm
                id="livening-icp-order-details-form"
                values={values}
                validateField={validateField}
                onFilesStillUploading={handleOnFilesStillUploading}
              />
            )}

            {activeStep === 3 && (
              <LiveningIcpReviewForm
                values={values}
                onEdit={handleReviewEdit}
                selectedTermsConditions={selectedTermsConditions}
                onSelectTermsConditions={handleOnSelectTermsConditions}
              />
            )}

            <Flex
              justifyContent={activeStep > 2 ? "space-between" : "flex-end"}
              mt={5}
            >
              {activeStep === 3 && (
                <Button
                  variant="secondary"
                  alignSelf="flex-start"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}

              {activeStep < 4 && (
                <Button
                  variant="secondary"
                  alignSelf="flex-start"
                  marginLeft="auto"
                  disabled={!isLoggedIn}
                  onClick={() => handleSave(values)}
                  mr="3"
                  px="6"
                  py="3"
                  data-testid={"save-button"}
                >
                  Save
                </Button>
              )}

              {activeStep < 4 && (
                <Button
                  type="submit"
                  disabled={
                    (isLastStep && !selectedTermsConditions) || showLoading
                  }
                  alignSelf="flex-end"
                  loading={showLoading}
                  data-testid={isLastStep ? "submit-button" : "next-button"}
                  px="6"
                  py="3"
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
              )}
            </Flex>

            {activeStep === 4 && (
              <SomethingWentWrong
                subHeading="It looks like there was an error submitting your order."
                message="Please try again in a few minutes"
                onBackClick={handleBack}
                onTryAgainClick={() => submitForm(values)}
                tryAgainLoading={showLoading}
                showTryAgainButton
                showBackButton
              />
            )}
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
