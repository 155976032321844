import React, { ReactElement, useEffect, useState } from "react";
import { AddressSearchField } from "./AddressSearchField";
import { FlexGrowProps } from "styled-system";
import lookupAddress from "../../services/AddressService";
import { Address } from "../../types/Address";

export interface AddressSearchFieldProps {
  label: string;
  visibleItems: number;
  optional?: boolean;
  error?: boolean;
  message?: ReactElement | string;
  noResultsMessage?: string;
  focusable?: boolean;
  onChange: (value?: string | null) => void;
  onSelect: (selected?: string | null, selectedId?: string | null) => void;
  isPostalAddress: boolean;
  infoMessage?: ReactElement | string;
}

const VALUE_LENGTH_LOOKUP_TRIGGER = 3;

export const AddressSearchFieldContainer: React.FC<
  AddressSearchFieldProps &
    FlexGrowProps &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "onSelect">
> = ({
  message,
  error,
  onChange,
  onSelect,
  isPostalAddress,
  noResultsMessage,
  ...props
}) => {
  const [items, setItems] = useState<Address[]>([]);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    setApiError(error as boolean);
  }, [error]);

  const handleOnChange = async (value?: string) => {
    onChange(value);

    let lookupAddresses: Address[] = [];

    if (value && value.length >= VALUE_LENGTH_LOOKUP_TRIGGER) {
      try {
        lookupAddresses = await lookupAddress(value, isPostalAddress);
      } catch (err: any) {
        setApiError(true);
      }
    }

    setItems(lookupAddresses);
  };

  const handleOnSelect = (selected?: string | null) => {
    if (!selected || !items) {
      return;
    }

    const selectedItem = items.find(
      (item) => selected === item?.label,
    ) as Address;

    onSelect(selected, selectedItem.id);
  };

  return (
    <AddressSearchField
      {...props}
      items={items.map((item) => item.label)}
      error={apiError}
      message={apiError ? message : props.infoMessage}
      noResultsMessage={noResultsMessage}
      valueLengthLookupTrigger={VALUE_LENGTH_LOOKUP_TRIGGER}
      onChange={handleOnChange}
      onSelect={handleOnSelect}
    />
  );
};

AddressSearchFieldContainer.defaultProps = {
  error: false,
  disabled: false,
  optional: false,
  focusable: true,
  isPostalAddress: false,
  noResultsMessage: "We couldn’t find this address.",
};
