import React from "react";
import styled from "styled-components";
import { Unmetered } from "../../../../../../types/Unmetered";
import { Flex } from "../../../../../Flex";
import { Span } from "../../../../../Span";
import { getAddressInformation } from "../FormattedAddressUtil/FormattedAddressUtil";

interface Props {
  unmetered: Unmetered;
}

const LabelWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.dn40};
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;
export const ReviewFormUnmeteredSiteDetails: React.FC<Props> = ({
  unmetered,
}) => {
  const {
    addressInformation,
    featureType,
    featureTypeOther,
    quantity,
    wattage,
    availableHours,
  } = unmetered;
  const { additionalAddressInformation } = addressInformation;

  return (
    <Flex flexDirection="column" mb={4}>
      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Address
        </LabelWrapper>
        <ValueWrapper
          data-testid="unmetered-street-address"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {getAddressInformation(addressInformation)}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Additional information
        </LabelWrapper>
        <ValueWrapper
          data-testid="unmetered-additional-address-info"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {additionalAddressInformation ? additionalAddressInformation : "N/A"}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Feature type
        </LabelWrapper>
        <ValueWrapper
          data-testid="unmetered-feature-type"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {featureType}
        </ValueWrapper>
      </Flex>

      {featureType === "Other" && (
        <Flex flexDirection="row" mb={3}>
          <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
            Feature type (Other)
          </LabelWrapper>
          <ValueWrapper
            data-testid="unmetered-feature-type-other"
            lineHeight={2}
            ml={3}
            flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
          >
            {featureTypeOther}
          </ValueWrapper>
        </Flex>
      )}

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Quantity
        </LabelWrapper>
        <ValueWrapper
          data-testid="unmetered-quantity"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {quantity}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Wattage
        </LabelWrapper>
        <ValueWrapper
          data-testid="unmetered-wattage"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {wattage}
        </ValueWrapper>
      </Flex>

      <Flex flexDirection="row" mb={3}>
        <LabelWrapper lineHeight={2} flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}>
          Available hours
        </LabelWrapper>
        <ValueWrapper
          data-testid="unmetered-available-hours"
          lineHeight={2}
          ml={3}
          flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        >
          {availableHours}
        </ValueWrapper>
      </Flex>
    </Flex>
  );
};
