import React from "react";
import { ProgressStepper } from "../ProgressStepper";

interface Props {
  currentStep: number;
}

const PAY_NOW_FORM_STEPS = ["Review", "Billing details", "Payment"];

export const PayNowProgressStepper: React.FC<Props> = ({ ...props }) => {
  return <ProgressStepper steps={PAY_NOW_FORM_STEPS} {...props} />;
};

export { PAY_NOW_FORM_STEPS };
