import * as yup from "yup";
import { noWhitespaceValidation } from "../../utils/noWhitespaceValidation/noWhitespaceValidation";
import {
  ONLY_LETTERS_AND_SPACE_REG_EXP,
  ONLY_NUMBERS_REG_EXP,
} from "../../constants/FormRegex";
import { EMAIL_REG_EXP } from "../../constants/EmailRegex";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noWhitespace",
  noWhitespaceValidation,
);

export const PayNowFormSchema = yup.object().shape({
  billingDetails: yup.object().shape({
    firstName: yup
      .string()
      .noWhitespace("First Name is invalid")
      .max(50, "Maximum length is 50")
      .required("First Name is required"),
    lastName: yup
      .string()
      .noWhitespace("Last Name is invalid")
      .max(50, "Maximum length is 50")
      .required("Last Name is required"),
    businessName: yup
      .string()
      .noWhitespace("Business name is invalid")
      .max(100, "Maximum length is 100 characters"),
    email: yup
      .string()
      .noWhitespace("Enter a valid email")
      .matches(EMAIL_REG_EXP, "Enter a valid email")
      .max(50, "Maximum length is 50")
      .required("Email is required"),
    billingAddressDisplay: yup.string().when("manuallyEnteredBillingAddress", {
      is: (manuallyEnteredBillingAddress: boolean) => {
        return !manuallyEnteredBillingAddress;
      },

      then: yup
        .string()
        .required("Address is required")
        .test("is-valid-address", "Address is invalid", function (value) {
          const { from } = this as yup.TestContext & {
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            from: any;
          };

          if (!value) {
            return false;
          }

          const formValues = from[0];
          if (formValues) {
            const addressId = from[0].value.addressId as string;
            const billingAddressId = from[0].value.billingAddressId as string;
            const billingAddressIdEmpty = !billingAddressId;
            const addressIdEmpty = !addressId;
            return !(billingAddressIdEmpty && addressIdEmpty);
          }
          return false;
        }),
    }),
    manuallyEnteredBillingAddress: yup.boolean(),
    manualBillingAddress: yup.object().when("manuallyEnteredBillingAddress", {
      is: true,
      then: yup.object({
        addressString: yup
          .string()
          .noWhitespace("Street address / PO Box or Private Bag is invalid")
          .required("Street address / PO Box or Private Bag is required")
          .max(100, "Maximum length is 100")
          .min(5, "Street address / PO Box or Private Bag is invalid"),
        suburb: yup
          .string()
          .noWhitespace("Suburb is invalid")
          .max(50, "Maximum length is 50")
          .matches(
            ONLY_LETTERS_AND_SPACE_REG_EXP,
            "Suburb can only contain letters",
          ),
        city: yup
          .string()
          .noWhitespace("City is invalid")
          .required("City is required")
          .max(50, "Maximum length is 50")
          .matches(
            ONLY_LETTERS_AND_SPACE_REG_EXP,
            "City can only contain letters",
          ),
        postcode: yup
          .string()
          .noWhitespace("Postcode is invalid")
          .required("Postcode is required")
          .matches(ONLY_NUMBERS_REG_EXP, "Postcode can only contain numbers")
          .length(4, "Postcode should contain 4 numbers, please update"),
      }),
    }),
  }),
  paymentMethod: yup.string().required(),
});
