import React from "react";
import { Title } from "../Title";
import { CompleteProfileFormValues } from "../../types/CompleteProfileFormValues";
import styled from "styled-components";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { Span } from "../Span";
import { AccountDetailsForm } from "../AccountDetailsForm";
import { AccountSecurityDetails } from "../AccountSecurityDetails";
import { Badge } from "../Badge";
import { Icon } from "../Icon";
import { Link } from "../Link";

interface AccountSettingsProps {
  userInfo: CompleteProfileFormValues;
  onSubmit: (values: CompleteProfileFormValues) => void;
  onResetPasswordClick: (email: string) => void;
}

const FlexWrapper = styled(Flex)`
  ${mediaQueries.largeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.largeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

export const AccountSettings: React.FC<AccountSettingsProps> = ({
  userInfo,
  onSubmit,
  onResetPasswordClick,
}) => {
  const handleSubmit = async (values: CompleteProfileFormValues) => {
    onSubmit(values);
  };

  let firstName = "";
  let isAdmin = false;

  if (userInfo && userInfo.profile) {
    firstName = userInfo.profile.firstName;
    isAdmin = userInfo.profile.isAdmin || false;
  }

  return (
    <GridWrapper mx={[2, 2, 4, 4, 0]}>
      <FlexWrapper flexDirection="column">
        <Flex alignItems="center" justifyContent={"flex-start"} mb={5}>
          <Title as="h1" lineHeight={7} fontWeight="medium" mr={4}>
            Hi {firstName}
          </Title>

          {isAdmin && <Badge>Admin</Badge>}
        </Flex>

        <Span
          display="inline-block"
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
        >
          Account details
        </Span>

        <AccountDetailsForm initialValues={userInfo} onSubmit={handleSubmit} />

        <AccountSecurityDetails onResetPasswordClick={onResetPasswordClick} />

        {isAdmin && (
          <Flex flexDirection="column" mt={7} mb={4}>
            <Title
              fontSize={4}
              lineHeight={4}
              fontStyle="normal"
              fontWeight="500"
            >
              User management
            </Title>

            <Flex
              flexDirection="row"
              p={4}
              borderRadius="notificationBanner"
              bg="neutrals.ln10"
              border="1px solid"
              borderColor="neutrals.ln30"
            >
              <Icon icon="person_add" mr={2} />
              <Span>
                If you need to invite users under this business account, please{" "}
                <Link href="mailto: vectorconnections@vector.co.nz">
                  contact us
                </Link>
                .
              </Span>
            </Flex>
          </Flex>
        )}
      </FlexWrapper>
    </GridWrapper>
  );
};
