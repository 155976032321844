import { useQueryInvalidator } from "../useQueryInvalidator/useQueryInvalidator";
import {
  basicAccountDetailsKey,
  fullAccountDetailsKey,
} from "../../../constants/ReactQueryKeys";

export const useInvalidateAccountDetails = () => {
  const { invalidateQuery } = useQueryInvalidator();

  const invalidateAccountDetailsCache = async () => {
    //a bit of timeout to give time for siebel to get updated
    await new Promise<void>((resolve) =>
      setTimeout(async () => {
        await invalidateQuery(fullAccountDetailsKey);
        await invalidateQuery(basicAccountDetailsKey);
        resolve();
      }, 500),
    );
  };

  return { invalidateAccountDetailsCache };
};
