import React from "react";
import { FlexGrowProps } from "styled-system";
import { Dropdown } from "../Dropdown";
import { useGetInspectorRetailers } from "../../hooks/cachedQueries/useGetInspectorRetailers/useGetInspectorRetailers";
import { InspectorRetailer } from "../../types/InspectorRetailer";

export interface LiveningInspectorFieldProps {
  label: string;
  visibleItems: number;
  optional?: boolean;
  error?: boolean;
  message?: string;
  focusable?: boolean;
  onSelect: (selected?: string | null, selectedId?: string | null) => void;
  path: "livening-inspectors" | "power-retailers";
}

export const InspectorRetailerDropdownFieldContainer: React.FC<
  LiveningInspectorFieldProps &
    FlexGrowProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onSelect">
> = ({ error, onSelect, path, ...props }) => {
  const { serviceProviders, apiError } = useGetInspectorRetailers(path);

  const handleOnSelect = (selected?: string | null) => {
    if (!selected || !serviceProviders) {
      return;
    }

    const selectedItem = serviceProviders.find(
      (item) => selected === item?.name,
    ) as InspectorRetailer;

    onSelect(selected, selectedItem.id);
  };

  return (
    <Dropdown
      {...props}
      onSelect={handleOnSelect}
      items={serviceProviders.map((item) => item.name)}
      error={error || apiError}
    />
  );
};

InspectorRetailerDropdownFieldContainer.defaultProps = {
  error: false,
  disabled: false,
  optional: false,
  focusable: true,
};
