import React from "react";
import { OrderDetailsPage, OrderDetailsProps } from "./OrderDetailsPage";

export const DEFAULT_ORDER_DETAILS_CONTENT: OrderDetailsProps = {
  tabTitle: "",
};

export const OrderDetailsPageContainer: React.FC = () => {
  return <OrderDetailsPage {...DEFAULT_ORDER_DETAILS_CONTENT} />;
};
