import React, { InputHTMLAttributes, useRef } from "react";
import {
  DisplayProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
} from "styled-system";
import { Icon } from "../Icon";
import { PopupTip } from "../PopupTip";
import styled from "styled-components";
import { Box } from "../Box";
import { mediaQueries } from "../../theme";
import { Span } from "../Span";
import { useClickedOutsideElement } from "../../hooks/useClickedOutsideElement/useClickedOutsideElement";

interface Props {
  icon: string;
  label?: string;
  onClose?: () => void;
}

const PopupWrapper = styled(PopupTip)`
  min-width: 200px;
  right: 0;

  ${mediaQueries.largeDevice} {
    right: auto;
    left: 0;
  }
`;

const IconWrapper = styled(Icon)<InputHTMLAttributes<HTMLInputElement>>`
  cursor: pointer;
`;

const LabelWrapper = styled(Span)`
  cursor: pointer;
`;

export const IconPopupTip: React.FC<
  Props &
    DisplayProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({ id, icon, label, checked, onClose, ml, mt, display, children }) => {
  const flexRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState(checked);

  const handleChange = () => {
    setShow((prevState) => !prevState);
  };

  const handleClickedOutsideElement = () => {
    setShow(false);
    onClose && onClose();
  };

  useClickedOutsideElement<HTMLDivElement>(
    flexRef,
    handleClickedOutsideElement,
    () => !show,
  );

  return (
    <Box display={display} position="relative" ml={ml} ref={flexRef} mt={mt}>
      <IconWrapper
        icon={icon}
        aria-labelledby={id}
        onClick={handleChange}
        data-testid="icon"
      />

      {label && (
        <LabelWrapper
          ml={2}
          color="primary"
          onClick={handleChange}
          data-testid="label"
        >
          {label}
        </LabelWrapper>
      )}

      {show && (
        <PopupWrapper id={id} mt={4} position="absolute" data-testid="popup">
          {children}
        </PopupWrapper>
      )}
    </Box>
  );
};

IconPopupTip.defaultProps = {
  icon: "",
  checked: false,
  display: "inline-block",
};
