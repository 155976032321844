export interface PaymentInformation {
  title: string;
  answer: string;
}

export const PaymentPageInformationValues: PaymentInformation = {
  title: "Things to know",
  answer:
    "This amount will be used by Vector Limited (Vector) to cover costs we incur:" +
    "<ul> <li>to assess your application, or</li>" +
    "<li> to complete the work</li></ul>" +
    "For more information, please refer to the letter attached in your email. We will proceed with your order once full payment has been received.<br/>" +
    "<br/>If payment has not been received by the due date, then the job will be cancelled and if still required you need to re-apply.<br/>" +
    "<br/>Please note for electricity or gas connection jobs, that costs associated with meter, livening and inspection are through your electricity retailer, and costs associated with any works on communications cables, are not included in our quotes.",
};
