import {
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  variant,
} from "styled-system";
import React, { InputHTMLAttributes, RefObject } from "react";
import styled, { css } from "styled-components";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Label } from "../Label";

export interface RadioButtonProps {
  label: string;
  magnitude?: "small" | "medium" | "large" | "xLarge";
  alignText?: string;
  currentValue?: string | number;
  icon?: string;
  focusable?: boolean;
  error?: boolean;
}

const FlexContainer = styled(Flex)(
  variant({
    scale: "radioButton",
    variants: {
      primary: {
        borderRadius: "radio",
        border: "2px solid",
        borderColor: "neutrals.ln40",
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
        },
        "&:focus-visible": {
          outline: "none",
        },
        "&:hover": {
          bg: "hydro10",
        },
      },
    },
  }),
  variant({
    prop: "magnitude",
    scale: "radioButtonMagnitude",
    variants: {
      xLarge: {
        fontSize: 4,
        lineHeight: 4,
        py: 7,
      },
      large: {
        fontSize: 4,
        lineHeight: 4,
        py: 4,
      },
      medium: {
        fontSize: 2,
        lineHeight: 2,
        paddingTop: [1, 2, "19px"],
        paddingBottom: [1, 2, "19px"],
      },
      small: {
        fontSize: 2,
        lineHeight: 2,
        py: 2,
      },
    },
  }),
);

const LabelWrapper = styled(Label)`
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const IconWrapper = styled(Icon)``;

const Container = styled(FlexContainer)<{
  alignText?: string;
}>`
  justify-content: center;
  text-align: ${(props) => props.alignText};
  flex-direction: column;
  cursor: pointer;
  border-color: ${({ error, theme }) => error && theme.colors.danger};
  border-color: ${({ checked, theme }) => checked && theme.colors.hydroDark};
  background-color: ${({ checked, theme }) => checked && theme.colors.hydro10};
  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: none;
      pointer-events: none;
      background-color: ${theme.colors.neutrals.ln20};
      & > ${LabelWrapper} {
        cursor: none;
        pointer-events: none;
        color: ${theme.colors.neutrals.mn40};
      }
      & > ${IconWrapper} {
        color: ${theme.colors.neutrals.mn40};
      }
    `}
`;

export const RadioButton: React.FC<
  RadioButtonProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    TypographyProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({
  id,
  label,
  magnitude,
  icon,
  currentValue,
  value,
  disabled,
  error,
  tabIndex,
  flexGrow,
  flexBasis,
  minWidth,
  focusable,
  ml,
  mr,
  mb,
  mt,
  mx,
  my,
  width,
  fontSize,
  fontFamily,
  alignText,
  ...props
}) => {
  const inputRef: RefObject<HTMLInputElement> = React.useRef(null);
  const checked = currentValue === value;

  const handleOnClick = () => {
    /* istanbul ignore next */
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  };

  return (
    <Container
      variant="primary"
      magnitude={magnitude}
      checked={checked}
      onClick={handleOnClick}
      error={error}
      disabled={disabled}
      tabIndex={focusable ? tabIndex : -1}
      flexGrow={flexGrow}
      flexBasis={flexBasis}
      minWidth={minWidth}
      width={width}
      alignText={alignText}
      ml={ml}
      mr={mr}
      mb={mb}
      mt={mt}
      mx={mx}
      my={my}
      px={3}
    >
      {icon && (
        <IconWrapper
          icon={icon}
          color="hydro"
          fontSize={8}
          fontFamily={fontFamily}
          lineHeight={7}
          mb={3}
        />
      )}
      <Input
        {...props}
        id={id}
        value={value}
        checked={checked}
        aria-checked={checked}
        ref={inputRef}
        disabled={disabled}
        type="radio"
      />
      <LabelWrapper fontSize={fontSize} htmlFor={id}>
        {label}
      </LabelWrapper>
    </Container>
  );
};

RadioButton.defaultProps = {
  error: false,
  magnitude: "large",
  disabled: false,
  focusable: true,
  tabIndex: 0,
  alignText: "center",
};
