import { useEffect } from "react";

type Hook = <T>(
  elementRef: React.RefObject<T>,
  onClickedOutsideElement: () => void,
  doNotCheckCondition?: () => boolean,
) => void;

export const useClickedOutsideElement: Hook = <T,>(
  elementRef: React.RefObject<T>,
  onClickedOutsideElement: () => void,
  doNotCheckCondition?: () => boolean,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {
      // Early termination
      if (!elementRef || (doNotCheckCondition && doNotCheckCondition())) {
        return;
      }

      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const refCurrent = elementRef.current as any;
      if (!refCurrent) {
        return;
      }

      if (!refCurrent.contains(event.target)) {
        onClickedOutsideElement();
      }
    };

    const handleEscKey = (e: KeyboardEvent) => {
      e.code === "Escape" && handleClickOutside(e);
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    /* istanbul ignore next */
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doNotCheckCondition]);
};
