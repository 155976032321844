import { ColorProps, FlexboxProps, LayoutProps } from "styled-system";
import { FieldProps, getIn } from "formik";
import React, { InputHTMLAttributes } from "react";

import { InputText } from "../InputText";
import { InputTextProps } from "../InputText/InputText";

export const FormikInputText: React.FC<
  FieldProps &
    FlexboxProps &
    InputTextProps &
    ColorProps &
    LayoutProps &
    InputHTMLAttributes<HTMLInputElement>
> = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <InputText {...field} error={touch && error ? error : false} {...props} />
  );
};
