import React, { InputHTMLAttributes, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled, { css } from "styled-components";
import { ActionLink } from "../../../ActionLink";
import { Flex } from "../../../Flex";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import { Icon } from "../../../Icon";
import { Span } from "../../../Span";
import { extendedFileNameValidator } from "../DropZoneFileValidator/DropZoneFileValidator";
import { getAcceptedMimeTypes } from "../FileUtil/FileUtil";

interface Props {
  allowMultiple?: boolean;
  maxFiles?: number;
  maxSizeInBytes?: number;
  acceptFileTypes: FileType[];
  rejectedFileNames?: string[];
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  onFilesSelected: (selectedFiles: CssFile[]) => void;
  onBlur?: () => void;
}

export const UploadWrapper = styled(Flex)<{
  isDragActive: boolean;
  isDragReject: boolean;
  error: boolean;
  disabled: boolean;
}>`
  outline: none;
  border-color: ${({ theme }) => theme.colors.neutrals.ln40};
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border-width: 2px;
  border-radius: 12px;
  border-style: dashed;
  transition: border 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;

  ${({ isDragActive, theme }) =>
    isDragActive &&
    css`
      border-color: ${theme.colors.hydroDark};
      background-color: ${theme.colors.hydro25};
    `};

  ${({ isDragReject, theme }) =>
    isDragReject &&
    css`
      border-color: ${theme.colors.urgentRed};
      background-color: ${theme.colors.urgentRed25};
    `};

  ${({ error, theme }) =>
    error &&
    css`
      border-style: solid;
      border-color: ${theme.colors.urgentRed};
    `};

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-style: solid;
      border-color: ${theme.colors.neutrals.ln40};
      background-color: ${theme.colors.neutrals.ln20};
    `};

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        border-color: ${theme.colors.hydro};
        background-color: ${theme.colors.hydro10};
      `};
  }

  &:focus {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        border-color: ${theme.colors.hydro};
        box-shadow: ${theme.shadows.inputFocus};
      `};
  }
`;

export const DropZoneFileUpload: React.FC<
  Props & Omit<InputHTMLAttributes<HTMLInputElement>, "OnBlur">
> = ({
  name,
  allowMultiple,
  maxFiles,
  maxSizeInBytes,
  acceptFileTypes,
  rejectedFileNames,
  error,
  errorMessage,
  disabled,
  onFilesSelected,
  onBlur,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesSelected(acceptedFiles);
    },
    [onFilesSelected],
  );

  /* istanbul ignore next */
  const handleOnFileDialogCancel = () => {
    onBlur && onBlur();
  };

  /* istanbul ignore next */
  const fileNameValidator = (file: File) => {
    return extendedFileNameValidator(file, rejectedFileNames);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      noClick: true,
      multiple: allowMultiple,
      maxFiles,
      maxSize: maxSizeInBytes,
      accept: acceptFileTypes.toString(),
      disabled,
      validator: fileNameValidator,
      onDrop,
      onFileDialogCancel: handleOnFileDialogCancel,
    });

  return (
    <Flex flexDirection="column">
      <UploadWrapper
        data-testid="dropzone-upload-wrapper"
        flexDirection="column"
        alignItems="center"
        padding={4}
        isDragActive={isDragActive}
        isDragReject={isDragReject}
        error={error}
        disabled={disabled}
        {...getRootProps()}
      >
        <input
          {...getInputProps({
            name,
          })}
          data-testid="dropzone-upload-input"
        />

        {!isDragActive && (
          <>
            <Span color="neutrals.mn80" textAlign="center" mb={1}>
              Drag and drop your file here or click &ldquo;Upload file&ldquo;
            </Span>

            <ActionLink
              iconLeft="add_circle"
              disabled={disabled}
              onClick={open}
            >
              Upload file
            </ActionLink>
          </>
        )}

        {isDragActive && (
          <>
            <Span textAlign="center" fontSize={1} lineHeight={1} mb={2}>
              Drag and drop your file here
            </Span>
            <Icon icon="file_download" fontSize={7}></Icon>
          </>
        )}
      </UploadWrapper>

      {error && errorMessage && (
        <FormInputErrorMessage>{errorMessage}</FormInputErrorMessage>
      )}
    </Flex>
  );
};

DropZoneFileUpload.defaultProps = {
  allowMultiple: true,
  maxFiles: 5,
  maxSizeInBytes: 10485760,
  acceptFileTypes: getAcceptedMimeTypes(),
  rejectedFileNames: [],
};
