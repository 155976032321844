import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  border,
  color,
  layout,
  space,
  typography,
} from "styled-system";
import React, {
  InputHTMLAttributes,
  RefObject,
  useEffect,
  useState,
} from "react";

import { Button } from "../Button";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Span } from "../Span";
import styled from "styled-components";
import { Label } from "../Label";

export interface CounterProps {
  value: number;
  error?: boolean;
  message?: string;
  onChange: (count: number) => void;
  focusable?: boolean;
  label?: string;
}

const InputWrapper = styled.input<
  Omit<CounterProps, "onChange"> &
    BorderProps &
    ColorProps &
    SpaceProps &
    TypographyProps
>`
  ${border}
  ${layout}
  ${color}
  ${space}
  ${typography}
  outline: none;

  &:disabled {
    border-color: transparent;
  }
`;

const FlexWrapper = styled(Flex)`
  box-sizing: border-box;

  &:focus {
    box-shadow: inputFocus;
  }
`;

const ButtonWrapper = styled(Button)`
  border: 2px solid;
  border-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.neutrals.ln30 : theme.colors.neutrals.ln20};

  &:hover {
    border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  }

  &:focus {
    border-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.neutrals.ln30 : theme.colors.neutrals.ln20};
  }
`;

export const Counter: React.FC<
  BackgroundProps &
    CounterProps &
    ColorProps &
    LayoutProps &
    SpaceProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">
> = ({
  value,
  min,
  max,
  error,
  message,
  disabled,
  mb,
  mr,
  width,
  tabIndex,
  focusable,
  onChange,
  onFocus,
  onBlur,
  label,
}) => {
  const regExp = /^[+-]?\d*(?:[.,]\d*)?$/;

  const [count, setCount] = useState(value);
  const inputRef: RefObject<HTMLInputElement> = React.useRef(null);

  useEffect(() => {
    onChange(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const countValue = event.target.value;

    if (regExp.test(countValue)) {
      setCount(Number(countValue));
    }
  };

  /* istanbul ignore next */
  const inputRefBlur = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.blur();
    }
  };

  const handleDecrement = () => {
    setCount((preValue) => preValue - 1);
    inputRefBlur();
  };

  const handleIncrement = () => {
    setCount((preValue) => preValue + 1);
    inputRefBlur();
  };

  const isDecrementDisabled = () => {
    if (disabled) {
      return true;
    }

    if (min && count <= min) {
      return true;
    }

    if (count <= 1) {
      return true;
    }

    return false;
  };

  const isIncrementDisabled = () => {
    if (disabled) {
      return true;
    }

    if (max && count >= max) {
      return true;
    }

    return false;
  };

  return (
    <Flex flexDirection="column" mb={mb} width={width}>
      <Label
        color="nightSky"
        fontSize="body"
        lineHeight="body"
        pl={2}
        pr={2}
        mb={1}
      >
        {label}
      </Label>

      <FlexWrapper
        justifyContent="space-between"
        border="2px solid"
        borderColor={error ? "urgentRed" : "neutrals.ln40"}
        borderRadius="input"
        bg={disabled ? "neutrals.ln20" : "neutrals.ln0"}
        alignItems="center"
        mr={mr}
        px={2}
        py="5px"
      >
        <ButtonWrapper
          id="icp-count-decrease-button"
          px={2}
          borderWidth={1}
          width={11}
          borderRadius="input"
          variant="lowAffordance"
          magnitude="medium"
          focusable={focusable}
          disabled={isDecrementDisabled()}
          onClick={handleDecrement}
        >
          <Icon icon="remove" margin="auto" />
        </ButtonWrapper>

        <InputWrapper
          type="text"
          textAlign="center"
          width="100%"
          border={disabled ? "neutrals.ln20" : "transparent"}
          bg={disabled ? "neutrals.ln20" : "transparent"}
          py={2}
          fontWeight="medium"
          fontSize={3}
          fontFamily="inherit"
          lineHeight={4}
          borderColor="transparent"
          color={disabled ? "neutrals.mn40" : "nightSky"}
          disabled={disabled}
          error={error}
          value={count}
          ref={inputRef}
          tabIndex={focusable ? tabIndex : -1}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <ButtonWrapper
          id="icp-count-increase-button"
          px={2}
          borderWidth={1}
          width={11}
          borderRadius="input"
          variant="lowAffordance"
          magnitude="medium"
          focusable={focusable}
          disabled={isIncrementDisabled()}
          onClick={handleIncrement}
        >
          <Icon icon="add" />
        </ButtonWrapper>
      </FlexWrapper>

      {message && (
        <Span
          color="neutrals.mn60"
          height={6}
          mx={2}
          fontSize={1}
          lineHeight={1}
          data-testid="counter-message"
        >
          {message}
        </Span>
      )}
    </Flex>
  );
};

Counter.defaultProps = {
  disabled: false,
  error: false,
  value: 1,
  focusable: true,
};
