import React from "react";
import { ActionLink } from "../../../../../ActionLink";
import { Flex } from "../../../../../Flex";
import { Line } from "../../../../../Line";
import { Span } from "../../../../../Span";

interface Props {
  id: string;
  title: string;
  onEditClick: () => void;
}

export const ReviewFormSection: React.FC<Props> = ({
  id,
  title,
  onEditClick,
  children,
}) => {
  const handleEditClick = () => {
    onEditClick();
  };

  return (
    <Flex flexDirection="column" id={id}>
      <Flex justifyContent="space-between" mb={5}>
        <Span
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          mr={3}
          alignSelf="flex-start"
        >
          {title}
        </Span>
        <ActionLink
          iconLeft="edit"
          alignSelf="flex-start"
          onClick={handleEditClick}
        >
          Edit
        </ActionLink>
      </Flex>

      {children}

      <Line
        width="100%"
        my={7}
        zIndex={0}
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
      />
    </Flex>
  );
};
