import React, { useEffect } from "react";
import { BasicNotificationBanner } from "../../../BasicNotificationBanner";
import { Box } from "../../../Box";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { Flex } from "../../../Flex";
import { FormikInputText } from "../../../FormikInputText";
import { FormikRadioButton } from "../../../FormikRadioButton";
import { LiveningIcpFormValues } from "../../../../types/LiveningIcpFormValues";
import { Span } from "../../../Span";
import { Title } from "../../../Title";
import { UnmeteredIcp } from "../../../UnmeteredIcp";
import { IconPopupTip } from "../../../IconPopupTip";
import { Line } from "../../../Line";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import { FormikInspectorRetailerField } from "../../../FormikInspectorRetailerField";
import { LiveningIcpContactDetailsForm } from "../LiveningIcpContactDetailsForm";
import { LiveningIcpSiteInformationForm } from "../LiveningIcpSiteInformationForm";
import { URLVALUES } from "../../../../constants/UrlValues";
import { Link } from "../../../Link";
import { getFirstErrorByPosition } from "../../../CheckErrorsUtil/CheckErrorsUtil";
import { handleScrollToName } from "../../../../utils/handleScroll/HandleScroll";
import { filesStillUploading } from "../../../FileUploadUtil/FileUploadUtil";
import {
  powerRetailerAccountHolderRoleSelectedInAllContactDetails,
  siteAccessContactRoleSelectedInAllContactDetails,
} from "../../../ContactDetailsForm/ContactDetailsRoleChecker/ContactDetailsRoleChecker";

interface Props {
  id: string;
  values: LiveningIcpFormValues;
  validateField: (field: string) => void;
  onFilesStillUploading?: (filesStillUploading: boolean) => void;
}

export const LiveningIcpOrderDetailsForm: React.FC<Props> = ({
  id,
  values,
  validateField,
  onFilesStillUploading,
}) => {
  useEffect(() => {
    const allContactDetailsRoles = values.contactDetails.map(
      (details) => details.roles,
    );

    values.siteAccessContactRoleChecked =
      siteAccessContactRoleSelectedInAllContactDetails(allContactDetailsRoles);
    values.powerRetailerAccountHolderRoleChecked =
      powerRetailerAccountHolderRoleSelectedInAllContactDetails(
        allContactDetailsRoles,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.contactDetails]);

  const { errors, isSubmitting } = useFormikContext<LiveningIcpFormValues>();

  // Scroll to first validation error (if any)
  useEffect(() => {
    if (isSubmitting) {
      const errorFieldName = getFirstErrorByPosition(errors);
      if (errorFieldName) {
        handleScrollToName(errorFieldName);
      }
    }
  }, [isSubmitting, errors]);

  // Check if files are still uploading
  useEffect(() => {
    if (onFilesStillUploading) {
      const areFilesStillUploading = filesStillUploading(values.metered.sites);
      onFilesStillUploading(areFilesStillUploading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.metered.sites]);

  return (
    <Flex id={id} flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="medium" mb={5}>
        Order details
      </Title>

      <Box id="livening-icp-order-details-section">
        <Flex
          justifyContent={[
            "space-between",
            "space-between",
            "space-between",
            "flex-start",
          ]}
          mt={[2, 2, 2, 0, 0]}
          mb={3}
        >
          <Span
            display="inline-block"
            fontSize={4}
            lineHeight={4}
            fontWeight="medium"
          >
            What type of ICP do you need?
          </Span>

          <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
            {"All residential supplies must be metered." +
              " It is possible to have an unmetered supply on some commercial or " +
              " ancillary supplies where capacity is less than 1kVA and usage is predictable or " +
              " can be accurately calculated without a meter. For example, street lightning or billboards."}
          </IconPopupTip>
        </Flex>

        <Flex mb={3}>
          <Field
            magnitude="medium"
            id="icp-type-metered"
            name="icpType"
            label="Metered ICP"
            value="metered ICP"
            currentValue={values.icpType}
            flexGrow={0.5}
            mr={[2, 2, 3]}
            component={FormikRadioButton}
          />
          <Field
            magnitude="medium"
            id="icp-type-unmetered"
            name="icpType"
            label="Unmetered ICP"
            value="unmetered ICP"
            currentValue={values.icpType}
            flexGrow={0.5}
            ml={[2, 2, 3]}
            component={FormikRadioButton}
          />
        </Flex>

        <BasicNotificationBanner
          message={
            "Please submit separate order for ICPs at different site locations that require different site contacts, " +
            "energy retailer account holders, and/or power retailer."
          }
          variant="default"
          icon="info"
          mb={7}
        />

        <Span
          display="inline-block"
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          mb={3}
        >
          Do you have a reference number to a service request or project related
          to the connection supply point? (Optional)
        </Span>

        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mr={[0, 0, 2]}
          mb={7}
        >
          <Field
            id="project-number"
            name="projectNumber"
            label="SR ref. or project number"
            optional
            flexGrow={1}
            component={FormikInputText}
          />
          <ErrorMessage
            name="projectNumber"
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Box>

      {values.icpType === "unmetered ICP" && (
        <UnmeteredIcp
          unmetered={values.unmetered}
          validateField={validateField}
        />
      )}

      {values.icpType === "metered ICP" && (
        <>
          <Span
            display="inline-block"
            fontSize={4}
            lineHeight={4}
            fontWeight="medium"
            mb={3}
          >
            Site addresses phase and fuse information
          </Span>

          <BasicNotificationBanner
            message={
              "You must consult your electrician to confirm the phase and fuse size you will need to power your home."
            }
            variant="default"
            icon="info"
            mb={3}
          />

          <LiveningIcpSiteInformationForm
            id="livening-icp-site-details-section"
            sites={values.metered.sites}
            validateField={validateField}
          />
        </>
      )}

      <Flex flexDirection="column" id="livening-icp-inspector-retailer-section">
        <Flex
          mb={3}
          alignItems="center"
          justifyContent={[
            "space-between",
            "space-between",
            "space-between",
            "flex-start",
          ]}
        >
          <Span
            display="inline-block"
            fontSize={4}
            lineHeight={4}
            fontWeight="medium"
            mr={3}
          >
            Select a Vector approved livening inspector
          </Span>

          <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
            A livening inspector will confirm the site is electrically safe,
            complete the necessary electrical certification and liven the
            supply. They will provide Vector with required information about
            livening. Visit{" "}
            <Link
              variant="secondary"
              href={URLVALUES.approvedLiveningInspector}
            >
              our website
            </Link>{" "}
            for a list of approved inspectors and their contact details. Your
            power retailer will organise the livening with the inspector.
          </IconPopupTip>
        </Flex>

        <Field
          id="liveningInspector"
          name="liveningInspector"
          nameId="liveningInspectorId"
          label="Livening inspector"
          path="livening-inspectors"
          placeholder=""
          width={["100%", "100%", "65%", "50%", "50%"]}
          component={FormikInspectorRetailerField}
        />
        <ErrorMessage
          name="liveningInspector"
          className="field-error"
          component={FormInputErrorMessage}
        />

        <Flex
          mt={7}
          mb={3}
          alignItems="center"
          justifyContent={[
            "space-between",
            "space-between",
            "space-between",
            "flex-start",
          ]}
        >
          <Span
            display="inline-block"
            fontSize={4}
            lineHeight={4}
            fontWeight="medium"
            mr={3}
          >
            Select a power retailer
          </Span>

          <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
            Retailers are companies that sell electricity to the end-consumer.
            They are responsible for organising the installation of the meter
            and the livening with your chosen livening inspector. You must sign
            up with a retailer before your power can be livened.
          </IconPopupTip>
        </Flex>

        <Field
          id="powerRetailer"
          name="powerRetailer"
          nameId="powerRetailerId"
          label="Power retailer"
          path="power-retailers"
          placeholder=""
          width={["100%", "100%", "65%", "50%", "50%"]}
          component={FormikInspectorRetailerField}
        />
        <ErrorMessage
          name="powerRetailer"
          className="field-error"
          component={FormInputErrorMessage}
        />

        <Line
          width="100%"
          my={7}
          zIndex={0}
          border="1px solid"
          borderColor="neutrals.ln30"
          bg="neutrals.ln30"
          opacity="1"
          height={0}
        />
      </Flex>

      <LiveningIcpContactDetailsForm
        id="livening-icp-contact-details-section"
        contactDetails={values.contactDetails}
        hasElectrician={values.hasElectrician}
        optionalContactDetails={values.optionalContactDetails}
      />
    </Flex>
  );
};
