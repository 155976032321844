import {
  Order,
  OrdersResponse,
  UnsubmittedOrder,
  UnsubmittedOrdersResponse,
} from "../types/Order";

export const EMPTY_ORDER: Order = {
  id: "",
  orderDetailsType: "",
  address: "",
  requestDate: "",
  jobType: "",
  requester: "",
  orderNumber: "",
  jobStatus: "",
  currentActivity: "",
  reason: "",
};

export const EMPTY_UNSUBMITTED_ORDER: UnsubmittedOrder = {
  orderId: "",
  createdDate: "",
  expiryDay: "",
  requester: "",
  orderType: "",
  // @ts-ignore
  liveningIcpOrder: null,
  // @ts-ignore
  newConnectionOrder: null,
  jobStatus: "",
};

export const EMPTY_UNSUBMITTED_ORDERS_RESPONSE: UnsubmittedOrdersResponse = {
  requestId: "",
  page: 1,
  totalRecords: 0,
  orderList: [],
};

export const EMPTY_ORDERS_RESPONSE: OrdersResponse = {
  orderList: [],
};

export const OPEN_ORDERS_TABLE_COLUMNS = [
  {
    id: "Site",
    Header: "Site",
  },
  {
    id: "Request date",
    Header: "Request date",
  },
  {
    id: "Job type",
    Header: "Job type",
  },
  {
    id: "Requested by",
    Header: "Requested by",
  },
  {
    id: "Order no.",
    Header: "Order no.",
  },
  {
    id: "Status",
    Header: "Status",
  },
  {
    id: "Current activity",
    Header: "Current activity",
  },
];

export const CLOSED_ORDERS_TABLE_COLUMNS = [
  {
    id: "Site",
    Header: "Site",
  },
  {
    id: "Request date",
    Header: "Request date",
  },
  {
    id: "Job type",
    Header: "Job type",
  },
  {
    id: "Requested by",
    Header: "Requested by",
  },
  {
    id: "Order no.",
    Header: "Order no.",
  },
  {
    id: "Status",
    Header: "Status",
  },
  {
    id: "Reason",
    Header: "Reason",
  },
];

export const UNSUBMITTED_ORDERS_TABLE_COLUMNS = [
  {
    id: "Site",
    Header: "Site",
  },
  {
    id: "Job type",
    Header: "Job type",
  },
  {
    id: "Created by",
    Header: "Created by",
  },
  {
    id: "Status",
    Header: "Status",
  },
  {
    id: "Creation date",
    Header: "Creation date",
  },
  {
    id: "Expires in",
    Header: "Expires in",
  },
  {
    id: "Delete",
    Header: " ",
  },
];
