import React from "react";
import { OrdersWithFilteredPagination } from "../OrdersWithFilteredPagination/OrdersWithFilteredPagination";
import { useGetRequesterList } from "../../../../hooks/useGetRequesterList/useGetRequesterList";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { Order } from "../../../../types/Order";

interface Props {
  type: OrdersFilterStatus;
  columns: { Header: string }[];
  apiError: boolean;
  orders: Order[];
}

export const OrdersContainer: React.FC<Props> = ({
  type,
  columns,
  apiError,
  orders,
}) => {
  const {
    authenticatedUserContext: { userBasicDetails },
  } = useUserAuthentication();
  const { firstName, lastName, userType } = userBasicDetails;

  const userFullName = firstName && lastName ? `${firstName} ${lastName}` : "";
  const requesterList = useGetRequesterList(orders);

  return (
    <>
      {orders && (
        <OrdersWithFilteredPagination
          orders={orders}
          userFullName={userFullName}
          requesterList={requesterList}
          type={type}
          userType={userType}
          columns={columns}
          error={apiError}
        />
      )}
    </>
  );
};
