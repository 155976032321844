import { theme } from "../../../../theme";

export const getStatusColor = (status: OrderProgressStatus): string => {
  if (!status) {
    return theme.colors.newEnergy;
  }

  switch (status.toLowerCase()) {
    case "in progress":
      return theme.colors.newEnergy;
    case "on hold":
      return theme.colors.orangeAlert;
    case "draft":
      return theme.colors.orangeAlert;
    case "cancelled":
      return theme.colors.neutrals.mn20;
    case "complete":
      return theme.colors.solar;
    default:
      return theme.colors.sunshine;
  }
};

export const isOrderCancelled = (orderStatus: OrderProgressStatus): boolean => {
  if (!orderStatus) {
    return false;
  }

  return orderStatus.toLowerCase() === "cancelled";
};

export const isOrderCancelledOrComplete = (
  orderStatus: OrderProgressStatus,
): boolean => {
  if (!orderStatus) {
    return false;
  }

  return (
    orderStatus.toLowerCase() === "cancelled" ||
    orderStatus.toLowerCase() === "completed"
  );
};

export const isOrderCurrentActivityReviewCancellation = (
  currentActivity: string,
): boolean => {
  if (!currentActivity) {
    return false;
  }

  return currentActivity.toLowerCase() === "reviewing cancellation request";
};
