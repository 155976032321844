import React, { AnchorHTMLAttributes } from "react";
import { Link } from "../Link";
import { IconTooltip } from "../IconTooltip";
import { SpaceProps } from "styled-system";
import styled from "styled-components";

interface Props {
  icon: string;
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
    border: none;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const IconTooltipLink: React.FC<
  Props & SpaceProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ icon, href, mr, children }) => {
  return (
    <StyledLink href={href}>
      <IconTooltip
        icon={icon}
        alignItems="center"
        color="neutrals.dn40"
        fontSize="26.67px"
        mr={mr}
      >
        {children}
      </IconTooltip>
    </StyledLink>
  );
};
