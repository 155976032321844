import {
  BorderProps,
  ColorProps,
  SpaceProps,
  TypographyProps,
  border,
  PositionProps,
  position,
} from "styled-system";
import React, { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

import { Icon } from "../Icon";
import { Label } from "../Label";

export interface CheckboxProps {
  value: boolean;
  icon?: string;
  label?: string;
  focusable?: boolean;
  error?: boolean;
}

const LabelWrapper = styled(Label)<
  { disabled?: boolean; checked?: boolean; error?: boolean } & BorderProps &
    TypographyProps
>`
  ${border}

  border: 2px solid;
  border-color: ${({ error, theme }) => error && theme.colors.danger};
  border-color: ${({ theme }) => theme.colors.neutrals.ln40};
  cursor: pointer;
  user-select: none;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.disabledColor};
      cursor: none;
      pointer-events: none;
      background-color: ${theme.colors.neutrals.ln20};
    `};

  ${({ checked, disabled, theme }) =>
    checked &&
    !disabled &&
    css`
      border-color: ${theme.colors.hydroDark};
      background-color: ${theme.colors.hydro10};
    `};

  ${({ checked, disabled, theme }) =>
    checked &&
    disabled &&
    css`
      border-color: ${theme.colors.neutrals.mn80};
      color: ${theme.colors.neutrals.mn80};
    `};

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydro};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
  }

  &:focus-visible {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.hydro10};
  }
`;

const InputWrapper = styled.input<PositionProps>`
  ${position}
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const IconWrapper = styled(Icon)<
  { disabled?: boolean } & ColorProps & SpaceProps
>`
  color: ${({ theme }) => theme.colors.hydroDark};
  color: ${({ disabled, theme }) => disabled && theme.colors.neutrals.mn80};
`;

export const Checkbox: React.FC<
  CheckboxProps &
    SpaceProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, "value">
> = ({
  id,
  label,
  name,
  checked,
  value,
  disabled,
  error,
  mr,
  mx,
  my,
  ml,
  focusable,
  tabIndex,
  onChange,
  onBlur,
}) => {
  const isChecked = checked || value;

  return (
    <LabelWrapper
      htmlFor={id}
      fontSize={2}
      lineHeight={2}
      pl={!isChecked ? 3 : "12px"}
      pr={3}
      py={2}
      mr={mr}
      my={my}
      mx={mx}
      ml={ml}
      borderRadius="checkboxWithBorder"
      checked={isChecked}
      disabled={disabled}
      error={error}
      textAlign="center"
      minWidth="125px"
    >
      {isChecked && <IconWrapper icon="check" disabled={disabled} pr={1} />}
      {label}

      <InputWrapper
        id={id}
        name={name}
        type="checkbox"
        position="absolute"
        disabled={disabled}
        checked={isChecked}
        onChange={onChange}
        onBlur={onBlur}
        tabIndex={focusable ? tabIndex : -1}
      />
    </LabelWrapper>
  );
};

Checkbox.defaultProps = {
  error: false,
  disabled: false,
  focusable: true,
};
