import React, { HTMLAttributes } from "react";
import { ColorProps, SpaceProps, TypographyProps } from "styled-system";
import { Box } from "../Box";
import { DataTest } from "../../types/DataTest";

interface Props {
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

export const Text: React.FC<
    Props &
    ColorProps &
    TypographyProps &
    SpaceProps &
    DataTest &
    HTMLAttributes<HTMLParagraphElement>
> = ({ dangerouslySetInnerHTML, children, dataTest, ...props }) => (
  <Box
    as="p"
    data-test={dataTest}
    {...props}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </Box>
);

Text.defaultProps = {
  fontSize: "body",
  lineHeight: "body"
};
