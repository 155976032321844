export const TOP_BAR_HEIGHT = -100;

export const getTopPositionByElementName = (elementName: string): number => {
  const elements = document.getElementsByName(elementName);
  if (!elements || !elements.length) {
    return -1;
  }

  const element = elements[0];

  const parent = element.parentElement;
  if (!parent) {
    return -1;
  }

  return parent.getBoundingClientRect().top;
};

export const handleScrollToId = (elementId: string) => {
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const element = document.getElementById(elementId)!;
    if (element) {
      const yAxisOffset =
        element.getBoundingClientRect().top +
        window.pageYOffset +
        TOP_BAR_HEIGHT;
      window.scrollTo({ top: yAxisOffset, behavior: "smooth" });
    }
  }, 1000);
};

export const handleScrollToName = (elementName: string) => {
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const boundingClientRectTop = getTopPositionByElementName(elementName);
    if (boundingClientRectTop === -1) {
      return;
    }

    const yAxisOffset =
      boundingClientRectTop + window.pageYOffset + TOP_BAR_HEIGHT;

    window.scrollTo({ top: yAxisOffset, behavior: "smooth" });
  }, 1000);
};

export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
