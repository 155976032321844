import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system";

const LineWrapper = styled.hr<
    BorderProps &
    LayoutProps &
    SpaceProps &
    ColorProps &
    PositionProps &
    HTMLAttributes<HTMLHRElement>
  >`
  ${border}
  ${layout}
  ${color}
  ${space}
  ${position}
`;

export const Line: React.FC<
  BorderProps &
  LayoutProps &
  SpaceProps &
  ColorProps &
  PositionProps &
  HTMLAttributes<HTMLHRElement>> = props => <LineWrapper {...props} />;

Line.defaultProps = {
  opacity: 0.2,
  height: 1,
  bg: "primary",
  border: "none",
};
