import React from "react";
import styled from "styled-components";
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import { mediaQueries } from "../../../../../../theme";
import { ManualUnitValues } from "../../../../../../types/ManualUnitValues";
import { Span } from "../../../../../Span";

interface Props {
  units: ManualUnitValues[];
}

const Table = styled.table<LayoutProps>`
  ${layout}
  display: none;

  ${mediaQueries.mediumDevice} {
    display: table;
    text-align: left;
  }
`;

const TableCell = styled.td<SpaceProps>`
  ${space}
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const TableHeadCell = styled.th<TypographyProps>`
  ${typography}
  color: ${({ theme }) => theme.colors.neutrals.mn80};
`;

export const ReviewFormDesktopMeteredUnits: React.FC<Props> = ({ units }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Unit/Lot number
          </TableHeadCell>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Supply type
          </TableHeadCell>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Phase
          </TableHeadCell>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Fuse (Amp)
          </TableHeadCell>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Supply
          </TableHeadCell>
        </tr>
      </thead>
      <tbody>
        {units.map((unit, index) => {
          return (
            <tr
              data-testid={`desktop-metered-unit-row-${index + 1}`}
              key={index}
            >
              <TableCell py={3}>
                <Span data-testid={`unit-${index + 1}-number`}>
                  {unit.unitNumber}
                </Span>
              </TableCell>
              <TableCell py={3}>
                <Span data-testid={`unit-${index + 1}-type`}>{unit.type}</Span>
              </TableCell>
              <TableCell py={3}>
                <Span data-testid={`unit-${index + 1}-phase`}>
                  {unit.phase}
                </Span>
              </TableCell>
              <TableCell py={3}>
                <Span data-testid={`unit-${index + 1}-fuse`}>
                  {unit.fuseAmp}
                </Span>
              </TableCell>
              <TableCell py={3}>
                <Span data-testid={`unit-${index + 1}-supply`}>
                  {unit.supply}
                </Span>
              </TableCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
