import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import {
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import { mediaQueries } from "../../theme";
import { Icon } from "../Icon";

interface Props {
  searchFilterChangeListener: (searchFilter: string) => void;
}

const SearchInputWrapper = styled(Flex)<ColorProps>`
  box-sizing: border-box;
  border-style: solid;
  height: 3rem;

  ${mediaQueries.smallDevice} {
    width: 100%;
  }
  ${mediaQueries.mediumDevice} {
    width: 16rem;
  }
`;

const HiddenInput = styled.input<SpaceProps & TypographyProps>`
  ${space}
  ${typography}
  font-family: inherit;
  font-weight: 500;
  flex-grow: 1;
  border: none;
  outline: none;
  background: none;
  color: ${({ theme }) => theme.colors.nightSky};

  :focus,
  :focus-visible,
  :focus-within {
    outline: none;
    border: none;
    box-shadow: none;
  }

  :enabled:focus {
    ::placeholder {
      color: ${({ theme }) => theme.colors.neutrals.mn40};
    }
  }

  ${mediaQueries.smallDevice} {
    width: 100%;
  }
  ${mediaQueries.mediumDevice} {
    width: 16rem;
  }
`;

const IconWrapper = styled.button<SpaceProps & TypographyProps>`
  ${space}
  ${typography}
  border: none;
  background: none;
  outline: none;
  padding: 0;
  flex-grow: 0;
  cursor: pointer;

  :active,
  :hover {
    background: none;
  }

  :active,
  :focus {
    border: none;
  }

  :focus,
  :focus-visible,
  :focus-within {
    box-shadow: none;
  }

  :disabled {
    color: ${({ theme }) => theme.colors.neutrals.dn40};
    cursor: default;
  }
`;

export const SearchInput: React.FC<Props> = ({
  searchFilterChangeListener,
}) => {
  const [searchFilter, setSearchFilter] = useState("");

  const handleSearchFilterChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSearchFilter(value);
    searchFilterChangeListener(value);
  };
  const handleSearchFilterClear = () => {
    setSearchFilter("");
    searchFilterChangeListener("");
  };

  return (
    <SearchInputWrapper
      bg="neutrals.ln0"
      borderWidth={2}
      borderRadius="input"
      borderColor={"neutrals.ln40"}
      p={2}
    >
      <HiddenInput
        type={"text"}
        value={searchFilter}
        onChange={handleSearchFilterChanged}
        placeholder={"Search address / order no."}
        fontSize={1}
        lineHeight={2}
        data-testid={"search-input"}
      />
      <IconWrapper
        disabled={!searchFilter}
        type={"button"}
        onClick={handleSearchFilterClear}
        data-testid={"clear-search-input"}
      >
        <Icon
          icon={searchFilter ? "close" : "search"}
          color={"neutrals.dn40"}
        />
      </IconWrapper>
    </SearchInputWrapper>
  );
};
