import { UnsubmittedOrder } from "../../../../types/Order";
import {
  getDraftAddressInformation,
  getDraftSiteLotInformation,
} from "../UnsubmittedOrderAddressUtil/UnsubmittedOrderAddressUtil";
import React from "react";

export const getExpiryDaysStringUtil = (expiryDays: string): string => {
  if (expiryDays === "1") {
    return expiryDays + " day";
  }

  if (expiryDays === "0") {
    return "today";
  }

  return expiryDays + " days";
};

export const getUnsubmittedAddressUtil = (order: UnsubmittedOrder): string => {
  if (order.liveningIcpOrder !== null) {
    //icp
    if (order.liveningIcpOrder.icpType === "unmetered ICP") {
      return getDraftAddressInformation(
        order.liveningIcpOrder.unmetered.addressInformation,
      );
    } else if (
      order.liveningIcpOrder.metered.sites[0].siteType === "lotAndDpNumber"
    ) {
      return getDraftSiteLotInformation(
        order.liveningIcpOrder.metered.sites[0].lotInformation,
      );
    } else {
      return getDraftAddressInformation(
        order.liveningIcpOrder.metered.sites[0].addressInformation,
      );
    }
  } else {
    //new connection
    if (order.newConnectionOrder.site.siteType === "lotAndDpNumber") {
      return getDraftSiteLotInformation(
        order.newConnectionOrder.site.lotInformation,
      );
    } else {
      return getDraftAddressInformation(
        order.newConnectionOrder.site.addressInformation,
      );
    }
  }
};

export const getOrderTypeUtil = (orderType: string): string => {
  if (orderType === "LiveningIcpOrder") {
    return "Livening (ICP number)";
  }
  return "New connection";
};

export const isDeleteOrderButton = (
  event: React.MouseEvent<HTMLTableRowElement>,
): boolean => {
  const htmlElement = event.target as HTMLElement;

  return htmlElement.innerText === "Delete";
};
