import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EMPTY_ORDER_DETAILS } from "../../constants/OrderDetails";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { getOrderDetails } from "../../services/OrderService";
import { OrderDetailsType } from "../../types/OrderDetails";
import { Flex } from "../Flex";
import { Loading } from "../Loading";
import { OrderDetails } from "./OrderDetails";
import { useQueryParams } from "../../hooks/useQueryParams/useQueryParams";

interface OrderDetailsContainerProps {
  handleOnCancelOrderNotification: (cancelResponse: boolean) => void;
}

type OrderDetailsParams = { orderId: string };
type OrderDetailsQueryParams = { orderDetailsType: string };

export const OrderDetailsContainer: React.FC<OrderDetailsContainerProps> = ({
  handleOnCancelOrderNotification,
}) => {
  const [orderDetails, setOrderDetails] =
    useState<OrderDetailsType>(EMPTY_ORDER_DETAILS);

  const [apiError, setApiError] = useState(false);

  const [showLoading, setShowLoading] = useState(true);

  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;

  const { orderId } = useParams<OrderDetailsParams>();
  const orderDetailsType =
    useQueryParams<OrderDetailsQueryParams>("orderDetailsType");

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const details = await getOrderDetails(
          orderId,
          orderDetailsType,
          accessToken,
        );
        setOrderDetails(details);
        setApiError(false);
      } catch (error) {
        setApiError(true);
      }

      setShowLoading(false);
    };

    fetchOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <>
      {showLoading && (
        <Flex
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
          align-content="center"
          height="100vh"
        >
          <Loading text="Retrieving details" />
        </Flex>
      )}

      {!showLoading && (
        <OrderDetails
          orderDetails={orderDetails}
          error={apiError}
          orderId={orderId}
          handleOnCancelOrderNotification={handleOnCancelOrderNotification}
        />
      )}
    </>
  );
};
