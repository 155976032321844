import React from "react";
import { Page } from "../../components/Page";
import { PayNow } from "../../components/PayNow";
import { PayNowOrderPayment } from "../../types/PayNowOrderPayment";

export interface PayNowProps {
  tabTitle: string;
  paymentId: string;
  payNowOrderPayment: PayNowOrderPayment;
  onSubmit: (value: PayNowOrderPayment) => void;
}

export const PayNowPage: React.FC<PayNowProps> = ({
  paymentId,
  tabTitle,
  payNowOrderPayment,
  onSubmit,
}) => {

  return (
    <Page tabTitle={tabTitle}>
      <PayNow
        paymentId={paymentId}
        initialValues={payNowOrderPayment}
        onSubmit={onSubmit}
      />
    </Page>
  );
};
