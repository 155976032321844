import React, { useEffect, useState } from "react";
import { PayNowPage } from "./PayNowPage";
import { useHistory, useParams } from "react-router-dom";
import {
  getOrderPayment,
  updateBillingDetails,
} from "../../services/OrderPaymentService";
import { EMPTY_PAY_NOW_ORDER_PAYMENT } from "../../constants/PayNowOrderPaymentValues";
import { CenteredLoading } from "../../components/CenteredLoading";
import { PayNowOrderPayment } from "../../types/PayNowOrderPayment";
import { PaymentErrorPageContainer } from "../PaymentErrorPage";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { SomethingWentWrong } from "../../components/SomethingWentWrong/SomethingWentWrong";
import { EMPTY_MANUAL_BILLING_ADDRESS } from "../../constants/ManualBillingAddress";
import lodash from "lodash";

type PayNowParams = { paymentId: string };

export const PayNowPageContainer: React.FC = () => {
  const {
    authenticatedUserContext: { isLoggedIn, userAuth0Details },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;

  const history = useHistory();

  const { paymentId } = useParams<PayNowParams>();
  const [payNowOrderPayment, setPayNowOrderPayment] = useState(
    EMPTY_PAY_NOW_ORDER_PAYMENT,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [billingDetailsUpdateFailed, setBillingDetailsUpdateFailed] =
    useState(false);
  const [updatingBillingDetails, setUpdatingBillingDetails] = useState(false);

  useEffect(() => {
    const getOrderPaymentAsync = async () => {
      try {
        const paymentDetails = await getOrderPayment(paymentId, accessToken);
        paymentDetails.billingDetails.manualBillingAddress = lodash.clone(
          EMPTY_MANUAL_BILLING_ADDRESS,
        );
        paymentDetails.billingDetails.manuallyEnteredBillingAddress = false;
        setPayNowOrderPayment(paymentDetails);
        setIsLoading(false);
      } catch (error) {
        payNowOrderPayment.status = "Error";
        setIsLoading(false);
      }
    };

    getOrderPaymentAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, accessToken]);

  const handleSubmit = (values: PayNowOrderPayment) => {
    setBillingDetailsUpdateFailed(false);
    setUpdatingBillingDetails(true);

    updateBillingDetails(values, accessToken).then((updated) => {
      if (!updated) {
        setUpdatingBillingDetails(false);
        setBillingDetailsUpdateFailed(true);
      } else {
        setPayNowOrderPayment(values);
      }
    });
  };

  const handleOnBackClick = () => {
    history.goBack();
  };

  if (isLoading) {
    return <CenteredLoading text="Loading" />;
  }

  if (billingDetailsUpdateFailed) {
    return (
      <SomethingWentWrong
        subHeading="It looks like there was an error updating your billing details."
        message="Please try again in a few minutes"
        onBackClick={handleOnBackClick}
        onTryAgainClick={() => handleSubmit(payNowOrderPayment)}
        tryAgainLoading={updatingBillingDetails}
        showTryAgainButton
        showBackButton
      />
    );
  }

  return (
    <>
      {payNowOrderPayment.status === "Ready To Pay" && (
        <PayNowPage
          paymentId={paymentId}
          tabTitle=""
          payNowOrderPayment={payNowOrderPayment}
          onSubmit={handleSubmit}
        />
      )}

      {payNowOrderPayment.status !== "Ready To Pay" && (
        <PaymentErrorPageContainer />
      )}
    </>
  );
};
