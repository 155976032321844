import ComboBoxStateChangeType from "../../../types/ComboBoxStateChangeType";

// Refer to UseComboboxStateChangeTypes for enum indexes
const INPUT_CHANGE_STATE_CHANGE_TYPE_INDEX = 6;
const ITEM_CLICK_STATE_CHANGE_TYPE_INDEX = 10;
const TOGGLE_BUTTON_CLICK_STATE_CHANGE_TYPE_INDEX = 11;

// Refer to UseComboboxStateChangeTypes for enum values
const INPUT_CHANGE_STATE_CHANGE_TYPE_VALUE = "__input_change__";
const ITEM_CLICK_STATE_CHANGE_TYPE_VALUE = "__item_click__";
const TOGGLE_BUTTON_CLICK_STATE_CHANGE_TYPE_VALUE = "__togglebutton_click__";

const mapComboBoxStateChangeType = (type: any) => {
  if (
    type === INPUT_CHANGE_STATE_CHANGE_TYPE_VALUE ||
    type === INPUT_CHANGE_STATE_CHANGE_TYPE_INDEX
  ) {
    return ComboBoxStateChangeType.InputChange;
  }

  if (
    type === ITEM_CLICK_STATE_CHANGE_TYPE_VALUE ||
    type === ITEM_CLICK_STATE_CHANGE_TYPE_INDEX
  ) {
    return ComboBoxStateChangeType.ItemClick;
  }

  if (
    type === TOGGLE_BUTTON_CLICK_STATE_CHANGE_TYPE_VALUE ||
    type === TOGGLE_BUTTON_CLICK_STATE_CHANGE_TYPE_INDEX
  ) {
    return ComboBoxStateChangeType.ToggleButtonClick;
  }

  return ComboBoxStateChangeType.InputChange;
};

export default mapComboBoxStateChangeType;
