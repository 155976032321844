import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  FlexboxProps,
  layout,
  LayoutProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system";
import { Icon } from "../Icon";
import { Spinner } from "../Spinner";

export interface ButtonProps {
  variant?: "primary" | "secondary" | "lowAffordance" | "ghost";
  magnitude?: "large" | "medium" | "small";
  iconLeft?: string;
  iconRight?: string;
  iconSize?: number;
  focusable?: boolean;
  loading?: boolean;
}

const Wrapper = styled.button`
  ${border}
  ${color}
  ${layout}
  ${space}
  ${size}
  ${typography}
  cursor: pointer;
  text-decoration: none;
  border: none;
  appearance: none;
  outline: none;
  font-family: inherit;

  &:disabled {
    pointer-events: none;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydroDark};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
  }
`;

const ButtonStyled = styled(Wrapper)<
  ButtonProps &
    SpaceProps &
    SizeProps &
    BorderProps &
    LayoutProps &
    TypographyProps &
    ButtonHTMLAttributes<HTMLButtonElement>
>(
  variant({
    scale: "button",
    variants: {
      primary: {
        bg: "primary",
        color: "white",
        ">:only-child": {
          color: "white",
        },
        "&:hover": {
          bg: "hover",
          ">span": {
            bg: "hover",
          },
        },
        "&:active": {
          bg: "active",
          ">:only-child": {
            bg: "active",
          },
        },
        "&:disabled": {
          bg: "disabledBackground",
          color: "disabledColor",
          ">:only-child": {
            color: "disabledColor",
          },
        },
      },
      secondary: {
        bg: "hydro10",
        color: "primary",
        ">:only-child": {
          color: "primary",
        },
        "&:hover": {
          bg: "hydro25",
          color: "hydro",
          ">:only-child": {
            color: "hydro",
          },
        },
        "&:active": {
          bg: "hydro10",
          color: "hydroLight",
          ">:only-child": {
            color: "hydroLight",
          },
        },
        "&:disabled": {
          bg: "neutrals.ln20",
          color: "disabledColor",
          ">:only-child": {
            color: "disabledColor",
          },
        },
      },
      lowAffordance: {
        bg: "neutrals.ln20",
        color: "neutrals.dn40",
        ">:only-child": {
          color: "neutrals.dn40",
        },
        "&:hover": {
          bg: "neutrals.ln30",
          color: "neutrals.dn40",
          ">:only-child": {
            color: "neutrals.dn40",
          },
        },
        "&:active": {
          bg: "neutrals.ln30",
          color: "neutrals.dn40",
          ">:only-child": {
            color: "neutrals.dn40",
          },
        },
        "&:disabled": {
          bg: "neutrals.ln30",
          color: "neutrals.mn20",
          ">:only-child": {
            color: "neutrals.mn20",
          },
        },
      },
      ghost: {
        bg: "transparent",
        color: "primary",
        ">:only-child": {
          color: "primary",
        },
        "&:hover": {
          bg: "neutrals.ln20",
        },
        "&:active": {
          bg: "neutrals.ln30",
        },
        "&:disabled": {
          bg: "transparent",
          color: "disabledColor",
          ">:only-child": {
            color: "disabledColor",
          },
        },
      },
    },
  }),
  variant({
    prop: "magnitude",
    scale: "buttonMagnitude",
    variants: {
      large: {
        height: 13,
      },
      medium: {
        height: 11,
      },
      small: {
        height: 9,
      },
    },
  }),
);

export const Button: React.FC<
  ButtonProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    SizeProps &
    TypographyProps &
    BorderProps &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children,
  iconLeft,
  iconRight,
  tabIndex,
  focusable,
  loading,
  iconSize,
  ...props
}) => {
  return (
    <ButtonStyled
      {...props}
      borderRadius="button"
      verticalAlign="middle"
      tabIndex={focusable ? tabIndex : -1}
    >
      {iconLeft && <Icon icon={iconLeft} mr={2} fontSize={iconSize} />}
      {children}
      {iconRight && <Icon icon={iconRight} ml={2} fontSize={iconSize} />}
      {loading && <Spinner color="color" ml={2} />}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  type: "button",
  variant: "primary",
  magnitude: "large",
  fontSize: "body",
  disabled: false,
  px: 5,
  focusable: true,
  loading: false,
  textAlign: "center",
  fontWeight: "bold",
};
