import { apiClient, apiClientConfig } from "../client/ApiClient";
import {
  OrdersResponse,
  UnsubmittedOrder,
  UnsubmittedOrdersResponse,
} from "../types/Order";
import { OrderDetailsType } from "../types/OrderDetails";
import { getOrderDetailsPath } from "../utils/apiPathsProvider/apiPathsProvider";
import { includeAccessToken } from "../client/AxiosConfigObjectUtil";
import { OrderDetailsCancelFormValues } from "../types/OrderDetailsCancelFormValues";

export const getOrderList = async (
  auth0AccessToken?: string,
): Promise<OrdersResponse> => {
  const result = await apiClient.get<OrdersResponse>("orders", {
    headers: includeAccessToken(apiClientConfig, auth0AccessToken),
  });

  return result.data;
};

export const getOrderDetails = async (
  id: string,
  orderDetailsType: string,
  auth0AccessToken?: string,
): Promise<OrderDetailsType> =>
  await apiClient
    .get<OrderDetailsType>(getOrderDetailsPath(id, orderDetailsType), {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => response.data);

export const getUnSubmittedOrderList = async (
  recordsPerPage?: number,
  page?: number,
  sortOrder?: string,
  auth0AccessToken?: string,
): Promise<UnsubmittedOrdersResponse> => {
  return await apiClient
    .get<UnsubmittedOrdersResponse>("orders/unsubmitted", {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
      params: {
        recordsPerPage,
        page,
        sortOrder,
      },
    })
    .then((response) => response.data);
};

export const putCancelOrder = async (
  values: OrderDetailsCancelFormValues,
  auth0AccessToken?: string,
): Promise<boolean> => {
  try {
    await apiClient.put("orders/cancel", values, {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const putUnSubmittedOrder = async (
  values: UnsubmittedOrder,
  auth0AccessToken?: string,
): Promise<UnsubmittedOrder> => {
  const response = await apiClient.put("orders/unsubmitted", values, {
    headers: includeAccessToken(apiClientConfig, auth0AccessToken),
  });
  return response.data;
};

export const deleteUnSubmittedOrder = async (
  orderId: string,
  auth0AccessToken?: string,
) => {
  if (orderId) {
    try {
      await apiClient.delete("orders/unsubmitted", {
        headers: includeAccessToken(apiClientConfig, auth0AccessToken),
        params: { orderId },
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  return false;
};
