import React from "react";
import { usePagination, useTable } from "react-table";
import env from "../../../../environment";
import { Order } from "../../../../types/Order";
import { Pagination } from "../../../Pagination";
import { Orders } from "../Orders/Orders";

interface Props {
  orders: Order[];
  ordersCount: number;
  type: OrdersFilterStatus;
  columns: { Header: string }[];
  error: boolean;
  onRetry?: () => void;
  requesterFilter?: string;
  searchFilter?: string;
}

const RECORDS_PER_PAGE = env.orderRecordsPerPage;

export const OrdersWithPagination: React.FC<Props> = ({
  orders,
  ordersCount,
  type,
  columns,
  error,
  onRetry,
  requesterFilter,
  searchFilter,
}) => {
  const {
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
    gotoPage,
  } = useTable(
    {
      columns: columns,
      data: orders,
      initialState: {
        pageSize: RECORDS_PER_PAGE,
        pageIndex: 0,
      },
    },
    usePagination,
  );

  const pagedOrders = page.map((row) => row.original);

  return (
    <>
      <Orders
        orders={pagedOrders}
        ordersCount={ordersCount}
        type={type}
        columns={columns}
        error={error}
        onRetry={onRetry}
        searchFilter={searchFilter}
      />

      {pageOptions.length > 1 && (
        <Pagination
          totalPages={pageOptions.length}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          goToPage={gotoPage}
          requesterFilter={requesterFilter}
          searchFilter={searchFilter}
        />
      )}
    </>
  );
};
