import React from "react";
import styled from "styled-components";
import { OrderDetailsNewConnectionSiteDetails } from "../../../../types/OrderDetailsSiteDetails";
import { Flex } from "../../../Flex";
import { LabelValuePair } from "../../../LabelValuePair";
import { ReviewFormDesktopLots } from "../../../NewConnectionForm/_/NewConnectionReviewForm/_/ReviewFormDesktopLots";
import { ReviewFormMobileLots } from "../../../NewConnectionForm/_/NewConnectionReviewForm/_/ReviewFormMobileLots";
import { Span } from "../../../Span";
import { getNewConnectionSubHeading } from "../../helpers/OrderDetailsHelpers";
import { NewConnectionTypes } from "../../../../types/OrderJobTypes";
import lodash from "lodash";
import { OrderDocument } from "../../../../types/OrderDocument";
import { OrderDocuments } from "../OrderDocument";
import { Line } from "../../../Line";

interface Props {
  address: string;
  newConnectionType: NewConnectionTypes;
  newConnectionSiteDetails: OrderDetailsNewConnectionSiteDetails;
  documents: OrderDocument[];
}

const LotsWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const OrderNewConnectionSiteDetails: React.FC<Props> = ({
  address,
  newConnectionType,
  newConnectionSiteDetails,
  documents,
}) => {
  const { lotCount, lots } = newConnectionSiteDetails;
  const subHeading = getNewConnectionSubHeading(newConnectionType);

  const renderLots = () => {
    if (lots.length) {
      const transformedLots = lots.map((lot) => ({
        ...lot,
        type: lodash.capitalize(lot.type),
      }));

      return (
        <>
          <ReviewFormDesktopLots lots={transformedLots} />
          <ReviewFormMobileLots lots={transformedLots} />
        </>
      );
    }
  };

  return (
    <Flex flexDirection="column">
      <Span
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mb={5}
        alignSelf="flex-start"
      >
        Site details
      </Span>
      <LabelValuePair label="Job type" value={subHeading} id="job-type" />
      <LabelValuePair label="Address" value={address} id="address" />
      <LabelValuePair
        label="Number of lots"
        value={lotCount}
        id="number-of-lots"
      />

      <LotsWrapper
        flexDirection="column"
        borderRadius="radio"
        padding={[3, 3, 3, 4]}
        mt={2}
      >
        {renderLots()}
      </LotsWrapper>

      <Line
        width="100%"
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
        my={7}
      />

      <OrderDocuments documents={documents} />
    </Flex>
  );
};
