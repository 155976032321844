import React from "react";
import { OrderLiveningIcpSiteDetails } from "../OrderLiveningIcpSiteDetails";
import { OrderNewConnectionSiteDetails } from "../OrderNewConnectionSiteDetails";
import { Flex } from "../../../Flex";
import { OrderDetailsSiteDetails } from "../../../../types/OrderDetailsSiteDetails";
import { NewConnectionTypes } from "../../../../types/OrderJobTypes";
import { OrderDocument } from "../../../../types/OrderDocument";

interface Props {
  siteDetails: OrderDetailsSiteDetails;
  documents: OrderDocument[];
}

export const OrderSiteDetails: React.FC<Props> = ({
  siteDetails,
  documents,
}) => {
  const { jobType, address, typeDetailed } = siteDetails;

  const renderSiteDetails = () => {
    if (jobType === "livening icp") {
      const { liveningIcpSiteDetails } = siteDetails;
      return (
        <OrderLiveningIcpSiteDetails
          address={address}
          liveningIcpSiteDetails={liveningIcpSiteDetails}
          documents={documents}
        />
      );
    }

    const { newConnectionSiteDetails } = siteDetails;
    return (
      <OrderNewConnectionSiteDetails
        address={address}
        newConnectionType={typeDetailed as NewConnectionTypes}
        newConnectionSiteDetails={newConnectionSiteDetails}
        documents={documents}
      />
    );
  };

  return <Flex flexDirection="column">{renderSiteDetails()}</Flex>;
};
