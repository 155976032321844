import React from "react";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { NewConnectionFormValues } from "../../types/NewConnectionFormValues";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { IconPopupTip } from "../IconPopupTip";
import { Line } from "../Line";
import { Link } from "../Link";
import { getFormattedAddress } from "../LiveningIcpForm/_/LiveningIcpReviewForm/_/FormattedAddressUtil/FormattedAddressUtil";
import { isComplexConnection } from "../NewConnectionComplexUtil/NewConnectionComplexUtil";
import { NewConnectionProgressStepper } from "../NewConnectionProgressStepper";
import { Page } from "../Page";
import { QualtricsPlaceHolder } from "../QualtricsPlaceHolder";
import { Span } from "../Span";
import { Title } from "../Title";
import { NewConnectionThankYouOrder } from "./_/NewConnectionThankYouOrder";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";

interface Props {
  values: NewConnectionFormValues;
}

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

const StyledOrderService = styled(Flex)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const NewConnectionThankYou: React.FC<Props> = ({ values }) => {
  const history = useHistory();

  const { site } = values;
  const isComplex = isComplexConnection(values.site);

  const handleOrderServiceClick = () => {
    history.push("/service-type");
  };

  return (
    <Page>
      <NewConnectionProgressStepper currentStep={4} />

      <GridWrapper mx={[2, 2, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          <Title as="h1" lineHeight={6} fontWeight="500">
            Thank you for your order
          </Title>

          <p>
            Please note that separate orders have been created for each
            site/location and you will receive a confirmation email for each
            order. <br /> <br /> Based on the information provided, we have
            assigned a type of service per site/location. <br />
            <br /> Once we have assessed your order, your type of service might
            change but we will update you if this is the case.
          </p>

          <Flex justifyContent="space-between" alignItems="center" my={4}>
            <Title as="h2" my={0} lineHeight={7} fontWeight="500">
              Your order(s)
            </Title>

            <Flex alignItems="center">
              {isComplex && (
                <IconPopupTip
                  label="Type of service"
                  icon="help"
                  alignItems="flex-start"
                >
                  A complex connection supply point is where our network
                  connects to your private service line. This type of connection
                  requires a customer works agreement and if applicable,
                  easements. These jobs generally have a longer timeframe as
                  they are more complex.
                </IconPopupTip>
              )}

              {!isComplex && (
                <IconPopupTip
                  label="Type of service"
                  icon="help"
                  alignItems="flex-start"
                >
                  A simple connection supply point is where our network connects
                  to your private service line. This type of connection only
                  requires a quote, but not an easement, and can usually be
                  completed in approximately 6-8 weeks.
                </IconPopupTip>
              )}
            </Flex>
          </Flex>

          <NewConnectionThankYouOrder
            jobCategory={`${
              isComplexConnection(values.site)
                ? "Complex connection"
                : "Simple connection"
            }`}
            siteAddress={getFormattedAddress(site)}
          />

          <Span my={4}>
            If you have any questions, please contact us on{" "}
            <Link href="tel:0508 832 867">0508 832 867</Link>.
          </Span>

          <StyledOrderService
            px={4}
            py={3}
            borderRadius="infoSection"
            flexDirection="column"
          >
            <Span fontSize={4} lineHeight={4} mb={3}>
              Do you want to order another service?
            </Span>

            <Span>
              You can keep ordering other services through the button below.
            </Span>

            <Flex marginRight="auto">
              <Button
                iconLeft="add"
                variant="primary"
                magnitude="medium"
                mt={3}
                data-testid="order-service-button"
                onClick={handleOrderServiceClick}
              >
                New Order
              </Button>
            </Flex>
          </StyledOrderService>

          {isComplex && (
            <BasicNotificationBanner
              message={
                "Unfortunately, we don’t support online tracking functionality for Complex connection." +
                " We will continue to update your order details via email or SMS."
              }
              variant="warning"
              icon="warning"
              mb={6}
              mt={4}
            />
          )}

          <Line
            width="100%"
            my={5}
            zIndex={0}
            border="1px solid"
            borderColor="neutrals.ln30"
            bg="neutrals.ln30"
            opacity="1"
            height={0}
          />

          <Flex flexDirection="column" pb={6}>
            <Title as="h2" my={3}>
              Feedback
            </Title>
            <QualtricsPlaceHolder />
          </Flex>
        </FlexWrapper>
      </GridWrapper>
    </Page>
  );
};

NewConnectionThankYou.defaultProps = {};
