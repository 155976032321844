import React from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { ErrorMessage, Field, FieldArray, FieldArrayRenderProps } from "formik";
import { Span } from "../Span";
import { ManualUnitValues } from "../../types/ManualUnitValues";
import { mediaQueries } from "../../theme";
import { ActionButton } from "../ActionButton";
import { FormikDropdown } from "../FormikDropdown";
import { FormikInputText } from "../FormikInputText";
import { UnitCopy } from "../UnitCopy";
import { ActionLink } from "../ActionLink";
import { EMPTY_MANUAL_UNIT_VALUES } from "../../constants/ManualUnitValues";
import { Line } from "../Line";
import { IconPopupTip } from "../IconPopupTip";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { Box } from "../Box";

interface Props {
  siteIndex: number;
  units: ManualUnitValues[];
}

const TableHeaders = styled(Flex)`
  display: none;

  ${mediaQueries.largeDevice} {
    display: flex;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  }
`;

const TableHead = styled(Flex)`
  min-width: 0;
  flex-basis: 0;
  color: ${({ theme }) => theme.colors.neutrals.mn80};
  align-self: end;
`;

const PHASE_POPUPTIP =
  "3 phase power can cope with the electricity demands of larger and more powerful appliances. " +
  "Therefore, it is more commonly used in commercial and industrial settings. " +
  "Most homes and apartments don’t need 3 phase power as all their appliances and power needs will run well on single phase supply. " +
  "But if you’re renovating, building new or adding high-energy appliances, " +
  "talk to your electrician to get advice on whether you need 3 phase power.";

const FUSE_POPUPTIP =
  "Different fuse sizes are used depending on the electricity supply. " +
  "Talk to your electrician to confirm the fuse size you need to power your home.";

export const SiteUnitsManual: React.FC<Props> = ({ units, siteIndex }) => {
  const handleUnitClearClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    arrayHelpers: FieldArrayRenderProps,
    siteUnitIndex: number,
  ) => {
    arrayHelpers.remove(siteUnitIndex);
  };

  const handleAddUnitClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    arrayHelpers.push(EMPTY_MANUAL_UNIT_VALUES);
  };

  return (
    <Flex flexDirection="column">
      <TableHeaders pb={2} mb={4}>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.1} mr={2}>
          Unit/Lot number
        </TableHead>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.25} mr={2}>
          Type
        </TableHead>
        <TableHead
          fontWeight="medium"
          fontSize={2}
          flexGrow={0.15}
          mr={2}
          display={"block"}
        >
          <Span>Phase</Span>
          <IconPopupTip icon="help" ml={2}>
            {PHASE_POPUPTIP}
          </IconPopupTip>
        </TableHead>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.15} mr={2}>
          <Span width="min-content">Fuse (Amp)</Span>
          <IconPopupTip icon="help" ml={2} mt={10}>
            {FUSE_POPUPTIP}
          </IconPopupTip>
        </TableHead>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.25} mr={2}>
          Supply
        </TableHead>
        <TableHead flexGrow={0.1}>
          {/* Empty by design for controls */}
        </TableHead>
      </TableHeaders>

      <BasicNotificationBanner
        message={
          "If you don't need to enter any unit/lot number, please enter N/A."
        }
        variant="information"
        icon="info"
        mb={3}
      />

      <FieldArray
        name={`metered.sites[${siteIndex}].manualUnits`}
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {units.map((unit, index) => (
              <Flex
                key={`metered.sites[${siteIndex}].manualUnits[${index}]`}
                flexDirection={["column", "column", "column", "row", "row"]}
                my={[4, 4, 4, 2, 2]}
              >
                <Flex
                  flexDirection="column"
                  flexGrow={0.1}
                  minWidth={["100%", "100%", "100%", 0, 0]}
                  flexBasis={0}
                  mr={[0, 0, 0, 2, 2]}
                  mb={[3, 3, 3, 0, 0]}
                >
                  <Field
                    id={`metered.sites[${siteIndex}].manualUnits[${index}].unitNumber`}
                    name={`metered.sites[${siteIndex}].manualUnits[${index}].unitNumber`}
                    label="Unit/Lot number"
                    displayLabel={[
                      "inline-block",
                      "inline-block",
                      "inline-block",
                      "none",
                      "none",
                    ]}
                    flexGrow={1}
                    placeholder="e.g. 1A"
                    component={FormikInputText}
                  />
                  <Box width="max-content" mt={[0, 0, 2]}>
                    <ErrorMessage
                      name={`metered.sites[${siteIndex}].manualUnits[${index}].unitNumber`}
                      className="field-error"
                      component={FormInputErrorMessage}
                    />
                  </Box>
                </Flex>

                <Field
                  id={`metered.sites[${siteIndex}].manualUnits[${index}].type`}
                  name={`metered.sites[${siteIndex}].manualUnits[${index}].type`}
                  label="Type"
                  displayLabel={[
                    "inline-block",
                    "inline-block",
                    "inline-block",
                    "none",
                    "none",
                  ]}
                  items={["Residential", "Commercial"]}
                  visibleItems={2}
                  placeholder=""
                  mr={[0, 0, 0, 2, 2]}
                  mb={[3, 3, 3, 0, 0]}
                  minWidth={["100%", "100%", "100%", 0, 0]}
                  flexGrow={0.25}
                  flexBasis={0}
                  component={FormikDropdown}
                />

                <Field
                  id={`metered.sites[${siteIndex}].manualUnits[${index}].phase`}
                  name={`metered.sites[${siteIndex}].manualUnits[${index}].phase`}
                  label="Phase"
                  displayLabel={[
                    "inline-block",
                    "inline-block",
                    "inline-block",
                    "none",
                    "none",
                  ]}
                  items={["1", "2", "3"]}
                  visibleItems={3}
                  placeholder=""
                  tooltip={PHASE_POPUPTIP}
                  displayTooltip={["flex", "flex", "flex", "none", "none"]}
                  mr={[0, 0, 0, 2, 2]}
                  mb={[3, 3, 3, 0, 0]}
                  minWidth={["100%", "100%", "100%", 0, 0]}
                  flexGrow={0.15}
                  flexBasis={0}
                  component={FormikDropdown}
                />

                <Field
                  id={`metered.sites[${siteIndex}].manualUnits[${index}].fuseAmp`}
                  name={`metered.sites[${siteIndex}].manualUnits[${index}].fuseAmp`}
                  label="Fuse (Amp)"
                  displayLabel={[
                    "inline-block",
                    "inline-block",
                    "inline-block",
                    "none",
                    "none",
                  ]}
                  tooltip={FUSE_POPUPTIP}
                  displayTooltip={["flex", "flex", "flex", "none", "none"]}
                  items={[
                    "30",
                    "32",
                    "60",
                    "63",
                    "80",
                    "100",
                    "150",
                    "160",
                    "200",
                    "250",
                    "300",
                    "350",
                    "400",
                    "430",
                    "450",
                    "500",
                    "600",
                    "630",
                    "650",
                    "700",
                    "750",
                    "800",
                    "1000",
                    "1200",
                    "1500",
                    "1600",
                    "3000",
                  ]}
                  visibleItems={6}
                  placeholder=""
                  mr={[0, 0, 0, 2, 2]}
                  mb={[3, 3, 3, 0, 0]}
                  minWidth={["100%", "100%", "100%", 0, 0]}
                  flexGrow={0.15}
                  flexBasis={0}
                  component={FormikDropdown}
                />

                <Field
                  id={`metered.sites[${siteIndex}].manualUnits[${index}].supply`}
                  name={`metered.sites[${siteIndex}].manualUnits[${index}].supply`}
                  label="Supply type"
                  displayLabel={[
                    "inline-block",
                    "inline-block",
                    "inline-block",
                    "none",
                    "none",
                  ]}
                  items={["Builders Temporary Supply", "Permanent"]}
                  visibleItems={2}
                  placeholder=""
                  mr={[0, 0, 0, 2, 2]}
                  mb={[3, 3, 3, 0, 0]}
                  minWidth={["100%", "100%", "100%", 0, 0]}
                  flexGrow={0.25}
                  flexBasis={0}
                  component={FormikDropdown}
                />

                <Flex
                  justifyContent="end"
                  alignItems="center"
                  ml={[0, 0, 0, 1, 1]}
                  mb={[3, 3, 3, 0, 0]}
                  minWidth={["100%", "100%", "100%", 0, 0]}
                  flexGrow={0.1}
                  flexBasis={0}
                >
                  <UnitCopy
                    units={units}
                    index={index}
                    arrayHelpers={arrayHelpers}
                  />

                  <ActionButton
                    data-testid="remove-unit-button"
                    icon="clear"
                    disabled={units.length === 1}
                    onClick={(e) =>
                      handleUnitClearClick(e, arrayHelpers, index)
                    }
                    ml={[4, 4, 4, 2, 2]}
                  />
                </Flex>
              </Flex>
            ))}

            <Span
              data-testid="icp-units-entered"
              color="neutrals.mn80"
              fontWeight="medium"
              fontSize={2}
              mt={4}
            >
              {`ICP units entered: ${units.length}`}
            </Span>

            <Line
              width="100%"
              border="1px solid"
              borderColor="neutrals.ln30"
              bg="neutrals.ln30"
              opacity="1"
              height={0}
              mt={5}
              mb={4}
            />

            <ActionLink
              iconLeft="add_circle"
              type="button"
              alignSelf="start"
              mt={1}
              onClick={(e) => handleAddUnitClick(e, arrayHelpers)}
            >
              Add unit
            </ActionLink>
          </>
        )}
      />
    </Flex>
  );
};
