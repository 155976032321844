import React from "react";
import { SomethingWentWrong } from "../SomethingWentWrong/SomethingWentWrong";
import { Flex } from "../Flex";
import { LogoutButton } from "../LogoutButton";

export const AccountVerificationError: React.FC = () => {
  return (
    <Flex flexDirection="column">
      <SomethingWentWrong
        subHeading="It looks like there was an error verifying your account."
        message="Please try logging in again"
      />

      <Flex justifyContent="center">
        <LogoutButton variant="secondary" iconLeft="" mt={3} />
      </Flex>
    </Flex>
  );
};
