import React from "react";
import styled from "styled-components";
import { QualtricsPlaceHolder } from "../../components/QualtricsPlaceHolder";
import { CantFindWhatYouAreLookingFor } from "../../components/CantFindWhatYouAreLookingFor";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Div = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

export const NotFound: React.FC<Props> = () => {
  return (
    <Div>
      <h1>Not Found</h1>
      <p>The page was not found.</p>
      <QualtricsPlaceHolder />
      <CantFindWhatYouAreLookingFor serviceType="electricity" />
    </Div>
  );
};

NotFound.defaultProps = {};
