import React from "react";
import { CompleteProfilePage, ProfileTypeProps } from "./CompleteProfilePage";

export const DEFAULT_PROFILE_TYPE: ProfileTypeProps = {
  tabTitle: "",
};

export const CompleteProfilePageContainer: React.FC = () => {
  return <CompleteProfilePage {...DEFAULT_PROFILE_TYPE} />;
};
