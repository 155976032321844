import React from "react";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import { Flex } from "../Flex";
import { Span } from "../Span";

interface Props {
  label: string;
  value: string | number | (() => void);
  id?: string;
}

const LabelWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
`;

const ValueWrapper = styled(Span)`
  min-width: 0;
  flex-basis: 0;
  text-transform: capitalize;
`;

export const LabelValuePair: React.FC<Props & SpaceProps> = ({
  label,
  value,
  id,
  mb = 3,
}) => {
  return (
    <Flex id={id} flexDirection="row" mb={mb}>
      <LabelWrapper
        lineHeight={2}
        flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}
        color="neutrals.dn40"
        data-testid="label"
      >
        {label}
      </LabelWrapper>

      <ValueWrapper
        lineHeight={2}
        ml={3}
        flexGrow={[0.5, 0.5, 0.5, 0.6, 0.7]}
        data-testid="value"
      >
        {(typeof value === "string" || typeof value === "number") && value}
        {typeof value === "function" && value()}
      </ValueWrapper>
    </Flex>
  );
};
