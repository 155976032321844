import React, { useReducer } from "react";
import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { mediaQueries } from "../../../../theme";
import { UnsubmittedOrder } from "../../../../types/Order";
import { OrderListEmpty } from "../../../OrderListEmpty";
import { OrderListError } from "../../../OrderListError";
import { Span } from "../../../Span";
import { UnsubmittedOrdersTable } from "../UnsubmittedOrdersTable";
import { UnsubmittedOrdersCards } from "../UnsubmittedOrdersCards";
import { Modal } from "../../../Modal";
import { deleteModalAction } from "../../../../constants/DeleteReducerAction";
import { ReducerFunction } from "../../../../types/ReducerFunction";

interface Props {
  orders: UnsubmittedOrder[];
  ordersCount: number;
  columns: { Header: string }[];
  userFullName: string;
  error: boolean;
  onRetry: () => void;
  onDeleteUnsubmittedOrder: (order: UnsubmittedOrder) => void;
}

type deleteModalState = {
  showModal: boolean;
  selectedOrder?: UnsubmittedOrder;
};

const DesktopTable = styled(UnsubmittedOrdersTable)<LayoutProps>`
  ${layout}
  display: none;

  ${mediaQueries.largeDevice} {
    display: flex;
  }
`;

const MobileTable = styled(UnsubmittedOrdersCards)<LayoutProps>`
  ${layout}
  display: flex;

  ${mediaQueries.largeDevice} {
    display: none;
  }
`;

export const UnsubmittedOrdersWithoutPagination: React.FC<Props> = ({
  orders,
  ordersCount,
  columns,
  userFullName,
  error,
  onRetry,
  onDeleteUnsubmittedOrder,
}) => {
  const deleteModalReducer: ReducerFunction<
    deleteModalState,
    deleteModalAction
  > = (
    _state: deleteModalState,
    action: deleteModalAction,
  ): deleteModalState => {
    switch (action.type) {
      case "Select":
        return { showModal: true, selectedOrder: action.payload };
      case "Deselect":
        return { showModal: false };
    }
  };

  const [deleteModalState, dispatch] = useReducer(deleteModalReducer, {
    showModal: false,
  });

  const confirmUnsubmittedOrderDelete = async () => {
    if (deleteModalState.selectedOrder) {
      await onDeleteUnsubmittedOrder(deleteModalState.selectedOrder);
      dispatch({ type: "Deselect" });
    }
  };

  return (
    <>
      <Modal
        title={"You are about to delete your order"}
        confirmText={"Delete"}
        showModal={deleteModalState.showModal}
        onCloseModal={() => dispatch({ type: "Deselect" })}
        onCancel={() => dispatch({ type: "Deselect" })}
        onConfirm={confirmUnsubmittedOrderDelete}
        showButtons={true}
      >
        <p>
          Please note that this order will be deleted and you will lose any
          entered data.
        </p>
      </Modal>
      <Span fontSize={1} color="neutrals.mn40" mb={3}>
        ({ordersCount} orders)
      </Span>

      <DesktopTable
        className="table-unsubmitted-orders"
        headers={columns}
        orders={orders}
        userFullName={userFullName}
        onDelete={dispatch}
      />

      <MobileTable
        className="mobile-unsubmitted-orders"
        orders={orders}
        userFullName={userFullName}
        onDelete={dispatch}
      />

      {ordersCount === 0 && !error && <OrderListEmpty />}

      {error && <OrderListError onRetry={onRetry} />}
    </>
  );
};
