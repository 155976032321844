import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";
import {
  BorderProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  border,
  space,
  typography,
} from "styled-system";
import { Flex } from "../Flex";
import { Label } from "../Label";
import { Span } from "../Span";

export interface TextBoxProps {
  label?: string;
  message?: string;
  error?: boolean;
  optional?: boolean;
  focusable?: boolean;
  resize?: "vertical" | "horizontal";
}

const StyledTextBox = styled.textarea<
  BorderProps &
    SpaceProps &
    TypographyProps & { error?: boolean; resize?: "vertical" | "horizontal" }
>`
  ${border}
  ${space}
  ${typography}
  outline: none;
  border-color: ${({ error, theme }) => error && theme.colors.danger};
  box-sizing: border-box;

  resize: ${({ resize }) => resize};

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydro};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.disabledBackground};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.disabledColor};
  }
`;

const LabelWrapper = styled(Label)<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) => disabled && theme.colors.disabledColor};
`;

const SpanWrapper = styled(Span)<{ error?: boolean }>`
  color: ${({ theme }) => theme.colors.neutrals.mn60};
  color: ${({ error, theme }) => error && theme.colors.danger};
`;

export const TextBox: React.FC<
  TextBoxProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    TypographyProps &
    TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({
  id,
  cols,
  rows,
  disabled,
  error,
  flexBasis,
  flexGrow,
  focusable,
  label,
  message,
  minWidth,
  mb,
  mt,
  name,
  optional,
  placeholder,
  tabIndex,
  value,
  width,
  resize,
  onChange,
}) => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={flexGrow}
      flexBasis={flexBasis}
      minWidth={minWidth}
      width={width}
      mt={mt}
      mb={mb}
    >
      <Flex pl={2} pr={2} mb={1} justifyContent="space-between">
        {label && (
          <>
            <LabelWrapper
              htmlFor={id}
              fontSize="body"
              lineHeight="body"
              disabled={disabled}
              data-testid="text-box-label"
            >
              {label}
            </LabelWrapper>
          </>
        )}

        {optional && (
          <SpanWrapper
            ml="auto"
            mr={2}
            fontSize={1}
            lineHeight={1}
            data-testid="text-box-optional"
          >
            Optional
          </SpanWrapper>
        )}
      </Flex>

      <StyledTextBox
        id={id}
        name={name}
        cols={cols}
        rows={rows}
        value={value}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        pl={3}
        py={3}
        resize={resize}
        border="2px solid"
        borderRadius="input"
        borderColor="disabledBackground"
        fontFamily="inherit"
        autoComplete="new-password"
        tabIndex={focusable ? tabIndex : -1}
        data-testid="text-box-input"
        onChange={onChange}
      />

      {message && (
        <SpanWrapper
          error={error}
          height={6}
          mx={2}
          fontSize={1}
          lineHeight={1}
          data-testid="text-box-message"
        >
          {message}
        </SpanWrapper>
      )}
    </Flex>
  );
};

TextBox.defaultProps = {
  error: false,
  disabled: false,
  optional: false,
  focusable: true,
  resize: "vertical",
};
