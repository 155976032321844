import { ErrorMessage, FieldArray } from "formik";
import React from "react";
import { NewConnectionContactDetailsValues } from "../../../../types/NewConnectionContactDetailsValues";
import { NewConnectionAddContact } from "../NewConnectionAddContact";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { NewConnectionContactDetailFields } from "../NewConnectionContactDetailFields";
import { ElectricianContactFields } from "../../../ElectricianContactFields";
import { ContactDetailsValues } from "../../../../types/ContactDetailsValues";
import { LandOwnerContactFields } from "../../../LandOwnerContactFields";
import { calculateSelectableNewConnectionRoles } from "../../../ContactDetailsForm/RemainingRolesCalculator/RemainingRolesCalculator";
import {
  HasElectricianTypes,
  HasLandOwnerTypes,
} from "../../../../constants/HasOptionalRoleValues";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import { getComplexAddContactErrorMsg } from "../NewConnectionContactDetailsFormUtil/NewConnectionContactDetailsFormUtil";

interface Props {
  id: string;
  contactDetails: NewConnectionContactDetailsValues[];
  newConnectionType: NewConnectionType;
  focusable?: boolean;
  hasElectrician: HasElectricianTypes;
  hasLandOwner: HasLandOwnerTypes;
  optionalContactDetails: ContactDetailsValues;
  validateField: (field: string) => void;
}

export const NewConnectionContactDetailsForm: React.FC<Props> = ({
  id,
  contactDetails,
  newConnectionType,
  focusable,
  hasElectrician,
  hasLandOwner,
  optionalContactDetails,
  validateField,
}) => {
  const newConnectionRoles = contactDetails.map((details) => details.roles);
  const remainingSelectableRoles = calculateSelectableNewConnectionRoles(
    newConnectionRoles,
    newConnectionType,
  );

  const complexAddContactErrorMsg = getComplexAddContactErrorMsg(
    newConnectionType,
    newConnectionRoles,
  );

  return (
    <Flex flexDirection="column" id={id}>
      <Span fontSize={4} lineHeight={4} mt={0} mb={3}>
        Contact details
      </Span>

      <Span lineHeight={2} mb={5}>
        Please fill out your contact details. We require the contact details for
        your electrician, quote payer, energy retailer account holder, and site
        contact.
      </Span>

      <FieldArray
        name="contactDetails"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {contactDetails.map((details, index) => (
              <NewConnectionContactDetailFields
                newConnectionType={newConnectionType}
                key={index}
                index={index}
                roleSelectorPosition={index === 0 ? "bottom" : "top"}
                contactDetails={contactDetails}
                contactDetailsIndex={index}
                fieldsVisible={index === 0}
                focusable={focusable}
                showRemoveButton={index > 0}
                arrayHelpers={arrayHelpers}
                hasElectrician={hasElectrician}
                hasLandOwner={hasLandOwner}
                validateField={validateField}
              />
            ))}

            {contactDetails.length < 3 && remainingSelectableRoles > 0 && (
              <Flex flexDirection="row" mb={5}>
                <Flex flexDirection="column">
                  <NewConnectionAddContact
                    contactDetails={contactDetails}
                    arrayHelpers={arrayHelpers}
                    focusable={focusable}
                    newConnectionType={newConnectionType}
                  />

                  {newConnectionType === "complex" &&
                    complexAddContactErrorMsg && (
                      <ErrorMessage
                        name={complexAddContactErrorMsg}
                        className="field-error"
                        component={FormInputErrorMessage}
                      />
                    )}
                </Flex>
              </Flex>
            )}
          </>
        )}
      />

      {newConnectionType === "simple" && (
        <ElectricianContactFields
          hasElectrician={hasElectrician}
          optionalContactDetails={optionalContactDetails}
          focusable
        />
      )}

      {newConnectionType === "complex" && (
        <LandOwnerContactFields hasLandOwner={hasLandOwner} focusable />
      )}
    </Flex>
  );
};

NewConnectionContactDetailsForm.defaultProps = {
  focusable: true,
};
