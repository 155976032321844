import React, { useState } from "react";
import { useFilterOrderList } from "../../../../hooks/useFilterOrderList/useFilterOrderList";
import { Order } from "../../../../types/Order";
import { OrdersWithPagination } from "../OrdersWithPagination/OrdersWithPagination";
import { FilterDropdown } from "../../../FilterDropdown";
import { Flex } from "../../../Flex";
import { UserType } from "../../../../types/UserFullDetails";
import { usePredictiveSearch } from "../../../../hooks/usePredictiveSearch";
import styled from "styled-components";
import { mediaQueries } from "../../../../theme";
import { SearchInput } from "../../../SearchInput";

interface Props {
  orders: Order[];
  userFullName: string;
  requesterList: string[];
  type: OrdersFilterStatus;
  userType: UserType;
  columns: { Header: string }[];
  error: boolean;
  onRetry?: () => void;
}

const FlexWrapper = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;

  ${mediaQueries.mediumDevice} {
    align-items: center;
    flex-direction: row;
  }
`;

export const OrdersWithFilteredPagination: React.FC<Props> = ({
  orders,
  userFullName,
  requesterList,
  type,
  userType,
  columns,
  error,
  onRetry,
}) => {
  const [requesterFilter, setRequesterFilter] = useState(userFullName);
  const [searchFilter, setSearchFilter] = useState("");

  const handleRequesterFilterSelected = (
    selected?: string | null | undefined,
  ) => {
    if (selected) {
      setRequesterFilter(selected);
    }
  };

  const { filterByRequester } = useFilterOrderList(orders);
  let filteredOrderList = filterByRequester(requesterFilter);
  const { search } = usePredictiveSearch(filteredOrderList, [
    "orderNumber",
    "address",
  ]);
  filteredOrderList = search(searchFilter);

  return (
    <>
      <FlexWrapper mb={4}>
        <SearchInput searchFilterChangeListener={setSearchFilter} />

        {userType === "business" && (
          <FilterDropdown
            userFullName={userFullName}
            onSelect={handleRequesterFilterSelected}
            visibleItems={6}
            items={requesterList}
          />
        )}
      </FlexWrapper>

      <OrdersWithPagination
        orders={filteredOrderList}
        ordersCount={filteredOrderList.length}
        type={type}
        columns={columns}
        error={error}
        onRetry={onRetry}
        requesterFilter={requesterFilter}
        searchFilter={searchFilter}
      />
    </>
  );
};
