import React, { useContext } from "react";
import ReactModal from "react-modal";
import { ThemeContext } from "styled-components";
import { ActionButton } from "../ActionButton";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { Line } from "../Line";
import { Span } from "../Span";

interface Props {
  title: string;
  showModal: boolean;
  onCloseModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showButtons?: boolean;
  cancelText?: string;
  confirmText?: string;
  cancelButtonVariant?: "secondary" | "ghost";
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Modal: React.FC<Props> = ({
  showModal,
  title,
  cancelText,
  confirmText,
  cancelButtonVariant,
  onCloseModal,
  onCancel,
  onConfirm,
  children,
  showButtons,
}) => {
  const themeContext = useContext(ThemeContext);
  const { innerWidth } = window;

  const getBreakpointValue = (index: number): number => {
    const breakpoint = themeContext.breakpoints[index];
    return breakpoint.substring(0, breakpoint.length - 2);
  };

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={title}
      onRequestClose={onCloseModal}
      style={{
        overlay: {
          backgroundColor: `${themeContext.colors.neutrals.ln2090}`,
          overflow: "auto",
        },
        content: {
          maxWidth: "660px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: `${
            innerWidth < getBreakpointValue(0)
              ? "translate(-50%, -35%)"
              : "translate(-50%, -50%)"
          }`,
          padding: "24px",
          borderRadius: "12px",
          boxShadow: `${themeContext.shadows.modal}`,
          width: `${innerWidth < getBreakpointValue(2) ? "80%" : "100%"}`,
        },
      }}
    >
      <Flex flexDirection="column" justifyContent="space-between">
        <Flex flexDirection="column" justifyContent="space-between">
          <Flex flexDirection="row" justifyContent="space-between">
            <Span fontSize={6} lineHeight={6}>
              {title}
            </Span>
            <ActionButton icon="close" onClick={onCloseModal} ml={3} />
          </Flex>
          <Line
            width="100%"
            mt={2}
            mb={5}
            border="1px solid"
            borderColor="neutrals.ln30"
            bg="neutrals.ln30"
            opacity="1"
            height={0}
          />
        </Flex>

        <Box>{children}</Box>

        {showButtons && (
          <Flex
            flexDirection="row"
            justifyContent={["center", "center", "flex-end"]}
            flexWrap="wrap"
            alignItems="flex-end"
          >
            <Button variant={cancelButtonVariant} onClick={onCancel}>
              {cancelText}
            </Button>

            <Button
              variant="primary"
              onClick={onConfirm}
              ml={[0, 0, 3]}
              mt={[3, 3, 0]}
            >
              {confirmText}
            </Button>
          </Flex>
        )}
      </Flex>
    </ReactModal>
  );
};

Modal.defaultProps = {
  showModal: false,
  showButtons: true,
  confirmText: "Confirm",
  cancelText: "Cancel",
  cancelButtonVariant: "secondary",
};
