import * as yup from "yup";
import { ALTERNATE_PHONE_REG_EXP, MOBILE_REG_EXP } from "../../constants/ContactNumbersRegex";
import { NewConnectionRoles } from "../../types/ContactDetailRoles";
import { ContactPreferences } from "../../types/ContactDetailsValues";
import {
  newConnectionFormMobileCheck
} from "./_/NewConnectionFormMobileValidateUtil/NewConnectionFormMobileValidateUtil";
import { HasElectricianTypes, HasLandOwnerTypes } from "../../constants/HasOptionalRoleValues";
import {
  ONLY_LETTERS_AND_SPACE_REG_EXP,
  ONLY_NUMBERS_REG_EXP,
  STARTS_WITH_NUMBERS_REG_EXP
} from "../../constants/FormRegex";
import { isBillingAddressRequired } from "../../utils/isBillingAddressRequired/isBillingAddressRequired";
import { noWhitespaceValidation } from "../../utils/noWhitespaceValidation/noWhitespaceValidation";
import { EMAIL_REG_EXP } from "../../constants/EmailRegex";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noWhitespace",
  noWhitespaceValidation,
);

export const NewConnectionOrderDetailsFormSchema = yup.object().shape({
  isUserLoggedIn: yup.boolean(),
  site: yup.object().shape({
    siteType: yup.string().required("Site type is required"),
    lotInformation: yup.object().when("siteType", {
      is: (siteType: string) => siteType === "lotAndDpNumber",
      then: yup.object({
        lotNumber: yup
          .string()
          .required("Lot Number is required")
          .matches(ONLY_NUMBERS_REG_EXP, "Lot Number can only contain numbers")
          .max(5, "Maximum length is 5"),
        dpNumber: yup
          .string()
          .required("DP Number is required")
          .matches(ONLY_NUMBERS_REG_EXP, "DP Number can only contain numbers")
          .max(50, "Maximum length is 50"),
        streetNumber: yup
          .string()
          .noWhitespace("Street Number is invalid")
          .max(6, "Maximum length is 6"),
        streetName: yup
          .string()
          .noWhitespace("Street name is invalid")
          .required("Street name is required")
          .test(
            "is-valid-street-name",
            "The street number cannot be entered in the Street Name field. Please move the number into the Street Number field",
            (value) => {
              if (!value) {
                return false;
              }

              const matches = STARTS_WITH_NUMBERS_REG_EXP.test(value);
              return !matches;
            },
          ),
        suburb: yup
          .string()
          .noWhitespace("Suburb is invalid")
          .max(50, "Maximum length is 50")
          .required("Suburb is required")
          .matches(
            ONLY_LETTERS_AND_SPACE_REG_EXP,
            "Suburb can only contain letters",
          ),
        postcode: yup
          .string()
          .noWhitespace("Post code is invalid")
          .required("Postcode is required")
          .matches(ONLY_NUMBERS_REG_EXP, "Postcode can only contain numbers")
          .length(4, "Postcode should contain 4 numbers, please update"),
      }),
    }),
    addressInformation: yup.object().when("siteType", {
      is: (siteType: string) => siteType === "streetNumberAndName",
      then: yup.object({
        manuallyEnteredAddress: yup.boolean(),
        streetAddress: yup.string().when("manuallyEnteredAddress", {
          is: false,

          then: yup.string().test(
            "is-valid-address",
            "Street address is invalid",

            function (value) {
              const { from } = this as yup.TestContext & {
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                from: any;
              };

              if (!value) {
                return false;
              }

              const formValues = from[0];
              if (formValues) {
                const streetAddressId = from[0].value.streetAddressId as string;
                return streetAddressId !== undefined && streetAddressId !== "";
              }

              return false;
            },
          ),
          otherwise: yup.string(),
        }),
        manualStreetAddress: yup.object().when("manuallyEnteredAddress", {
          is: true,
          then: yup.object({
            streetNumber: yup
              .string()
              .noWhitespace("Street Number is invalid")
              .required("Street Number is required")
              .max(6, "Maximum length is 6"),
            streetName: yup
              .string()
              .noWhitespace("Street Name is invalid")
              .required("Street Name is required")
              .max(50, "Maximum length is 50"),
            suburb: yup
              .string()
              .noWhitespace("Suburb is invalid")
              .required("Suburb is required")
              .max(50, "Maximum length is 50")
              .matches(
                ONLY_LETTERS_AND_SPACE_REG_EXP,
                "Suburb can only contain letters",
              ),
            unit: yup
              .string()
              .noWhitespace("Unit is invalid")
              .max(5, "Maximum length is 5"),
            postcode: yup
              .string()
              .noWhitespace("Postcode is invalid")
              .required("Postcode is required")
              .matches(
                ONLY_NUMBERS_REG_EXP,
                "Postcode can only contain numbers",
              )
              .length(4, "Postcode should contain 4 numbers, please update"),
          }),
        }),
        additionalAddressInformation: yup
          .string()
          .noWhitespace("Additional address information is invalid")
          .max(
            100,
            "Additional address information has a 100 characters limit",
          ),
      }),
    }),
    lotCount: yup
      .string()
      .noWhitespace("Lot count is invalid")
      .required("Lot count is required"),
    lots: yup.array().of(
      yup.object().shape({
        type: yup.string().required(),
        phase: yup.number().required(),
        fuseAmp: yup.string().required(),
      }),
    ),
    additionalSiteRequirements: yup
      .string()
      .noWhitespace("Site requirements is invalid")
      .max(2000, "Site requirements has a 2000 characters limit"),
  }),
  serviceType: yup.string().when("isComplexConnection", {
    is: (isComplexConnection: boolean) => isComplexConnection,
    then: yup.string().required("Service type is required"),
    otherwise: yup.string(),
  }),
  projectType: yup.string().when("isComplexConnection", {
    is: (isComplexConnection: boolean) => isComplexConnection,
    then: yup.string().required("Project type is required"),
    otherwise: yup.string(),
  }),
  subdivisionType: yup.string().when(["isComplexConnection", "projectType"], {
    is: (isComplexConnection: boolean, projectType: string) =>
      projectType === "subdivision" && isComplexConnection,
    then: yup.string().required("Subdivision type is required"),
    otherwise: yup.string(),
  }),
  developmentDescription: yup
    .string()
    .noWhitespace("Description is invalid")
    .max(300, "Description has a 300 characters limit"),
  filesUploadStatus: yup.string(),
  sitePlans: yup.array().when("filesUploadStatus", {
    is: (filesUploadStatus: string) => !filesUploadStatus,
    then: yup
      .array()
      .of(
        yup.object().shape({
          fileName: yup.string().required("File is required"),
          md5: yup.string().required("File md5 is required"),
        }),
      )
      .min(1, "Please upload the file containing the site plan details")
      .max(5, "Max of 5 files allowed"),
  }),
  isComplexConnection: yup.boolean(),
  siteAccessContactRoleChecked: yup.boolean(),
  quotePayerRoleChecked: yup.boolean(),
  customerRoleChecked: yup.boolean(),
  consultantRoleChecked: yup.boolean(),
  hasElectrician: yup.string().when("isComplexConnection", {
    is: (isComplexConnection: boolean) => !isComplexConnection,
    then: yup.string().required("Please select a option for electrician"),
  }),
  hasLandOwner: yup.string().when("isComplexConnection", {
    is: (isComplexConnection: boolean) => isComplexConnection,
    then: yup.string().required("Please select a option for land owner"),
  }),
  contactDetails: yup.array().of(
    yup.object().shape(
      {
        firstName: yup
          .string()
          .noWhitespace("First Name is invalid")
          .max(50, "Maximum length is 50")
          .required("First Name is required"),
        lastName: yup
          .string()
          .noWhitespace("Last Name is invalid")
          .max(50, "Maximum length is 50")
          .required("Last Name is required"),
        email: yup.string().when(["workContactPreferences", "roles"], {
          is: (
            workContactPreferences: ContactPreferences,
            roles: NewConnectionRoles,
          ) => {
            return (
              workContactPreferences.email ||
              roles.quotePayerRole ||
              roles.customerRole
            );
          },
          then: yup
            .string()
            .noWhitespace("Enter a valid email")
            .matches(EMAIL_REG_EXP, "Enter a valid email")
            .max(50, "Maximum length is 50")
            .required("Email is required"),
          otherwise: yup
            .string()
            .noWhitespace("Enter a valid email")
            .matches(EMAIL_REG_EXP, "Enter a valid email")
            .max(50, "Maximum length is 50"),
        }),
        mobile: yup
          .string()
          .when(
            [
              "quoteContactPreferences",
              "workContactPreferences",
              "roles",
              "alternatePhone",
            ],
            {
              is: (
                quoteContactPreferences: ContactPreferences,
                workContactPreferences: ContactPreferences,
                roles: NewConnectionRoles,
                alternatePhone: string,
              ) => {
                return newConnectionFormMobileCheck(
                  quoteContactPreferences,
                  workContactPreferences,
                  roles,
                  alternatePhone,
                );
              },
              then: yup
                .string()
                .noWhitespace("Enter a valid mobile number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid mobile number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            },
          ),
        alternatePhone: yup
          .string()
          .noWhitespace("Enter a valid alternate phone number")
          .nullable()
          .matches(
            ALTERNATE_PHONE_REG_EXP,
            "Enter a valid alternate phone number",
          ),
        businessName: yup
          .string()
          .noWhitespace("Business name is invalid")
          .max(100, "Maximum length is 100 characters"),
        businessNumber: yup
          .string()
          .noWhitespace("Business number is invalid")
          .nullable()
          .matches(ONLY_NUMBERS_REG_EXP, "NZBN can only contain numbers")
          .length(13, "Your NZBN should contain 13 numbers, please update"),
        billingAddressId: yup.string(),
        billingAddressDisplay: yup
          .string()
          .when(["roles", "manuallyEnteredBillingAddress"], {
            is: (
              roles: NewConnectionRoles,
              manuallyEnteredBillingAddress: boolean,
            ) => {
              return roles.quotePayerRole && !manuallyEnteredBillingAddress;
            },

            then: yup.string().test(
              "is-valid-address",
              "Billing address is invalid",

              function (value) {
                const { from } = this as yup.TestContext & {
                  /* eslint-disable  @typescript-eslint/no-explicit-any */
                  from: any;
                };

                if (!value) {
                  return false;
                }

                const contactDetailsValues = from[0];
                const formValues = from[1];

                if (!contactDetailsValues || !formValues) {
                  return false;
                }

                const isUserLoggedIn = formValues.value
                  .isUserLoggedIn as boolean;
                const billingAddressId = contactDetailsValues.value
                  .billingAddressId as string;
                const roles = contactDetailsValues.value
                  .roles as NewConnectionRoles;

                const isRequired = isBillingAddressRequired(
                  roles,
                  isUserLoggedIn,
                  billingAddressId,
                );

                return !isRequired;
              },
            ),
            otherwise: yup.string(),
          }),
        manuallyEnteredBillingAddress: yup.boolean(),
        manualBillingAddress: yup
          .object()
          .when("manuallyEnteredBillingAddress", {
            is: true,
            then: yup.object({
              addressString: yup
                .string()
                .noWhitespace(
                  "Street address / PO Box or Private Bag is invalid",
                )
                .required("Street address / PO Box or Private Bag is required")
                .max(100, "Maximum length is 100")
                .min(5, "Street address / PO Box or Private Bag is invalid"),
              suburb: yup
                .string()
                .noWhitespace("Suburb is invalid")
                .max(50, "Maximum length is 50")
                .matches(
                  ONLY_LETTERS_AND_SPACE_REG_EXP,
                  "Suburb can only contain letters",
                ),
              city: yup
                .string()
                .noWhitespace("City is invalid")
                .required("City is required")
                .max(50, "Maximum length is 50")
                .matches(
                  ONLY_LETTERS_AND_SPACE_REG_EXP,
                  "City can only contain letters",
                ),
              postcode: yup
                .string()
                .noWhitespace("Postcode is invalid")
                .required("Postcode is required")
                .matches(
                  ONLY_NUMBERS_REG_EXP,
                  "Postcode can only contain numbers",
                )
                .length(4, "Postcode should contain 4 numbers, please update"),
            }),
          }),
        workContactPreferences: yup.object().shape({
          email: yup.boolean(),
          sms: yup.boolean(),
        }),
        quoteContactPreferences: yup.object().shape({
          email: yup.boolean(),
          sms: yup.boolean(),
        }),
        roles: yup.object().shape({
          requester: yup.boolean(),
          quotePayerRole: yup.boolean().test(
            "quotePayerCheck",
            "Must be assigned to one contact",
            /* eslint-disable @typescript-eslint/no-explicit-any */
            /* eslint-disable @typescript-eslint/no-unused-vars */
            function (values: any) {
              /* @ts-ignore */
              const formValues = this.from[2];
              if (formValues) {
                if (formValues.value.isComplexConnection) {
                  return true;
                }

                return formValues.value.quotePayerRoleChecked;
              }
              return true;
            },
          ),
          siteAccessContactRole: yup.boolean().test(
            "siteAccessContactCheck",
            "Must be assigned to one contact",
            /* eslint-disable @typescript-eslint/no-explicit-any */
            /* eslint-disable @typescript-eslint/no-unused-vars */
            function (values: any) {
              /* @ts-ignore */
              const formValues = this.from[2];
              if (formValues) {
                if (formValues.value.isComplexConnection) {
                  return true;
                }

                return formValues.value.siteAccessContactRoleChecked;
              }
              return true;
            },
          ),
          customerRole: yup.boolean().test(
            "customerContactCheck",
            "Customer contact details are required",
            /* eslint-disable @typescript-eslint/no-explicit-any */
            /* eslint-disable @typescript-eslint/no-unused-vars */
            function (values: any) {
              /* @ts-ignore */
              const formValues = this.from[2];
              if (formValues) {
                if (!formValues.value.isComplexConnection) {
                  return true;
                }

                return formValues.value.customerRoleChecked;
              }
              return true;
            },
          ),
          consultantRole: yup.boolean(),
        }),
        contactHasRole: yup.boolean()
        .test(
          "contactDetailsRole-selected",
          "Role is required",
          /* eslint-disable @typescript-eslint/no-explicit-any */
          /* eslint-disable @typescript-eslint/no-unused-vars */
          function (values: any) {
            /* @ts-ignore */
            const formValues = this.from[0];
            // @ts-ignore
            const complexForm = this.from[1].value.isComplexConnection;

            let roleSelected = false;

            if (!complexForm){
              roleSelected =
                formValues.value.roles.requester ||
                formValues.value.roles.siteAccessContactRole ||
                formValues.value.roles.quotePayerRole;
            }else {
              roleSelected =
                formValues.value.roles.requester ||
                formValues.value.roles.customerRole ||
                formValues.value.roles.consultantRole;
              }

            return roleSelected;
          }),
        },
      [["mobile", "alternatePhone"]],
    )
  ),
  optionalContactDetails: yup
    .object()
    .when("hasElectrician", {
      is: (hasElectrician: HasElectricianTypes) => hasElectrician === "yes",
      then: yup.object().shape(
        {
          firstName: yup
            .string()
            .noWhitespace("First name is invalid")
            .max(50, "Maximum length is 50")
            .required("First name is required"),
          lastName: yup
            .string()
            .noWhitespace("Last name is invalid")
            .max(50, "Maximum length is 50")
            .required("Last name is required"),
          email: yup.string().when("workContactPreferences", {
            is: (workContactPreferences: ContactPreferences) =>
              workContactPreferences.email,
            then: yup
              .string()
              .noWhitespace("Enter a valid email")
              .matches(EMAIL_REG_EXP, "Enter a valid email")
              .max(50, "Maximum length is 50")
              .required("Email is required"),
            otherwise: yup
              .string()
              .noWhitespace("Enter a valid email")
              .matches(EMAIL_REG_EXP, "Enter a valid email")
              .max(50, "Maximum length is 50"),
          }),
          mobile: yup
            .string()
            .when("workContactPreferences", {
              is: (workContactPreferences: ContactPreferences) =>
                workContactPreferences.sms,
              then: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid phone number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            })
            .when("alternatePhone", {
              is: (alternatePhone: string) =>
                !alternatePhone || alternatePhone === "",
              then: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid phone number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            }),
          alternatePhone: yup
            .string()
            .noWhitespace("Enter a valid alternate phone number")
            .nullable()
            .matches(
              ALTERNATE_PHONE_REG_EXP,
              "Enter a valid alternate phone number",
            ),
          businessName: yup
            .string()
            .noWhitespace("Business name is invalid")
            .max(100, "Maximum length is 100 characters"),
          workContactPreferences: yup.object().shape({
            email: yup.boolean(),
            sms: yup.boolean(),
          }),
        },
        [["mobile", "alternatePhone"]],
      ),
    })
    .when("hasLandOwner", {
      is: (hasLandOwner: HasLandOwnerTypes) => hasLandOwner === "yes",
      then: yup
        .object()
        .shape(
          {
            firstName: yup
              .string()
              .noWhitespace("First name is invalid")
              .max(50, "Maximum length is 50")
              .required("First name is required"),
            lastName: yup
              .string()
              .noWhitespace("Last name is invalid")
              .max(50, "Maximum length is 50")
              .required("Last name is required"),
            email: yup.string().when("workContactPreferences", {
              is: (workContactPreferences: ContactPreferences) =>
                workContactPreferences.email,
              then: yup
                .string()
                .noWhitespace("Enter a valid email")
                .matches(EMAIL_REG_EXP, "Enter a valid email")
                .max(50, "Maximum length is 50"),
            }),
            mobile: yup
              .string()
              .noWhitespace("Enter a valid mobile number")
              .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            alternatePhone: yup
              .string()
              .noWhitespace("Enter a valid alternate phone number")
              .matches(
                ALTERNATE_PHONE_REG_EXP,
                "Enter a valid alternate phone number",
              ),
            businessName: yup
              .string()
              .noWhitespace("Business name is invalid")
              .max(100, "Maximum length is 100 characters"),
            workContactPreferences: yup.object().shape({
              email: yup.boolean(),
              sms: yup.boolean(),
            }),
          },
          [["mobile", "alternatePhone"]],
        )
        .required("Must complete optional contact detail"),
    }),
});
