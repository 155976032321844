import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Span } from "../Span";
import { InputTextProps } from "../InputText/InputText";
import { ColorProps, SpaceProps } from "styled-system";

export const FormInputErrorMessage = styled(Span)<
    InputTextProps &
    ColorProps &
    SpaceProps &
    InputHTMLAttributes<HTMLInputElement>>`
    height: 20px;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 0.75rem;
    line-height: 1.13rem;
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.danger};
`;
