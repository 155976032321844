import React from "react";
import { ActionLink } from "../ActionLink";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { Flex } from "../Flex";
import { Span } from "../Span";
import { Title } from "../Title";
import { SpaceProps } from "styled-system";
import { Box } from "../Box";
import { Line } from "../Line";
import { Link } from "../Link";

interface Props {
  email: string;
  passwordChangeDate: string;
  createdDate: string;
  onResetPasswordClick: (email: string) => void;
}

export const AccountSecurityDetails: React.FC<Props & SpaceProps> = ({
  email,
  passwordChangeDate,
  createdDate,
  onResetPasswordClick,
}) => {
  const handleResetPasswordClick = () => {
    onResetPasswordClick(email);
  };

  const noPasswordChangeDate = passwordChangeDate === "";

  return (
    <>
      <Flex flexDirection="column">
        <Title fontSize={4} lineHeight={4} fontStyle="normal" fontWeight="500">
          Security
        </Title>

        <Flex
          flexDirection="column"
          p={4}
          borderRadius="notificationBanner"
          bg="neutrals.ln10"
          border="1px solid"
          borderColor="neutrals.ln30"
        >
          <Flex mb={[2, 2, 3]} flexDirection={["column", "column", "row"]}>
            <Span
              color="neutrals.dn40"
              flexGrow={0.25}
              flexBasis={0}
              minWidth={0}
            >
              Email
            </Span>

            <Span
              color="nightSky"
              flexGrow={0.75}
              flexBasis={0}
              minWidth={0}
              data-testid="security-info-email"
              mt={[3, 3, 0]}
            >
              {email}
            </Span>
          </Flex>

          <Flex
            my={5}
            alignItems="flex-start"
            flexDirection={["column", "column", "row"]}
          >
            <Span
              color="neutrals.dn40"
              flexGrow={[1, 1, 0.25]}
              flexBasis={0}
              minWidth={0}
            >
              Password
            </Span>

            {noPasswordChangeDate && (
              <Span
                color="neutrals.mn80"
                fontSize={1}
                lineHeight={1}
                flexGrow={[1, 1, 0.5]}
                flexBasis={0}
                minWidth={0}
                data-testid="security-info-created-date"
                mt={[3, 3, 0]}
              >
                {`Created ${createdDate}`}
              </Span>
            )}

            {!noPasswordChangeDate && (
              <Span
                color="neutrals.mn80"
                fontSize={1}
                lineHeight={1}
                flexGrow={[1, 1, 0.5]}
                flexBasis={0}
                minWidth={0}
                data-testid="security-info-password"
                mt={[3, 3, 0]}
              >
                {`Last updated ${passwordChangeDate}`}
              </Span>
            )}

            <Box
              flexGrow={[1, 1, 0.25]}
              flexBasis={0}
              minWidth={0}
              ml={["0", "0", "auto"]}
              mt={[3, 3, 0]}
              textAlign="right"
            >
              <ActionLink onClick={handleResetPasswordClick}>
                Reset password
              </ActionLink>
            </Box>
          </Flex>

          <Line
            width="100%"
            border="1px solid"
            borderColor="neutrals.ln30"
            bg="neutrals.ln30"
            opacity="1"
            height={0}
            mb={[5]}
          />

          <BasicNotificationBanner
            message={() => (
              <Span>
                To change your 2-step verification method, please contact us on{" "}
                <Link href="mailto:tech.support@vector.co.nz">
                  tech.support@vector.co.nz
                </Link>
              </Span>
            )}
            variant="information"
            icon="info"
          />
        </Flex>
      </Flex>
    </>
  );
};
