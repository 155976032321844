import styled from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";

export const Label = styled.label<
  ColorProps & SpaceProps & TypographyProps & LayoutProps
>`
  ${color}
  ${space}
  ${typography}
  ${layout}
`;
