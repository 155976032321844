import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import { Box } from "../Box";
import { useSelect } from "downshift";
import { Icon } from "../Icon";

interface Props {
  items: string[];
  userFullName: string;
  visibleItems: number;
  onSelect: (selected?: string | null) => void;
}

const FlexWrapper = styled(Flex)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }
`;

const StyledSelectButton = styled.button<
  BorderProps & LayoutProps & TypographyProps & ColorProps
>`
  ${border}
  ${layout}
  ${space}
  ${typography}
  ${color}
  cursor: pointer;
  text-decoration: none;
  border: none;
  appearance: none;
  outline: none;

  text-align: middle;
  background: none;

  width: min-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :hover,
  :focus,
  :focus-visible,
  :focus-within,
  :active {
    outline: none;
    background: none;
  }

  :enabled:focus {
    color: ${({ theme }) => theme.colors.nightSky};
  }
`;

const StyledToggleButton = styled.button<BorderProps & SpaceProps>`
  ${border}
  ${space}
  border: none;
  background: none;
  outline: none;
  padding-right: 16px;
  flex-grow: 0;
  cursor: pointer;
`;

const StyledItemsContainer = styled.ul<
  BorderProps & LayoutProps & SpaceProps & ColorProps
>`
  ${border}
  ${layout}
  ${space}
  ${color}
  
  overflow: auto;
  position: absolute;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutrals.ln30};
    border-radius: 6px;
  }

  outline: none;
`;

const StyledItem = styled.li<
  ColorProps & SpaceProps & TypographyProps & LayoutProps
>`
  ${color}
  ${space}
  ${typography}
  ${layout}

  background: none;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  border-top: 2px solid ${({ theme }) => theme.colors.neutrals.ln10};
  color: ${({ theme }) => theme.colors.nightSky};
  text-transform: none;
  box-shadow: none;
  whitespace: normal;
  wordwrap: normal;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.hydro25};
  }
`;

export const FilterDropdown: React.FC<
  Props & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onSelect">
> = ({ items, userFullName, visibleItems, onBlur, onFocus, onSelect }) => {
  const MENU_ITEM_HEIGHT_PIXELS = 60;

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem);
    },
  });

  return (
    <Box>
      <FlexWrapper
        border="none"
        backgroundColor={isOpen ? "neutrals.ln30" : "neutrals.ln0"}
        borderRadius="input"
        height={11}
      >
        <StyledSelectButton
          {...getToggleButtonProps({
            type: "button",
          })}
          data-testid="select-button"
          fontFamily="current"
          fontWeight="medium"
          color="nightSky"
          lineHeight={1}
          fontSize={1}
          pl={3}
        >
          {selectedItem
            ? `Requester: ${selectedItem}`
            : `Requester: ${userFullName}`}
        </StyledSelectButton>
        <StyledToggleButton
          {...getToggleButtonProps({
            type: "button",
          })}
          backgroundColor="none"
        >
          <Icon
            fontSize={5}
            color="neutrals.dn40"
            icon={isOpen ? "expand_less" : "expand_more"}
          />
        </StyledToggleButton>
      </FlexWrapper>

      <StyledItemsContainer
        {...getMenuProps({
          onBlur: onBlur,
          onFocus: onFocus,
        })}
        data-testid="unordered-list-container"
        hidden={!isOpen || !items.length}
        mt={1}
        mb={0}
        mx={0}
        padding={0}
        border="1px solid"
        borderColor="neutrals.ln20"
        borderRadius="input"
        backgroundColor="neutrals.ln0"
        maxHeight={`${visibleItems * MENU_ITEM_HEIGHT_PIXELS}px`}
        overflowY={items.length > visibleItems ? "scroll" : "hidden"}
        width="216px"
      >
        {items.map((item: string, index: number): JSX.Element => {
          return (
            <StyledItem
              key={index}
              {...getItemProps({
                item,
                index,
              })}
              fontSize={1}
              fontWeight={
                selectedItem === item
                  ? "bold"
                  : userFullName === item && !selectedItem
                  ? "bold"
                  : "medium"
              }
              lineHeight={1}
              height={10}
              px={3}
              textAlign="center"
            >
              {item}
            </StyledItem>
          );
        })}
      </StyledItemsContainer>
    </Box>
  );
};

FilterDropdown.defaultProps = {
  visibleItems: 5,
};
