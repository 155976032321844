import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthenticationProvider } from "./components/AuthenticationProvider";
import { LoginPage } from "./components/LoginPage";
import { LogoutPage } from "./components/LogoutPage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AccountSettingsPage } from "./pages/AccountSettingsPage";
import { HelpCentrePageContainer } from "./pages/HelpCentrePage/HelpCentrePageContainer";
import { LiveningIcpPageContainer } from "./pages/LiveningIcpPage/LiveningIcpPageContainer";
import { LoginPageContainer } from "./pages/LoginPage/LoginPageContainer";
import { NewConnectionPageContainer } from "./pages/NewConnectionPage/NewConnectionPageContainer";
import { NotFound } from "./pages/NotFound";
import { OrderDetailsPageContainer } from "./pages/OrderDetailsPage/OrderDetailsPageContainer";
import { OrderDetailsListPageContainer } from "./pages/OrderDetailsListPage/OrderDetailsListPageContainer";
import { ServiceTypePageContainer } from "./pages/ServiceTypePage/ServiceTypePageContainer";
import { ThankYouPage } from "./pages/ThankYouPage";
import { CompleteProfilePage } from "./pages/CompleteProfilePage";
import { withSiebelAccountVerification } from "./utils/withSiebelAccountVerification";
import { PaymentResultPageContainer } from "./pages/PaymentResultPage";
import { WindcaveContainer } from "./components/WindcaveContainer/WindcaveContainer";
import { PayNowPageContainer } from "./pages/PayNowPage";
import { PaymentErrorPageContainer } from "./pages/PaymentErrorPage";
import { MaintenancePage } from "./pages/MaintenancePage";
import { PersistedQueryProvider } from "./contexts/PersistedQueryProvider/PersistedQueryProvider";

function App() {
  return (
    <Router>
      <PersistedQueryProvider cacheMinutes={15} staleMinutes={15}>
        <AuthenticationProvider>
          <Switch>
            <Route
              exact
              path="/"
              component={withSiebelAccountVerification(
                ServiceTypePageContainer,
              )}
            />
            <Route
              exact
              path="/service-type"
              component={withSiebelAccountVerification(
                ServiceTypePageContainer,
              )}
            />
            <Route
              exact
              path="/login-option/:orderType"
              component={withSiebelAccountVerification(LoginPageContainer)}
            />
            <Route
              exact
              path="/icp-request"
              component={withSiebelAccountVerification(
                LiveningIcpPageContainer,
              )}
            />
            <Route
              exact
              path="/new-connection"
              component={withSiebelAccountVerification(
                NewConnectionPageContainer,
              )}
            />
            <Route exact path="/thank-you/:pageType" component={ThankYouPage} />
            <ProtectedRoute
              path="/order-details-list"
              component={withSiebelAccountVerification(
                OrderDetailsListPageContainer,
              )}
            />
            <ProtectedRoute
              path="/orders/:orderId"
              component={withSiebelAccountVerification(
                OrderDetailsPageContainer,
              )}
            />
            <ProtectedRoute
              exact
              path="/complete-profile"
              component={CompleteProfilePage}
            />
            <ProtectedRoute
              exact
              path="/account-settings"
              component={withSiebelAccountVerification(AccountSettingsPage)}
            />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/help" component={HelpCentrePageContainer} />
            <Route
              exact
              path="/payment/:pageType"
              component={PaymentResultPageContainer}
            />
            <Route
              exact
              path="/pay-now/:paymentId"
              component={withSiebelAccountVerification(PayNowPageContainer)}
            />
            <Route
              exact
              path="/create-payment-session"
              component={WindcaveContainer}
            />
            <Route
              exact
              path="/payment-error"
              component={PaymentErrorPageContainer}
            />
            <Route exact path="/maintenance" component={MaintenancePage} />
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </AuthenticationProvider>
      </PersistedQueryProvider>
    </Router>
  );
}

export default App;
