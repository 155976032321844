import React from "react";
import { ErrorMessage, Field } from "formik";
import { Flex } from "../Flex";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { FormikInputText } from "../FormikInputText";
import { Link } from "../Link";
import { Icon } from "../Icon";
import { Box } from "../Box";

interface Props {
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  focusable?: boolean;
}

export const LotNumberDpInformation: React.FC<Props> = ({
  fieldIdPrefix,
  fieldNamePrefix,
  focusable,
}) => {
  return (
    <Flex flexDirection="column">
      <Flex mb={[3, 3, 4]}>
        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mr={2}
        >
          <Field
            id={`${fieldIdPrefix}.lotInformation.lotNumber`}
            name={`${fieldNamePrefix}.lotInformation.lotNumber`}
            label="Lot Number"
            placeholder="e.g. 109"
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.lotInformation.lotNumber`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={2}
        >
          <Field
            id={`${fieldIdPrefix}.lotInformation.dpNumber`}
            name={`${fieldNamePrefix}.lotInformation.dpNumber`}
            label="DP"
            placeholder="e.g. 12345"
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.lotInformation.dpNumber`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Flex>

      <Flex flexDirection={["column", "column", "row", "row"]} mb={[3, 3, 4]}>
        <Flex
          flexDirection="column"
          flexGrow={0.3}
          flexBasis={0}
          minWidth={0}
          mr={[0, 0, 2, 2]}
          mb={[3, 3, 0, 0]}
        >
          <Field
            id={`${fieldIdPrefix}.lotInformation.streetNumber`}
            name={`${fieldNamePrefix}.lotInformation.streetNumber`}
            label="Street number"
            placeholder="e.g. 10"
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.lotInformation.streetNumber`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex
          flexDirection="column"
          flexGrow={0.7}
          flexBasis={0}
          minWidth={0}
          ml={[0, 0, 2, 2]}
        >
          <Field
            id={`${fieldIdPrefix}.lotInformation.streetName`}
            name={`${fieldNamePrefix}.lotInformation.streetName`}
            label="Street name"
            placeholder="e.g. Newton road"
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.lotInformation.streetName`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={["column", "column", "column", "column", "row"]}
        mb={[3, 3, 4]}
      >
        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mb={[3, 3, 4, 4, 0]}
          mr={[0, 0, 0, 0, 2]}
        >
          <Field
            id={`${fieldIdPrefix}.lotInformation.suburb`}
            name={`${fieldNamePrefix}.lotInformation.suburb`}
            label="Suburb"
            placeholder="e.g. Glen Eden"
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.lotInformation.suburb`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex
          flexDirection={["column", "row"]}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={[0, 0, 0, 0, 2]}
        >
          <Flex
            flexDirection="column"
            flexBasis={["auto", 0]}
            flexGrow={[1, 0.5]}
            minWidth={["auto", 0]}
          >
            <Field
              id={`${fieldIdPrefix}.lotInformation.postcode`}
              name={`${fieldNamePrefix}.lotInformation.postcode`}
              label="Postcode"
              placeholder="e.g. 1010"
              focusable={focusable}
              component={FormikInputText}
            />
            <ErrorMessage
              name={`${fieldNamePrefix}.lotInformation.postcode`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>

          <Box
            ml={[0, 3]}
            mt={4}
            flexBasis={["auto", 0]}
            flexGrow={[1, 0.5]}
            minWidth={["auto", 0]}
          >
            <Link href="https://www.nzpost.co.nz/tools/address-postcode-finder">
              Postcode Finder
              <Icon icon="open_in_new" />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

LotNumberDpInformation.defaultProps = {
  focusable: true,
};
