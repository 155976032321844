import { useCallback, useReducer } from "react";
import { ReducerFunction } from "../../types/ReducerFunction";
import { PaginationReducerAction } from "../../types/PaginationReducerAction";

type PaginationState = {
  pages: number[];
  currentPageGroup: number;
};

/***
 *  use to compute page groups, and to compute indices using the page group
 * 	 e.g. 31 items total and each page shows 10 items, then we have 4 total page indices,
 * 	 but we can only show 2 clickable indices(numberOfPages):
 * 	 group 0 -> page index 0, 1
 * 	 group 1 -> page index 2, 3
 * @param numberOfPages page numbers to show on the screen
 * @param totalPages
 */
export const usePaginationFunctions = (
  numberOfPages: number,
  totalPages: number,
) => {
  const computePageGroup = (pageIndex: number) =>
    Math.floor(pageIndex / numberOfPages);

  const computeRemainingPages = (pageGroup: number) => {
    const remainingPages = totalPages - pageGroup * numberOfPages;
    return remainingPages < numberOfPages ? remainingPages : numberOfPages;
  };

  const paginationReducer: ReducerFunction<
    PaginationState,
    PaginationReducerAction
  > = (_state: PaginationState, action: PaginationReducerAction) => {
    const { payload, type } = action;
    switch (type) {
      case "GO_TO_FIRST_PAGE": {
        return {
          currentPageGroup: payload,
          pages: Array(computeRemainingPages(0)).fill(0),
        };
      }
      case "GO_TO_LAST_PAGE": {
        const pageGroup = computePageGroup(payload);

        return {
          currentPageGroup: pageGroup,
          pages: Array(computeRemainingPages(pageGroup)).fill(0),
        };
      }
      case "GO_TO_PAGE": {
        const pageGroup = computePageGroup(payload);

        return {
          currentPageGroup: pageGroup,
          pages: Array(computeRemainingPages(pageGroup)).fill(0),
        };
      }
    }
  };

  const [paginationState, dispatch] = useReducer(paginationReducer, {
    pages: [],
    currentPageGroup: 0,
  });

  const goToFirstPage = useCallback(
    () => dispatch({ type: "GO_TO_FIRST_PAGE", payload: 0 }),
    [],
  );

  const goToLastPage = () =>
    dispatch({ type: "GO_TO_LAST_PAGE", payload: totalPages - 1 });

  const goToPageIndex = (pageIndex: number) =>
    dispatch({ type: "GO_TO_PAGE", payload: pageIndex });

  const getRealPageIndex = (pageGroup: number, pageIndex: number) =>
    pageGroup * numberOfPages + pageIndex;

  return {
    getRealPageIndex,
    goToFirstPage,
    goToLastPage,
    goToPageIndex,
    ...paginationState,
  };
};
