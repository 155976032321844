import React from "react";
import { ActionLink } from "../ActionLink";
import { FieldProps } from "formik";
import { ActionLinkProps } from "../ActionLink/ActionLink";

interface Props {
  label?: string;
}

export const FormikActionLink: React.FC<Props & ActionLinkProps & FieldProps> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ field, form, label, ...props }) => {
    return (
      <ActionLink {...field} {...props}>
        {label}
      </ActionLink>
    );
  };
