import React from "react";
import { Page } from "../../components/Page";
import { LoginOptionScreen } from "../../components/LoginOptionScreen";

interface Props {
  orderType: string;
}

export const LoginPage: React.FC<Props> = ({ orderType }) => {
  return (
    <Page>
      <LoginOptionScreen orderType={orderType} />
    </Page>
  );
};
