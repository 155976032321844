import axios, { AxiosRequestConfig } from "axios";
import env from "../environment";

export const downloadClientConfig: AxiosRequestConfig = {
  baseURL: env.apiBaseUrl,
  responseType: "blob",
  headers: {
    apikey: env.apikey,
    "referrer-policy": "strict-origin-when-cross-origin",
  },
};

export const downloadClient = axios.create(downloadClientConfig);
