import React from "react";
import styled from "styled-components";
import {
  FlexboxProps,
  grid,
  GridProps,
  LayoutProps,
  SpaceProps,
} from "styled-system";
import { Box } from "../Box";

const GridContainer = styled(Box)`
  display: grid;
  ${grid}
`;

export const Grid: React.FC<
  GridProps & FlexboxProps & SpaceProps & LayoutProps
> = ({ children, ...props }) => (
  <GridContainer {...props}>{children}</GridContainer>
);
