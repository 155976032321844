import React from "react";
import { CenteredLoading } from "../CenteredLoading";
import { useWindcaveSession } from "../../hooks/useWindcaveSession/useWindcaveSession";
import { Redirect, useHistory } from "react-router-dom";
import { SomethingWentWrong } from "../SomethingWentWrong/SomethingWentWrong";

export const WindcaveContainer: React.FC = () => {
  const { redirectUrl, apiError } = useWindcaveSession();

  const history = useHistory();

  const handleOnBackClick = () => {
    history.goBack();
  };

  if (apiError) {
    return (
      <SomethingWentWrong
        subHeading="It looks like there was an error processing your payment."
        message="Please try again in a few minutes"
        onBackClick={handleOnBackClick}
        showBackButton
      />
    );
  }

  if (redirectUrl === "/") {
    return <Redirect to={redirectUrl} />;
  }

  if (redirectUrl) {
    window.location.href = redirectUrl;
    return null;
  }

  return <CenteredLoading text="Redirecting" />;
};
