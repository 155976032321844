import React, { useEffect, useState } from "react";
import env from "../../../../environment";
import { UnsubmittedOrdersWithoutPagination } from "../UnsubmittedOrdersWithoutPagination/UnsubmittedOrdersWithoutPagination";
import { UnsubmittedOrder } from "../../../../types/Order";
import { deleteUnSubmittedOrder } from "../../../../services/OrderService";
import { useGetUnsubmittedOrderList } from "../../../../hooks/useGetUnsubmittedOrderList/useGetUnsubmittedOrderList";
import { BasicNotificationBannerProps } from "../../../BasicNotificationBanner/BasicNotificationBanner";
import styled from "styled-components";
import { FloatingBanner } from "../../../FloatingBanner";
import {
  draftOrderDeleteFailure,
  draftOrderDeleteSuccess,
} from "../../../../constants/SnackbarMessages";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

interface Props {
  columns: { Header: string }[];
}

interface DeleteNotificationState extends BasicNotificationBannerProps {
  show: boolean;
}

const WrappedNotificationBanner = styled.div`
  position: fixed;
  left: 2.2vh;
  bottom: 14.7vh;
`;

const RECORDS_PER_PAGE = env.orderRecordsPerPage;

export const UnsubmittedOrdersContainer: React.FC<Props> = ({ columns }) => {
  const deleteNotificationInitState: DeleteNotificationState = {
    show: false,
    title: "",
    message: "",
    variant: "success",
    icon: "check_circle",
  };

  const [retryCount, setRetryCount] = useState(0);

  const [unsubmittedOrders, setUnsubmittedOrders] = useState<
    UnsubmittedOrder[]
  >([]);

  const [deleteNotificationState, setDeleteNotificationState] = useState(
    deleteNotificationInitState,
  );

  const {
    authenticatedUserContext: { userAuth0Details, userBasicDetails },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;

  const orderResponse = useGetUnsubmittedOrderList(
    retryCount,
    RECORDS_PER_PAGE,
  );

  useEffect(() => {
    setUnsubmittedOrders(orderResponse.orderList);
  }, [orderResponse.orderList]);

  const handleRetry = () => setRetryCount((prevState) => prevState + 1);

  const resetBannerState = () =>
    setDeleteNotificationState(deleteNotificationInitState);

  const handleDeleteUnsubmittedOrder = async (order: UnsubmittedOrder) => {
    const index: number = unsubmittedOrders.findIndex(
      (item) => item.orderId === order.orderId,
    );

    // delete only when we have something
    if (index > -1) {
      const deleted = await deleteUnSubmittedOrder(order.orderId, accessToken);

      if (deleted) {
        setUnsubmittedOrders((prev) =>
          prev.filter((item) => item.orderId !== order.orderId),
        );
      }
      setDeleteNotificationState({
        show: true,
        title: deleted
          ? draftOrderDeleteSuccess.title
          : draftOrderDeleteFailure.title,
        message: deleted
          ? draftOrderDeleteSuccess.message
          : draftOrderDeleteFailure.message,
        variant: deleted ? "success" : "error",
        icon: deleted ? "check_circle" : "warning",
      });
    }
  };

  const userFullName =
    userBasicDetails.firstName && userBasicDetails.lastName
      ? `${userBasicDetails.firstName} ${userBasicDetails.lastName}`
      : "";

  return (
    <>
      <WrappedNotificationBanner>
        <FloatingBanner
          visible={deleteNotificationState.show}
          onBannerClose={resetBannerState}
          title={deleteNotificationState.title}
          message={deleteNotificationState.message}
          variant={deleteNotificationState.variant}
          icon={deleteNotificationState.icon}
        />
      </WrappedNotificationBanner>

      <UnsubmittedOrdersWithoutPagination
        orders={unsubmittedOrders}
        ordersCount={unsubmittedOrders.length}
        columns={columns}
        userFullName={userFullName}
        error={orderResponse.apiError}
        onRetry={handleRetry}
        onDeleteUnsubmittedOrder={handleDeleteUnsubmittedOrder}
      />
    </>
  );
};
