import React from "react";
import styled from "styled-components";
import { LayoutProps, SpaceProps } from "styled-system";
import { Flex } from "../Flex";
import { Span } from "../Span";

interface Props {
  userInitials: string;
  userFullName: string;
  userEmail: string;
}

const StyledLogoWrapper = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledNameWrapper = styled(Flex)`
  display: flex;
`;

export const UserLogo = React.forwardRef<
  HTMLDivElement,
  Props & LayoutProps & SpaceProps
>(({ userInitials, userFullName, userEmail, height, width, ml, mr }, ref) => {
  return (
    <>
      <StyledLogoWrapper
        borderRadius="50%"
        backgroundColor="neutrals.mn90"
        width={width || 12}
        height={height || 12}
        ml={ml}
        mr={mr || 2}
        ref={ref}
      >
        <Span
          margin="auto"
          color="neutrals.ln10"
          fontSize={3}
          data-testid="userInitials"
        >
          {userInitials}
        </Span>
      </StyledLogoWrapper>

      <StyledNameWrapper flexDirection="column" my="auto">
        <Span color="nightSky" fontSize={1} data-testid="userFullName">
          {userFullName}
        </Span>

        <Span color="neutrals.mn80" fontSize={0} pt={1} data-testid="userEmail">
          {userEmail}
        </Span>
      </StyledNameWrapper>
    </>
  );
});

UserLogo.displayName = "UserLogo";

UserLogo.defaultProps = {
  userInitials: "G",
  userFullName: "Guest",
  userEmail: "Limited features",
};
