import React from "react";
import { getOrderDetailsRolesTitle } from "../../../ContactDetailsRolesTitle/ContactDetailsRolesTitle";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { OrderDetailsContactDetails } from "../../../../types/OrderDetailsContactDetails";
import { ContactDetailsDisplay } from "../../../ContactDetailsDisplay";

interface Props {
  contactDetails: OrderDetailsContactDetails[];
}

export const OrderContactDetails: React.FC<Props> = ({ contactDetails }) => {
  return (
    <Flex flexDirection="column">
      <Span
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mb={5}
        alignSelf="flex-start"
      >
        Contact details
      </Span>

      {contactDetails.map((contact, index) => (
        <Flex
          key={`${contact.firstName} + ${contact.lastName} + ${index}`}
          flexDirection="column"
        >
          <Span lineHeight={2} fontWeight="bold" mb={3}>
            {getOrderDetailsRolesTitle(contact.roles)}
          </Span>

          <ContactDetailsDisplay
            firstName={contact.firstName}
            lastName={contact.lastName}
            email={contact.email}
            mobile={contact.mobile}
            alternatePhone={contact.alternatePhone}
            businessName={contact.businessName}
            billingAddress={contact.postalAddress}
            workContactPreferences={contact.workContactPreferences}
            quoteContactPreferences={contact.quoteContactPreferences}
          />
        </Flex>
      ))}
    </Flex>
  );
};
