import React from "react";
import { Title } from "../Title";
import { Flex } from "../Flex";
import { Span } from "../Span";
import { Field, Form, Formik } from "formik";
import { FormikRadioButton } from "../FormikRadioButton";
import { ServiceTypeElectricity } from "../ServiceTypeElectricity";
import { ServiceTypeFormValues } from "../../types/ServiceTypeFormValues";
import { useHistory } from "react-router-dom";
import { ServiceTypeGas } from "../ServiceTypeGas";
import { ServiceTypeConsentApprovals } from "../ServiceTypeConsentApprovals";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

interface Props {
  id: string;
  initialValues?: ServiceTypeFormValues;
}

export const ServiceTypeForm: React.FC<Props> = ({ initialValues, id }) => {
  const history = useHistory();

  const {
    authenticatedUserContext: { isLoggedIn },
  } = useUserAuthentication();

  const handleSubmit = (values: ServiceTypeFormValues) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const {
      service,
      electricityServiceType,
      gasServiceType,
      consentApprovalsType,
    } = values;

    switch (service) {
      case "electricity":
        switch (electricityServiceType) {
          case "livening":
            history.push(
              isLoggedIn ? "/icp-request" : "/login-option/icp-request",
            );
            break;

          case "newConnection":
            history.push(
              isLoggedIn ? "/new-connection" : "/login-option/new-connection",
            );
            break;

          case "electricityDisconnection":
            window.open("https://my.vector.co.nz/decommissionicp");
            break;

          case "reportPowerOutage":
            window.open("http://help.vector.co.nz/");
            break;

          case "referenceMaps":
            window.open(
              "https://www.vector.co.nz/personal/help-safety/near-our-network/reference-plans",
            );
            break;
        }
        break;

      case "gas":
        switch (gasServiceType) {
          case "newResidentialConnection":
            window.open(
              "https://www.vector.co.nz/personal/get-connected/gas-estimator",
            );
            break;

          case "gasDisconnection":
            window.open("https://my.vector.co.nz/decommissionicp");
            break;

          case "referenceMaps":
            window.open(
              "https://www.vector.co.nz/personal/help-safety/near-our-network/reference-plans",
            );
            break;
        }
        break;

      case "consent_approvals":
        switch (consentApprovalsType) {
          case "highLoadPermit":
            window.open(
              "https://www.vector.co.nz/personal/help-safety/near-our-network/high-load-permit",
            );
            break;
        }
        break;

      /* istanbul ignore next */
      default:
        break;
    }
  };

  return (
    <Formik
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      initialValues={initialValues!}
      onSubmit={handleSubmit}
    >
      {({ values, submitForm }) => (
        <Form>
          <Flex flexDirection="column" id={id}>
            <Title as="h1" lineHeight={7} fontWeight="500">
              New service order
            </Title>

            <Span
              display="inline-block"
              fontSize={4}
              lineHeight={4}
              fontWeight="medium"
              mt={2}
              mb={3}
            >
              What service do you need?
            </Span>

            <Flex
              flexDirection={["column", "column", "row"]}
              justifyContent="space-between"
            >
              <Field
                id="electricity"
                name="service"
                label="Electricity"
                value="electricity"
                currentValue={values.service}
                magnitude={["large", "large", "medium", "xLarge"]}
                flexGrow={0.33}
                flexBasis={0}
                minWidth={0}
                mr={[0, 0, 2]}
                mb={[2, 2, 0]}
                icon="flash_on"
                component={FormikRadioButton}
              />

              <Field
                id="gas"
                name="service"
                label="Gas"
                value="gas"
                currentValue={values.service}
                magnitude={["large", "large", "medium", "xLarge"]}
                flexGrow={0.33}
                flexBasis={0}
                minWidth={0}
                mx={[0, 0, 1]}
                my={[1, 1, 0]}
                icon="local_fire_department"
                fontFamily="Material Icons Outlined"
                component={FormikRadioButton}
              />

              <Field
                id="consent_approvals"
                name="service"
                label={"Consents & Approvals"}
                value="consent_approvals"
                currentValue={values.service}
                magnitude={["large", "large", "medium", "xLarge"]}
                flexGrow={0.33}
                flexBasis={0}
                minWidth={0}
                ml={[0, 0, 2]}
                my={[2, 2, 0]}
                icon="task"
                fontFamily="Material Icons Outlined"
                component={FormikRadioButton}
              />
            </Flex>

            {values.service === "electricity" && (
              <ServiceTypeElectricity
                values={values}
                onServiceTypeSelected={submitForm}
              />
            )}

            {values.service === "gas" && (
              <ServiceTypeGas
                values={values}
                onServiceTypeSelected={submitForm}
              />
            )}

            {values.service === "consent_approvals" && (
              <ServiceTypeConsentApprovals
                values={values}
                onServiceTypeSelected={submitForm}
              />
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

ServiceTypeForm.defaultProps = {
  initialValues: {
    service: "",
    electricityServiceType: "",
    gasServiceType: "",
    consentApprovalsType: "",
  },
};
