import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  border,
  color,
  layout,
  space,
  typography,
  ResponsiveValue,
} from "styled-system";
import React, { ButtonHTMLAttributes, useRef, useState } from "react";
import * as CSS from "csstype";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Label } from "../Label";
import { Span } from "../Span";
import styled from "styled-components";
import { useSelect } from "downshift";
import { IconPopupTip } from "../IconPopupTip";

export interface DropdownProps {
  label: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  displayLabel?: ResponsiveValue<CSS.Property.Display, any>;
  items: string[];
  visibleItems: number;
  message?: string;
  error?: boolean;
  optional?: boolean;
  tooltip?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  displayTooltip?: ResponsiveValue<CSS.Property.Display, any>;
  onSelect: (selected?: string | null) => void;
}

const InputWrapper = styled(Flex)<ColorProps & { disabled: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  background-color: ${({ disabled, theme }) =>
    disabled && theme.colors.neutrals.ln30};

  box-sizing: border-box;
  border-style: solid;
`;

const StyledSelectButton = styled.button<
  DropdownProps & BorderProps & LayoutProps & TypographyProps & ColorProps
>`
  ${border}
  ${layout}
  ${space}
  ${typography}
  ${color}
  cursor: pointer;
  text-decoration: none;
  border: none;
  appearance: none;
  outline: none;
  font-family: inherit;

  text-align: left;
  flex-grow: 1;
  background: none;

  width: min-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :hover,
  :focus,
  :focus-visible,
  :focus-within,
  :active {
    outline: none;
    background: none;
  }

  :enabled:focus {
    color: ${({ theme }) => theme.colors.nightSky};
  }
`;

const StyledToggleButton = styled.button<
  DropdownProps & BorderProps & SpaceProps
>`
  ${border}
  ${space}
  background: ${({ disabled }) => (disabled ? "" : "none")};
  border: none;
  outline: none;
  padding-right: 16px;
  flex-grow: 0;
  cursor: pointer;
  min-height: 56px;
`;

const StyledOptionalMessage = styled(Span)`
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.neutrals.mn60};
`;

const StyledMessage = styled(Span)<{ error?: boolean }>`
  align-self: flex-start;
  color: ${({ error, theme }) =>
    error ? theme.colors.urgentRed : theme.colors.neutrals.mn60};
`;

const StyledItemsContainer = styled.ul<BorderProps & LayoutProps>`
  ${border}
  ${layout}

  overflow: auto;
  position: absolute;
  z-index: 1;
  margin: 0;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-style: solid;
  border-width: 2px;
  background-color: ${({ theme }) => theme.colors.neutrals.ln0};
  border-top: 0;
  list-style: none;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutrals.ln30};
    border-radius: 6px;
  }

  outline: none;
`;

const StyledItem = styled.li<ColorProps & SpaceProps & TypographyProps>`
  ${color}
  ${space}
  ${typography}

  background: none;
  position: relative;
  cursor: pointer;
  display: block;
  border-top: 2px solid ${({ theme }) => theme.colors.neutrals.ln10};
  height: auto;
  text-align: left;
  color: ${({ theme }) => theme.colors.nightSky};
  text-transform: none;
  box-shadow: none;
  whitespace: normal;
  wordwrap: normal;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  :last-child {
    border-radius: 0 0 6px 6px;
  }
`;

export const Dropdown: React.FC<
  DropdownProps &
    ColorProps &
    FlexboxProps &
    LayoutProps &
    SpaceProps &
    TypographyProps &
    Omit<
      ButtonHTMLAttributes<HTMLButtonElement>,
      "onSelect" | "width" | "height" | "size"
    >
> = ({
  id,
  name,
  label,
  displayLabel,
  optional,
  placeholder,
  message,
  error,
  disabled,
  onSelect,
  onFocus,
  onBlur,
  visibleItems,
  value,
  items,
  tooltip,
  displayTooltip,
  width,
  minWidth,
  flexBasis,
  flexGrow,
  my,
  mr,
  mb,
}) => {
  const MENU_ITEM_HEIGHT_PIXELS = 60;

  const [hasFocus, setHasFocus] = useState(false);

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem);
    },
  });

  const handleInputOnFocus = () => {
    setHasFocus(true);
  };

  const handleInputOnBlur = () => {
    setHasFocus(false);
  };

  const inputWrapperRef = useRef(null);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const containerWidth = inputWrapperRef.current as any;

  return (
    <Box
      mr={mr}
      my={my}
      mb={mb}
      flexGrow={flexGrow}
      flexBasis={flexBasis}
      minWidth={minWidth}
    >
      {(label || optional) && (
        <Flex pl={2} pr={2} mb={1}>
          {label && (
            <>
              <Label
                {...getLabelProps({
                  id: `${id}-label`,
                })}
                htmlFor={id}
                color="nightSky"
                fontSize="body"
                lineHeight="body"
                display={displayLabel}
                data-testid={`${id}-label-testid`}
              >
                {label}
              </Label>

              {tooltip && (
                <IconPopupTip
                  display={displayTooltip}
                  icon="help"
                  ml="auto"
                  data-testid={`${id}-popuptip-testid`}
                >
                  {tooltip}
                </IconPopupTip>
              )}
            </>
          )}

          {optional && (
            <StyledOptionalMessage
              ml="auto"
              mr={2}
              fontSize={1}
              lineHeight={1}
              data-testid={`${id}-optional-testid`}
            >
              Optional
            </StyledOptionalMessage>
          )}
        </Flex>
      )}

      <InputWrapper
        ref={inputWrapperRef}
        flexDirection="row"
        width={width}
        borderWidth={2}
        borderRadius="input"
        borderBottomLeftRadius={isOpen ? "0" : "input"}
        borderBottomRightRadius={isOpen ? "0" : "input"}
        borderColor={
          error ? "urgentRed" : hasFocus || isOpen ? "hydro" : "neutrals.ln40"
        }
        borderBottom={isOpen ? "0" : ""}
        boxShadow={hasFocus && !(error || isOpen) ? "inputFocus" : ""}
        disabled={disabled}
      >
        <StyledSelectButton
          {...getToggleButtonProps({
            id: `${id}-select`,
            "aria-labelledby": `${id}-label`,
            type: "button",
            onFocus: handleInputOnFocus,
            onBlur: handleInputOnBlur,
          })}
          fontSize={2}
          lineHeight={2}
          py={3}
          pl={3}
          pr={0}
          name={name}
          disabled={disabled}
          error={error}
          data-testid={`${id}-select-testid`}
          color="nightSky"
        >
          {value || selectedItem || placeholder}
        </StyledSelectButton>

        <StyledToggleButton
          {...getToggleButtonProps({
            id: `${id}-toggle-button`,
            type: "button",
          })}
          variant="lowAffordance"
          lineHeight={2}
          pl={2}
          pr={3}
          disabled={disabled}
          aria-label="reset"
          data-testid={`${id}-toggle-testid`}
        >
          <Icon
            fontSize={5}
            color="neutrals.dn40"
            icon={isOpen ? "expand_less" : "expand_more"}
          />
        </StyledToggleButton>
      </InputWrapper>

      <StyledItemsContainer
        {...getMenuProps({
          id: `${id}-menu`,
          onBlur: onBlur,
          onFocus: onFocus,
        })}
        hidden={!isOpen || !items.length}
        borderColor={isOpen && error ? "urgentRed" : isOpen ? "hydro" : ""}
        borderBottomLeftRadius="input"
        borderBottomRightRadius="input"
        maxHeight={`${visibleItems * MENU_ITEM_HEIGHT_PIXELS}px`}
        overflowY={items.length > visibleItems ? "scroll" : "hidden"}
        tabIndex={-1}
        width={`${containerWidth?.offsetWidth - 4}px`}
        data-testid={`${id}-menu-testid`}
      >
        {items.map((item: string, index: number) => (
          <StyledItem
            fontSize={1}
            lineHeight={2}
            p={3}
            key={index}
            {...getItemProps({
              id: `${id}-menu-item-${index}`,
              item,
              index,
            })}
          >
            {item}
          </StyledItem>
        ))}
      </StyledItemsContainer>

      {!isOpen && message && (
        <StyledMessage
          fontSize={1}
          lineHeight={1}
          pl={2}
          pr={2}
          error={error}
          data-testid={`${id}-message-testid`}
        >
          {message}
        </StyledMessage>
      )}
    </Box>
  );
};

Dropdown.defaultProps = {
  placeholder: "Select",
  visibleItems: 5,
  displayLabel: "inline-block",
  displayTooltip: "flex",
};
