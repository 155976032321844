import {
  border,
  BorderProps,
  color,
  ColorProps,
  FlexGrowProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import React, { ReactElement, useEffect, useState } from "react";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Label } from "../Label";
import { Span } from "../Span";
import styled from "styled-components";
import { useCombobox } from "downshift";
import ComboBoxStateChangeType from "../../types/ComboBoxStateChangeType";
import mapComboBoxStateChangeType from "./_/ComboBoxStateChangeTypeMapper";

interface Props {
  optional?: boolean;
  label: string;
  message?: ReactElement | string;
  items: string[];
  visibleItems: number;
  valueLengthLookupTrigger: number;
  error?: boolean;
  noResultsMessage?: string;
  onSelect: (selected?: string | null) => void;
  onChange: (value?: string) => void;
}

const LabelWrapper = styled(Label)<ColorProps & { disabled: boolean }>`
  color: ${({ disabled, theme }) => disabled && theme.colors.disabledColor};
`;

const InputWrapper = styled(Flex)<ColorProps & { disabled: boolean }>`
  background-color: ${({ disabled, theme }) =>
    disabled && theme.colors.disabledBackground};

  box-sizing: border-box;
  border-style: solid;
`;

const StyledInput = styled.input<Props & SpaceProps & TypographyProps>`
  ${space}
  ${typography}
  font-family: inherit;
  color: ${({ value, theme }) =>
    value === "" ? theme.colors.neutrals.mn40 : theme.colors.nightSky};
  border: none;
  outline: none;
  background: none;
  flex-grow: 1;

  :focus,
  :focus-visible,
  :focus-within {
    outline: none;
  }

  :enabled:focus {
    ::placeholder {
      color: ${({ theme }) => theme.colors.nightSky};
    }
  }

  :disabled {
    color: ${({ theme }) => theme.colors.disabledColor};
  }
`;

const StyledButton = styled.button`
  ${space}
  background: none;
  border: none;
  outline: none;
  flex-grow: 0;
  cursor: pointer;

  :active,
  :hover {
    background: none;
  }

  :active,
  :focus {
    border: none;
  }

  :focus {
    box-shadow: none;
  }
`;

const StyledOptionalMessage = styled(Span)`
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.neutrals.mn60};
`;

const StyledMessage = styled(Span)<{ error?: boolean }>`
  align-self: flex-start;
  color: ${({ error, theme }) =>
    error ? theme.colors.urgentRed : theme.colors.neutrals.mn60};
`;

const StyledItemsContainer = styled.ul<BorderProps & LayoutProps>`
  ${border}
  ${layout}

  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-style: solid;
  border-width: 2px;
  background-color: ${({ theme }) => theme.colors.neutrals.ln0};
  border-top: 0;
  list-style: none;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutrals.ln30};
    border-radius: 6px;
  }

  outline: none;
`;

const StyledItem = styled.li<ColorProps & SpaceProps & TypographyProps>`
  ${color}
  ${space}
  ${typography}

  background: none;
  position: relative;
  cursor: pointer;
  display: block;
  border-top: 2px solid ${({ theme }) => theme.colors.neutrals.ln10};
  height: auto;
  text-align: left;
  color: ${({ theme }) => theme.colors.nightSky};
  text-transform: none;
  box-shadow: none;
  whitespace: normal;
  wordwrap: normal;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  :last-child {
    border-radius: 0 0 6px 6px;
  }
`;

export const LookupComboBox: React.FC<
  Props &
    ColorProps &
    TypographyProps &
    FlexGrowProps &
    SpaceProps &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "onSelect">
> = ({
  id,
  name,
  label,
  optional,
  placeholder,
  message,
  visibleItems,
  error,
  value,
  disabled,
  noResultsMessage,
  items,
  valueLengthLookupTrigger,
  flexGrow,
  onSelect,
  onChange,
  onFocus,
  onBlur,
}) => {
  const MENU_ITEM_HEIGHT_PIXELS = 60;

  const [hasFocus, setHasFocus] = useState(false);
  const [menuItems, setMenuItems] = useState<string[]>(items);

  const {
    inputValue,
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    setInputValue,
  } = useCombobox({
    defaultSelectedItem: "",
    items: menuItems,
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem);
    },
    onInputValueChange: ({ inputValue, selectedItem, type }) => {
      const changeType = mapComboBoxStateChangeType(type);

      if (
        changeType === ComboBoxStateChangeType.InputChange &&
        inputValue !== selectedItem
      ) {
        onChange(inputValue);
      }

      if (changeType === ComboBoxStateChangeType.ItemClick) {
        onSelect(inputValue);
      }

      if (changeType === ComboBoxStateChangeType.ToggleButtonClick) {
        onChange("");
      }

      if (!inputValue && !selectedItem) {
        onChange(inputValue);
      }
    },
  });

  useEffect(() => {
    if (
      inputValue &&
      inputValue.length >= valueLengthLookupTrigger &&
      items.length === 0 &&
      noResultsMessage
    ) {
      items.push(noResultsMessage);
    }

    setMenuItems(items);
  }, [inputValue, valueLengthLookupTrigger, items, noResultsMessage]);

  // Can't use isOpen alone to toggle menu because we may not have any items to display
  // Downshift automatically sets isOpen once input is changed
  // This, however, does not mean there are items to display yet
  const showMenu =
    inputValue &&
    inputValue.length >= valueLengthLookupTrigger &&
    menuItems.length > 0 &&
    isOpen;

  const handleResetClick = () => {
    setInputValue("");
    onChange("");
  };

  const handleInputOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(true);

    if (onFocus) {
      onFocus(event);
    }
  };

  const handleInputOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <>
      <Flex flexDirection="column" flexGrow={flexGrow}>
        {(label || optional) && (
          <Flex
            pl={2}
            pr={2}
            mb={1}
            flexDirection="row"
            justifyContent="space-between"
          >
            {label && (
              <LabelWrapper
                {...getLabelProps({
                  id: `${id}-label`,
                })}
                htmlFor={id}
                mr="auto"
                disabled={disabled}
                color="nightSky"
                fontSize={2}
                lineHeight={2}
              >
                {label}
              </LabelWrapper>
            )}

            {optional && (
              <StyledOptionalMessage fontSize={1} lineHeight={1}>
                Optional
              </StyledOptionalMessage>
            )}
          </Flex>
        )}

        <InputWrapper
          position="relative"
          flexDirection="row"
          width="100%"
          bg="neutrals.ln0"
          borderWidth={2}
          borderRadius="input"
          borderBottomLeftRadius={showMenu ? "0" : "input"}
          borderBottomRightRadius={showMenu ? "0" : "input"}
          borderColor={
            error
              ? "urgentRed"
              : hasFocus || showMenu
              ? "hydro"
              : "neutrals.ln40"
          }
          borderBottom={showMenu ? "0" : ""}
          boxShadow={hasFocus && !(error || showMenu) ? "inputFocus" : ""}
          disabled={disabled}
          {...getComboboxProps()}
        >
          <StyledInput
            {...getInputProps({
              onFocus: handleInputOnFocus,
              onBlur: handleInputOnBlur,
              autoComplete: "off",
              value: value,
            })}
            padding={3}
            fontSize={2}
            lineHeight={2}
            id={id}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            error={error}
            data-error={error} // Expose the data attribute for acceptance tests
          />
          <StyledButton
            {...getToggleButtonProps({
              id: `${id}-reset-button`,
              onClick: handleResetClick,
              type: "button",
            })}
            variant="lowAffordance"
            lineHeight={2}
            pl={2}
            pr={3}
            disabled={disabled}
            aria-label="reset"
          >
            <Icon fontSize={5} color="neutrals.dn40" icon={"close"} />
          </StyledButton>
        </InputWrapper>

        {!showMenu && message && (
          <StyledMessage fontSize={1} lineHeight={1} px={2} error={error}>
            {message}
          </StyledMessage>
        )}
      </Flex>

      <StyledItemsContainer
        {...getMenuProps({
          id: `${id}-menu`,
        })}
        hidden={!showMenu || !menuItems.length}
        borderColor={showMenu && error ? "urgentRed" : showMenu ? "hydro" : ""}
        borderBottomLeftRadius="input"
        borderBottomRightRadius="input"
        maxHeight={`${visibleItems * MENU_ITEM_HEIGHT_PIXELS}px`}
        overflowY={menuItems.length > visibleItems ? "scroll" : "hidden"}
      >
        {showMenu &&
          menuItems.map((item: string, index: number) => (
            <StyledItem
              fontSize={1}
              lineHeight={2}
              p={3}
              key={index}
              {...getItemProps({
                id: `${id}-menu-item-${index}`,
                item,
                index,
                disabled: item === noResultsMessage,
              })}
            >
              {item}
            </StyledItem>
          ))}
      </StyledItemsContainer>
    </>
  );
};

LookupComboBox.defaultProps = {
  placeholder: "Select",
  noResultsMessage: "No results",
  visibleItems: 5,
  valueLengthLookupTrigger: 3,
};
