import React, { ImgHTMLAttributes } from "react";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

const Component = styled.img<
  LayoutProps & SpaceProps & ImgHTMLAttributes<HTMLImageElement>
>`
  ${layout}
  ${space}
`;

export const VectorLogo: React.FC<
  LayoutProps & SpaceProps & ImgHTMLAttributes<HTMLImageElement>
> = ({ ...props }) => <Component alt="logo" {...props} />;
