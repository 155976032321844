import React from "react";
import { Flex } from "../Flex";
import { Title } from "../Title";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { Span } from "../Span";
import { FaqAccordionGroup } from "./_/FaqAccordionGroup";
import { FaqGetSupport } from "./_/FaqGetSupport";
import { FaqValues } from "../../constants/FaqValues";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.largeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.largeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

export const HelpCentre: React.FC = () => {
  return (
    <GridWrapper mx={[2, 2, 4, 4, 0]}>
      <FlexWrapper flexDirection="column">
        <Title as="h1" lineHeight={6} fontWeight="500" mb={6} pt={7}>
          Help centre
        </Title>

        <Span
          display="inline-block"
          fontSize={3}
          lineHeight={3}
          fontWeight="500"
          mb={5}
        >
          Please take a look at the frequently asked questions below.
        </Span>

        <FaqAccordionGroup faqs={FaqValues} />

        <FaqGetSupport />
      </FlexWrapper>
    </GridWrapper>
  );
};
