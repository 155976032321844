import React from "react";
import styled from "styled-components";
import { color, ColorProps, layout, LayoutProps } from "styled-system";
import { theme } from "../../theme";

interface Props {
  percentageComplete: string;
  color?: string;
}

const Background = styled.div`
  height: 8px;
  width: 100%;
  background: ${theme.colors.neutrals.ln20};
  border-radius: 4px;
`;

const ProgressBar = styled.div<LayoutProps>`
  ${color}
  ${layout}
  height: 100%;
  background: currentColor;
  border-radius: 4px;
`;

export const OrderProgressBar: React.FC<Props & ColorProps> = ({
  color,
  percentageComplete,
}) => {
  return (
    <Background>
      <ProgressBar color={color} width={`${percentageComplete}%`} />
    </Background>
  );
};

OrderProgressBar.defaultProps = {
  color: theme.colors.hydroDark,
};
