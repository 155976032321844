import { FileTypeValues } from "../../../../constants/FileTypeValues";

export const getFileNameWithoutExtension = (fileName: string): string => {
  if (!fileName) {
    return "";
  }

  const index = fileName.lastIndexOf(".");
  if (index === -1) {
    return fileName;
  }

  return fileName.substring(0, index);
};

export const getFileExtension = (fileName: string): string => {
  if (!fileName) {
    return "";
  }

  const index = fileName.lastIndexOf(".");
  if (index === -1) {
    return "";
  }

  return fileName.substring(index + 1);
};

export const getAcceptedMimeTypes = (): FileType[] => {
  return FileTypeValues;
};

/* istanbul ignore next */
export const mockArrayBuffer = (): Promise<ArrayBuffer> => {
  //eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Promise<ArrayBuffer>(() => {});
};

/* istanbul ignore next */
export const mockSlice = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  start?: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  end?: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contentType?: string,
): Blob => {
  return new Blob();
};

/* istanbul ignore next */
export const mockStream = (): ReadableStream => {
  return new ReadableStream();
};

/* istanbul ignore next */
export const mockText = (): Promise<string> => {
  //eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Promise<string>(() => {});
};
