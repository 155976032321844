import React from "react";
import { ErrorMessage, Field } from "formik";
import { Span } from "../../../Span";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import { Flex } from "../../../Flex";
import { FormikRadioButton } from "../../../FormikRadioButton";
import { OrderDetailsCancelFormValues } from "../../../../types/OrderDetailsCancelFormValues";

interface Props {
  id: string;
  values: OrderDetailsCancelFormValues;
}

export const OrderDetailCancelFormFields: React.FC<Props> = ({ values }) => {
  return (
    <Flex flexDirection="column">
      <Span
        display="inline-block"
        fontSize={3}
        lineHeight={3}
        fontWeight="500"
        mb={5}
      >
        Please note we will review your request to cancel this order. If you
        have already paid the quote to complete the works, we will organise a
        refund minus any costs incurred. A cancellation fee of $100 may apply.
      </Span>

      <Span
        display="inline-block"
        fontSize={3}
        lineHeight={3}
        fontWeight="500"
        mb={4}
      >
        Reason for cancelling:
      </Span>

      <Flex flexDirection="column">
        <Flex flexDirection="column" mb={3}>
          <Field
            magnitude="small"
            alignText="left"
            label="No longer required"
            id="No longer required"
            name="cancelledReason"
            value="No longer required"
            currentValue={values.cancelledReason}
            component={FormikRadioButton}
            data-testid="firstReason"
          />
        </Flex>
        <Flex flexDirection="column" mb={3}>
          <Field
            magnitude="small"
            alignText="left"
            label="Replaced by new order"
            id="Replaced by new order"
            name="cancelledReason"
            value="Replaced by new order"
            currentValue={values.cancelledReason}
            component={FormikRadioButton}
            data-testid="secondReason"
          />
        </Flex>
        <Flex flexDirection="column" mb={3}>
          <Field
            magnitude="small"
            alignText="left"
            label="Requested by accident"
            id="Requested by accident"
            name="cancelledReason"
            value="Requested by accident"
            currentValue={values.cancelledReason}
            component={FormikRadioButton}
            data-testid="thirdReason"
          />
        </Flex>
        <Flex flexDirection="column">
          <Field
            magnitude="small"
            alignText="left"
            label="Cost too high"
            id="Cost too high"
            name="cancelledReason"
            value="Cost too high"
            currentValue={values.cancelledReason}
            component={FormikRadioButton}
            data-testid="fourthReason"
          />
        </Flex>
        <ErrorMessage
          name="cancelledReason"
          className="field-error"
          component={FormInputErrorMessage}
        />
      </Flex>
    </Flex>
  );
};
