import React, { useEffect, useState } from "react";
import { Flex } from "../Flex";
import { Title } from "../Title";
import { Span } from "../Span";
import { IconPopupTip } from "../IconPopupTip";
import { ContactDetailFields } from "../ContactDetailsForm/ContactDetailFields";
import { ErrorMessage, Field } from "formik";
import { FormikRadioButton } from "../FormikRadioButton";
import { CompleteProfileFormValues } from "../../types/CompleteProfileFormValues";
import { CompanyNzbn } from "../CompanyNzbn/CompanyNzbn";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import styled from "styled-components";
import { ColorProps } from "styled-system";
import { BillingAddressSection } from "../BillingAddressSection";
import {
  checkIsManualAddress,
  checkIsSearchedAddress,
} from "../ManualAddressHelperUtil/ManualAddressHelperUtil";

interface Props {
  values: CompleteProfileFormValues;
  validateField: (field: string) => void;
}

const JoiningMessage = styled(Span)<ColorProps>`
  color: ${({ theme }) => theme.colors.neutrals.mn60};
`;

export const CompleteProfileDetailsForm: React.FC<Props> = ({
  values,
  validateField,
}) => {
  const profileType = values?.profile?.type;
  const prefix = "profile";

  const isPersonalAccount = values.profile.type === "personal";
  const isBusinessAccount = values.profile.type === "business";
  const isInvitedUser = values.profile.isInvitedUser === "true";
  const isNewBusinessAccount = isBusinessAccount && !isInvitedUser;

  const billingAddressInfoMessage =
    isPersonalAccount || isNewBusinessAccount
      ? "You will need to contact Vector if you need to change this information in the future"
      : "";

  const businessNameInfoMessage = isNewBusinessAccount
    ? "You will need to contact Vector if you need to change this information in the future"
    : "";

  const businessNumberInfoMessage = isNewBusinessAccount
    ? "You will need to contact Vector if you need to change this information in the future"
    : "";

  const [isManualAddress, setIsManualAddress] = useState(false);

  const handleActionLinkClick = () => {
    // Manually trigger Formik field validation for street address because action link has no blur
    validateField("profile.billingAddressDisplay");

    // Toggle manuallyEnteredAddress in Formik values to manage field validation
    values.profile.manuallyEnteredBillingAddress =
      !values.profile.manuallyEnteredBillingAddress;

    setIsManualAddress((prevState) => !prevState);
  };

  useEffect(() => {
    if (values.profile.manuallyEnteredBillingAddress) {
      setIsManualAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //the address is manual entered
    if (checkIsManualAddress(isManualAddress, true)) {
      values.profile.billingAddressDisplay = "";
      values.profile.billingAddressId = "";
    }

    //the address is searched
    if (checkIsSearchedAddress(isManualAddress, false)) {
      values.profile.manualBillingAddress.addressString = "";
      values.profile.manualBillingAddress.city = "";
      values.profile.manualBillingAddress.suburb = "";
      values.profile.manualBillingAddress.postcode = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualAddress]);

  return (
    <Flex flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="500">
        Complete your profile
      </Title>

      <Flex
        justifyContent={[
          "space-between",
          "space-between",
          "space-between",
          "flex-start",
        ]}
        mt={[2, 2, 2, 0, 0]}
        mb={3}
      >
        <Span
          display="inline-block"
          fontSize={4}
          lineHeight={4}
          fontWeight="medium"
          mt={2}
          mb={3}
        >
          Choose an account that suits your needs.
        </Span>
        <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
          The first person who creates a business account, becomes the admin for
          that account. They can add or remove employees to and from this
          account by emailing Vector. All employees who have access to the
          account will have visibility of all orders. Personal accounts cannot
          be shared with others.
        </IconPopupTip>
      </Flex>

      <Flex flexDirection="column">
        <Flex flexDirection={"row"} mb={4}>
          <Flex
            flexDirection="column"
            flexGrow={0.5}
            flexBasis={0}
            minWidth={0}
            mr={2}
            mb={[4, 4, 0]}
          >
            <Field
              magnitude="medium"
              id="personal"
              name={`${prefix}.type`}
              label="Personal account"
              value="personal"
              currentValue={profileType}
              component={FormikRadioButton}
            />
          </Flex>

          <Flex
            flexDirection="column"
            flexGrow={0.5}
            flexBasis={0}
            minWidth={0}
            ml={[0, 0, 2]}
            mb={[4, 4, 0]}
          >
            <Field
              magnitude="medium"
              id="business"
              name={`${prefix}.type`}
              label="Business Account"
              value="business"
              currentValue={profileType}
              component={FormikRadioButton}
            />
          </Flex>
        </Flex>
      </Flex>

      {/*business account type*/}
      {isBusinessAccount && (
        <>
          <Flex
            justifyContent={[
              "space-between",
              "space-between",
              "space-between",
              "flex-start",
            ]}
            mt={[2, 2, 2, 0, 0]}
            mb={3}
          >
            <Span
              display="inline-block"
              fontSize={4}
              lineHeight={4}
              fontWeight="medium"
              mt={2}
              mb={3}
            >
              Is this a new business account or have you been invited to join an
              existing business account?
            </Span>
            <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
              A business admin may invite additional users to their business
              account. An email invitation will be sent to the new user with
              instructions on how to join the existing account.
            </IconPopupTip>
          </Flex>

          <Flex flexDirection="column">
            <Flex flexDirection={"row"} mb={4}>
              <Flex
                flexDirection="column"
                flexGrow={0.5}
                flexBasis={0}
                minWidth={0}
                mr={2}
                mb={[4, 4, 0]}
              >
                <Field
                  magnitude="medium"
                  id="new-business-account"
                  name={`${prefix}.isInvitedUser`}
                  label="Creating new account"
                  value={"false"}
                  currentValue={values.profile.isInvitedUser}
                  component={FormikRadioButton}
                />
                <ErrorMessage
                  name={`${prefix}.isInvitedUser`}
                  className="field-error"
                  component={FormInputErrorMessage}
                />
              </Flex>

              <Flex
                flexDirection="column"
                flexGrow={0.5}
                flexBasis={0}
                minWidth={0}
                ml={[0, 0, 2]}
                mb={[4, 4, 0]}
              >
                <Field
                  magnitude="medium"
                  id="invited-business-account"
                  name={`${prefix}.isInvitedUser`}
                  label="Joining existing account"
                  value={"true"}
                  currentValue={values.profile.isInvitedUser}
                  component={FormikRadioButton}
                />
                <JoiningMessage mx={2} mt={1} fontSize={1} lineHeight={1}>
                  You can only join an existing account if you have received an
                  invitation email from the account admin
                </JoiningMessage>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}

      <ContactDetailFields
        focusable={true}
        idPrefix={prefix}
        namePrefix={prefix}
        optionalAlternatePhone
        showEmail={false}
        showBusinessName={isBusinessAccount}
        businessNameInfoMessage={businessNameInfoMessage}
      />

      {isBusinessAccount && values.profile.isInvitedUser === "false" && (
        <CompanyNzbn prefix={prefix} message={businessNumberInfoMessage} />
      )}

      {(values.profile.isInvitedUser === "false" || !isBusinessAccount) && (
        <BillingAddressSection
          fieldIdPrefix={"profile"}
          fieldNamePrefix={"profile"}
          shouldDisableContactDetails={false}
          isManualAddress={isManualAddress}
          validateField={validateField}
          handleActionLinkClick={handleActionLinkClick}
          infoMessage={billingAddressInfoMessage}
        />
      )}
    </Flex>
  );
};
