import React from "react";
import { FieldProps, getIn } from "formik";
import { FileUploadProps } from "../FileUpload/FileUploadContainer";
import { FileUpload } from "../FileUpload";
import { UploadUnitValues } from "../../types/UploadUnitValues";

export const FormikFileUpload: React.FC<FieldProps & FileUploadProps> = ({
  field,
  form,
  ...props
}) => {
  const handleOnBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  const handleOnChange = (units: UploadUnitValues[]) => {
    form.setFieldValue(field.name, units);
    form.validateField(field.name);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <FileUpload
      {...field}
      {...props}
      error={touch && error ? error : false}
      errorMessage={error}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
    />
  );
};
