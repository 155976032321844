import { ContactPreferences } from "../../../../types/ContactDetailsValues";
import { NewConnectionRoles } from "../../../../types/ContactDetailRoles";

export const newConnectionFormMobileCheck = (
  quoteContactPreference: ContactPreferences,
  workContactPreferences: ContactPreferences,
  roles: NewConnectionRoles,
  alternatePhone: string,
): boolean => {
  const requiredRoles =
    roles.siteAccessContactRole || roles.quotePayerRole || roles.requester;

  const alternatePhoneNotPresent = !alternatePhone;
  const smsPreferenceSelected = workContactPreferences.sms;
  const smsQuotePreferenceSelected = quoteContactPreference.sms;

  if (
    !alternatePhoneNotPresent &&
    !smsPreferenceSelected &&
    !smsQuotePreferenceSelected
  ) {
    return false;
  }

  return (
    (smsPreferenceSelected && requiredRoles) ||
    requiredRoles ||
    smsQuotePreferenceSelected
  );
};
