import React, { ReactElement } from "react";
import { FlexGrowProps } from "styled-system";
import { LookupComboBox } from "../LookupComboBox";

interface Props {
  label: string;
  visibleItems: number;
  valueLengthLookupTrigger: number;
  optional?: boolean;
  message?: ReactElement | string;
  error?: boolean;
  noResultsMessage?: string;
  allowManualEntry?: boolean;
  items: string[];
  onSelect: (selected?: string | null) => void;
  onChange: (value?: string) => void;
}

export const AddressSearchField: React.FC<
  Props &
    FlexGrowProps &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "onSelect">
> = ({ ...props }) => {
  return <LookupComboBox {...props} />;
};

AddressSearchField.defaultProps = {
  id: "address-search",
  name: "address-search",
  disabled: false,
  label: "Address",
  placeholder: "Specify address",
};
