import React, { ComponentType, FC } from "react";
import { CenteredLoading } from "../../components/CenteredLoading";
import { AccountVerificationError } from "../../components/AccountVerificationError";
import { Redirect } from "react-router-dom";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { shouldCompleteProfile } from "../completeProfileCheck/completeProfileCheck";

const withSiebelAccountVerification = <P extends object>(
  Component: ComponentType<P>,
): FC<P> => {
  return function WithSiebelAccountVerification(props: P): JSX.Element {
    const {
      authenticatedUserContext: {
        isLoading,
        isLoggedIn,
        hasSiebelAccount,
        hasError,
      },
    } = useUserAuthentication();

    const completeProfile = shouldCompleteProfile(
      isLoading,
      isLoggedIn,
      hasSiebelAccount,
    );

    if (hasError) {
      return <AccountVerificationError />;
    }

    if (isLoading) {
      return <CenteredLoading />;
    }

    if (completeProfile) {
      return <Redirect to={{ pathname: "/complete-profile" }} />;
    }

    return <Component {...props} />;
  };
};

export default withSiebelAccountVerification;
