export const reducer = (
  uploadState: UploadState,
  action: UploadAction,
): UploadState => {
  const { type } = action;

  switch (type) {
    case "UPLOAD": {
      return { status: "UPLOADING" };
    }

    case "GET_SCAN_RESULT": {
      return { status: "SCANNING", md5: action.payload?.md5 };
    }

    case "UPLOAD_FAILED": {
      return {
        status: "UPLOAD_FAILED",
      };
    }

    case "UPLOAD_NAME_FAILED": {
      return {
        status: "UPLOAD_NAME_FAILED",
      };
    }

    case "SCAN_FAILED": {
      return { status: "SCAN_FAILED" };
    }

    case "COMPLETE": {
      return { status: "COMPLETE" };
    }

    default:
      throw new Error("Unknown action");
  }
};
