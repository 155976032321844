import { ErrorMessage, Field, FieldArrayRenderProps } from "formik";
import React from "react";
import { Site } from "../../types/Site";
import { ActionButton } from "../ActionButton";
import { Flex } from "../Flex";
import { FormikRadioButton } from "../FormikRadioButton";
import { IconPopupTip } from "../IconPopupTip";
import { Line } from "../Line";
import { LotNumberDpInformation } from "../LotNumberDpInformation";
import { Span } from "../Span";
import { StreetNumberAndName } from "../StreetNumberAndName";
import { FormInputErrorMessage } from "../FormInputErrorMessage";

interface Props {
  site: Site;
  index: number;
  title: string;
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  validateField: (field: string) => void;
  arrayHelpers?: FieldArrayRenderProps;
  showRemoveButton?: boolean;
  showUnit: boolean;
}

export const SiteInformationFields: React.FC<Props> = ({
  site,
  index,
  title,
  fieldIdPrefix,
  fieldNamePrefix,
  arrayHelpers,
  showRemoveButton,
  validateField,
  showUnit,
}) => {
  const handleSiteClearClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    siteIndex: number,
    arrayHelpers?: FieldArrayRenderProps,
  ) => {
    arrayHelpers && arrayHelpers.remove(siteIndex);
  };

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent={[
          "space-between",
          "space-between",
          "space-between",
          "flex-start",
        ]}
      >
        <Span fontSize={[3, 3, 3, 4]} lineHeight={4}>
          {title}
        </Span>

        <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
          {"Your street number may not be in our address database as it’s in a new subdivision or is a new build. " +
            "In these situations we’ll ask for the Lot / DP number and looks something like this: Lot 123 DP45678. " +
            "This information can normally be found on the Certificate of Title of the land or on rates bills from the council."}
        </IconPopupTip>

        {showRemoveButton && (
          <ActionButton
            data-testid="remove-site-button"
            onClick={(e) => handleSiteClearClick(e, index, arrayHelpers)}
            icon="clear"
            ml={2}
          />
        )}
      </Flex>

      <Line
        width="100%"
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
        mt={3}
        mb={5}
      />

      <Flex flexDirection="column">
        <Flex flexDirection="column" mb={4}>
          <Flex width="100%">
            <Field
              magnitude="medium"
              id={`${fieldIdPrefix}.lotNumberAndDP`}
              name={`${fieldNamePrefix}.siteType`}
              label="Lot # and DP information"
              value="lotAndDpNumber"
              currentValue={site.siteType}
              flexGrow={0.5}
              mr={[2, 2, 3]}
              component={FormikRadioButton}
            />

            <Field
              magnitude="medium"
              id={`${fieldIdPrefix}.streetNumberAndName`}
              name={`${fieldNamePrefix}.siteType`}
              label="Street number and name"
              value="streetNumberAndName"
              currentValue={site.siteType}
              flexGrow={0.5}
              ml={[2, 2, 3]}
              component={FormikRadioButton}
            />
          </Flex>
          <ErrorMessage
            name={`${fieldNamePrefix}.siteType`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Flex>

      {site.siteType === "lotAndDpNumber" ? (
        <LotNumberDpInformation
          fieldIdPrefix={fieldIdPrefix}
          fieldNamePrefix={fieldNamePrefix}
        />
      ) : site.siteType === "streetNumberAndName" ? (
        <StreetNumberAndName
          fieldIdPrefix={fieldIdPrefix}
          fieldNamePrefix={fieldNamePrefix}
          siteAddressInformation={site.addressInformation}
          validateField={validateField}
          showUnit={showUnit}
        />
      ) : null}
    </>
  );
};
