import React from "react";
import { Flex } from "../Flex";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { Grid } from "../Grid";
import { Span } from "../Span";
import { Title } from "../Title";
import { ActionLink } from "../ActionLink";
import { Box } from "../Box";
import { Button } from "../Button";
import { useHistory } from "react-router-dom";
import { LoginButton } from "../LoginButton";
import { LoginActionLink } from "../LoginActionLink";
import env from "../../environment";

interface Props {
  orderType: string;
}

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

export const LoginOptionScreen: React.FC<Props> = ({ orderType }) => {
  const history = useHistory();

  const handleBackOnClick = () => {
    history.push("/service-type");
  };

  const handleGuestOnClick = () => {
    window.location.replace(`/${orderType}`);
  };

  return (
    <>
      <GridWrapper mx={[2, 2, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          <Box
            flexGrow={[1, 1, 0.25]}
            flexBasis={0}
            minWidth={0}
            mt={6}
            textAlign="left"
          >
            <ActionLink
              fontSize={2}
              iconLeft="west"
              onClick={handleBackOnClick}
              data-testid="back-action"
            >
              Back
            </ActionLink>
          </Box>

          <Title as="h1" lineHeight={6} fontWeight="500" mb={5} pt={5}>
            Sign in or continue as a guest
          </Title>
          <Span
            display="inline-block"
            fontSize={2}
            lineHeight={2}
            fontWeight="500"
          >
            By signing in, you can save your progress and finish your order
            later. Otherwise, you can continue as a guest.
          </Span>

          <Flex mt={5}>
            <LoginButton
              title={"Sign in"}
              url={`${env.appBaseUri}/${orderType}`}
              data-testid="login-button"
            />

            <Button
              variant={"secondary"}
              ml={5}
              onClick={handleGuestOnClick}
              data-testid="guest-button"
            >
              Continue as a guest
            </Button>
          </Flex>

          <Flex mt={5}>
            <Span
              display="inline-block"
              fontSize={2}
              lineHeight={2}
              fontWeight="500"
            >
              Don’t have an account?{" "}
              <LoginActionLink
                title={"Sign up"}
                url={`${env.appBaseUri}/${orderType}`}
                data-testid="signup-action"
              />
            </Span>
          </Flex>
        </FlexWrapper>
      </GridWrapper>
    </>
  );
};
