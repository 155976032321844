import { Order } from "../../../types/Order";
import { useHistory } from "react-router-dom";
import { getOrderDetailsPath } from "../../../utils/apiPathsProvider/apiPathsProvider";
import {
  LiveningIcpTypes,
  NewConnectionTypes,
  OrderDetailMainTypes,
} from "../../../types/OrderJobTypes";
import {
  LIVENING_ICP_SUB_HEADING_MAP,
  NEW_CONNECTION_SUB_HEADING_MAP,
  ORDER_DETAILS_HEADING_MAP,
} from "../../../constants/OrderDetails";

export const JOB_TYPE_DEFAULT_VALUE = "Unmapped job type";

export const getOrderDetailsHeading = (orderType: OrderDetailMainTypes) =>
  ORDER_DETAILS_HEADING_MAP[orderType] ?? JOB_TYPE_DEFAULT_VALUE;

export const getLiveningIcpSubHeading = (icpType: LiveningIcpTypes) =>
  LIVENING_ICP_SUB_HEADING_MAP[icpType] ?? JOB_TYPE_DEFAULT_VALUE;

export const getNewConnectionSubHeading = (
  newConnectionType: NewConnectionTypes,
) =>
  NEW_CONNECTION_SUB_HEADING_MAP[newConnectionType] ?? JOB_TYPE_DEFAULT_VALUE;

export const useGoToOrderDetails = (order: Order) => {
  const history = useHistory();
  return () => {
    history.push(getOrderDetailsPath(order.id, order.orderDetailsType));
  };
};
