import {
  downloadClient,
  downloadClientConfig,
} from "../client/FileDownloadClient";
import { includeAccessToken } from "../client/AxiosConfigObjectUtil";
import { OrderDocument } from "../types/OrderDocument";
import { getFileDownloadPath } from "../utils/apiPathsProvider/apiPathsProvider";

export const downloadFile = async (
  accessToken: string,
  orderDocument: OrderDocument,
) => {
  try {
    const { id: fileId, fileName, documentType } = orderDocument;
    const fileDownloadPath = getFileDownloadPath(fileName);
    const response = await downloadClient.get(fileDownloadPath, {
      headers: includeAccessToken(downloadClientConfig, accessToken),
      params: { fileId, documentType },
    });
    const tempUrl = URL.createObjectURL(response.data);
    const tempTag = document.createElement("a");

    tempTag.href = tempUrl;
    tempTag.download = fileName;
    tempTag.click();
    URL.revokeObjectURL(tempUrl);

    return true;
  } catch (error) {
    return false;
  }
};
