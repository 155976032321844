import { FieldArrayRenderProps, FieldArray } from "formik";
import React from "react";
import styled from "styled-components";
import { EMPTY_LIVENING_ICP_SITES } from "../../../../constants/Sites";
import { LiveningIcpSite } from "../../../../types/LiveningIcpSite";
import { getGaAddSiteEvent } from "../../../../utils/ga/GaLiveningIcpEvents";
import { ActionLink } from "../../../ActionLink";
import { Box } from "../../../Box";
import { countAllSiteUnits } from "../../../CountSiteUnits/CountSiteUnits";
import { Flex } from "../../../Flex";
import { Line } from "../../../Line";
import { SiteInformationFields } from "../../../SiteInformationFields";
import { SiteUnitSection } from "../../../SiteUnitSection";
import { Span } from "../../../Span";
import { sendGAEvent } from "../../../../utils/ga/GaEventSender";

interface Props {
  id: string;
  sites: LiveningIcpSite[];
  validateField: (field: string) => void;
}

const SiteWrapper = styled(Box)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const TotalIcpCountWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.mn80};
`;

export const LiveningIcpSiteInformationForm: React.FC<Props> = ({
  id,
  sites,
  validateField,
}) => {
  const handleAddSiteClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    sendGAEvent(getGaAddSiteEvent());
    arrayHelpers.push(EMPTY_LIVENING_ICP_SITES);
  };

  const handleUploadUnitsStatusChange = (
    siteIndex: number,
    filesUploadStatus: FileSelectUploadStatus,
    arrayHelpers: FieldArrayRenderProps,
  ) => {
    const site = sites[siteIndex];
    site.filesUploadStatus = filesUploadStatus;

    arrayHelpers.replace(siteIndex, site);
  };

  return (
    <Flex flexDirection="column" id={id}>
      <FieldArray
        name="metered.sites"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {sites.map((site, index) => (
              <SiteWrapper key={index} mb={3} p={4} borderRadius="radio">
                <SiteInformationFields
                  title={`Site ${index + 1}`}
                  key={index}
                  index={index}
                  site={site}
                  fieldIdPrefix={`metered.sites[${index}]`}
                  fieldNamePrefix={`metered.sites[${index}]`}
                  arrayHelpers={arrayHelpers}
                  showRemoveButton={index > 0}
                  validateField={validateField}
                  showUnit={false}
                />

                {site.siteType && (
                  <SiteUnitSection
                    siteIndex={index}
                    unitType={site.unitType}
                    manualUnits={site.manualUnits}
                    onUploadUnitsStatusChange={(filesUploadStatus) =>
                      handleUploadUnitsStatusChange(
                        index,
                        filesUploadStatus,
                        arrayHelpers,
                      )
                    }
                  />
                )}
              </SiteWrapper>
            ))}

            <ActionLink
              iconLeft="add_circle"
              type="button"
              alignSelf="flex-start"
              onClick={(e) => handleAddSiteClick(e, arrayHelpers)}
              mb={3}
            >
              Add site
            </ActionLink>
          </>
        )}
      />

      <TotalIcpCountWrapper id="total-icp-count" fontSize={1} lineHeight={1}>
        {`Total ICP entered: ${countAllSiteUnits(sites)}`}
      </TotalIcpCountWrapper>

      <Line
        width="100%"
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
        my={7}
      />
    </Flex>
  );
};
