import { ErrorMessage, Field, FieldArray } from "formik";
import React from "react";
import styled from "styled-components";
import { NewConnectionSite } from "../../../../types/NewConnectionSite";
import { getGaLotCountEvent } from "../../../../utils/ga/GaNewConnectionEvents";
import { Box } from "../../../Box";
import { Flex } from "../../../Flex";
import { FormikDropdown } from "../../../FormikDropdown";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import { Line } from "../../../Line";
import { SiteInformationFields } from "../../../SiteInformationFields";
import { NewConnectionLot } from "../NewConnectionLot";
import { FormikTextBox } from "../../../FormikTextBox";
import { isComplexConnection } from "../../../NewConnectionComplexUtil/NewConnectionComplexUtil";

interface Props {
  id: string;
  site: NewConnectionSite;
  validateField: (field: string) => void;
}

const SiteWrapper = styled(Box)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const NewConnectionSiteInformationForm: React.FC<Props> = ({
  id,
  site,
  validateField,
}) => {
  const isComplex = isComplexConnection(site);

  return (
    <Flex flexDirection="column" id={id}>
      <SiteWrapper p={4} borderRadius="radio">
        <SiteInformationFields
          title={"Site details"}
          key={0}
          index={0}
          site={site}
          fieldIdPrefix="site"
          fieldNamePrefix="site"
          showRemoveButton={false}
          validateField={validateField}
          showUnit
        />

        {site.siteType && (
          <>
            <Line
              width="100%"
              border="1px solid"
              borderColor="neutrals.ln30"
              bg="neutrals.ln30"
              opacity="1"
              height={0}
              mt={0}
              mb={4}
            />

            <Flex flexDirection="column" mb={5}>
              <Field
                id="site.lotCount"
                name="site.lotCount"
                items={[
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "10",
                  "More than 10",
                ]}
                label="Number of lots"
                visibleItems={11}
                placeholder=""
                gaEvent={getGaLotCountEvent(site.lotCount)}
                component={FormikDropdown}
              />
              <ErrorMessage
                name="site.lotCount"
                className="field-error"
                component={FormInputErrorMessage}
              />
            </Flex>

            {site.lotCount !== "More than 10" && (
              <FieldArray
                name="site.lots"
                validateOnChange={false}
                render={(arrayHelpers) => (
                  <NewConnectionLot
                    lotCount={site.lotCount}
                    lots={site.lots}
                    arrayHelpers={arrayHelpers}
                  />
                )}
              />
            )}

            {!isComplex && (
              <>
                <Flex flexDirection="column" mt={6}>
                  <Field
                    id="site.additionalSiteRequirements"
                    name="site.additionalSiteRequirements"
                    label="Additional site requirements details"
                    placeholder="eg. This request is for a new dwelling at the back. The existing house remains."
                    rows={5}
                    resize="vertical"
                    optional
                    component={FormikTextBox}
                  />
                  <ErrorMessage
                    name="site.additionalSiteRequirements"
                    className="field-error"
                    component={FormInputErrorMessage}
                  />
                </Flex>
              </>
            )}
          </>
        )}
      </SiteWrapper>

      <Line
        width="100%"
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
        my={7}
      />
    </Flex>
  );
};
