export const draftOrderDeleteSuccess = {
  title: "Draft order successfully deleted",
  message: "",
};
export const draftOrderDeleteFailure = {
  title: "Draft order failed to delete",
  message: "Please wait a few minutes and try again.",
};

export const fileDownloadSuccess = {
  title: "File successfully downloaded",
  message: "is now available in your downloads folder.",
};
export const fileDownloadFailure = {
  title: "Oops, looks like something went wrong",
  message: "download failed. Please wait a few minutes and try again.",
};
