import {
  LiveningIcpRoles,
  NewConnectionRoles,
} from "../../../types/ContactDetailRoles";

export const liveningIcpRoleDisabler = (
  contactDetailRoles: LiveningIcpRoles[],
  contactDetailsIndex: number,
): LiveningIcpRoles => {
  let siteAccessContactRole = false;
  let powerRetailerAccountHolderRole = false;

  contactDetailRoles.forEach((roles, index) => {
    if (roles.siteAccessContactRole && contactDetailsIndex !== index) {
      siteAccessContactRole = roles.siteAccessContactRole;
    }
    if (roles.powerRetailerAccountHolderRole && contactDetailsIndex !== index) {
      powerRetailerAccountHolderRole = roles.powerRetailerAccountHolderRole;
    }
  });

  return {
    requester: false,
    siteAccessContactRole,
    powerRetailerAccountHolderRole,
  };
};

export const newConnectionRoleDisabler = (
  contactDetailRoles: NewConnectionRoles[],
  contactDetailsIndex: number,
): NewConnectionRoles => {
  let siteAccessContactRole = false;
  let quotePayerRole = false;
  let customerRole = false;
  let consultantRole = false;

  contactDetailRoles.forEach((roles, index) => {
    if (roles.quotePayerRole && contactDetailsIndex !== index) {
      quotePayerRole = roles.quotePayerRole;
    }
    if (roles.siteAccessContactRole && contactDetailsIndex !== index) {
      siteAccessContactRole = roles.siteAccessContactRole;
    }
    if (roles.customerRole && contactDetailsIndex !== index) {
      customerRole = roles.customerRole;
    }
    if (roles.consultantRole && contactDetailsIndex !== index) {
      consultantRole = roles.consultantRole;
    }
    if (roles.customerRole && contactDetailsIndex === index) {
      consultantRole = true;
    }
    if (roles.consultantRole && contactDetailsIndex === index) {
      customerRole = true;
    }
  });

  return {
    requester: false,
    quotePayerRole,
    siteAccessContactRole,
    customerRole,
    consultantRole,
  };
};
