import React, { useEffect, useState } from "react";
import { Form, Formik, FormikState } from "formik";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { AccountDetailsFormSchema } from "./AccountDetailsFormSchema";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { Button } from "../Button";
import { ContactDetailFields } from "../ContactDetailsForm/ContactDetailFields";
import { CompanyNzbn } from "../CompanyNzbn/CompanyNzbn";
import { Flex } from "../Flex";
import { Link } from "../Link";
import { PostalAddress } from "../PostalAddress";
import { Span } from "../Span";
import { CompleteProfileFormValues } from "../../types/CompleteProfileFormValues";

interface Props {
  onSubmit: (values: CompleteProfileFormValues) => void;
  initialValues?: CompleteProfileFormValues;
}

const FormWrapper = styled(Form)<SpaceProps>`
  ${space}
`;

export const AccountDetailsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationSchema = AccountDetailsFormSchema;

  const handleSubmit = (values: CompleteProfileFormValues) => {
    onSubmit(values);
    setIsSubmitting(true);
  };

  const handleOnCancelClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    resetForm: (
      nextState?: Partial<FormikState<CompleteProfileFormValues>> | undefined,
    ) => void,
  ) => {
    resetForm && resetForm();
  };

  const changePostalOrBusinessInfoText = (isBusinessAccount: boolean) => {
    return (
      <Span>
        {`If you need to update your postal address${
          isBusinessAccount ? " or business information" : ""
        },
			  please`}
        <Link href={"mailto:vectorconnections@vector.co.nz"}> contact us.</Link>
      </Span>
    );
  };

  useEffect(() => {
    setIsSubmitting(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Formik
      validateOnChange={false}
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      initialValues={initialValues!}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, resetForm, dirty, validateField }) => {
        const prefix = "profile";
        const isBusinessAccount = values?.profile?.type === "business";

        return (
          <FormWrapper mb={7}>
            <Flex
              flexDirection="column"
              mt={3}
              border="1px solid"
              borderColor="neutrals.ln30"
              bg="neutrals.ln10"
              borderRadius="radio"
              padding={4}
            >
              <ContactDetailFields
                focusable={true}
                idPrefix={prefix}
                namePrefix={prefix}
                optionalAlternatePhone
                showEmail={false}
                disabledBusinessName={isBusinessAccount}
                showBusinessName={isBusinessAccount}
              />

              {isBusinessAccount && (
                <CompanyNzbn prefix={prefix} disabled={true} />
              )}

              <PostalAddress
                fieldIdPrefix={prefix}
                fieldNamePrefix={prefix}
                validateField={validateField}
                disabled={true}
              />

              <BasicNotificationBanner
                message={() =>
                  changePostalOrBusinessInfoText(isBusinessAccount)
                }
                variant="default"
                icon="info"
              />
            </Flex>

            <Flex justifyContent="flex-end" mt={7}>
              <Button
                onClick={(e) => handleOnCancelClick(e, resetForm)}
                disabled={!dirty}
                variant="ghost"
                mr={3}
                data-testid="cancel-button"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
                data-testid="save-button"
              >
                Save
              </Button>
            </Flex>
          </FormWrapper>
        );
      }}
    </Formik>
  );
};
