import { NewConnectionLotValues } from "../../types/NewConnectionLotValues";
import { createGaEvent } from "./GaEventFactory";
import { GAEvent } from "./GaEventSender";

export const getGaLotCountEvent = (lotCount: string): GAEvent => {
  let gaLotCountLabel = "";

  switch (lotCount) {
    case "1":
      gaLotCountLabel = "E_NC_1_lots";
      break;
    case "2":
      gaLotCountLabel = "E_NC_2_lots";
      break;
    case "3":
      gaLotCountLabel = "E_NC_3_lots";
      break;
    case "4":
      gaLotCountLabel = "E_NC_4_lots";
      break;
    case "5":
      gaLotCountLabel = "E_NC_5_lots";
      break;
    case "6":
      gaLotCountLabel = "E_NC_6_lots";
      break;
    case "7":
      gaLotCountLabel = "E_NC_7_lots";
      break;
    case "8":
      gaLotCountLabel = "E_NC_8_lots";
      break;
    case "9":
      gaLotCountLabel = "E_NC_9_lots";
      break;
    case "10":
      gaLotCountLabel = "E_NC_10_lots";
      break;
    case "More than 10":
      gaLotCountLabel = "E_NC_More_than_10_lots";
      break;
  }

  return createGaEvent("select_connection_points", gaLotCountLabel);
};

export const getGaFuseAmpEvents = (
  lots: NewConnectionLotValues[],
): GAEvent[] => {
  const gaFuseAmpEvents: GAEvent[] = [];

  lots.forEach((lot) => {
    let gaFuseAmpLabel = "";

    switch (lot.fuseAmp) {
      case "32":
        gaFuseAmpLabel = "E_NC_32amp";
        break;
      case "60":
        gaFuseAmpLabel = "E_NC_60amp";
        break;
      case "100":
        gaFuseAmpLabel = "E_NC_100amp";
        break;
      case "more than 100":
        gaFuseAmpLabel = "E_NC_>100amp";
        break;
    }

    const gaEvent = createGaEvent("select_service_amp", gaFuseAmpLabel);
    gaFuseAmpEvents.push(gaEvent);
  });

  return gaFuseAmpEvents;
};

export const getGaIndicativeServiceEvent = (): GAEvent => {
  return createGaEvent("select_complex_service", "E_NC_indicative_cost");
};

export const getGaDetailServiceEvent = (): GAEvent => {
  return createGaEvent("select_complex_service", "E_NC_detailed_design");
};

export const getGaSubdivisionProjectEvent = (): GAEvent => {
  return createGaEvent("select_project_type", "E_NC_subdivision");
};

export const getGaPosProjectEvent = (): GAEvent => {
  return createGaEvent("select_project_type", "E_NC_PoS");
};

export const getGaPosSubdivisionEvent = (): GAEvent => {
  return createGaEvent("select_subdivision_type", "E_NC_sub_POS");
};

export const getGaReticSubdivisionEvent = (): GAEvent => {
  return createGaEvent("select_subdivision_type", "E_NC_sub_retic");
};

export const getGaEditSiteDetailsEvent = (): GAEvent => {
  return createGaEvent("edit_order", "E_NC_site_details");
};

export const getGaEditContactDetailsEvent = (): GAEvent => {
  return createGaEvent("edit_order", "E_NC_contacts");
};

export const getGaSubmitSuccessEvent = (): GAEvent => {
  return createGaEvent("submit_order", "E_NC_submit_order_successful");
};

export const getGaSubmitFailedEvent = (): GAEvent => {
  return createGaEvent("submit_order", "E_NC_submit_order_failed");
};

export const getGaSaveDraftSuccessEvent = (): GAEvent => {
  return createGaEvent("save_draft_order", "E_NC_save_draft_successful");
};

export const getGaSaveDraftFailedEvent = (): GAEvent => {
  return createGaEvent("save_draft_order", "E_NC_save_draft_failed");
};
