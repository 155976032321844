import React, { useEffect, useRef, useState } from "react";
import { AccountSettings } from "../../components/AccountSettings";
import { updateUser } from "../../services/UsersService";
import { CompleteProfileFormValues } from "../../types/CompleteProfileFormValues";
import { EMPTY_COMPLETE_PROFILE_VALUES } from "../../constants/CompleteProfileDetails";
import sendPasswordResetEmail from "../../services/Auth0Service";
import { BasicNotificationBannerProps } from "../../components/BasicNotificationBanner/BasicNotificationBanner";
import { FloatingBanner } from "../../components/FloatingBanner";
import { OrderDetailsListNavigationMenu } from "../../components/OrderDetailsList/_/OrderDetailsListNavigationMenu";
import { Page } from "../../components/Page";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import styled from "styled-components";
import { useGetFullUserDetails } from "../../hooks/cachedQueries/userAccount/useGetFullUserDetails";
import { useInvalidateAccountDetails } from "../../hooks/cachedQueries/useInvalidateAccountDetails/useInvalidateAccountDetails";
import UserAuthenticationActionType from "../../types/UserAuthenticationActionType";
import { useInvalidateOrderList } from "../../hooks/cachedQueries/useInvalidateOrderList/useInvalidateOrderList";
import { CenteredLoading } from "../../components/CenteredLoading";

export interface AccountSettingsPageProps {
  tabTitle: string;
}

interface NotificationBannerProps extends BasicNotificationBannerProps {
  show: boolean;
}

const WrappedNotificationBanner = styled.div`
  position: fixed;
  left: 2.2vh;
  bottom: 14.7vh;
`;

export const AccountSettingsPage: React.FC<AccountSettingsPageProps> = ({
  tabTitle,
}) => {
  const [notificationBannerContent, setNotificationBannerContent] =
    useState<NotificationBannerProps>({
      show: false,
      title: "",
      message: "",
      variant: "success",
      icon: "check_circle",
    });

  const { invalidateAccountDetailsCache } = useInvalidateAccountDetails();
  const { invalidateOrdersCache } = useInvalidateOrderList();

  const {
    authenticatedUserContext: { userAuth0Details, hasSiebelAccount },
    setAuthenticatedUserContext,
  } = useUserAuthentication();
  const { accessToken, email } = userAuth0Details;

  const userDetailsInitRef = useRef(false);
  const { current: userDetailsInitialized } = userDetailsInitRef;
  const { userFullDetails, apiError } = useGetFullUserDetails(
    accessToken,
    email,
    hasSiebelAccount,
  );

  const [userInfo, setUserInfo] = useState<
    CompleteProfileFormValues | undefined
  >(userFullDetails ? { profile: userFullDetails } : undefined);

  useEffect(() => {
    if (apiError) {
      setNotificationBannerContent({
        show: true,
        title: "Something went wrong",
        message:
          "There was an error retrieving your details. Please try again in a few minutes.",
        variant: "error",
        icon: "warning",
      });
      setUserInfo(EMPTY_COMPLETE_PROFILE_VALUES);
    }
  }, [apiError]);

  useEffect(() => {
    if (userDetailsInitialized) {
      return;
    }

    if (userFullDetails) {
      userDetailsInitRef.current = true;
      setUserInfo({ profile: userFullDetails });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFullDetails, userInfo]);

  const handleSubmit = async (values: CompleteProfileFormValues) => {
    let userUpdated = false;

    try {
      const updatedUser = await updateUser(values, accessToken);
      setUserInfo({ profile: updatedUser });
      userUpdated = true;

      await invalidateAccountDetailsCache();
      await invalidateOrdersCache();

      setAuthenticatedUserContext({
        type: UserAuthenticationActionType.BasicDetailsUpdated,
        payload: {
          userBasicDetails: {
            firstName: updatedUser.firstName,
            lastName: updatedUser.lastName,
            mobile: updatedUser.mobile,
            alternatePhone: updatedUser.alternatePhone,
          },
        },
      });
    } catch (error) {
      userUpdated = false;
      setUserInfo({ profile: values.profile });
    }

    setNotificationBannerContent({
      show: true,
      title: userUpdated ? "Update successful" : "Something went wrong",
      message: userUpdated
        ? "Your account settings have been successfully saved."
        : "There was an error saving your details. Please try again in a few minutes.",
      variant: userUpdated ? "success" : "error",
      icon: userUpdated ? "check_circle" : "warning",
    });
  };

  const handleResetPasswordClick = async (email: string) => {
    let emailSent = false;

    try {
      emailSent = await sendPasswordResetEmail(email);
    } catch (error) {
      // @ts-ignore
      // Do nothing
    }

    setNotificationBannerContent({
      show: true,
      title: emailSent
        ? "Reset password email sent"
        : "Reset password email not sent",
      message: emailSent
        ? "An email has been sent with instructions to reset your password."
        : "We could not send you the email to reset your password. Please wait a few minutes and try again.",
      variant: emailSent ? "success" : "error",
      icon: emailSent ? "check_circle" : "warning",
    });
  };

  const handleNotificationBannerClose = () => {
    setNotificationBannerContent({
      ...notificationBannerContent,
      show: false,
    });
  };

  return (
    <Page
      tabTitle={tabTitle}
      navigationMenuContents={() => <OrderDetailsListNavigationMenu />}
      hasMobileNavMenu
    >
      <WrappedNotificationBanner>
        <FloatingBanner
          title={notificationBannerContent?.title}
          message={notificationBannerContent?.message}
          variant={notificationBannerContent?.variant}
          icon={notificationBannerContent?.icon}
          visible={notificationBannerContent?.show}
          onBannerClose={handleNotificationBannerClose}
        />
      </WrappedNotificationBanner>
      {!userInfo && <CenteredLoading />}

      {userInfo && (
        <AccountSettings
          userInfo={userInfo}
          onSubmit={handleSubmit}
          onResetPasswordClick={handleResetPasswordClick}
        />
      )}
    </Page>
  );
};
