import React from "react";
import { ActionLink } from "../../../ActionLink";
import { COMPLETION_INFO_DATA_MAP } from "../../../../constants/OrderDetails";
import { OrderDetailSubTypes } from "../../../../types/OrderJobTypes";
import { Span } from "../../../Span";
import { Box } from "../../../Box";

interface Props {
  orderDetailType: OrderDetailSubTypes;
}

export const OrderCompletionInfo: React.FC<Props> = ({ orderDetailType }) => {
  const completionInfo = COMPLETION_INFO_DATA_MAP[orderDetailType];

  return (
    <>
      {completionInfo && (
        <Box data-testid="order-completion-info">
          <Span color="neutrals.dn40" fontSize={1} lineHeight={1}>
            {completionInfo.estimatedCompletion}
          </Span>

          <Span color="neutrals.dn40" fontSize={1} lineHeight={1}>
            {"\n"}Want to know more? Read this
          </Span>

          <ActionLink
            fontSize={1}
            lineHeight={1}
            p={0}
            data-testid="open-info-link"
            onClick={() => window.open(completionInfo.infoLink, "_blank")}
          >
            {" "}
            {completionInfo.infoLinkText}
          </ActionLink>
        </Box>
      )}
    </>
  );
};
