import { FormikErrors } from "formik";
import lodash from "lodash";
import { getTopPositionByElementName } from "../../utils/handleScroll/HandleScroll";

const getHierarchicalFieldName = (
  fieldName: string,
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  errors: FormikErrors<any>,
): string => {
  const keyValuePairs = lodash.toPairs(errors);

  for (let index = 0; index < keyValuePairs.length; index++) {
    const keyValue = keyValuePairs[index];

    const propertyName = keyValue[0];
    const propertyValue = keyValue[1];

    if (lodash.isString(propertyValue) || lodash.isNumber(propertyValue)) {
      if (fieldName === propertyName) {
        return propertyName;
      }

      continue;
    } else if (lodash.isArray(propertyValue)) {
      for (
        let innerIndex = 0;
        innerIndex < propertyValue.length;
        innerIndex++
      ) {
        const property = propertyValue[innerIndex];
        const response = getHierarchicalFieldName(
          fieldName,
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          property as FormikErrors<any>,
        );

        if (response) {
          return `${propertyName}[${innerIndex}].` + response;
        }
      }
    } /* istanbul ignore next */ else if (lodash.isObject(propertyValue)) {
      const response = getHierarchicalFieldName(
        fieldName,
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        propertyValue as FormikErrors<any>,
      );

      if (response) {
        return `${propertyName}.` + response;
      }
    }
  }

  /* istanbul ignore next */
  return "";
};

const findFirstErrorByPosition = (
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  originalErrors: FormikErrors<any>,
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  errorsSubset: FormikErrors<any>,
  minTopPosition: number,
  fieldName: string,
): { fieldName: string; minTopPosition: number } => {
  const keyValuePairs = lodash.toPairs(errorsSubset);

  for (let index = 0; index < keyValuePairs.length; index++) {
    const keyValue = keyValuePairs[index];

    const propertyName = keyValue[0];
    const propertyValue = keyValue[1];

    if (lodash.isString(propertyValue) || lodash.isNumber(propertyValue)) {
      const fieldFullName = getHierarchicalFieldName(
        propertyName,
        originalErrors,
      );

      const topPosition = getTopPositionByElementName(fieldFullName);
      if (topPosition === -1 || topPosition >= minTopPosition) {
        continue;
      }

      minTopPosition = topPosition;
      fieldName = propertyName;
    } else if (lodash.isArray(propertyValue)) {
      for (
        let innerIndex = 0;
        innerIndex < propertyValue.length;
        innerIndex++
      ) {
        const property = propertyValue[innerIndex];

        const arrayResponse = findFirstErrorByPosition(
          originalErrors,
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          property as FormikErrors<any>,
          minTopPosition,
          fieldName,
        );

        fieldName = arrayResponse.fieldName;
        minTopPosition = arrayResponse.minTopPosition;
      }
    } /* istanbul ignore next */ else if (lodash.isObject(propertyValue)) {
      const objectResponse = findFirstErrorByPosition(
        originalErrors,
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        propertyValue as FormikErrors<any>,
        minTopPosition,
        fieldName,
      );

      fieldName = objectResponse.fieldName;
      minTopPosition = objectResponse.minTopPosition;
    }
  }

  return { fieldName, minTopPosition };
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getFirstErrorByPosition = (errors: FormikErrors<any>): string => {
  if (lodash.isEmpty(errors)) {
    return "";
  }

  const { fieldName } = findFirstErrorByPosition(
    errors,
    errors,
    Number.MAX_VALUE,
    "",
  );

  const hierarchicalFieldName = getHierarchicalFieldName(fieldName, errors);
  return hierarchicalFieldName;
};
