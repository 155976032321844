import React from "react";
import { OrderStatus } from "../../../../types/OrderStatus";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { shouldShowNextActivity } from "../OrderProgressSectionUtil/OrderProgressSectionUtil";

interface Props {
  orderStatus: OrderStatus;
}

export const StatusNextActivity: React.FC<Props> = ({ orderStatus }) => {
  const { progressStatus, nextActivity } = orderStatus;

  const showNextActivity = shouldShowNextActivity(nextActivity, progressStatus);

  return (
    <>
      {showNextActivity && (
        <Flex flexDirection="column" mt={3}>
          <Span color="neutrals.mn80" data-testid="next-activity">
            Next step: {nextActivity}
          </Span>
        </Flex>
      )}
    </>
  );
};
