import { LiveningIcpRoles, NewConnectionRoles } from "../../../types/ContactDetailRoles";

export const showLiveningRoleRequiredErrorMessage = (
  contactDetailRoles: LiveningIcpRoles,
): boolean => {

  return contactDetailRoles.requester ||
      contactDetailRoles.siteAccessContactRole ||
      contactDetailRoles.powerRetailerAccountHolderRole;
};

export const showNewConnectionRoleRequiredErrorMessage = (
  contactDetailRoles: NewConnectionRoles,
  newConnectionType: NewConnectionType
): boolean => {
  

  if (newConnectionType === "simple") {
    return contactDetailRoles.requester ||
      contactDetailRoles.siteAccessContactRole ||
      contactDetailRoles.quotePayerRole;
    
  } else {
    return contactDetailRoles.requester ||
      contactDetailRoles.customerRole ||
      contactDetailRoles.consultantRole;
  }

};
