import { LiveningIcpContactDetailsValues } from "../types/LiveningIcpContactDetailsValues";
import { NewConnectionContactDetailsValues } from "../types/NewConnectionContactDetailsValues";

export const EMPTY_LIVENING_ICP_CONTACT_DETAILS_VALUES: LiveningIcpContactDetailsValues =
  {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    alternatePhone: "",
    businessName: "",
    workContactPreferences: {
      email: false,
      sms: false,
    },
    roles: {
      requester: false,
      siteAccessContactRole: false,
      powerRetailerAccountHolderRole: false,
    },
    contactHasRole: false
  };

export const EMPTY_NEW_CONNECTION_CONTACT_DETAILS_VALUES: NewConnectionContactDetailsValues =
  {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    alternatePhone: "",
    billingAddressDisplay: "",
    billingAddressId: "",
    manuallyEnteredBillingAddress: false,
    manualBillingAddress: {
      addressString: "",
      suburb: "",
      city: "",
      postcode: "",
    },
    businessName: "",
    businessNumber: "",
    workContactPreferences: {
      email: false,
      sms: false,
    },
    quoteContactPreferences: {
      email: false,
      sms: false,
    },
    roles: {
      requester: false,
      quotePayerRole: false,
      siteAccessContactRole: false,
      customerRole: false,
      consultantRole: false,
    },
    contactHasRole: false
  };
