import { Field, FieldArrayRenderProps } from "formik";
import { toNumber } from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";
import { EMPTY_NEW_CONNECTION_LOT_VALUES } from "../../../../constants/NewConnectionLotValues";
import { mediaQueries } from "../../../../theme";
import { NewConnectionLotValues } from "../../../../types/NewConnectionLotValues";
import { getGaFuseAmpEvents } from "../../../../utils/ga/GaNewConnectionEvents";
import { Flex } from "../../../Flex";
import { FormikDropdown } from "../../../FormikDropdown";
import { IconPopupTip } from "../../../IconPopupTip";
import { Span } from "../../../Span";

interface Props {
  lotCount: string;
  lots: NewConnectionLotValues[];
  arrayHelpers: FieldArrayRenderProps;
}

const TableHeaders = styled(Flex)`
  display: none;

  ${mediaQueries.largeDevice} {
    display: flex;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  }
`;

const TableHead = styled(Flex)`
  min-width: 0;
  flex-basis: 0;
  color: ${({ theme }) => theme.colors.neutrals.mn80};
  align-self: end;
`;

const PHASE_POPUPTIP =
  "3 phase power can cope with the electricity demands of larger and more powerful appliances. " +
  "Therefore, it is more commonly used in commercial and industrial settings. " +
  "Most homes and apartments don’t need 3 phase power as all their appliances and power needs will run well on single phase supply. " +
  "But if you’re renovating, building new or adding high-energy appliances, " +
  "talk to your electrician to get advice on whether you need 3 phase power.";

const FUSE_POPUPTIP =
  "Different fuse sizes are used depending on the electricity supply. " +
  "Talk to your electrician to confirm the fuse size you need to power your home.";

export const NewConnectionLot: React.FC<Props> = ({
  lotCount,
  lots,
  arrayHelpers,
}) => {
  const addLots = (count: number) => {
    for (let index = 0; index < count; index++) {
      arrayHelpers.push(EMPTY_NEW_CONNECTION_LOT_VALUES);
    }
  };

  const removeLots = (count: number) => {
    for (let index = count; index > 0; index--) {
      arrayHelpers.remove(lots.length - 1);
    }
  };

  useEffect(() => {
    const convertedLotCount = toNumber(lotCount);

    if (convertedLotCount > lots.length) {
      addLots(convertedLotCount - lots.length);
    } else if (convertedLotCount < lots.length) {
      removeLots(lots.length - convertedLotCount);
    } else {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotCount, lots]);

  const fuseAmpGaEvents = getGaFuseAmpEvents(lots);

  return (
    <Flex flexDirection="column">
      <TableHeaders pb={2} mb={4}>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.33} mr={2}>
          Type
        </TableHead>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.33} mr={2}>
          <Span>Phase</Span>
          <IconPopupTip icon="help" ml={2}>
            {PHASE_POPUPTIP}
          </IconPopupTip>
        </TableHead>
        <TableHead fontWeight="medium" fontSize={2} flexGrow={0.33} mr={2}>
          <Span width="min-content">Fuse (Amp)</Span>
          <IconPopupTip icon="help" ml={2} alignSelf="end">
            {FUSE_POPUPTIP}
          </IconPopupTip>
        </TableHead>
      </TableHeaders>

      {lots.map((lot, index) => (
        <Flex
          key={`site.lots[${index}]`}
          flexDirection={["column", "column", "column", "row", "row"]}
          my={[4, 4, 4, 2, 2]}
        >
          <Field
            id={`site.lots[${index}].type`}
            name={`site.lots[${index}].type`}
            label="Type"
            displayLabel={[
              "inline-block",
              "inline-block",
              "inline-block",
              "none",
              "none",
            ]}
            items={["Commercial", "Residential"]}
            visibleItems={2}
            placeholder=""
            mr={[0, 0, 0, 2, 2]}
            mb={[3, 3, 3, 0, 0]}
            flexGrow={0.33}
            flexBasis={0}
            minWidth={["100%", "100%", "100%", 0, 0]}
            component={FormikDropdown}
          />

          <Field
            id={`site.lots[${index}].phase`}
            name={`site.lots[${index}].phase`}
            label="Phase"
            displayLabel={[
              "inline-block",
              "inline-block",
              "inline-block",
              "none",
              "none",
            ]}
            items={["1", "2", "3"]}
            visibleItems={3}
            placeholder=""
            tooltip={PHASE_POPUPTIP}
            displayTooltip={["flex", "flex", "flex", "none", "none"]}
            mr={[0, 0, 0, 2, 2]}
            mb={[3, 3, 3, 0, 0]}
            flexGrow={0.33}
            flexBasis={0}
            minWidth={["100%", "100%", "100%", 0, 0]}
            component={FormikDropdown}
          />

          <Field
            id={`site.lots[${index}].fuseAmp`}
            name={`site.lots[${index}].fuseAmp`}
            label="Fuse (Amp)"
            displayLabel={[
              "inline-block",
              "inline-block",
              "inline-block",
              "none",
              "none",
            ]}
            tooltip={FUSE_POPUPTIP}
            displayTooltip={["flex", "flex", "flex", "none", "none"]}
            items={["30", "32", "60", "63", "100", "more than 100"]}
            visibleItems={6}
            placeholder=""
            mr={[0, 0, 0, 2, 2]}
            mb={[3, 3, 3, 0, 0]}
            flexGrow={0.33}
            flexBasis={0}
            minWidth={["100%", "100%", "100%", 0, 0]}
            gaEvent={fuseAmpGaEvents[index]}
            component={FormikDropdown}
          />
        </Flex>
      ))}
    </Flex>
  );
};
