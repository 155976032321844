import { ErrorMessage, Field } from "formik";
import React from "react";
import { SpaceProps } from "styled-system";
import { NewConnectionRoles } from "../../../../types/ContactDetailRoles";
import {
  newConnectionRoleDisabler
} from "../../../ContactDetailsForm/ContactDetailsRoleDisabler/ContactDetailsRoleDisabler";
import { Flex } from "../../../Flex";
import { FormikCheckbox } from "../../../FormikCheckbox";
import { IconPopupTip } from "../../../IconPopupTip";
import { Span } from "../../../Span";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";
import {
  showNewConnectionRoleRequiredErrorMessage
} from "../../../ContactDetailsForm/ContactDetailsShowRoleRequiredUtil/ContactDetailsShowRoleRequiredUtil";

interface Props {
  contactDetailsIndex: number;
  contactDetailsRoles: NewConnectionRoles[];
  electricianRole?: boolean;
  siteAccessContactRole?: boolean;
  quotePayerRole?: boolean;
  customerRole?: boolean;
  landOwnerRole?: boolean;
  consultantRole?: boolean;
  newConnectionType: NewConnectionType;
}

export const NewConnectionRoleSelector: React.FC<Props & SpaceProps> = ({
  contactDetailsIndex,
  contactDetailsRoles,
  siteAccessContactRole,
  quotePayerRole,
  customerRole,
  consultantRole,
  newConnectionType,
  mb,
}) => {
  const disabledRoles = newConnectionRoleDisabler(
    contactDetailsRoles,
    contactDetailsIndex,
  );

  const roleRequiredRoleErrorMessage = showNewConnectionRoleRequiredErrorMessage(
      contactDetailsRoles[contactDetailsIndex],
      newConnectionType);

  return (
    <Flex flexDirection="column" mb={mb}>
      <Flex mb={3}>
        <Span fontSize={2} lineHeight={2} mr={3}>
          Select what other role(s) this contact has.
        </Span>
        <IconPopupTip icon="help">
          {"Vector requires the contact details of all people / roles, " +
            "as they need to contact them at different times during the job. " +
            "Sometimes one person fulfils several roles eg the requestor and the electrician might be the same person"}
        </IconPopupTip>
      </Flex>

      <Flex flexDirection={["column", "column", "row"]} width="100%">
        {siteAccessContactRole && (
          <Flex flexDirection="column">
            <Field
              id={`contactDetails[${contactDetailsIndex}].roles.siteAccessContactRole`}
              name={`contactDetails[${contactDetailsIndex}].roles.siteAccessContactRole`}
              label="Site access contact"
              disabled={disabledRoles.siteAccessContactRole}
              my={[1, 1, 0]}
              mx={[0, 0, 1]}
              component={FormikCheckbox}
            />

            {!disabledRoles.siteAccessContactRole && (
              <ErrorMessage
                name={`contactDetails[${contactDetailsIndex}].roles.siteAccessContactRole`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            )}

            {!roleRequiredRoleErrorMessage && (
              <ErrorMessage
                name={`contactDetails[${contactDetailsIndex}].contactHasRole`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            )}

          </Flex>
        )}

        {quotePayerRole && (
          <Flex flexDirection="column">
            <Field
              id={`contactDetails[${contactDetailsIndex}].roles.quotePayerRole`}
              name={`contactDetails[${contactDetailsIndex}].roles.quotePayerRole`}
              label="Quote payer"
              disabled={disabledRoles.quotePayerRole}
              my={[1, 1, 0]}
              mx={[0, 0, 1]}
              component={FormikCheckbox}
            />

            {!disabledRoles.quotePayerRole && (
              <ErrorMessage
                name={`contactDetails[${contactDetailsIndex}].roles.quotePayerRole`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            )}
          </Flex>
        )}

        {customerRole && (
          <Flex flexDirection="column">
            <Field
              id={`contactDetails[${contactDetailsIndex}].roles.customerRole`}
              name={`contactDetails[${contactDetailsIndex}].roles.customerRole`}
              label="Customer"
              disabled={disabledRoles.customerRole}
              my={[1, 1, 0]}
              mx={[0, 0, 1]}
              component={FormikCheckbox}
            />

            {!disabledRoles.customerRole && (
              <ErrorMessage
                name={`contactDetails[${contactDetailsIndex}].roles.customerRole`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            )}

            {!roleRequiredRoleErrorMessage && (
              <ErrorMessage
                name={`contactDetails[${contactDetailsIndex}].contactHasRole`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            )}
          </Flex>
        )}

        {consultantRole && (
          <Flex flexDirection="column">
            <Field
              id={`contactDetails[${contactDetailsIndex}].roles.consultantRole`}
              name={`contactDetails[${contactDetailsIndex}].roles.consultantRole`}
              label="Consultant"
              disabled={disabledRoles.consultantRole}
              my={[1, 1, 0]}
              mx={[0, 0, 1]}
              component={FormikCheckbox}
            />

            {!disabledRoles.consultantRole && (
              <ErrorMessage
                name={`contactDetails[${contactDetailsIndex}].roles.consultantRole`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            )}
          </Flex>
        )}
      </Flex>


    </Flex>
  );
};
