import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  ShadowProps,
  SizeProps,
  SpaceProps,
  TypographyProps,
  border,
  color,
  flexbox,
  layout,
  shadow,
  size,
  space,
  typography,
  variant,
} from "styled-system";
import React, { ButtonHTMLAttributes } from "react";

import { Icon } from "../Icon";
import styled from "styled-components";

export interface ActionLinkProps {
  variant?: "primary" | "secondary";
  iconLeft?: string;
  iconRight?: string;
}

const ButtonComponent = styled.button`
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${shadow}
  ${space}
  ${size}
  ${typography}
  background: transparent;
  border: none;
  text-align: center;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  font-family: inherit;
  outline: none;

  &:disabled {
    pointer-events: none;
  }
`;

const Component = styled(ButtonComponent)<
  ActionLinkProps &
    FlexboxProps &
    SpaceProps &
    SizeProps &
    BorderProps &
    LayoutProps &
    ColorProps &
    TypographyProps &
    ShadowProps &
    ButtonHTMLAttributes<HTMLButtonElement>
>(
  variant({
    scale: "actionLink",
    variants: {
      primary: {
        color: "primary",
        lineHeight: "body",
        fontSize: "body",
        px: 0,
        fontWeight: "medium",
        ">:only-child": {
          color: "primary",
        },
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
        },
        "&:hover": {
          borderBottom: "1px solid",
          borderBottomColor: "primary",
        },
        "&:active": {
          color: "hydroDarker",
          borderBottom: "1px solid",
          borderBottomColor: "hydroDarker",
          ">:only-child": {
            color: "hydroDarker",
          },
        },
        "&:visited": {
          color: "hydroLight",
          borderBottomColor: "hydroLight",
          ">:only-child": {
            color: "hydroLight",
          },
        },
        "&:disabled": {
          color: "neutrals.mn40",
          ">:only-child": {
            color: "neutrals.mn40",
          },
        },
      },
      secondary: {
        color: "nightSky",
        lineHeight: "body",
        fontSize: "body",
        px: 0,
        fontWeight: "medium",
        ">:only-child": {
          color: "nightSky",
        },
        "&:focus": {
          borderColor: "nightSky",
          boxShadow: "inputFocus",
        },
        "&:hover": {
          borderBottom: "1px solid",
          borderBottomColor: "nightSky",
        },
        "&:active": {
          color: "nightSkyLight",
          borderBottom: "1px solid",
          borderBottomColor: "nightSkyLight",
          ">:only-child": {
            color: "nightSkyLight",
          },
        },
        "&:visited": {
          color: "nightSkyLight",
          borderBottomColor: "nightSkyLight",
          ">:only-child": {
            color: "nightSkyLight",
          },
        },
        "&:disabled": {
          color: "neutrals.mn40",
          ">:only-child": {
            color: "neutrals.mn40",
          },
        },
      },
    },
  }),
);

export const ActionLink: React.FC<
  ActionLinkProps &
    SpaceProps &
    SizeProps &
    BorderProps &
    LayoutProps &
    TypographyProps &
    ShadowProps &
    FlexboxProps &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({ variant, iconLeft, iconRight, children, ...props }) => {
  return (
    <Component
      type="button"
      verticalAlign="middle"
      variant={variant}
      {...props}
    >
      {iconLeft && <Icon icon={iconLeft} mr={2} />}
      {children}
      {iconRight && <Icon icon={iconRight} ml={2} />}
    </Component>
  );
};

ActionLink.defaultProps = {
  variant: "primary",
  disabled: false,
};
