import React from "react";
import { ServiceTypePage, ServiceTypeProps } from "./ServiceTypePage";

export const DEFAULT_SERVICE_TYPE_CONTENT: ServiceTypeProps = {
  tabTitle: "",
};

export const ServiceTypePageContainer: React.FC = () => {
  return <ServiceTypePage {...DEFAULT_SERVICE_TYPE_CONTENT} />;
};
