import React, { useEffect, useState } from "react";
import { BasicNotificationBannerProps } from "../BasicNotificationBanner/BasicNotificationBanner";
import { FloatingBanner } from "./FloatingBanner";

export interface FloatingBannerProps extends BasicNotificationBannerProps {
  visible: boolean;
  onBannerClose?: () => void;
}

const BANNER_AUTO_DISMISS_TIME_MILLISECONDS = 5000;
const BANNER_RENDER_DELAY_TIME_MILLISECONDS = 1000;

/**
 * Has to be relative to the main parent on page in order to render correctly at the bottom of the page
 */
export const FloatingBannerContainer: React.FC<FloatingBannerProps> = ({
  visible,
  onBannerClose,
  ...props
}) => {
  const [showBanner, setShowBanner] = useState(false);
  const [renderBanner, setRenderBanner] = useState(false);

  const closeBanner = () => {
    // Slight delay before removing element from DOM
    // This allows sufficient time for animations to complete (hiding banner)
    setTimeout(() => {
      setRenderBanner(false);
      onBannerClose && onBannerClose();
    }, BANNER_RENDER_DELAY_TIME_MILLISECONDS);
  };

  useEffect(() => {
    setShowBanner(visible);
    setRenderBanner(visible);
  }, [visible]);

  useEffect(() => {
    if (!renderBanner) {
      return;
    }

    // Automatically dismiss the banner after x seconds if it is visible
    setTimeout(() => {
      setShowBanner(false);
      closeBanner();
    }, BANNER_AUTO_DISMISS_TIME_MILLISECONDS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderBanner]);

  const handleOnBannerClose = () => {
    closeBanner();
  };

  return (
    <>
      {renderBanner && (
        <FloatingBanner
          showBanner={showBanner}
          onBannerClose={handleOnBannerClose}
          {...props}
        />
      )}
    </>
  );
};
