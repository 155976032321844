import { Field } from "formik";
import React from "react";
import { LIVENING_ICP_UPLOAD_TEMPLATE_PATH } from "../../constants/LiveningIcpOrder";
import { Flex } from "../Flex";
import { FormikFileUpload } from "../FormikFileUpload";
import { Link } from "../Link";
import { Span } from "../Span";

interface Props {
  siteIndex: number;
  onStatusChange?: (filesUploadStatus: FileSelectUploadStatus) => void;
}

export const SiteUnitsUpload: React.FC<Props> = ({
  siteIndex,
  onStatusChange,
}) => {
  return (
    <Flex flexDirection="column">
      <Span lineHeight={2} mb={3}>
        Upload fuse and phase information.
      </Span>

      <Span lineHeight={2} mb={3}>
        Please download our{" "}
        <Link href={LIVENING_ICP_UPLOAD_TEMPLATE_PATH}>template file</Link>,
        fill it out and then upload the file below (max. 5 files, xlsx, xls or
        ods format). 10Mb max per file.
      </Span>

      <Field
        id={`metered.sites[${siteIndex}].uploadUnits`}
        name={`metered.sites[${siteIndex}].uploadUnits`}
        acceptFileTypes={[
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.oasis.opendocument.spreadsheet",
        ]}
        attachmentType={"ICP unit details"}
        onStatusChange={onStatusChange}
        component={FormikFileUpload}
      />
    </Flex>
  );
};
