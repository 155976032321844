/**
 * This is the baseline theme object that is designed to provide the raw theme
 * values alongside some common utilities and baseline definitions to meet the
 * Theme UI Spec (see: https://system-ui.com/theme/)
 * Reference table: https://styled-system.com/table
 */

// Note: ..Alt colours are special-use colours for contrast-ratio purposes on certain backgrounds.
const baseColors = {
  nightSky: "#000840",
  nightSkyLight: "#0D2E54",
  newEnergy: "#0DCF5A",
  newEnergy25: "#C2F3D6",
  newEnergyLight: "#0CDF6E",
  hydro: "#1F79FF",
  hydroLight: "#1E97FF",
  hydroDark: "#0053DE",
  hydroDarker: "#004AC7",
  hydro10: "rgba(31,121,255,0.1)",
  hydro25: "#C7DDFF",
  solar: "#00B5E0",
  solarLight: "#00C5EE",
  urgentRed: "#E64B38",
  urgentRed25: "#F9D2CD",
  orangeAlert: "#FF6C0E",
  sunshine: "#FFA400",
  optimism: "#F7BE00",
  optimism25: "#FDEFBF",
  black: "#535249",
  greyWhite: "#E4E4E4",
  gradient:
    "linear-gradient(rgba(31, 121, 225, 1.0) 4%, rgba(0, 181, 224, 1.0) 29%, rgba(13, 207, 90, 1.0) 95%)",
  neutrals: {
    ln0: "#FFFFFF",
    ln10: "#FAFBFC",
    ln20: "#F4F5F7",
    ln2090: "rgba(244, 245, 247, 0.9)",
    ln30: "#EBECF0",
    mn20: "#B3BAC5",
    mn40: "#97A0AF",
    mn60: "#7A869A",
    mn80: "#5E6C84",
    mn90: "#505F79",
    ln40: "#DFE1E6",
    dn40: "#42526E",
    dn50: "#344563",
  },
};

const colors = {
  ...baseColors,
  text: baseColors.black,
  background: baseColors.neutrals.ln0,
  primary: baseColors.hydroDark,
  secondary: baseColors.newEnergy,
  tertiary: baseColors.solar,
  accent: baseColors.hydro,
  disabledBackground: baseColors.neutrals.ln20,
  disabledBorder: baseColors.neutrals.ln40,
  disabledColor: baseColors.neutrals.mn20,
  danger: baseColors.urgentRed,
  hover: baseColors.hydro,
  active: baseColors.hydroLight,
  white: baseColors.neutrals.ln0,
};

const fonts = {
  current: "Montserrat, sans-serif",
};

const fontWeights = {
  regular: 400,
  medium: 500,
  heavy: 700,
  bold: "bold",
};

export interface AliasedFontSizes extends Array<string> {
  title?: string;
  heading?: string;
  body?: string;
}

const fontSizes: AliasedFontSizes = [
  "0.75rem", // 12px
  "0.88rem", // 14px
  "1.00rem", // 16px
  "1.13rem", // 18px
  "1.25rem", // 20px
  "1.50rem", // 24px
  "1.75rem", // 28px
  "2.00rem", // 32px
  "2.50rem", // 40px
  "3.00rem", // 48px
  "3.50rem", // 56px
  "4.19rem", // 67px
];
fontSizes.title = fontSizes[11];
fontSizes.heading = fontSizes[9];
fontSizes.body = fontSizes[2];

export interface AliasedLineHeights extends Array<string> {
  title?: string;
  heading?: string;
  body?: string;
}

const lineHeights: AliasedLineHeights = [
  "1.13rem", // 18px
  "1.31rem", // 21px
  "1.50rem", // 24px
  "1.69rem", // 26px
  "1.88rem", // 30px
  "2.25rem", // 36px
  "2.63rem", // 42px
  "3.00rem", // 48px
  "3.75rem", // 60px
  "4.50rem", // 72px
  "5.25rem", // 84px
  "6.31rem", // 101px
];
lineHeights.title = lineHeights[10];
lineHeights.heading = lineHeights[7];
lineHeights.body = lineHeights[2];

// eslint-disable-next-line max-len
// margin, margin-top, margin-right, margin-bottom, margin-left, padding, padding-top, padding-right, padding-bottom, padding-left, grid-gap, grid-column-gap, grid-row-gap
const space = [0, 4, 8, 16, 24, 32, 48, 64, 96];

// width, height, min-width, max-width, min-height, max-height
const sizes = [
  0, 2, 4, 8, 12, 16, 20, 24, 28, 30, 32, 40, 48, 56, 62, 64, 80, 96,
];

const shadows = {
  baseFocus: "0px 0px 0px 4px rgba(0, 83, 222, 0.25)",
  inputFocus: "0px 0px 0px 4px rgba(0, 83, 222, 0.25)",
  inputFocusLight: "0px 0px 0px 4px rgba(0, 197, 238, 0.25)",
  inputFocusWhite: "0px 0px 0px 4px rgba(255, 255, 255, 0.25)",
  modal: "0px 8px 24px rgba(13, 46, 84, 0.08",
  menu: "0px 8px 24px rgba(13, 46, 84, 0.08)",
};

// the breakpoints come from
// https://www.figma.com/file/omCaNpCUE0v9LvvDzzIm7f/Vector-UI-Kits?node-id=796%3A8
const breakpoints = ["375px", "600px", "900px", "1200px"];

export const mediaQueries = {
  // Small devices (landscape phones, 375px and up)
  smallDevice: `@media screen and (min-width: ${breakpoints[0]})`,
  // Medium devices (tablets, 600px and up)
  mediumDevice: `@media screen and (min-width: ${breakpoints[1]})`,
  // Large devices (desktops, 900px and up)
  largeDevice: `@media screen and (min-width: ${breakpoints[2]})`,
  // Extra large devices (large desktops, 1200px and up)
  extraLargeDevice: `@media screen and (min-width: ${breakpoints[3]})`,
};

const radii = {
  button: 4,
  input: 6,
  radio: 12,
  checkbox: 20,
  checkboxWithBorder: 22,
  squareCheckbox: 6,
  fileType: 3,
  notificationBanner: 12,
  menu: 12,
  infoSection: 4,
};

const borderStyles = [
  "dotted",
  "dashed",
  "solid",
  "double",
  "groove",
  "ridge",
  "inset",
  "outset",
  "none",
  "hidden",
];

const zIndices = ["-1"];

export const theme = {
  // Structure & Utility
  space,
  sizes,
  breakpoints,
  mediaQueries,
  radii,
  shadows,
  zIndices,
  // Colours
  colors,
  // Typography
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  // Elements
  // Border Styles
  borderStyles,
};
