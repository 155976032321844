import React, { HTMLAttributes } from "react";
import {
  ColorProps,
  FlexboxProps,
  SpaceProps,
  TypographyProps,
} from "styled-system";
import { Box } from "../Box";
import { DataTest } from "../../types/DataTest";

interface Props {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export const Title: React.FC<
  Props &
    ColorProps &
    FlexboxProps &
    TypographyProps &
    SpaceProps &
    DataTest &
    HTMLAttributes<HTMLHeadingElement>
> = ({ as, dataTest, ...props }) => (
  <Box as={as} data-test={dataTest} {...props}>
    {props.children}
  </Box>
);

Title.defaultProps = {
  as: "h1",
};
