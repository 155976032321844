import { FieldProps, getIn } from "formik";
import React, { ButtonHTMLAttributes } from "react";
import { Dropdown } from "../Dropdown";
import { DropdownProps } from "../Dropdown/Dropdown";
import { FlexboxProps, FlexGrowProps, LayoutProps } from "styled-system";
import { GAEvent, sendGAEvent } from "../../utils/ga/GaEventSender";

interface Props {
  gaEvent?: GAEvent;
}

export const FormikDropdown: React.FC<
  Props &
    DropdownProps &
    FieldProps &
    LayoutProps &
    FlexboxProps &
    FlexGrowProps &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({ field, form, label, items, gaEvent, ...props }) => {
  const handleOnSelect = (selected?: string | null | undefined) => {
    if (gaEvent) {
      sendGAEvent(gaEvent);
    }

    form.setFieldValue(field.name, selected, true);
  };

  const handleOnBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);

  return (
    <Dropdown
      {...field}
      {...props}
      onSelect={handleOnSelect}
      onBlur={handleOnBlur}
      error={touch && error ? error : false}
      label={label}
      items={items}
    />
  );
};
