import { Field, ErrorMessage } from "formik";
import React from "react";
import { Flex } from "../../../Flex";
import { FormikInputText } from "../../../FormikInputText";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";

export const FeatureTypeOther: React.FC = () => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={0.7}
      flexBasis={0}
      minWidth={["100%", "100%", "100%", 0]}
    >
      <Field
        id="unmetered.featureTypeOther"
        name="unmetered.featureTypeOther"
        label="'Other' details"
        placeholder="Please provide details"
        maxLength={100}
        component={FormikInputText}
      />
      <ErrorMessage
        name="unmetered.featureTypeOther"
        className="field-error"
        component={FormInputErrorMessage}
      />
    </Flex>
  );
};
