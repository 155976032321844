import { FieldArrayRenderProps } from "formik";
import React from "react";
import { EMPTY_NEW_CONNECTION_CONTACT_DETAILS_VALUES } from "../../../../constants/ContactDetailsValues";
import { NewConnectionContactDetailsValues } from "../../../../types/NewConnectionContactDetailsValues";
import { ContactDetailsFormAddContact } from "../../../ContactDetailsForm/ContactDetailsFormAddContact";
// eslint-disable-next-line max-len
import {
  consultantRoleSelectedInAllContactDetails,
  customerRoleSelectedInAllContactDetails,
  newConnectionRolesSelectedInAllContactDetails,
  quotePayerRoleSelectedInAllContactDetails,
  siteAccessContactRoleSelectedInAllContactDetails,
} from "../../../ContactDetailsForm/ContactDetailsRoleChecker/ContactDetailsRoleChecker";
// eslint-disable-next-line max-len
import { calculateSelectableNewConnectionRoles } from "../../../ContactDetailsForm/RemainingRolesCalculator/RemainingRolesCalculator";
import { checksNewConnectionSelectedRole } from "../../../CheckSelectedRolesUtil/CheckSelectedRolesUtil";
import { getNewConnectionAddContactButtonTitle } from "../../../AddContactButtonTitleUtil/AddContactButtonTitleUtil";

interface Props {
  contactDetails: NewConnectionContactDetailsValues[];
  arrayHelpers: FieldArrayRenderProps;
  tabIndex?: number;
  focusable?: boolean;
  newConnectionType: string;
}

export const NewConnectionAddContact: React.FC<Props> = ({
  contactDetails,
  arrayHelpers,
  tabIndex,
  focusable,
  newConnectionType,
}) => {
  const newConnectionRoles = contactDetails.map((details) => details.roles);

  const areAnyRolesSelected =
    newConnectionRolesSelectedInAllContactDetails(newConnectionRoles);

  const remainingSelectableRoles = calculateSelectableNewConnectionRoles(
    newConnectionRoles,
    newConnectionType,
  );

  const hasSiteAccessContactRoleBeenSelected =
    siteAccessContactRoleSelectedInAllContactDetails(newConnectionRoles);

  const hasQuotePayerRoleRoleBeenSelected =
    quotePayerRoleSelectedInAllContactDetails(newConnectionRoles);

  const hasCustomerRoleBeenSelected =
    customerRoleSelectedInAllContactDetails(newConnectionRoles);

  const hasConsultantRoleBeenSelected =
    consultantRoleSelectedInAllContactDetails(newConnectionRoles);

  const actionLinkRoleTitle = getNewConnectionAddContactButtonTitle(
    contactDetails.length,
    remainingSelectableRoles,
    hasSiteAccessContactRoleBeenSelected,
    hasQuotePayerRoleRoleBeenSelected,
    hasConsultantRoleBeenSelected,
    hasCustomerRoleBeenSelected,
  );

  const handleOnClick = () => {
    const roles = checksNewConnectionSelectedRole(
      remainingSelectableRoles,
      hasSiteAccessContactRoleBeenSelected,
      hasQuotePayerRoleRoleBeenSelected,
      hasCustomerRoleBeenSelected,
      hasConsultantRoleBeenSelected,
      newConnectionType,
    );

    const newConnectionContactDetailsValues =
      EMPTY_NEW_CONNECTION_CONTACT_DETAILS_VALUES;

    newConnectionContactDetailsValues.roles = roles;

    arrayHelpers.push(newConnectionContactDetailsValues);
  };

  return (
    <ContactDetailsFormAddContact
      anyRolesSelected={areAnyRolesSelected}
      remainingSelectableRoles={remainingSelectableRoles}
      actionLinkRoleTitle={actionLinkRoleTitle}
      tabIndex={tabIndex}
      focusable={focusable}
      onClick={handleOnClick}
    />
  );
};

NewConnectionAddContact.defaultProps = {
  tabIndex: 0,
  focusable: true,
};
