import { LiveningIcpFormValues } from "../../types/LiveningIcpFormValues";
import { UserBasicDetails } from "../../types/UserBasicDetails";
import { NewConnectionFormValues } from "../../types/NewConnectionFormValues";

export const setRequesterDetailsLiveningIcp = (
  values: LiveningIcpFormValues,
  userBasicDetails: UserBasicDetails,
): LiveningIcpFormValues => {
  if (!userBasicDetails.firstName && !userBasicDetails.lastName) {
    return values;
  }

  values.contactDetails[0].firstName = userBasicDetails.firstName;
  values.contactDetails[0].lastName = userBasicDetails.lastName;
  values.contactDetails[0].businessName = userBasicDetails.businessName;
  values.contactDetails[0].mobile = userBasicDetails.mobile;
  values.contactDetails[0].alternatePhone = userBasicDetails.alternatePhone;
  values.contactDetails[0].email = userBasicDetails.email;

  return values;
};

export const setRequesterDetailsNewConnection = (
  values: NewConnectionFormValues,
  userBasicDetails: UserBasicDetails,
): NewConnectionFormValues => {
  if (!userBasicDetails.firstName && !userBasicDetails.lastName) {
    return values;
  }

  values.contactDetails[0].firstName = userBasicDetails.firstName;
  values.contactDetails[0].lastName = userBasicDetails.lastName;
  values.contactDetails[0].businessName = userBasicDetails.businessName;
  values.contactDetails[0].mobile = userBasicDetails.mobile;
  values.contactDetails[0].alternatePhone = userBasicDetails.alternatePhone;
  values.contactDetails[0].email = userBasicDetails.email;
  values.contactDetails[0].billingAddressDisplay =
    userBasicDetails.billingAddress;
  values.contactDetails[0].businessNumber = userBasicDetails.companyNzbn;

  return values;
};
