import { createGaEvent } from "./GaEventFactory";
import { GAEvent } from "./GaEventSender";

export const getGaIcpTypeEvent = (icpType: string): GAEvent => {
  const eventLabel =
    icpType === "metered ICP" ? "E_ICP_metered" : "E_ICP_unmetered";

  return createGaEvent("select_service_sub_type", eventLabel);
};

export const getGaMeteredUnitManualEvent = (): GAEvent => {
  return createGaEvent("select_unit_info", "E_ICP_metered_manual");
};

export const getGaMeteredUnitUploadEvent = (): GAEvent => {
  return createGaEvent("select_unit_info", "E_ICP_metered_upload");
};

export const getGaAddSiteEvent = (): GAEvent => {
  return createGaEvent("select_site", "E_ICP_new_site");
};

export const getGaEditOrderDetailsEvent = (): GAEvent => {
  return createGaEvent("edit_order", "E_ICP_order_details");
};

export const getGaEditSiteDetailsEvent = (): GAEvent => {
  return createGaEvent("edit_order", "E_ICP_site_details");
};

export const getGaEditContactDetailsEvent = (): GAEvent => {
  return createGaEvent("edit_order", "E_ICP_contacts");
};

export const getGaEditInspectorRetailerEvent = (): GAEvent => {
  return createGaEvent("edit_order", "E_ICP_livening_retailer");
};

export const getGaSubmitSuccessEvent = (): GAEvent => {
  return createGaEvent("submit_order", "E_ICP_submit_order_successful");
};

export const getGaSubmitFailedEvent = (): GAEvent => {
  return createGaEvent("submit_order", "E_ICP_submit_order_failed");
};

export const getGaSaveDraftSuccessEvent = (): GAEvent => {
  return createGaEvent("save_draft_order", "E_ICP_save_draft_successful");
};

export const getGaSaveDraftFailedEvent = (): GAEvent => {
  return createGaEvent("save_draft_order", "E_ICP_save_draft_failed");
};