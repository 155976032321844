import {
  LiveningIcpRoles,
  NewConnectionRoles,
} from "../../types/ContactDetailRoles";

export const checksLiveningIcpSelectedRole = (
  remainingSelectableRoles: number,
  hasSiteAccessContactRoleBeenSelected: boolean,
  hasPowerRetailerAccountHolderRoleBeenSelected: boolean,
): LiveningIcpRoles => {
  const roles = {
    requester: false,
    powerRetailerAccountHolderRole: false,
    siteAccessContactRole: false,
  };

  if (remainingSelectableRoles === 1) {
    roles.powerRetailerAccountHolderRole =
      !hasPowerRetailerAccountHolderRoleBeenSelected;
    roles.siteAccessContactRole = !hasSiteAccessContactRoleBeenSelected;
  }

  return roles;
};

export const checksNewConnectionSelectedRole = (
  remainingSelectableRoles: number,
  hasSiteAccessContactRoleBeenSelected: boolean,
  hasQuotePayerRoleRoleBeenSelected: boolean,
  hasCustomerRoleBeenSelected: boolean,
  hasConsultantRoleBeenSelected: boolean,
  newConnectionType: string,
): NewConnectionRoles => {
  const roles = {
    requester: false,
    siteAccessContactRole: false,
    quotePayerRole: false,
    customerRole: false,
    consultantRole: false,
  };

  if (remainingSelectableRoles === 1 && newConnectionType === "simple") {
    roles.quotePayerRole = !hasQuotePayerRoleRoleBeenSelected;
    roles.siteAccessContactRole = !hasSiteAccessContactRoleBeenSelected;
  }

  if (remainingSelectableRoles === 1 && newConnectionType === "complex") {
    roles.customerRole = !hasCustomerRoleBeenSelected;
    roles.consultantRole = !hasConsultantRoleBeenSelected;
  }

  return roles;
};
