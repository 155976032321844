import React from "react";
import { Field } from "formik";
import { PostalAddress } from "../PostalAddress";
import { FormikActionLink } from "../FormikActionLink";
import { ManualBillingAddressFields } from "../ManualBillingAddressFields";

interface Props {
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  shouldDisableContactDetails?: boolean;
  isManualAddress: boolean;
  validateField: (field: string) => void;
  handleActionLinkClick: () => void;
  infoMessage?: string;
}

export const BillingAddressSection: React.FC<Props> = ({
  fieldIdPrefix,
  fieldNamePrefix,
  shouldDisableContactDetails,
  isManualAddress,
  validateField,
  handleActionLinkClick,
  infoMessage,
}) => {
  return (
    <>
      <PostalAddress
        fieldIdPrefix={`${fieldIdPrefix}`}
        fieldNamePrefix={`${fieldNamePrefix}`}
        validateField={validateField}
        disabled={shouldDisableContactDetails || isManualAddress}
        data-testid="postal-address-search"
        infoMessage={infoMessage}
      />

      <Field
        id={`${fieldIdPrefix}.manuallyEnteredBillingAddress`}
        name={`${fieldNamePrefix}.manuallyEnteredBillingAddress`}
        iconLeft={` ${!isManualAddress ? "add_circle" : "remove_circle"}`}
        label={`${
          !isManualAddress
            ? "Add billing address manually"
            : "Remove manually entered billing address"
        }`}
        alignSelf="flex-start"
        mt={2}
        mb={[3, 3, 3, 3, 4]}
        onClick={handleActionLinkClick}
        component={FormikActionLink}
        disabled={shouldDisableContactDetails}
        data-testid="manual-address-button-field"
      />

      {isManualAddress && (
        <ManualBillingAddressFields
          fieldIdPrefix={`${fieldIdPrefix}`}
          fieldNamePrefix={`${fieldNamePrefix}`}
          disabled={shouldDisableContactDetails}
        />
      )}
    </>
  );
};

BillingAddressSection.defaultProps = {
  shouldDisableContactDetails: false,
};
