import React from "react";
import { Flex } from "../../components/Flex";
import { Button } from "../../components/Button";
import { useHistory } from "react-router-dom";
import { SomethingWentWrong } from "../../components/SomethingWentWrong/SomethingWentWrong";

export const PaymentErrorPage: React.FC = () => {
  const history = useHistory();

  const redirect = () => {
    history.push("/");
  };

  return (
    <Flex flexDirection="column">
      <SomethingWentWrong
        heading="This link is not valid anymore"
        subHeading="This link has already been actioned, or it has expired."
        message="If it has expired, you will need to submit a new order. If you have any questions, please contact us on 0508 832 867"
        contactEmailAddress="tech.support@vector.co.nz"
      />

      <Flex justifyContent="center">
        <Button
          data-testid="order-service-button"
          alignSelf="flex-end"
          variant="secondary"
          magnitude="medium"
          mt={4}
          onClick={redirect}
        >
          Order service
        </Button>
      </Flex>
    </Flex>
  );
};
