import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { mediaQueries } from "../../theme";
import { ActionButton } from "../ActionButton";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { BasicNotificationBannerProps } from "../BasicNotificationBanner/BasicNotificationBanner";
import { Flex } from "../Flex";

interface Props extends BasicNotificationBannerProps {
  showBanner: boolean;
  onBannerClose: () => void;
}

const slideInAnimation = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  75% {
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideOutAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
`;

const StyledFloatingBanner = styled(Flex)<{ isVisible: boolean }>`
  position: sticky;
  top: 75vh;
  z-index: 9999;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);

  ${mediaQueries.mediumDevice} {
    width: 374px;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${slideInAnimation} 0.25s ease-out forwards;
    `}

  ${({ isVisible }) =>
    !isVisible &&
    css`
      animation: ${slideOutAnimation} 0.25s ease-in forwards;
    `}
`;

export const FloatingBanner: React.FC<Props> = ({
  showBanner,
  onBannerClose,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleCloseClick = () => {
    onBannerClose();
    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(showBanner);
  }, [showBanner]);

  return (
    <StyledFloatingBanner
      borderRadius="notificationBanner"
      flexDirection="column"
      my={[2, 2, 1]}
      mx={[2, 2, 1]}
      left={["0", "0", "2vw"]}
      isVisible={isVisible}
      data-testid="floating-banner"
    >
      <BasicNotificationBanner
        {...props}
        closeButton={
          <ActionButton
            icon="close"
            variant="transparent"
            color="neutrals.dn40"
            onClick={handleCloseClick}
            ml="auto"
          />
        }
      />
    </StyledFloatingBanner>
  );
};
