import React from "react";
import { Flex } from "../Flex";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { Redirect, Route, Switch } from "react-router-dom";
import { OpenOrders } from "./_/OpenOrders";
import { ClosedOrders } from "./_/ClosedOrders";
import { UnsubmittedOrders } from "./_/UnsubmittedOrders";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.largeDevice} {
    margin-left: 18em;
    height: calc(100vh - 82px);
  }
`;

export const OrderDetailsList: React.FC = () => {
  return (
    <FlexWrapper flexDirection="column" mx={4}>
      <Switch>
        <Route exact path="/order-details-list">
          <Redirect to="/order-details-list/open-orders" />
        </Route>
        <Route
          exact
          path="/order-details-list/open-orders"
          component={OpenOrders}
        />
        <Route
          exact
          path="/order-details-list/closed-orders"
          component={ClosedOrders}
        />
        <Route
          exact
          path="/order-details-list/draft-orders"
          component={UnsubmittedOrders}
        />
      </Switch>
    </FlexWrapper>
  );
};
