import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "../Button";
import env from "../../environment";
import { LayoutProps, SpaceProps, TypographyProps } from "styled-system";
import { ButtonProps } from "../Button/Button";
import { sessionStorageWrapper } from "../../utils/sessionStorageWrapper/sessionStorageWrapper";

export const LogoutButton: React.FC<
  ButtonProps & LayoutProps & SpaceProps & TypographyProps
> = ({ ...props }) => {
  const { logout } = useAuth0();

  const handleOnClick = () => {
    sessionStorageWrapper.removeItem("ecssQueryCache");
    logout({ returnTo: env.auth0LogoutUri });
  };

  return (
    <Button {...props} onClick={handleOnClick}>
      Sign out
    </Button>
  );
};

LogoutButton.defaultProps = {
  variant: "ghost",
  magnitude: "medium",
  iconLeft: "logout",
};
