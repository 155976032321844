import {
  InvalidateOptions,
  InvalidateQueryFilters,
  useQueryClient,
} from "@tanstack/react-query";
import { ReactQueryKeys } from "../../../constants/ReactQueryKeys";

export const useQueryInvalidator = () => {
  const queryClient = useQueryClient();

  /***
   * use to invalidate queries that match the key/s.
   * Array keys are hashed deterministically, so the order of the keys does not matter inside objects, but matters for array
   * items
   *
   * relevant docs - https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientinvalidatequeries
   * @param queryKey one of the primary query keys used throughout the app
   * @param filterOptions
   * @param invalidateOptions
   * @param additionalKeys additional keys to further filter the query key
   */
  const invalidateQuery = async <T = unknown>(
    queryKey: ReactQueryKeys | ReactQueryKeys[],
    filterOptions?: InvalidateQueryFilters<T>,
    invalidateOptions?: InvalidateOptions,
    ...additionalKeys: string[]
  ) => {
    const mainQueryKey = Array.isArray(queryKey) ? [...queryKey] : [queryKey];

    await queryClient.invalidateQueries(
      [...mainQueryKey, ...additionalKeys],
      filterOptions,
      invalidateOptions,
    );
  };

  return { invalidateQuery };
};
