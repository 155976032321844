import React from "react";
import { ContactDetailsValues } from "../../types/ContactDetailsValues";
import { ContactDetailsDisplay } from "../ContactDetailsDisplay";

interface Props {
  contactDetails: ContactDetailsValues;
}

export const ReviewFormContactDetails: React.FC<Props> = ({
  contactDetails,
}) => {
  const {
    firstName,
    lastName,
    email,
    mobile,
    alternatePhone,
    businessName,
    businessNumber,
    billingAddress,
    workContactPreferences,
    quoteContactPreferences,
  } = contactDetails;

  return (
    <ContactDetailsDisplay
      firstName={firstName}
      lastName={lastName}
      email={email}
      mobile={mobile}
      alternatePhone={alternatePhone}
      businessName={businessName}
      businessNumber={businessNumber}
      billingAddress={billingAddress}
      workContactPreferences={workContactPreferences}
      quoteContactPreferences={quoteContactPreferences}
    />
  );
};
