import React from "react";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { LiveningIcpFormValues } from "../../types/LiveningIcpFormValues";
import { LiveningIcpSite } from "../../types/LiveningIcpSite";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import { Line } from "../Line";
import { Link } from "../Link";
import {
  getAddressInformation,
  getFormattedAddress,
} from "../LiveningIcpForm/_/LiveningIcpReviewForm/_/FormattedAddressUtil/FormattedAddressUtil";
import { LiveningIcpProgressStepper } from "../LiveningIcpProgressStepper";
import { Span } from "../Span";
import { LiveningIcpThankYouOrder } from "./_/LiveningIcpThankYouOrder";
import { Title } from "../Title";
import { QualtricsPlaceHolder } from "../QualtricsPlaceHolder";
import { Page } from "../Page";
import { IconPopupTip } from "../IconPopupTip";
import { Button } from "../Button";
import { useHistory } from "react-router-dom";

interface Props {
  values: LiveningIcpFormValues;
}

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 30vw auto 15vw;
  }
`;

const StyledOrderService = styled(Flex)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

export const LiveningIcpThankYou: React.FC<Props> = ({ values }) => {
  const history = useHistory();

  const { icpType, metered, unmetered } = values;

  const handleOrderServiceClick = () => {
    history.push("/service-type");
  };

  return (
    <Page>
      <LiveningIcpProgressStepper currentStep={4} />

      <GridWrapper mx={[2, 2, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          <Title as="h1" lineHeight={6} fontWeight="500">
            Thank you for your order
          </Title>

          <p>
            Please note that separate orders have been created for each
            site/location and you will receive a confirmation email for each
            order. <br /> <br /> Based on the information provided, we have
            assigned a type of service per site/location. <br />
            <br /> Once we have assessed your order, your type of service might
            change but we will update you if this is the case.
          </p>

          <Flex justifyContent="space-between" alignItems="center" my={4}>
            <Title as="h2" lineHeight={7} fontWeight="500">
              Your order(s)
            </Title>

            <Flex alignItems="center">
              <IconPopupTip
                label="Type of service"
                icon="help"
                alignItems="flex-start"
              >
                A livening connection is where we provide a unique installation
                point (ICP) and organise the permanent or temporary livening of
                the connection with your power retailer.
              </IconPopupTip>
            </Flex>
          </Flex>

          {icpType === "metered ICP" &&
            metered.sites.map((site: LiveningIcpSite, index: number) => (
              <LiveningIcpThankYouOrder
                key={index}
                siteIndex={index + 1}
                siteAddress={getFormattedAddress(site)}
                serviceType="Livening (ICP number)"
              />
            ))}

          {icpType === "unmetered ICP" && (
            <LiveningIcpThankYouOrder
              siteAddress={getAddressInformation(unmetered.addressInformation)}
              serviceType="Livening (ICP number)"
            />
          )}

          <Span my={5}>
            If you have any questions, please contact us on{" "}
            <Link href="tel:0508 832 867">0508 832 867</Link>.
          </Span>

          <StyledOrderService
            px={4}
            py={3}
            borderRadius="infoSection"
            flexDirection="column"
          >
            <Span fontSize={4} lineHeight={4} mb={3}>
              Do you want to order another service?
            </Span>

            <Span>
              You can keep ordering other services through the button below.
            </Span>

            <Flex marginRight="auto">
              <Button
                iconLeft="add"
                variant="primary"
                magnitude="medium"
                mt={3}
                data-testid="order-service-button"
                onClick={handleOrderServiceClick}
              >
                New Order
              </Button>
            </Flex>
          </StyledOrderService>

          <Line
            width="100%"
            my={5}
            zIndex={0}
            border="1px solid"
            borderColor="neutrals.ln30"
            bg="neutrals.ln30"
            opacity="1"
            height={0}
          />

          <Flex flexDirection="column" pb={6}>
            <Title as="h2" my={3}>
              Feedback
            </Title>
            <QualtricsPlaceHolder />
          </Flex>
        </FlexWrapper>
      </GridWrapper>
    </Page>
  );
};
