import { useUserAuthentication } from "../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { getOrderList } from "../../../services/OrderService";
import moment from "moment";
import { mapStringToOrderStatus } from "../../../utils/orderStatusMapper/orderStatusMapper";
import { useQuery } from "@tanstack/react-query";
import { OrdersResponse } from "../../../types/Order";
import { EMPTY_ORDERS_RESPONSE } from "../../../constants/Orders";
import { ReactQueryKeys } from "../../../constants/ReactQueryKeys";

export const useGetOrderList = (
  orderStatuses: OrderProgressStatus[] = ["in progress", "on hold"],
) => {
  const queryKey: ReactQueryKeys = "orders";

  const {
    authenticatedUserContext: {
      userAuth0Details: { accessToken },
    },
  } = useUserAuthentication();

  const filterAndTransformOrders = (orders: OrdersResponse) => {
    const transformed = orders.orderList
      .filter((order) => {
        const orderStatus = mapStringToOrderStatus(order.jobStatus);
        return orderStatuses.includes(orderStatus);
      })
      .map((order) => ({
        ...order,
        requestDate: moment(order.requestDate).format("DD/MM/YYYY"),
      }));

    return { orderList: transformed };
  };

  /***
   * useQuery docs - https://tanstack.com/query/v4/docs/react/reference/useQuery
   */
  const {
    data: orders,
    isError: apiError,
    isLoading: loading,
  } = useQuery<OrdersResponse>({
    queryKey: [queryKey, accessToken],
    queryFn: () => getOrderList(accessToken),
    select: filterAndTransformOrders,
    enabled: !!accessToken,
  });

  return {
    orders: orders ?? EMPTY_ORDERS_RESPONSE,
    apiError,
    loading,
  };
};
