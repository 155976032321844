const MOBILE_REG_STRING = "^02([ ]*[0-9]){7,9}$";

const LANDLINE_REG_STRING =
  "^[+]?(64|0064)?(03|04|06|07|09){1}([ ]*[0-9]){7,9}$";

// Matches a wide range of international phone number including signs like + and ()
// eslint-disable-next-line no-useless-escape
const INTERNATIONAL_REG_STRING =
  "^[+]?([0-9]{2,4})?([(]?[0-9]{3}[)]?)[0-9]{3}[0-9]{4,6}$";

const ALTERNATE_PHONE_REG_STRING = `(${MOBILE_REG_STRING})|(${LANDLINE_REG_STRING})|(${INTERNATIONAL_REG_STRING})`;

export const MOBILE_REG_EXP = new RegExp(MOBILE_REG_STRING);
export const ALTERNATE_PHONE_REG_EXP = new RegExp(ALTERNATE_PHONE_REG_STRING);
