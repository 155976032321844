import React from "react";
import { HelpCentre } from "../../components/HelpCentre";
import { Page } from "../../components/Page";
import { OrderDetailsListNavigationMenu } from "../../components/OrderDetailsList/_/OrderDetailsListNavigationMenu";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

interface Props {
  tabTitle: string;
}

export const HelpCentrePage: React.FC<Props> = ({ tabTitle }) => {
  const {
    authenticatedUserContext: { isLoggedIn },
  } = useUserAuthentication();

  return (
    <Page
      tabTitle={tabTitle}
      navigationMenuContents={
        isLoggedIn ? () => <OrderDetailsListNavigationMenu /> : undefined
      }
      hasMobileNavMenu={isLoggedIn}
    >
      <HelpCentre />
    </Page>
  );
};
