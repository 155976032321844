import { ManualStreetAddress } from "../../../../../../types/ManualStreetAddress";
import { Site } from "../../../../../../types/Site";
import { SiteAddressInformation } from "../../../../../../types/SiteAddressInformation";
import { SiteLotInformation } from "../../../../../../types/SiteLotInformation";

const getManualAddress = (manualStreetAddress: ManualStreetAddress): string => {
  //eslint-disable-next-line max-len
  let formattedAddress = manualStreetAddress.unit
    ? `${manualStreetAddress.unit} / `
    : "";

  //eslint-disable-next-line max-len
  return (formattedAddress += `${manualStreetAddress.streetNumber} ${manualStreetAddress.streetName}, ${manualStreetAddress.suburb}, ${manualStreetAddress.postcode}`);
};

export const getSiteLotInformation = (
  siteLotInformation: SiteLotInformation,
): string => {
  if (
    siteLotInformation &&
    siteLotInformation.lotNumber &&
    siteLotInformation.dpNumber &&
    siteLotInformation.streetName &&
    siteLotInformation.suburb &&
    siteLotInformation.postcode
  ) {
    //eslint-disable-next-line max-len
    return `Lot ${siteLotInformation.lotNumber}, DP ${
      siteLotInformation.dpNumber
    } / ${
      siteLotInformation.streetNumber ? siteLotInformation.streetNumber + " " : ""
    }${siteLotInformation.streetName}, ${siteLotInformation.suburb}, ${
      siteLotInformation.postcode
    }`;
  }

  return "";
};

export const getAddressInformation = (
  siteAddressInformation: SiteAddressInformation,
): string => {
  if (siteAddressInformation.manuallyEnteredAddress) {
    return getManualAddress(siteAddressInformation.manualStreetAddress);
  }

  return siteAddressInformation.streetAddress;
};

export const getFormattedAddress = (site: Site): string => {
  if (site.siteType === "lotAndDpNumber") {
    return getSiteLotInformation(site.lotInformation);
  } else if (site.siteType === "streetNumberAndName") {
    return getAddressInformation(site.addressInformation);
  } else {
    return "N/A";
  }
};
