import React from "react";
import { SpaceProps } from "styled-system";
import { ActionLink } from "../../../ActionLink";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { Spinner } from "../../../Spinner";

interface Props {
  uploadStatus: UploadStatus;
  onRetry: () => void;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const UploadFileStatus: React.FC<Props & SpaceProps> = ({
  uploadStatus,
  onDelete,
  onRetry,
  ml,
}) => {
  return (
    <Flex ml={ml} alignItems="center">
      {(uploadStatus === "UPLOADING" || uploadStatus === "SCANNING") && (
        <>
          <Span
            data-testid="upload-item-uploading-message"
            color="neutrals.mn80"
            mr={5}
          >
            {uploadStatus === "UPLOADING" && "Uploading..."}
            {uploadStatus === "SCANNING" && "Scanning..."}
          </Span>

          <Span mr={5}>
            <Spinner color="neutrals.mn80" />
          </Span>
        </>
      )}

      {((uploadStatus === "UPLOAD_FAILED" || uploadStatus === "SCAN_FAILED" || uploadStatus === "UPLOAD_NAME_FAILED")) && (
        <>
          <Span
            data-testid="upload-item-upload-failed-message"
            color="urgentRed"
            mr={3}
          >
            {uploadStatus === "UPLOAD_FAILED" && "Failed to upload"}
            {uploadStatus === "SCAN_FAILED" && "File corrupted"}
            {uploadStatus === "UPLOAD_NAME_FAILED" && "Please check the file name is not empty and does not contain any special characters"}
          </Span>
        </>
      )}

      {uploadStatus === "UPLOAD_FAILED" && (
        <ActionLink mr={3} onClick={onRetry}>
          Retry
        </ActionLink>
      )}

      {!(uploadStatus === "UPLOADING" || uploadStatus === "SCANNING") && (
        <ActionLink ml="auto" onClick={onDelete}>
          Delete
        </ActionLink>
      )}
    </Flex>
  );
};
