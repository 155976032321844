import { Order } from "../../types/Order";

export const useFilterOrderList = (orderList: Order[]) => {
  const filterByRequester = (requester: string) => {
    if (requester === "All") {
      return orderList;
    }

    return orderList.filter((order) => order.requester === requester);
  };

  return { filterByRequester };
};
