import React from "react";
import { Title } from "../../../Title";
import { CLOSED_ORDERS_TABLE_COLUMNS } from "../../../../constants/Orders";
import { Flex } from "../../../Flex";
import { OrdersContainer } from "../OrdersContainer/OrdersContainer";
import { BasicNotificationBanner } from "../../../BasicNotificationBanner";
import { Span } from "../../../Span";
import { Link } from "../../../Link";
import { useGetOrderList } from "../../../../hooks/cachedQueries/useGetOrderList/useGetOrderList";
import { CenteredLoading } from "../../../CenteredLoading";
import { Box } from "../../../Box";

export const ClosedOrders: React.FC = () => {
  const { apiError, orders, loading } = useGetOrderList([
    "completed",
    "cancelled",
  ]);

  return (
    <Flex height="100%" width="100%" flexDirection="column">
      <Title as="h1" lineHeight={7} fontWeight="medium">
        Closed Orders
      </Title>

      <Box mb={3}>
        <BasicNotificationBanner
          message={() => (
            <Span>
              Complex connection orders and projects are not listed here. If
              you’ve placed an order and have questions, please{" "}
              <Link href="mailto:customerdevelopments@vector.co.nz">
                email us
              </Link>
              .
            </Span>
          )}
          variant="warning"
          icon="warning"
          mb={3}
        />
      </Box>
      <Box mb={3}>
        <BasicNotificationBanner
          message={
            "Please note that only the last 6 months of completed orders and the last 3 months of cancelled orders will show on the list."
          }
          variant="default"
          icon="info"
          mb={3}
        />
      </Box>

      {loading && <CenteredLoading text="Loading" />}

      {!loading && (
        <OrdersContainer
          type="closed"
          columns={CLOSED_ORDERS_TABLE_COLUMNS}
          apiError={apiError}
          orders={orders.orderList}
        />
      )}
    </Flex>
  );
};
