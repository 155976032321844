import React from "react";
import { Flex } from "../Flex";
import { ErrorMessage, Field } from "formik";
import { FormikInputText } from "../FormikInputText";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { Box } from "../Box";
import { Link } from "../Link";
import { Icon } from "../Icon";

interface Props {
  focusable?: boolean;
  fieldIdPrefix: string;
  fieldNamePrefix: string;
  showPoBox?: boolean;
  showCity?: boolean;
  optionalStreetNumber?: boolean;
  optionalStreetName?: boolean;
  showUnit?: boolean;
}

export const StreetAddressFields: React.FC<Props> = ({
  focusable,
  fieldIdPrefix,
  fieldNamePrefix,
  showPoBox,
  showCity,
  optionalStreetNumber,
  optionalStreetName,
  showUnit,
}) => {
  return (
    <Flex flexDirection="column">
      {(showPoBox || showCity) && (
        <Flex
          flexDirection={["column", "column", "column", "row", "row"]}
          mb={[3, 3, 3, 3, 4]}
        >
          {showPoBox && (
            <Flex
              flexDirection="column"
              flexGrow={0.25}
              flexBasis={0}
              minWidth={0}
              mr={[0, 0, 0, 3, 3]}
            >
              <Field
                id={`${fieldIdPrefix}.poBox`}
                name={`${fieldNamePrefix}.poBox`}
                label="PO Box"
                placeholder="e.g. PO Box 99882"
                focusable={focusable}
                optional
                component={FormikInputText}
              />
              <ErrorMessage
                name={`${fieldNamePrefix}.poBox`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            </Flex>
          )}

          {showCity && (
            <Flex
              flexDirection="column"
              flexGrow={0.75}
              flexBasis={0}
              minWidth={0}
            >
              <Field
                id={`${fieldIdPrefix}.city`}
                name={`${fieldNamePrefix}.city`}
                label="City"
                placeholder="e.g. Auckland"
                focusable={focusable}
                optional
                component={FormikInputText}
              />
              <ErrorMessage
                name={`${fieldNamePrefix}.city`}
                className="field-error"
                component={FormInputErrorMessage}
              />
            </Flex>
          )}
        </Flex>
      )}

      <Flex
        flexDirection={["column", "column", "column", "row", "row"]}
        mb={[3, 3, 3, 3, 4]}
      >
        {showUnit && (
          <Flex
            flexDirection="column"
            flexGrow={0.25}
            flexBasis={0}
            minWidth={0}
            mb={[3, 3, 3, 0, 0]}
            mr={[0, 0, 0, 3, 3]}
          >
            <Field
              id={`${fieldIdPrefix}.unit`}
              name={`${fieldNamePrefix}.unit`}
              label="Unit number"
              placeholder="e.g. 3"
              focusable={focusable}
              component={FormikInputText}
            />
            <ErrorMessage
              name={`${fieldNamePrefix}.unit`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>
        )}

        <Flex
          flexDirection="column"
          flexGrow={0.25}
          flexBasis={0}
          minWidth={0}
          mb={[3, 3, 3, 0, 0]}
          mr={[0, 0, 0, 3, 3]}
        >
          <Field
            id={`${fieldIdPrefix}.streetNumber`}
            name={`${fieldNamePrefix}.streetNumber`}
            label="Street number"
            placeholder="e.g. 109"
            focusable={focusable}
            optional={optionalStreetNumber}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.streetNumber`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex flexDirection="column" flexGrow={0.75} flexBasis={0} minWidth={0}>
          <Field
            id={`${fieldIdPrefix}.streetName`}
            name={`${fieldNamePrefix}.streetName`}
            label="Street name"
            placeholder="e.g. Newton road"
            focusable={focusable}
            optional={optionalStreetName}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.streetName`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection={["column", "column", "column", "column", "row"]}
        mb={[3, 3, 4]}
      >
        <Flex
          flexDirection="column"
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          mb={[3, 3, 3, 3, 0]}
          mr={[0, 0, 0, 0, 2]}
        >
          <Field
            id={`${fieldIdPrefix}.suburb`}
            name={`${fieldNamePrefix}.suburb`}
            label="Suburb"
            placeholder="e.g. Glen Eden"
            focusable={focusable}
            component={FormikInputText}
          />
          <ErrorMessage
            name={`${fieldNamePrefix}.suburb`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        <Flex
          flexDirection={["column", "column", "row"]}
          flexGrow={0.5}
          flexBasis={0}
          minWidth={0}
          ml={[0, 0, 0, 0, 2]}
        >
          <Flex
            flexDirection="column"
            flexBasis={["auto", 0]}
            flexGrow={[1, 0.5]}
            minWidth={["auto", 0]}
          >
            <Field
              id={`${fieldIdPrefix}.postcode`}
              name={`${fieldNamePrefix}.postcode`}
              label="Postcode"
              placeholder="e.g. 1010"
              focusable={focusable}
              component={FormikInputText}
            />
            <ErrorMessage
              name={`${fieldNamePrefix}.postcode`}
              className="field-error"
              component={FormInputErrorMessage}
            />
          </Flex>

          <Box
            ml={[0, 3]}
            mt={4}
            flexGrow={[1, 0.5]}
            flexBasis={["auto", 0]}
            minWidth={["auto", 0]}
          >
            <Link href="https://www.nzpost.co.nz/tools/address-postcode-finder">
              Postcode Finder
              <Icon icon="open_in_new" />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
