import { EMPTY_USER_AUTH0_DETAILS } from "../../constants/UserAuth0Details";
import env from "../../environment";
import { UserAuth0Details } from "../../types/UserAuth0Details";
import {
  getAuthId,
  getCreatedDate,
  getPasswordChangeDate,
} from "../auth0MetaDataExtractor/auth0MetaDataExtractor";
import { GetTokenSilentlyOptions, User } from "@auth0/auth0-react";

const AUTH0_TOKEN_ISSUE_OFFSET_DELAY = 1500;

export const getUserAuth0DetailsWrapper = async (
  auth0LoggedIn: boolean,
  auth0User: User | undefined,
  getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>,
): Promise<UserAuth0Details> => {
  if (!auth0LoggedIn) {
    return Promise.resolve(EMPTY_USER_AUTH0_DETAILS);
  }

  const accessToken = await getAccessTokenSilently({
    audience: `${env.auth0ApiBaseUrl}/api/v2/`,
  });

  const userAuth0Details: UserAuth0Details = {
    auth0Id: getAuthId(auth0User),
    email: auth0User?.email ?? "",
    passwordChangeDate: getPasswordChangeDate(auth0User),
    createdDate: getCreatedDate(auth0User),
    accessToken,
  };

  // Add slight delay between when access token was issued and when it will be used
  // Ensures that the token is valid when being sent to the backend to validate (avoids token used before the issue date)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(userAuth0Details);
    }, AUTH0_TOKEN_ISSUE_OFFSET_DELAY);
  });
};
