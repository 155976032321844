import React from "react";
import styled from "styled-components";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { OrderStatusIcon } from "../OrderStatusIcon";
import { getStatusColor } from "../OrderDetailsList/_/OrderStatusUtil/OrderStatusUtil";
import { OrderStatus } from "../../types/OrderStatus";
import { OrderDetailSubTypes } from "../../types/OrderJobTypes";
import { DuePayment } from "../../types/DuePayment";
import { OrderCompletionInfo } from "./_/OrderCompletionInfo/OrderCompletionInfo";
import { StatusCurrentActivity } from "./_/StatusCurrentActivity/StatusCurrentActivity";
import { StatusActionMessage } from "./_/StatusActionMessage/StatusActionMessage";
import { StatusNextActivity } from "./_/StatusNextActivity/StatusNextActivity";
import { StatusAlertMessage } from "./_/StatusAlertMessage/StatusAlertMessage";
import { StatusInfoMessage } from "./_/StatusInfoMessage/StatusInfoMessage";

interface Props {
  orderStatus: OrderStatus;
  duePayment: DuePayment;
  orderDetailType: OrderDetailSubTypes;
}

const SiteWrapper = styled(Box)`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  white-space: pre-wrap;
`;

export const OrderProgressSection: React.FC<Props> = ({
  orderStatus,
  orderDetailType,
  duePayment,
}) => {
  const { progressStatus } = orderStatus;

  return (
    <Flex flexDirection="column" mb={7}>
      <StatusAlertMessage orderStatus={orderStatus} />

      <SiteWrapper p={4} borderRadius="radio">
        <Flex flexDirection="column" mb={4}>
          <OrderStatusIcon
            color={getStatusColor(progressStatus)}
            status={progressStatus}
            fontSize={2}
          />
        </Flex>

        <OrderCompletionInfo orderDetailType={orderDetailType} />

        <StatusCurrentActivity orderStatus={orderStatus} />

        <StatusActionMessage
          orderStatus={orderStatus}
          duePayment={duePayment}
        />

        <StatusNextActivity orderStatus={orderStatus} />

        <StatusInfoMessage orderStatus={orderStatus} />
      </SiteWrapper>
    </Flex>
  );
};
