import React from "react";
import { Flex } from "../Flex";
import { SiteUnitsManual } from "../SiteUnitsManual";
import { Span } from "../Span";
import { ManualUnitValues } from "../../types/ManualUnitValues";
import { ErrorMessage, Field } from "formik";
import { FormikRadioButton } from "../FormikRadioButton";
import { SiteUnitsUpload } from "../SiteUnitsUpload";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import {
  getGaMeteredUnitManualEvent,
  getGaMeteredUnitUploadEvent,
} from "../../utils/ga/GaLiveningIcpEvents";
import { ProofOfAddressUpload } from "../ProofOfAddressUpload";

interface Props {
  siteIndex: number;
  unitType: string;
  manualUnits: ManualUnitValues[];
  onUploadUnitsStatusChange?: (
    filesUploadStatus: FileSelectUploadStatus,
  ) => void;
}

export const SiteUnitSection: React.FC<Props> = ({
  siteIndex,
  unitType,
  manualUnits,
  onUploadUnitsStatusChange,
}) => {
  const handleUploadUnitsStatusChange = (
    filesUploadStatus: FileSelectUploadStatus,
  ) => {
    onUploadUnitsStatusChange && onUploadUnitsStatusChange(filesUploadStatus);
  };

  return (
    <Flex flexDirection="column">
      <ProofOfAddressUpload siteIndex={siteIndex} />

      <Flex flexDirection="column">
        <Span lineHeight={2} mb={3} mt={4} fontWeight={"bold"}>
          Site requirements (including fuse / phase information)
        </Span>

        <Span lineHeight={2} mb={[3, 3, 4]}>
          We recommend uploading a file (using our template) if the unit/lot
          number is more than 50.
        </Span>

        <Flex flexDirection="column" mb={5}>
          <Flex width="100%">
            <Field
              magnitude="medium"
              label="Enter manually"
              id={`metered.sites[${siteIndex}].manual`}
              name={`metered.sites[${siteIndex}].unitType`}
              value="manual"
              currentValue={unitType}
              flexGrow={0.5}
              ml={[2, 2, 3]}
              flexBasis={0}
              minWidth={0}
              mr={3}
              component={FormikRadioButton}
              gaEvent={getGaMeteredUnitManualEvent()}
            />

            <Field
              magnitude="medium"
              label="Upload a file"
              id={`metered.sites[${siteIndex}].upload`}
              name={`metered.sites[${siteIndex}].unitType`}
              value="upload"
              currentValue={unitType}
              flexGrow={0.5}
              ml={[2, 2, 3]}
              flexBasis={0}
              minWidth={0}
              component={FormikRadioButton}
              gaEvent={getGaMeteredUnitUploadEvent()}
            />
          </Flex>
          <ErrorMessage
            name={`metered.sites[${siteIndex}].unitType`}
            className="field-error"
            component={FormInputErrorMessage}
          />
        </Flex>

        {unitType === "manual" && (
          <SiteUnitsManual siteIndex={siteIndex} units={manualUnits} />
        )}

        {unitType === "upload" && (
          <SiteUnitsUpload
            siteIndex={siteIndex}
            onStatusChange={handleUploadUnitsStatusChange}
          />
        )}
      </Flex>
    </Flex>
  );
};
