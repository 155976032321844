import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { DesktopProgressStep } from "./_/DesktopProgressStep";
import { ProgressSteps } from "../../types/ProgressSteps";

const StyledList = styled.ul<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav<SpaceProps>`
  ${space}
`;

const StyledListItem = styled(DesktopProgressStep)<SpaceProps>`
  ${space}
`;

export const DesktopProgressBar: React.FC<ProgressSteps> = ({
  steps,
  currentStep,
}) => {
  const completedSteps = Array.from(Array(currentStep).keys());

  return (
    <Nav role="navigation" m="auto">
      <StyledList pl={4}>
        {steps.map((step: string, index: number) => {
          const stepNumber = index + 1;

          return (
            <StyledListItem
              key={stepNumber}
              stepNumber={stepNumber}
              stepText={step}
              current={stepNumber === currentStep}
              completed={
                stepNumber !== currentStep &&
                completedSteps?.some(
                  (completedStep: number) => completedStep === stepNumber,
                )
              }
              mb={6}
            />
          );
        })}
      </StyledList>
    </Nav>
  );
};

DesktopProgressBar.defaultProps = {};
