import styled from "styled-components";
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from "styled-system";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const Box: any = styled.div(
  background,
  space,
  layout,
  flexbox,
  color,
  typography,
  border,
  position,
  shadow,
  grid,
);
