export const checkIsManualAddress = (
  isManualAddress: boolean,
  manuallyEnteredAddress: boolean,
): boolean => {
  return isManualAddress && manuallyEnteredAddress;
};

export const checkIsSearchedAddress = (
  isManualAddress: boolean,
  manuallyEnteredAddress: boolean,
): boolean => {
  return !isManualAddress && !manuallyEnteredAddress;
};
