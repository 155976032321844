import React from "react";
import styled from "styled-components";
import { OrderStatus } from "../../../../types/OrderStatus";
import { isOrderCancelled } from "../../../OrderDetailsList/_/OrderStatusUtil/OrderStatusUtil";
import { Flex } from "../../../Flex";
import { Title } from "../../../Title";
import { OrderProgressBar } from "../../../OrderProgressBar";

interface Props {
  orderStatus: OrderStatus;
}

const StyledTitle = styled(Title)<{ isCancelled: boolean }>`
  text-decoration: ${({ isCancelled }) =>
    isCancelled ? "line-through" : "none"};
  color: ${({ isCancelled, theme }) =>
    isCancelled ? theme.colors.neutrals.mn40 : theme.colors.nightSky};
`;

export const StatusCurrentActivity: React.FC<Props> = ({ orderStatus }) => {
  const { progressStatus, percentageComplete, currentActivity } = orderStatus;

  const isCancelled = isOrderCancelled(progressStatus);

  return (
    <>
      {currentActivity && (
        <Flex flexDirection="column" mb={5}>
          <StyledTitle
            as="h4"
            fontWeight="bold"
            data-testid="current-activity"
            isCancelled={isCancelled}
          >
            {currentActivity}
          </StyledTitle>

          <OrderProgressBar
            percentageComplete={percentageComplete}
            color={isCancelled ? "neutrals.mn40" : "hydroDark"}
          />
        </Flex>
      )}
    </>
  );
};
