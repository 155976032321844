import React from "react";
import { Flex } from "../Flex";
import { Span } from "../Span";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { Field } from "formik";
import { FormikRadioButtonDescriptive } from "../FormikRadioButtonDescriptive";
import { Box } from "../Box";
import { ServiceTypeFormValues } from "../../types/ServiceTypeFormValues";
import { CantFindWhatYouAreLookingFor } from "../CantFindWhatYouAreLookingFor";

interface Props {
  values: ServiceTypeFormValues;
  onServiceTypeSelected: () => void;
}

export const ServiceTypeElectricity: React.FC<Props> = ({
  values,
  onServiceTypeSelected,
}) => {
  const handleOnServiceTypeSelected = () => {
    onServiceTypeSelected();
  };

  return (
    <>
      <Span
        display="inline-block"
        fontSize={4}
        lineHeight={4}
        fontWeight="medium"
        mt={7}
        mb={5}
      >
        Select a service type.
      </Span>

      <BasicNotificationBanner
        message="Please make sure you have consulted an electrician before applying for an electricity connection."
        variant="warning"
        icon="warning"
      />

      <Flex flexDirection="column" justifyContent="space-between" mt={3}>
        <Box mb={3}>
          <Field
            id="newConnection"
            name="electricityServiceType"
            label="New connection"
            value="newConnection"
            description="If there is no available connection supply point at the boundary of the property where our
             network connects to your private service line, request a new connection supply point."
            currentValue={values.electricityServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <Box mb={3}>
          <Field
            id="livening"
            name="electricityServiceType"
            label="Livening (ICP number)"
            value="livening"
            description="If there is a connection supply point available and you are ready for the service line to be
            connected to the network, request an ICP and livening."
            currentValue={values.electricityServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <Box mb={3}>
          <Field
            id="electricityDisconnection"
            name="electricityServiceType"
            label="Disconnection (Permanent)"
            value="electricityDisconnection"
            description="If you need to organise a permanent disconnection."
            currentValue={values.electricityServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <Box mb={3}>
          <Field
            id="reportPowerOutage"
            name="electricityServiceType"
            label="Report a power outage"
            value="reportPowerOutage"
            description="Report a power outage on our Outage Centre."
            currentValue={values.electricityServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <Box mb={7}>
          <Field
            id="referenceMaps"
            name="electricityServiceType"
            label="Reference maps"
            value="referenceMaps"
            description={
              "Request reference maps indicating the approximate location of our underground electricity," +
              " gas, fibre cables and pipes."
            }
            currentValue={values.electricityServiceType}
            flexGrow={1}
            onClick={handleOnServiceTypeSelected}
            component={FormikRadioButtonDescriptive}
          />
        </Box>

        <CantFindWhatYouAreLookingFor serviceType={values.service} />
      </Flex>
    </>
  );
};
