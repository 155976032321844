import {
  BorderProps,
  ColorProps,
  SpaceProps,
  TypographyProps,
} from "styled-system";
import React, { InputHTMLAttributes } from "react";
import styled, { css } from "styled-components";

import { Flex } from "../Flex";
import { Icon } from "../Icon";
import { Label } from "../Label";
import { Span } from "../Span";

export interface SquareCheckboxProps {
  value: boolean;
  label?: string | (() => void);
  focusable?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLLabelElement>;
}

const LabelWrapper = styled(Label)<{ checked?: boolean; disabled?: boolean }>`
  user-select: none;
  color: ${({ theme }) => theme.colors.nightSky};

  ${({ checked, disabled, theme }) =>
    !checked &&
    disabled &&
    css`
      color: ${theme.colors.neutrals.mn40};
    `};
`;

const SpanWrapper = styled(Span)<
  { disabled?: boolean } & BorderProps & TypographyProps
>`
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln40};
  border-radius: 6px;
  user-select: none;

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "default")};

  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutrals.ln20 : theme.colors.white};

  &:focus {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        border-color: ${theme.colors.hydro};
        box-shadow: ${theme.shadows.inputFocus};
      `};
  }
  &:focus-visible {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.hydro10};
  }
`;

const IconWrapper = styled(Icon)<
  { disabled?: boolean; checked?: boolean } & ColorProps & SpaceProps
>`
  padding: 0 3px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "default")};

  border-color: ${({ disabled, theme }) =>
    !disabled ? theme.colors.hydroDark : theme.colors.neutrals.mn80};
  background-color: ${({ disabled, theme }) =>
    !disabled ? theme.colors.hydroDark : theme.colors.neutrals.mn80};

  &:focus {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        border-color: ${theme.colors.hydro};
        box-shadow: ${theme.shadows.inputFocus};
      `};
  }
  &:focus-visible {
    outline: none;
  }
`;

export const SquareCheckbox: React.FC<
  SquareCheckboxProps &
    SpaceProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onKeyDown">
> = ({
  id,
  label,
  name,
  checked,
  value,
  disabled,
  mr,
  focusable,
  tabIndex,
  onChange,
  onBlur,
  onKeyDown,
}) => {
  const isChecked = checked || value;

  return (
    <LabelWrapper
      htmlFor={id}
      fontSize={2}
      disabled={disabled}
      checked={isChecked}
      onKeyDown={onKeyDown}
    >
      <Flex flexDirection="row" alignItems="center">
        {!isChecked && (
          <SpanWrapper
            borderRadius="squareCheckbox"
            tabIndex={focusable ? tabIndex : -1}
            disabled={disabled}
            width={6}
            height={6}
            mr={mr}
          />
        )}
        {isChecked && (
          <IconWrapper
            icon="check"
            fontSize={3}
            mr={2}
            tabIndex={focusable ? tabIndex : -1}
            disabled={disabled}
          />
        )}

        {typeof label === "string" && label}
        {typeof label === "function" && label()}

        <input
          id={id}
          name={name}
          type="checkbox"
          disabled={disabled}
          defaultChecked={isChecked}
          onChange={onChange}
          onBlur={onBlur}
          style={{ display: "none" }}
        />
      </Flex>
    </LabelWrapper>
  );
};

SquareCheckbox.defaultProps = {
  disabled: false,
  focusable: true,
  tabIndex: 0,
};
