import React from "react";
import { OrderStatus } from "../../../../types/OrderStatus";
import { BasicNotificationBanner } from "../../../BasicNotificationBanner";
import { Span } from "../../../Span";

interface Props {
  orderStatus: OrderStatus;
}

export const StatusAlertMessage: React.FC<Props> = ({ orderStatus }) => {
  const { alertMessage } = orderStatus;

  return (
    <>
      {alertMessage && (
        <BasicNotificationBanner
          message={() => (
            <Span dangerouslySetInnerHTML={{ __html: alertMessage }} />
          )}
          variant="warning"
          icon="warning"
          mb={3}
          data-testid="alert-message"
        />
      )}
    </>
  );
};
