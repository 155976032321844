import React from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { mediaQueries } from "../../theme";

interface Props {
  menuContents: () => void;
  showMobileNav: boolean;
}

const MenuWrapper = styled(Flex)<{ showMobileNav: boolean }>`
  display: ${({ showMobileNav }) => (showMobileNav ? "block" : "none")};
  width: 100vw;
  height: 100%;
  background-color: white;

  ${mediaQueries.largeDevice} {
    display: flex;
    position: fixed;
    border: none;
    border-right: 2px solid;
    border-right-color: ${(props) => props.theme.colors.neutrals.ln30};
    width: max-content;
    min-width: 240px;
  }
`;

export const NavigationMenu: React.FC<Props> = ({
  menuContents,
  showMobileNav,
}) => {
  return (
    <MenuWrapper showMobileNav={showMobileNav} data-testid="nav-menu">
      {menuContents()}
    </MenuWrapper>
  );
};
