import { Field } from "formik";
import React from "react";
import { Flex } from "../../../Flex";
import { FormikFileUpload } from "../../../FormikFileUpload";
import { IconPopupTip } from "../../../IconPopupTip";
import { Span } from "../../../Span";

interface Props {
  onStatusChange?: (filesUploadStatus: FileSelectUploadStatus) => void;
}

export const SitePlanUpload: React.FC<Props> = ({ onStatusChange }) => {
  return (
    <Flex flexDirection="column">
      <Flex
        justifyContent={[
          "space-between",
          "space-between",
          "space-between",
          "flex-start",
        ]}
      >
        <Span fontSize={4} lineHeight={4} mb={3}>
          Upload site plan
        </Span>

        <IconPopupTip icon="help" ml={["auto", "auto", "auto", 3]}>
          A site plan shows the dimensions and form of the site, the proposed
          building work and where on the site the building will be located, and
          where it is in relation to other buildings on the site. You should
          also include any known information on existing and proposed services.
        </IconPopupTip>
      </Flex>

      <Span lineHeight={2} mb={3}>
        Please upload a site plan or any other relevant documents (max. 5 files,
        pdf, dwg, jpg, doc pr docx format). 20Mb max per file.
      </Span>

      <Field
        id="sitePlans"
        name="sitePlans"
        acceptFileTypes={[
          "application/pdf",
          "application/acad",
          "application/x-acad",
          "application/autocad_dwg",
          "image/x-dwg",
          "application/dwg",
          "application/x-dwg",
          "application/x-autocad",
          "image/vnd.dwg",
          "drawing/dw",
          "image/jpg",
          "image/jpeg",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]}
        onStatusChange={onStatusChange}
        attachmentType={"Site plan"}
        component={FormikFileUpload}
      />
    </Flex>
  );
};
