import React from "react";
import { ProgressStepperDesktop } from "../ProgressStepperDesktop";

interface Props {
  currentStep: number;
}

const SERVICE_TYPE_STEPS: string[] = [];

export const ServiceTypeProgressStepper: React.FC<Props> = ({
  currentStep,
}) => {
  return (
    <>
      <ProgressStepperDesktop
        steps={SERVICE_TYPE_STEPS}
        currentStep={currentStep}
      />
    </>
  );
};

export { SERVICE_TYPE_STEPS };
