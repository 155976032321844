import { Unmetered } from "../types/Unmetered";

export const UnmeteredFeatureTypeValues = [
  "Bulk water meter",
  "Bus timetable monitor",
  "Bus shelter",
  "CCTV camera",
  "Datran unit",
  "Flow recorder",
  "Gate control",
  "Lever control equipment",
  "Light",
  "Motorway sign",
  "Payphone",
  "PCM unit (Chorus)",
  "Pump",
  "Railway lights/bell",
  "Remote monitoring beacon",
  "Siren",
  "Speed camera",
  "Other",
];

export type UnmeteredFeatureType =
  | "" // Empty because Formik initial values are empty
  | "Bulk water meter"
  | "Bus timetable monitor"
  | "Bus shelter"
  | "CCTV camera"
  | "Datran unit"
  | "Flow recorder"
  | "Gate control"
  | "Lever control equipment"
  | "Light"
  | "Motorway sign"
  | "Payphone"
  | "PCM unit (Chorus)"
  | "Pump"
  | "Railway lights/bell"
  | "Remote monitoring beacon"
  | "Siren"
  | "Speed camera"
  | "Other";

export const UnmeteredAvailableHourValues = [
  "Daytime hours",
  "Night-time hours",
  "24 hours",
];

export type UnmeteredAvailableHourType =
  | "" // Empty because Formik initial values are empty
  | "Daytime hours"
  | "Night-time hours"
  | "24 hours";

export const EMPTY_UNMETERED: Unmetered = {
  addressInformation: {
    streetAddress: "",
    streetAddressId: "",
    manuallyEnteredAddress: false,
    manualStreetAddress: {
      streetNumber: "",
      streetName: "",
      suburb: "",
      unit: "",
      postcode: "",
    },
    additionalAddressInformation: "",
  },
  featureType: "",
  featureTypeOther: "",
  quantity: 0,
  wattage: 1.1,
  availableHours: "",
};
