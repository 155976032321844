import { UserBasicDetails } from "../types/UserBasicDetails";

export const EMPTY_USER_BASIC_DETAILS: UserBasicDetails = {
  contactId: "",
  accountId: "",
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  alternatePhone: "",
  businessName: "",
  billingAddress: "",
  companyNzbn: "",
  userType: "personal",
};
