export const getLiveningIcpAddContactButtonTitle = (
  contactDetailsCount: number,
  remainingSelectableRoles: number,
  hasSiteAccessContactRoleBeenSelected: boolean,
  hasPowerRetailerAccountHolderRoleBeenSelected: boolean,
): string => {
  if (
    !hasSiteAccessContactRoleBeenSelected &&
    !hasPowerRetailerAccountHolderRoleBeenSelected &&
    contactDetailsCount > 1 &&
    remainingSelectableRoles > 1
  ) {
    return "Add contact";
  }
  if (remainingSelectableRoles > 1) {
    return `Add contact (${remainingSelectableRoles} more left)`;
  }
  if (
    hasSiteAccessContactRoleBeenSelected &&
    !hasPowerRetailerAccountHolderRoleBeenSelected
  ) {
    return "Add Power Retailer Account Holder contact details";
  }
  if (
    hasPowerRetailerAccountHolderRoleBeenSelected &&
    !hasSiteAccessContactRoleBeenSelected
  ) {
    return "Add Site Access Contact details";
  }

  return "N/A";
};

export const getNewConnectionAddContactButtonTitle = (
  contactDetailsCount: number,
  remainingSelectableRoles: number,
  hasSiteAccessContactRoleBeenSelected: boolean,
  hasQuotePayerRoleRoleBeenSelected: boolean,
  hasConsultantRoleBeenSelected: boolean,
  hasCustomerRoleBeenSelected: boolean,
): string => {
  if (
    !hasSiteAccessContactRoleBeenSelected &&
    !hasQuotePayerRoleRoleBeenSelected &&
    !hasCustomerRoleBeenSelected &&
    !hasConsultantRoleBeenSelected &&
    contactDetailsCount > 1 &&
    remainingSelectableRoles > 1
  ) {
    return "Add contact";
  }
  if (remainingSelectableRoles > 1) {
    return `Add contact (${remainingSelectableRoles} more left)`;
  }
  if (
    hasSiteAccessContactRoleBeenSelected &&
    !hasQuotePayerRoleRoleBeenSelected
  ) {
    return "Add Quote Payer contact details";
  }
  if (
    hasQuotePayerRoleRoleBeenSelected &&
    !hasSiteAccessContactRoleBeenSelected
  ) {
    return "Add Site Access Contact details";
  }
  if (hasConsultantRoleBeenSelected && !hasCustomerRoleBeenSelected) {
    return "Add Customer contact details";
  }
  if (hasCustomerRoleBeenSelected && !hasConsultantRoleBeenSelected) {
    return "Add Consultant contact details";
  }

  return "N/A";
};
