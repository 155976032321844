import React from "react";
import { LiveningIcpPage, LiveningIcpProps } from "./LiveningIcpPage";

export const DEFAULT_LIVENING_ICP_CONTENT: LiveningIcpProps = {
  tabTitle: "",
};

export const LiveningIcpPageContainer: React.FC = () => {
  return <LiveningIcpPage {...DEFAULT_LIVENING_ICP_CONTENT} />;
};
