import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CenteredLoading } from "../CenteredLoading";
import { Flex } from "../Flex";
import { createUser } from "../../services/UsersService";
import { mediaQueries } from "../../theme";
import { CompleteProfileFormValues } from "../../types/CompleteProfileFormValues";
import { CompleteProfileForm } from "../CompleteProfileForm";
import { completeProfileReducer } from "../../reducers/CompleteProfileReducer/CompleteProfileReducer";
import CompleteProfileActionType from "../../types/CompleteProfileActionType";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import UserAuthenticationActionType from "../../types/UserAuthenticationActionType";
import { useVerifySiebelAccount } from "../../hooks/cachedQueries/userAccount/useVerifySiebelAccount";
import { AccountVerificationError } from "../AccountVerificationError";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

export const CompleteProfile: React.FC = () => {
  const [state, dispatch] = useReducer(completeProfileReducer, {
    isLoading: false,
    hasError: false,
    userCreated: undefined,
  });
  const { isLoading, userCreated, hasError } = state;

  const history = useHistory();

  const {
    authenticatedUserContext: {
      isLoading: authIsLoading,
      userAuth0Details,
      hasSiebelAccount,
    },
    setAuthenticatedUserContext,
  } = useUserAuthentication();

  const { manuallyVerifyAccount } = useVerifySiebelAccount(
    userAuth0Details.accessToken,
    userAuth0Details.email,
  );

  const verifyAccountAndUpdateUserContext = async (createSuccess: boolean) => {
    if (!createSuccess) {
      return false;
    }

    const { data: verificationStatus } = await manuallyVerifyAccount();
    const accountVerified = verificationStatus === 204;

    setAuthenticatedUserContext({
      type: UserAuthenticationActionType.SiebelAccountVerified,
      payload: {
        isLoading: false,
        hasSiebelAccount: accountVerified,
      },
    });

    return accountVerified;
  };

  const handleSubmit = (values: CompleteProfileFormValues) => {
    dispatch({
      type: CompleteProfileActionType.UserCreateStarted,
      payload: {},
    });

    (async () => {
      const { email, accessToken } = userAuth0Details;
      values.profile.email = email;

      const success = await createUser(values, accessToken);
      dispatch({
        type: CompleteProfileActionType.UserCreateStatusChanged,
        payload: { userCreated: success },
      });

      // Add a timeout before calling verify manually in case siebel is slow to update the created user on their end
      // Remove comment if all is well
      const verified = await verifyAccountAndUpdateUserContext(success);
      dispatch({
        type: CompleteProfileActionType.UserCreateStatusChanged,
        payload: { userCreated: verified },
      });
    })();
  };

  if (hasSiebelAccount) {
    history.push("/order-details-list");
  }

  if (authIsLoading || isLoading) {
    return <CenteredLoading />;
  }

  if (hasError) {
    return <AccountVerificationError />;
  }

  if (userCreated) {
    return (
      <CenteredLoading text="Verifying account, you will be redirected shortly." />
    );
  }

  return (
    <FlexWrapper flexDirection="column" justifyContent="center">
      <CompleteProfileForm onSubmit={handleSubmit} />
    </FlexWrapper>
  );
};
