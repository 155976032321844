import React from "react";
import { LiveningIcpContactDetailsValues } from "../../../../../../types/LiveningIcpContactDetailsValues";
import { getLiveningIcpRolesTitle } from "../../../../../ContactDetailsRolesTitle/ContactDetailsRolesTitle";
import { Flex } from "../../../../../Flex";
import { Span } from "../../../../../Span";
import { ReviewFormContactDetails } from "../../../../../ReviewFormContactDetails";
import { HasElectricianTypes } from "../../../../../../constants/HasOptionalRoleValues";

interface Props {
  contactDetails: LiveningIcpContactDetailsValues;
  contactDetailsIndex: number;
  hasElectrician: HasElectricianTypes;
}

export const ReviewFormLiveningIcpContactDetailsWithTitle: React.FC<Props> = ({
  contactDetails,
  contactDetailsIndex,
  hasElectrician,
}) => {
  return (
    <Flex flexDirection="column">
      <Span lineHeight={2} fontWeight="bold" mb={3}>
        {getLiveningIcpRolesTitle(
          contactDetails.roles,
          contactDetailsIndex === 0,
          hasElectrician,
        )}
      </Span>

      <ReviewFormContactDetails contactDetails={contactDetails} />
    </Flex>
  );
};
