import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CenteredLoading } from "../CenteredLoading";

export const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CenteredLoading text="Redirecting" />;
};
