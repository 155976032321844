import { NewConnectionRoles } from "../../types/ContactDetailRoles";

export const isBillingAddressRequired = (
  roles: NewConnectionRoles,
  isUserLoggedIn: boolean,
  billingAddressId: string,
): boolean => {
  if (isUserLoggedIn && roles.requester && roles.quotePayerRole) {
    return false;
  }

  if (roles.quotePayerRole && !billingAddressId) {
    return true;
  }

  return false;
};
