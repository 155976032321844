import React from "react";
import { OrderDetailsList } from "../../components/OrderDetailsList";
import { OrderDetailsListNavigationMenu } from "../../components/OrderDetailsList/_/OrderDetailsListNavigationMenu";
import { Page } from "../../components/Page";

export interface OrderDetailsListProps {
  tabTitle: string;
}

export const OrderDetailsListPage: React.FC<OrderDetailsListProps> = ({
  tabTitle,
}) => {
  return (
    <Page
      tabTitle={tabTitle}
      navigationMenuContents={() => <OrderDetailsListNavigationMenu />}
      hasMobileNavMenu
    >
      <OrderDetailsList />
    </Page>
  );
};
