import { apiClient, apiClientConfig } from "../client/ApiClient";
import { PayNowOrderPayment } from "../types/PayNowOrderPayment";
import { includeAccessToken } from "../client/AxiosConfigObjectUtil";
import { sendGAEvent } from "../utils/ga/GaEventSender";
import { getGaBillingSavedFailedEvent, getGaBillingSavedSuccessEvent } from "../utils/ga/GaBillingEvents";

export const getOrderPayment = async (
  paymentGuid: string,
  auth0AccessToken?: string,
): Promise<PayNowOrderPayment> => {
  const response = await apiClient.get(`payments/${paymentGuid}`, {
    headers: includeAccessToken(apiClientConfig, auth0AccessToken),
  });
  return response.data;
};

export const updateBillingDetails = async (
  orderPayment: PayNowOrderPayment,
  auth0AccessToken: string,
): Promise<boolean> => {
  try {
    await apiClient.put("billing-details", orderPayment, {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    });
    sendGAEvent(getGaBillingSavedSuccessEvent());
    return true;
  } catch (error) {
    sendGAEvent(getGaBillingSavedFailedEvent());
    return false;
  }
};
