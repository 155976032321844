import { sessionStorageWrapper } from "../../../utils/sessionStorageWrapper/sessionStorageWrapper";
import { PersistedClient } from "@tanstack/react-query-persist-client";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { retrieveUserProfile } from "../../../services/UsersService";
import {
  hoursToMinutes,
  minutesToMs,
} from "../../../utils/numberComputationHelper/numberComputationHelper";
import { UserBasicDetails } from "../../../types/UserBasicDetails";
import { filterWithMultipleValues } from "../../../utils/arrayHelper/arrayHelper";
import {
  basicAccountDetailsKey,
  ReactQueryKeys,
} from "../../../constants/ReactQueryKeys";

export const useGetBasicUserDetails = (
  auth0AccessToken: string,
  email: string,
  hasSiebelAccount?: boolean,
) => {
  const queryKey: ReactQueryKeys = "accountDetails";

  const ecssQueryCache =
    sessionStorageWrapper.getItem<PersistedClient>("ecssQueryCache");

  const [firstQuery] = filterWithMultipleValues(
    ecssQueryCache?.clientState?.queries,
    [...basicAccountDetailsKey, email],
    (query, filter) =>
      query.queryKey.includes(filter) && !query.state.isInvalidated,
  );

  const cachedBasicDetails = firstQuery?.state?.data as
    | UserBasicDetails
    | undefined;

  // override default staleTime and cacheTime in this query
  // - https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydefaults
  const minutes = hoursToMinutes(24);
  const queryClient = useQueryClient();
  queryClient.setQueryDefaults([queryKey], {
    staleTime: minutesToMs(minutes),
    cacheTime: minutesToMs(minutes),
  });

  /***
   * useQuery docs - https://tanstack.com/query/v4/docs/react/reference/useQuery
   */
  const { data: userBasicDetails } = useQuery<UserBasicDetails>({
    queryKey: [...basicAccountDetailsKey, email, auth0AccessToken],
    queryFn: () => retrieveUserProfile(auth0AccessToken),
    enabled: !!auth0AccessToken && !!hasSiebelAccount && !cachedBasicDetails,
  });

  return { userBasicDetails: cachedBasicDetails ?? userBasicDetails };
};
