import { ContactPreferences } from "../../types/ContactDetailsValues";

export const getContactPreferencesDisplay = (
  type: "site" | "quote",
  contactPreferences?: ContactPreferences,
): string => {
  let result = `${
    type === "quote" ? "Quote" : "Work"
  } update contact preference: `;

  if (!contactPreferences) {
    return "";
  }

  if (contactPreferences.email && contactPreferences.sms) {
    return (result += "Email & SMS");
  }

  if (contactPreferences.email) {
    return (result += "Email");
  }

  if (contactPreferences.sms) {
    return (result += "SMS");
  }

  return "";
};
