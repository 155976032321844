import { LiveningIcpFormValues } from "../types/LiveningIcpFormValues";
import { EMPTY_METERED } from "./Metered";
import { EMPTY_UNMETERED } from "./Unmetered";
import { EMPTY_LIVENING_ICP_CONTACT_DETAILS_VALUES } from "./ContactDetailsValues";
import { EMPTY_CONTACT_DETAILS_VALUES } from "./EmptyContactDetailsValues";

export const EMPTY_LIVENING_ICP_ORDER: LiveningIcpFormValues = {
  icpType: "metered ICP",
  projectNumber: "",
  metered: EMPTY_METERED,
  unmetered: EMPTY_UNMETERED,
  liveningInspector: "",
  liveningInspectorId: "",
  powerRetailer: "",
  powerRetailerId: "",
  contactDetails: [EMPTY_LIVENING_ICP_CONTACT_DETAILS_VALUES],
  optionalContactDetails: EMPTY_CONTACT_DETAILS_VALUES,
  siteAccessContactRoleChecked: false,
  powerRetailerAccountHolderRoleChecked: false,
  hasElectrician: "",
};

export const LIVENING_ICP_UPLOAD_TEMPLATE_PATH =
  "/assets/files/multiple-icp-request-template.xlsx";
