import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { LayoutProps, TypographyProps } from "styled-system";
import { Span } from "../Span";

const TooltipBox = styled(Span)`
    background-color: ${({ theme }) => theme.colors.neutrals.dn50};
    color: ${({ theme }) => theme.colors.neutrals.ln0};
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Tooltip: React.FC<
    LayoutProps &
    TypographyProps &
    InputHTMLAttributes<HTMLInputElement>> =
    ({   id,
         children,
         ...props }) => {
  return(
      <TooltipBox borderRadius="input"
                  id={id}
                  px={2}
                  py="2px"
                  fontSize={0}
                  overflow="hidden"
                  textAlign="center"
                  role="tooltip"
                  {...props}
      >
          {children}
      </TooltipBox>
  );
};

Tooltip.defaultProps = {
  fontSize: 1,
  lineHeight: 1
};
