import React from "react";
import styled from "styled-components";
import { Box } from "../../../Box";
import { Span } from "../../../Span";
import { OrderDocumentItem } from "./OrderDocumentItem";
import { OrderDocument } from "../../../../types/OrderDocument";
import { Line } from "../../../Line";

interface Props {
  documents: OrderDocument[];
}

const DocumentWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
  white-space: pre-wrap;
`;

export const OrderDocuments: React.FC<Props> = ({ documents }) => {
  const transformedDocs = documents.map((item) => ({
    ...item,
    fileName:
      item.fileName || item.attachmentCategory || "Unmapped document name",
  }));

  return (
    <>
      {transformedDocs.length > 0 && (
        <>
          <Span
            fontSize={4}
            lineHeight={4}
            fontWeight="medium"
            mb={5}
            alignSelf="flex-start"
          >
            Documents
          </Span>
          <DocumentWrapper flexDirection={"column"} p={4} borderRadius="radio">
            {transformedDocs.map((document, index) => (
              <OrderDocumentItem key={index} document={document} />
            ))}
          </DocumentWrapper>
          <Line
            width="100%"
            border="1px solid"
            borderColor="neutrals.ln30"
            bg="neutrals.ln30"
            opacity="1"
            height={0}
            my={7}
          />
        </>
      )}
    </>
  );
};
