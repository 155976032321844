import React from "react";
import { LiveningIcpContactDetailsValues } from "../../../../types/LiveningIcpContactDetailsValues";
import { ContactDetailFields } from "../../../ContactDetailsForm/ContactDetailFields";
import { ContactDetailsContactPreferences } from "../../../ContactDetailsForm/ContactDetailsContactPreferences";
import { getLiveningIcpRolesTitle } from "../../../ContactDetailsRolesTitle/ContactDetailsRolesTitle";
import { Flex } from "../../../Flex";
import { IconPopupTip } from "../../../IconPopupTip";
import { Line } from "../../../Line";
import { Span } from "../../../Span";
import { LiveningIcpRoleSelector } from "../LiveningIcpRoleSelector";
import { MOBILE_REG_EXP } from "../../../../constants/ContactNumbersRegex";
import { ActionButton } from "../../../ActionButton";
import { FieldArrayRenderProps } from "formik";
import { HasElectricianTypes } from "../../../../constants/HasOptionalRoleValues";
import { isRequesterAndLoggedIn } from "../../../FormContactDetailsFieldsUtil/FormContactDetailsFieldsUtil";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

interface Props {
  contactDetails: LiveningIcpContactDetailsValues[];
  contactDetailsIndex: number;
  roleSelectorPosition?: "top" | "bottom";
  fieldsVisible?: boolean;
  disableWorkEmailPreference?: boolean;
  disableWorkSmsPreference?: boolean;
  focusable?: boolean;
  showRemoveButton?: boolean;
  index: number;
  arrayHelpers?: FieldArrayRenderProps;
  hasElectrician: HasElectricianTypes;
}

export const LiveningIcpContactDetailFields: React.FC<Props> = ({
  contactDetails,
  contactDetailsIndex,
  roleSelectorPosition,
  disableWorkEmailPreference,
  disableWorkSmsPreference,
  focusable,
  showRemoveButton,
  index,
  arrayHelpers,
  hasElectrician,
}) => {
  const {
    authenticatedUserContext: { isLoggedIn, userBasicDetails },
  } = useUserAuthentication();
  const { userType } = userBasicDetails;

  const currentContactDetails = contactDetails[contactDetailsIndex];
  const currentContactDetailsRoles = currentContactDetails.roles;

  const allContactDetailsRoles = contactDetails.map((details) => details.roles);

  if (contactDetailsIndex === 0) {
    currentContactDetailsRoles.requester = true;

    // Force email work contact preference to be true for requester
    currentContactDetails.workContactPreferences.email = true;
  }

  const title = getLiveningIcpRolesTitle(
    currentContactDetailsRoles,
    contactDetailsIndex === 0,
    hasElectrician,
  );

  const shouldDisableWorkSmsPreference =
    disableWorkSmsPreference ||
    !currentContactDetails.mobile ||
    !MOBILE_REG_EXP.test(currentContactDetails.mobile);

  const shouldDisableWorkEmailPreference =
    disableWorkEmailPreference || !currentContactDetails.email;

  const shouldDisableContactDetails = isRequesterAndLoggedIn(
    isLoggedIn,
    contactDetailsIndex,
  );

  const showBusinessName = userType === "business";

  const handleContactClearClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    contactIndex: number,
    arrayHelpers?: FieldArrayRenderProps,
  ) => {
    arrayHelpers && arrayHelpers.remove(contactIndex);
  };

  return (
    <Flex flexDirection="column">
      <Flex
        alignItems="center"
        mb={4}
        justifyContent={[
          "space-between",
          "space-between",
          "space-between",
          "flex-start",
        ]}
      >
        <Span fontWeight="bold">{title}</Span>

        {showRemoveButton && (
          <ActionButton
            data-testid="remove-contact-button"
            onClick={(e) => handleContactClearClick(e, index, arrayHelpers)}
            icon="clear"
            ml={2}
          />
        )}
      </Flex>

      {/* Role selector (top)  */}
      {roleSelectorPosition === "top" && (
        <LiveningIcpRoleSelector
          contactDetailsRoles={allContactDetailsRoles}
          contactDetailsIndex={contactDetailsIndex}
          mb={4}
        />
      )}

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        data-testid="contact-details-fields-wrapper"
      >
        <ContactDetailFields
          focusable={focusable}
          idPrefix={`contactDetails[${contactDetailsIndex}]`}
          namePrefix={`contactDetails[${contactDetailsIndex}]`}
          disabledFirstName={shouldDisableContactDetails}
          disabledLastName={shouldDisableContactDetails}
          disabledEmail={shouldDisableContactDetails}
          disabledMobile={shouldDisableContactDetails}
          disabledAlternatePhone={shouldDisableContactDetails}
          disabledBusinessName={shouldDisableContactDetails}
          showBusinessName={showBusinessName}
          optionalAlternatePhone
        />

        {/* Role selector (bottom)  */}
        {roleSelectorPosition === "bottom" && (
          <LiveningIcpRoleSelector
            contactDetailsRoles={allContactDetailsRoles}
            contactDetailsIndex={contactDetailsIndex}
            mb={5}
          />
        )}

        {/* Contact preferences selector */}
        <Flex flexDirection="column">
          <Flex
            flexDirection="row"
            mb={3}
            justifyContent={[
              "space-between",
              "space-between",
              "space-between",
              "flex-start",
            ]}
          >
            <Span fontSize={2} lineHeight={2} mr={3}>
              {"Select contact preferences"}
            </Span>
            <IconPopupTip icon="help" ml={["auto", "auto", 3]}>
              Different roles might have different contact preferences. Identify
              whether they want to be notified via email and/or SMS.
            </IconPopupTip>
          </Flex>

          <ContactDetailsContactPreferences
            id={`contactDetails[${contactDetailsIndex}].workContactPreferences`}
            name={`contactDetails[${contactDetailsIndex}].workContactPreferences`}
            title="Work update:"
            emailLabel="Email"
            smsLabel="SMS"
            emailDisabled={shouldDisableWorkEmailPreference}
            smsDisabled={shouldDisableWorkSmsPreference}
            focusable={focusable}
          />
        </Flex>
      </Flex>

      <Line
        width="100%"
        my={5}
        zIndex={0}
        border="1px solid"
        borderColor="neutrals.ln30"
        bg="neutrals.ln30"
        opacity="1"
        height={0}
      />
    </Flex>
  );
};

LiveningIcpContactDetailFields.defaultProps = {
  roleSelectorPosition: "top",
  fieldsVisible: false,
  focusable: true,
  disableWorkEmailPreference: false,
  disableWorkSmsPreference: false,
};
