import React from "react";
import { ProgressStepper } from "../ProgressStepper";

interface Props {
  currentStep: number;
}

const NEW_CONNECTION_FORM_STEPS = ["Service Type", "Order Details", "Review"];

export const NewConnectionProgressStepper: React.FC<Props> = ({ ...props }) => {
  return <ProgressStepper steps={NEW_CONNECTION_FORM_STEPS} {...props} />;
};

export { NEW_CONNECTION_FORM_STEPS };
