import { ReactQueryKeys } from "../../../constants/ReactQueryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import getInspectorRetailers from "../../../services/InspectorRetailerService";
import {
  hoursToMinutes,
  minutesToMs,
} from "../../../utils/numberComputationHelper/numberComputationHelper";
import { sessionStorageWrapper } from "../../../utils/sessionStorageWrapper/sessionStorageWrapper";
import { PersistedClient } from "@tanstack/react-query-persist-client";
import { filterWithMultipleValues } from "../../../utils/arrayHelper/arrayHelper";
import { InspectorRetailer } from "../../../types/InspectorRetailer";

export const useGetInspectorRetailers = (
  path: "livening-inspectors" | "power-retailers",
) => {
  const queryKey: ReactQueryKeys = "serviceProviders";

  const ecssQueryCache =
    sessionStorageWrapper.getItem<PersistedClient>("ecssQueryCache");

  const [firstQuery] = filterWithMultipleValues(
    ecssQueryCache?.clientState?.queries,
    [queryKey, path],
    (query, filter) =>
      query.queryKey.includes(filter) && !query.state.isInvalidated,
  );

  const cachedProviders = firstQuery?.state?.data as
    | InspectorRetailer[]
    | undefined;

  // override default staleTime and cacheTime in this query
  // - https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydefaults
  const minutes = hoursToMinutes(24);
  const queryClient = useQueryClient();
  queryClient.setQueryDefaults([queryKey], {
    staleTime: minutesToMs(minutes),
    cacheTime: minutesToMs(minutes),
  });

  /***
   * useQuery docs - https://tanstack.com/query/v4/docs/react/reference/useQuery
   */
  const { data: serviceProviders, isError: apiError } = useQuery<
    InspectorRetailer[]
  >({
    queryKey: [queryKey, path],
    queryFn: () => getInspectorRetailers(path),
    enabled: !cachedProviders,
  });

  return {
    serviceProviders: cachedProviders ?? serviceProviders ?? [],
    apiError,
  };
};
