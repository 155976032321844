import { FieldArrayRenderProps } from "formik";
import React from "react";
import { EMPTY_LIVENING_ICP_CONTACT_DETAILS_VALUES } from "../../../../constants/ContactDetailsValues";
import { LiveningIcpContactDetailsValues } from "../../../../types/LiveningIcpContactDetailsValues";
import { ContactDetailsFormAddContact } from "../../../ContactDetailsForm/ContactDetailsFormAddContact";
import {
  liveningIcpRolesSelectedInAllContactDetails,
  powerRetailerAccountHolderRoleSelectedInAllContactDetails,
  siteAccessContactRoleSelectedInAllContactDetails,
} from "../../../ContactDetailsForm/ContactDetailsRoleChecker/ContactDetailsRoleChecker";
import { calculateSelectableLiveningIcpRoles } from "../../../ContactDetailsForm/RemainingRolesCalculator/RemainingRolesCalculator";
import { checksLiveningIcpSelectedRole } from "../../../CheckSelectedRolesUtil/CheckSelectedRolesUtil";
import { getLiveningIcpAddContactButtonTitle } from "../../../AddContactButtonTitleUtil/AddContactButtonTitleUtil";

interface Props {
  contactDetails: LiveningIcpContactDetailsValues[];
  arrayHelpers: FieldArrayRenderProps;
  tabIndex?: number;
  focusable?: boolean;
}

export const LiveningIcpAddContact: React.FC<Props> = ({
  contactDetails,
  arrayHelpers,
  tabIndex,
  focusable,
}) => {
  const liveningIcpRoles = contactDetails.map((details) => details.roles);

  const areAnyRolesSelected =
    liveningIcpRolesSelectedInAllContactDetails(liveningIcpRoles);

  const hasSiteAccessContactRoleBeenSelected =
    siteAccessContactRoleSelectedInAllContactDetails(liveningIcpRoles);

  const hasPowerRetailerAccountHolderRoleBeenSelected =
    powerRetailerAccountHolderRoleSelectedInAllContactDetails(liveningIcpRoles);

  const remainingSelectableRoles =
    calculateSelectableLiveningIcpRoles(liveningIcpRoles);

  const handleOnClick = () => {
    const roles = checksLiveningIcpSelectedRole(
      remainingSelectableRoles,
      hasSiteAccessContactRoleBeenSelected,
      hasPowerRetailerAccountHolderRoleBeenSelected,
    );

    const liveningIcpContactDetailsValues =
      EMPTY_LIVENING_ICP_CONTACT_DETAILS_VALUES;

    liveningIcpContactDetailsValues.roles = roles;

    arrayHelpers.push(liveningIcpContactDetailsValues);
  };

  const actionLinkRoleTitle = getLiveningIcpAddContactButtonTitle(
    contactDetails.length,
    remainingSelectableRoles,
    hasSiteAccessContactRoleBeenSelected,
    hasPowerRetailerAccountHolderRoleBeenSelected,
  );

  return (
    <ContactDetailsFormAddContact
      anyRolesSelected={areAnyRolesSelected}
      remainingSelectableRoles={remainingSelectableRoles}
      actionLinkRoleTitle={actionLinkRoleTitle}
      tabIndex={tabIndex}
      focusable={focusable}
      onClick={handleOnClick}
    />
  );
};

LiveningIcpAddContact.defaultProps = {
  tabIndex: 0,
  focusable: true,
};
