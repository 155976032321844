import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../../environment";
import { CenteredLoading } from "../CenteredLoading";
import { sessionStorageWrapper } from "../../utils/sessionStorageWrapper/sessionStorageWrapper";

export const LogoutPage: React.FC = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    sessionStorageWrapper.removeItem("ecssQueryCache");
    logout({ returnTo: env.auth0LogoutUri });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CenteredLoading text="Logging out" />;
};
