import React from "react";
import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { mediaQueries } from "../../../../theme";
import { Order } from "../../../../types/Order";
import { OrderListEmpty } from "../../../OrderListEmpty";
import { OrderListError } from "../../../OrderListError";
import { Span } from "../../../Span";
import { OrdersCards } from "../OrdersCards";
import { OrdersTable } from "../OrdersTable";

interface Props {
  orders: Order[];
  ordersCount: number;
  type: OrdersFilterStatus;
  columns: { Header: string }[];
  error: boolean;
  onRetry?: () => void;
  searchFilter?: string;
}

const DesktopTable = styled(OrdersTable)<LayoutProps>`
  ${layout}
  display: none;

  ${mediaQueries.largeDevice} {
    display: flex;
  }
`;

const MobileTable = styled(OrdersCards)<LayoutProps>`
  ${layout}
  display: flex;

  ${mediaQueries.largeDevice} {
    display: none;
  }
`;

export const Orders: React.FC<Props> = ({
  orders,
  ordersCount,
  type,
  columns,
  error,
  onRetry,
  searchFilter,
}) => {
  const renderEmptyResponse = () => {
    if (!ordersCount && !error) {
      return searchFilter ? (
        <Span data-testid={"search-results-empty"}>
          There is no result for your search.
        </Span>
      ) : (
        <OrderListEmpty />
      );
    }
  };

  return (
    <>
      <Span fontSize={1} color="neutrals.mn40" mb={3}>
        ({ordersCount} orders)
      </Span>

      <DesktopTable
        className="table-open-orders"
        headers={columns}
        type={type}
        orders={orders}
      />

      <MobileTable className="mobile-open-orders" type={type} orders={orders} />

      {renderEmptyResponse()}

      {error && <OrderListError onRetry={onRetry} />}
    </>
  );
};
