import React from "react";
import { FieldArray } from "formik";
import { LiveningIcpContactDetailsValues } from "../../../../types/LiveningIcpContactDetailsValues";
import { Flex } from "../../../Flex";
import { Span } from "../../../Span";
import { LiveningIcpAddContact } from "../LiveningIcpAddContact";
import { LiveningIcpContactDetailFields } from "../LiveningIcpContactDetailFields";
import { ElectricianContactFields } from "../../../ElectricianContactFields";
import { ContactDetailsValues } from "../../../../types/ContactDetailsValues";
import { calculateSelectableLiveningIcpRoles } from "../../../ContactDetailsForm/RemainingRolesCalculator/RemainingRolesCalculator";
import { HasElectricianTypes } from "../../../../constants/HasOptionalRoleValues";

interface Props {
  id: string;
  contactDetails: LiveningIcpContactDetailsValues[];
  focusable?: boolean;
  hasElectrician: HasElectricianTypes;
  optionalContactDetails: ContactDetailsValues;
}

export const LiveningIcpContactDetailsForm: React.FC<Props> = ({
  id,
  contactDetails,
  focusable,
  hasElectrician,
  optionalContactDetails,
}) => {
  const liveningIcpRoles = contactDetails.map((details) => details.roles);
  const remainingSelectableRoles =
    calculateSelectableLiveningIcpRoles(liveningIcpRoles);

  return (
    <Flex flexDirection="column" id={id}>
      <Span fontSize={4} lineHeight={4} mt={0} mb={3}>
        Contact details
      </Span>

      <Span lineHeight={2} mb={5}>
        Please fill out your contact details. We require the contact details for
        your electrician, quote payer, energy retailer account holder, and site
        contact.
      </Span>

      <FieldArray
        name="contactDetails"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {contactDetails.map((details, index) => (
              <LiveningIcpContactDetailFields
                key={index}
                index={index}
                roleSelectorPosition={index === 0 ? "bottom" : "top"}
                contactDetails={contactDetails}
                contactDetailsIndex={index}
                fieldsVisible={index === 0}
                focusable={focusable}
                arrayHelpers={arrayHelpers}
                disableWorkEmailPreference={index === 0}
                showRemoveButton={index > 0}
                hasElectrician={hasElectrician}
              />
            ))}

            {contactDetails.length < 3 && remainingSelectableRoles > 0 && (
              <Flex flexDirection="row" mb={5}>
                <LiveningIcpAddContact
                  contactDetails={contactDetails}
                  arrayHelpers={arrayHelpers}
                  focusable={focusable}
                />
              </Flex>
            )}
          </>
        )}
      />

      <ElectricianContactFields
        hasElectrician={hasElectrician}
        optionalContactDetails={optionalContactDetails}
        focusable
      />
    </Flex>
  );
};

LiveningIcpContactDetailsForm.defaultProps = {
  focusable: true,
};
