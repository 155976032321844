import {
  LiveningIcpRoles,
  NewConnectionRoles,
} from "../../../types/ContactDetailRoles";

const MAX_SELECTED_LIVENING_ICP_ROLES = 2;
const MAX_SELECTED_NEW_CONNECTION_ROLES = 2;

export const calculateSelectableLiveningIcpRoles = (
  liveningIcpRoles: LiveningIcpRoles[],
): number => {
  let selectedRolesCount = 0;

  liveningIcpRoles.forEach((role) => {
    if (role.powerRetailerAccountHolderRole) {
      selectedRolesCount = selectedRolesCount + 1;
    }

    if (role.siteAccessContactRole) {
      selectedRolesCount = selectedRolesCount + 1;
    }
  });

  const remainingSelectableRoleCount =
    MAX_SELECTED_LIVENING_ICP_ROLES - selectedRolesCount;

  return remainingSelectableRoleCount;
};

export const calculateSelectableNewConnectionRoles = (
  newConnectionRoles: NewConnectionRoles[],
  newConnectionType: string,
): number => {
  let selectedRolesCount = 0;

  newConnectionRoles.forEach((role) => {
    if (role.siteAccessContactRole && newConnectionType === "simple") {
      selectedRolesCount = selectedRolesCount + 1;
    }

    if (role.quotePayerRole && newConnectionType === "simple") {
      selectedRolesCount = selectedRolesCount + 1;
    }
    if (role.customerRole && newConnectionType === "complex") {
      selectedRolesCount = selectedRolesCount + 1;
    }

    if (role.consultantRole && newConnectionType === "complex") {
      selectedRolesCount = selectedRolesCount + 1;
    }
  });

  const remainingSelectableRoleCount =
    MAX_SELECTED_NEW_CONNECTION_ROLES - selectedRolesCount;

  return remainingSelectableRoleCount;
};
