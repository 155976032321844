import { FileError } from "react-dropzone";

export const extendedFileNameValidator = (
  file: File,
  rejectedFileNames?: string[],
): FileError | FileError[] | null => {
  if (!rejectedFileNames) {
    return null;
  }

  const fileIndex = rejectedFileNames.findIndex(
    (rejectedFileName) => rejectedFileName === file.name,
  );

  if (fileIndex !== -1) {
    return {
      code: "duplicate-file-name",
      message: `A file with name ${file.name} has already been selected`,
    };
  }

  return null;
};
