import * as yup from "yup";
import { ContactPreferences } from "../../types/ContactDetailsValues";
import { UnmeteredFeatureType } from "../../constants/Unmetered";
import { LiveningIcpRoles } from "../../types/ContactDetailRoles";
import { ManualUnitValues } from "../../types/ManualUnitValues";
import { ALTERNATE_PHONE_REG_EXP, MOBILE_REG_EXP } from "../../constants/ContactNumbersRegex";
import { HasElectricianTypes } from "../../constants/HasOptionalRoleValues";
import {
  ONLY_LETTERS_AND_SPACE_REG_EXP,
  ONLY_NUMBERS_REG_EXP,
  STARTS_WITH_NUMBERS_REG_EXP
} from "../../constants/FormRegex";
import { noWhitespaceValidation } from "../../utils/noWhitespaceValidation/noWhitespaceValidation";
import { EMAIL_REG_EXP } from "../../constants/EmailRegex";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "noWhitespace",
  noWhitespaceValidation,
);

export const LiveningIcpOrderDetailsFormSchema = yup.object().shape({
  icpType: yup.string(),
  metered: yup.object().when("icpType", {
    is: (siteType: string) => siteType === "metered ICP",
    then: yup.object().shape({
      sites: yup.array().of(
        yup.object().shape({
          siteType: yup.string().required("Type of address is required"),
          lotInformation: yup.object().when("siteType", {
            is: (siteType: string) => siteType === "lotAndDpNumber",
            then: yup.object({
              lotNumber: yup
                .string()
                .required("Lot number is required")
                .matches(
                  ONLY_NUMBERS_REG_EXP,
                  "Lot Number can only contain numbers",
                )
                .max(5, "Maximum length is 5"),
              dpNumber: yup
                .string()
                .required("DP number is required")
                .matches(
                  ONLY_NUMBERS_REG_EXP,
                  "DP Number can only contain numbers",
                )
                .max(50, "Maximum length is 50"),
              streetNumber: yup
                .string()
                .noWhitespace("Street number is invalid")
                .max(6, "Maximum length is 6"),
              streetName: yup
                .string()
                .noWhitespace("Street name is invalid")
                .required("Street name is required")
                .test(
                  "is-valid-street-name",
                  "The street number cannot be entered in the Street Name field. Please move the number into the Street Number field",
                  (value) => {
                    if (!value) {
                      return false;
                    }

                    const matches = STARTS_WITH_NUMBERS_REG_EXP.test(value);
                    return !matches;
                  },
                ),
              suburb: yup
                .string()
                .noWhitespace("Suburb is invalid")
                .required("Suburb is required")
                .max(50, "Maximum length is 50")
                .matches(
                  ONLY_LETTERS_AND_SPACE_REG_EXP,
                  "Suburb can only contain letters",
                ),
              postcode: yup
                .string()
                .required("Postcode is required")
                .matches(
                  ONLY_NUMBERS_REG_EXP,
                  "Postcode can only contain numbers",
                )
                .length(4, "Postcode should contain 4 numbers, please update"),
            }),
          }),
          addressInformation: yup.object().when("siteType", {
            is: (siteType: string) => siteType === "streetNumberAndName",
            then: yup.object({
              additionalAddressInformation: yup
                .string()
                .noWhitespace("Additional address information is invalid")
                .max(100, "Maximum length is 100"),
              manuallyEnteredAddress: yup.boolean(),
              streetAddress: yup.string().when("manuallyEnteredAddress", {
                is: false,
                then: yup.string().test(
                  "is-valid-address",
                  "Street address is invalid",

                  function (value) {
                    const { from } = this as yup.TestContext & {
                      /* eslint-disable  @typescript-eslint/no-explicit-any */
                      from: any;
                    };

                    if (!value) {
                      return false;
                    }

                    if (!from[0] || !from[0].value) {
                      return true;
                    }

                    const streetAddressId = from[0].value
                      .streetAddressId as string;
                    return (
                      streetAddressId !== undefined && streetAddressId !== ""
                    );
                  },
                ),
                otherwise: yup.string(),
              }),
              manualStreetAddress: yup.object().when("manuallyEnteredAddress", {
                is: true,
                then: yup.object({
                  streetNumber: yup
                    .string()
                    .noWhitespace("Street number is invalid")
                    .required("Street number is required")
                    .max(6, "Maximum length is 6"),
                  streetName: yup
                    .string()
                    .noWhitespace("Street name is invalid")
                    .max(50, "Maximum length is 50")
                    .required("Street name is required"),
                  suburb: yup
                    .string()
                    .noWhitespace("Suburb is invalid")
                    .max(50, "Maximum length is 50")
                    .required("Suburb is required")
                    .matches(
                      ONLY_LETTERS_AND_SPACE_REG_EXP,
                      "Suburb can only contain letters",
                    ),
                  postcode: yup
                    .string()
                    .noWhitespace("Postcode is invalid")
                    .required("Postcode is required")
                    .matches(
                      ONLY_NUMBERS_REG_EXP,
                      "Postcode can only contain numbers",
                    )
                    .length(
                      4,
                      "Postcode should contain 4 numbers, please update",
                    ),
                }),
              }),
            }),
          }),
          unitType: yup
            .string()
            .noWhitespace("Unit type is invalid")
            .required("Unit type is required"),
          manualUnits: yup.array().when("unitType", {
            is: (unitType: string) => unitType === "manual",
            then: yup.array().of(
              yup.object().shape({
                unitNumber: yup
                  .string()
                  .noWhitespace("Unit number is invalid")
                  .max(5, "Maximum length is 5")
                  .required("Unit/Lot number is required")
                  .test(
                    "is-unique",
                    "Duplicate number",

                    function (value) {
                      const { from } = this as yup.TestContext & {
                        /* eslint-disable  @typescript-eslint/no-explicit-any */
                        from: any;
                      };

                      const manualUnits = from[1].value
                        .manualUnits as ManualUnitValues[];

                      const unitValues = manualUnits.filter(
                        (unit) => unit.unitNumber === value,
                      );
                      return unitValues.length === 1;
                    },
                  ),
                type: yup.string().required("Type is required"),
                phase: yup.number().moreThan(0).required("Phase is required"),
                fuseAmp: yup.number().moreThan(0).required("Fuse is required"),
                supply: yup.string().required("Supply is required"),
              }),
            ),
          }),
          filesUploadStatus: yup.string(),
          uploadProofOfAddresses: yup
            .array()
            .of(
              yup.object().shape({
                fileName: yup.string().required("File is required"),
                md5: yup.string().required("File md5 is required"),
              }),
            )
            .max(5, "Max of 5 files allowed"),
          uploadUnits: yup.array().when(["unitType", "filesUploadStatus"], {
            is: (unitType: string, filesUploadStatus: string) =>
              unitType === "upload" && !filesUploadStatus,
            then: yup
              .array()
              .of(
                yup.object().shape({
                  fileName: yup.string().required("File is required"),
                  md5: yup.string().required("File md5 is required"),
                }),
              )
              .min(1, "Please upload the file containing the unit details")
              .max(5, "Max of 5 files allowed"),
          }),
        }),
      ),
    }),
  }),
  unmetered: yup.object().when("icpType", {
    is: (siteType: string) => siteType === "unmetered ICP",
    then: yup.object().shape({
      addressInformation: yup.object().shape({
        additionalAddressInformation: yup
          .string()
          .noWhitespace("Address information is invalid")
          .max(100, "Maximum length is 100"),
        manuallyEnteredAddress: yup.boolean(),
        streetAddress: yup.string().when("manuallyEnteredAddress", {
          is: false,
          then: yup.string().test(
            "is-valid-address",
            "Street address is invalid",

            function (value) {
              const { from } = this as yup.TestContext & {
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                from: any;
              };

              if (!value) {
                return false;
              }

              const formValues = from[0];
              if (formValues) {
                const streetAddressId = from[0].value.streetAddressId as string;
                return streetAddressId !== undefined && streetAddressId !== "";
              }

              return false;
            },
          ),
          otherwise: yup.string(),
        }),
        manualStreetAddress: yup.object().when("manuallyEnteredAddress", {
          is: true,
          then: yup.object({
            streetNumber: yup
              .string()
              .noWhitespace("Street number is invalid")
              .required("Street number is required")
              .max(6, "Maximum length is 6"),
            streetName: yup
              .string()
              .noWhitespace("Street name is invalid")
              .required("Street name is required")
              .max(50, "Maximum length is 50"),
            suburb: yup
              .string()
              .noWhitespace("Suburb is invalid")
              .required("Suburb is required")
              .max(50, "Maximum length is 50"),
            postcode: yup
              .string()
              .noWhitespace("Postcode is invalid")
              .required("Postcode is required")
              .matches(
                ONLY_NUMBERS_REG_EXP,
                "Postcode can only contain numbers",
              )
              .length(4, "Postcode should contain 4 numbers, please update"),
          }),
        }),
      }),
      featureType: yup.string().required("Feature type is required"),
      featureTypeOther: yup.string().when("featureType", {
        is: (featureType: UnmeteredFeatureType) => featureType === "Other",
        then: yup
          .string()
          .noWhitespace("Other is invalid")
          .required("Other is required"),
      }),
      quantity: yup
        .number()
        .required("Quantity is required")
        .moreThan(0, "Minimum is 1")
        .lessThan(11, "Maximum is 10"),
      wattage: yup
        .number()
        .typeError("Wattage must be a number between 1.1 and 1000.0")
        .required("Wattage is required")
        .moreThan(1, "Minimum is 1.1W")
        .lessThan(1000.1, "Maximum is 1000.0W")
        .test(
          "is-decimal",
          "Wattage must be a number with 1 decimal point",
          (value) =>
            value ? /(^\d+$)|(^\d+\.\d{1}$)/.test(value?.toString()) : false,
        ),
      availableHours: yup.string().required("Available hours is required"),
    }),
  }),
  projectNumber: yup
    .string()
    .noWhitespace("Project number is invalid")
    .max(20, "Maximum length is 20"),
  siteAccessContactRoleChecked: yup.boolean(),
  powerRetailerAccountHolderRoleChecked: yup.boolean(),
  hasElectrician: yup
    .string()
    .required("Please select a option for electrician"),
  liveningInspector: yup.string().required("Livening inspector is required"),
  liveningInspectorId: yup.string(),
  powerRetailer: yup.string().required("Power retailer is required"),
  powerRetailerId: yup.string(),
  optionalContactDetails: yup.object().when("hasElectrician", {
    is: (hasElectrician: HasElectricianTypes) => hasElectrician === "yes",
    then: yup
      .object()
      .shape(
        {
          firstName: yup
            .string()
            .noWhitespace("First name is invalid")
            .max(50, "Maximum length is 50")
            .required("First name is required"),
          lastName: yup
            .string()
            .noWhitespace("Last name is invalid")
            .max(50, "Maximum length is 50")
            .required("Last name is required"),
          email: yup.string().when("workContactPreferences", {
            is: (workContactPreferences: ContactPreferences) =>
              workContactPreferences.email,
            then: yup
              .string()
              .noWhitespace("Enter a valid email")
              .matches(EMAIL_REG_EXP, "Enter a valid email")
              .max(50, "Maximum length is 50")
              .required("Email is required"),
            otherwise: yup
              .string()
              .noWhitespace("Enter a valid email")
              .matches(EMAIL_REG_EXP, "Enter a valid email")
              .max(50, "Maximum length is 50"),
          }),
          mobile: yup
            .string()
            .when("workContactPreferences", {
              is: (workContactPreferences: ContactPreferences) =>
                workContactPreferences.sms,
              then: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid phone number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            })
            .when("alternatePhone", {
              is: (alternatePhone: string) =>
                !alternatePhone || alternatePhone === "",
              then: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid phone number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            }),
          alternatePhone: yup
            .string()
            .noWhitespace("Enter a valid alternate phone number")
            .nullable()
            .matches(
              ALTERNATE_PHONE_REG_EXP,
              "Enter a valid alternate phone number",
            ),
          businessName: yup
            .string()
            .noWhitespace("Business name is invalid")
            .max(100, "Maximum length is 100 characters"),
          workContactPreferences: yup.object().shape({
            email: yup.boolean(),
            sms: yup.boolean(),
          }),
        },
        [["mobile", "alternatePhone"]],
      )
      .required("Must complete electrician contact detail"),
  }),
  contactDetails: yup
    .array()
    .of(
      yup.object().shape(
        {
          firstName: yup
            .string()
            .noWhitespace("First name is invalid")
            .max(50, "Maximum length is 50")
            .required("First name is required"),
          lastName: yup
            .string()
            .noWhitespace("Last name is invalid")
            .max(50, "Maximum length is 50")
            .required("Last name is required"),
          email: yup.string().when(["workContactPreferences", "roles"], {
            is: (
              workContactPreferences: ContactPreferences,
              roles: LiveningIcpRoles,
            ) => {
              return (
                workContactPreferences.email ||
                roles.requester ||
                roles.powerRetailerAccountHolderRole
              );
            },
            then: yup
              .string()
              .noWhitespace("Enter a valid email")
              .matches(EMAIL_REG_EXP, "Enter a valid email")
              .max(50, "Maximum length is 50")
              .required("Email is required"),
            otherwise: yup
              .string()
              .noWhitespace("Enter a valid email")
              .matches(EMAIL_REG_EXP, "Enter a valid email")
              .max(50, "Maximum length is 50"),
          }),
          mobile: yup
            .string()
            .when("workContactPreferences", {
              is: (workContactPreferences: ContactPreferences) => {
                return workContactPreferences.sms;
              },
              then: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid phone number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            })
            .when("alternatePhone", {
              is: (alternatePhone: string) => {
                return !alternatePhone || alternatePhone === "";
              },
              then: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid phone number")
                .required("Mobile is required"),
              otherwise: yup
                .string()
                .noWhitespace("Enter a valid phone number")
                .matches(MOBILE_REG_EXP, "Enter a valid mobile number"),
            }),
          alternatePhone: yup
            .string()
            .noWhitespace("Enter a valid alternate phone number")
            .nullable()
            .matches(
              ALTERNATE_PHONE_REG_EXP,
              "Enter a valid alternate phone number",
            ),
          businessName: yup
            .string()
            .noWhitespace("Business name is invalid")
            .max(100, "Maximum length is 100 characters"),
          workContactPreferences: yup.object().shape({
            email: yup.boolean(),
            sms: yup.boolean(),
          }),
          contactHasRole: yup.boolean()
            .test(
              "contactDetailsRole-selected",
              "Role is required",
              /* eslint-disable @typescript-eslint/no-explicit-any */
              /* eslint-disable @typescript-eslint/no-unused-vars */
              function (values: any) {
                /* @ts-ignore */
                const formValues = this.from[0];

                return formValues.value.roles.requester ||
                  formValues.value.roles.siteAccessContactRole ||
                  formValues.value.roles.powerRetailerAccountHolderRole;
              }),
          roles: yup.object().shape({
            siteAccessContactRole: yup.boolean().test(
              "siteAccessContactCheck",
              "Must be assigned to one contact",
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              function (values: any) {
                /* @ts-ignore */
                if (this.from[2]) {
                  /* @ts-ignore */
                  return this.from[2].value.siteAccessContactRoleChecked;
                }
                return true;
              },
            ),
            powerRetailerAccountHolderRole: yup.boolean().test(
              "powerRetailerAccountHolderCheck",
              "Must be assigned to one contact",
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              function (values: any) {
                /* @ts-ignore */
                if (this.from[2]) {
                  /* @ts-ignore */
                  return this.from[2].value
                    .powerRetailerAccountHolderRoleChecked;
                }
                return true;
              },
            ),
          }),
        },
        [["mobile", "alternatePhone"]],
      ),
    )
});
