import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  layout,
  LayoutProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system";

interface Props {
  variant: "current" | "disabled" | "completed";
}

const ButtonWrapper = styled.button`
  ${border}
  ${color}
  ${layout}
  ${space}
  ${size}
  ${typography}
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  font-family: inherit;
  border-style: solid;
  border-width: 2px;

  &:disabled {
    pointer-events: none;
  }
`;

const StyledButton = styled(ButtonWrapper)<
  Props &
    SpaceProps &
    SizeProps &
    BorderProps &
    LayoutProps &
    TypographyProps &
    ButtonHTMLAttributes<HTMLButtonElement>
>(
  variant({
    scale: "progressStepButton",
    variants: {
      current: {
        bg: "primary",
        color: "white",
        borderColor: "primary",

        ">:only-child": {
          color: "white",
        },
      },
      disabled: {
        bg: "neutrals.ln30",
        color: "neutrals.mn80",
        borderColor: "neutrals.ln30",

        ">:only-child": {
          color: "neutrals.mn80",
        },
      },
      completed: {
        bg: "hydro25",
        color: "hydroDark",
        borderColor: "hydro25",

        ">:only-child": {
          color: "hydroDark",
        },
      },
    },
  }),
);

const StyledButtonWrapper = styled(StyledButton)`
  display: inline-block;
  vertical-align: top;
`;

export const DesktopProgressStepButton: React.FC<Props> = ({ children, ...props }) => {
  return (
    <StyledButtonWrapper
      borderRadius="button"
      fontSize={1}
      lineHeight={2}
      width={11}
      height={11}
      fontWeight="bold"
      {...props}
    >
      {children}
    </StyledButtonWrapper>
  );
};

DesktopProgressStepButton.defaultProps = {};
