import React from "react";
import { Order } from "../../../../types/Order";
import { TableData } from "../../../TableData";
import { getStatusColor } from "../OrderStatusUtil/OrderStatusUtil";
import { OrderStatusIcon } from "../../../OrderStatusIcon";
import styled from "styled-components";
import { useGoToOrderDetails } from "../../../OrderDetails/helpers/OrderDetailsHelpers";
import { mapStringToOrderStatus } from "../../../../utils/orderStatusMapper/orderStatusMapper";

interface Props {
  index: number;
  order: Order;
  type: OrdersFilterStatus;
}

const TrWrapper = styled.tr`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydroDark};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
    outline: none;
  }
`;

export const OrderRow: React.FC<Props> = ({ index, type, order }) => {
  const goToOrderDetails = useGoToOrderDetails(order);

  const orderStatus = mapStringToOrderStatus(order.jobStatus);

  return (
    <TrWrapper onClick={goToOrderDetails} tabIndex={index}>
      <TableData
        data-testid="order-row-site"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {order.address}
      </TableData>

      <TableData
        data-testid="order-row-request-date"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {order.requestDate}
      </TableData>

      <TableData
        data-testid="order-row-job-type"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {order.jobType}
      </TableData>

      <TableData
        data-testid="order-row-requested-by"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {order.requester}
      </TableData>

      <TableData
        data-testid="order-row-order-number"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {order.orderNumber}
      </TableData>

      <TableData
        data-testid="order-row-status"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {orderStatus !== "" && (
          <OrderStatusIcon
            color={getStatusColor(orderStatus)}
            status={orderStatus}
          />
        )}
      </TableData>

      {type === "open" && (
        <TableData
          data-testid="order-row-currentActivity"
          height={15}
          border="none"
          borderBottom="1px solid"
          borderBottomColor="neutrals.ln30"
          fontSize={1}
        >
          {order.currentActivity}
        </TableData>
      )}

      {type === "closed" && (
        <TableData
          data-testid="order-row-reason"
          height={15}
          border="none"
          borderBottom="1px solid"
          borderBottomColor="neutrals.ln30"
          fontSize={1}
        >
          {order.reason}
        </TableData>
      )}
    </TrWrapper>
  );
};
