import React, { InputHTMLAttributes } from "react";
import { Span } from "../Span";
import { PositionProps, SpaceProps } from "styled-system";

export const PopupTip: React.FC<
  SpaceProps & PositionProps & InputHTMLAttributes<HTMLInputElement>
> = ({ id, children, ...props }) => {
  return (
    <Span
      id={id}
      borderRadius="input"
      p={3}
      fontSize={1}
      bg="neutrals.dn50"
      color="neutrals.ln0"
      lineHeight="21px"
      width="inherit"
      maxWidth="292px"
      zIndex={1}
      {...props}
    >
      {children}
    </Span>
  );
};
