import React from "react";
import { CompleteProfile } from "../../components/CompleteProfile";
import { Page } from "../../components/Page";

export interface ProfileTypeProps {
  tabTitle: string;
}

export const CompleteProfilePage: React.FC<ProfileTypeProps> = ({
  tabTitle,
}) => {
  return (
    <Page tabTitle={tabTitle} hasTopBar={false}>
      <CompleteProfile />
    </Page>
  );
};
