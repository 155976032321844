import { LiveningIcpFormValues } from "../../../../types/LiveningIcpFormValues";
import { filesStillUploading } from "../../../FileUploadUtil/FileUploadUtil";

export const shouldGoToReview = (values: LiveningIcpFormValues) => {
  if (values.icpType === "unmetered ICP") {
    return true;
  } else {
    const areFilesUploading = filesStillUploading(values.metered.sites);
    return !areFilesUploading;
  }
};
