import { GAEvent } from "./GaEventSender";

export const createGaEvent = (
  action: string,
  label: string,
): GAEvent => {
  return {
    event: "ecss_event",
    category: "order_service",
    action,
    label,
  };
};
