import { verifySiebelAccount } from "../../../services/UsersService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { sessionStorageWrapper } from "../../../utils/sessionStorageWrapper/sessionStorageWrapper";
import { PersistedClient } from "@tanstack/react-query-persist-client";
import { filterWithMultipleValues } from "../../../utils/arrayHelper/arrayHelper";
import {
  accountVerificationKey,
  ReactQueryKeys,
} from "../../../constants/ReactQueryKeys";
import {
  hoursToMinutes,
  minutesToMs,
} from "../../../utils/numberComputationHelper/numberComputationHelper";

export const useVerifySiebelAccount = (
  auth0AccessToken: string,
  email: string,
) => {
  const queryKey: ReactQueryKeys = "accountDetails";

  const ecssQueryCache =
    sessionStorageWrapper.getItem<PersistedClient>("ecssQueryCache");

  const queries = filterWithMultipleValues(
    ecssQueryCache?.clientState?.queries,
    [...accountVerificationKey, email],
    (query, filter) =>
      query.queryKey.includes(filter) && !query.state.isInvalidated,
  );

  const cachedSuccessResponse = queries.some(
    (query) => query.state.data === 204,
  );

  // override default staleTime and cacheTime in this query
  // - https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientsetquerydefaults
  const minutes = hoursToMinutes(24);
  const queryClient = useQueryClient();
  queryClient.setQueryDefaults([queryKey], {
    staleTime: minutesToMs(minutes),
    cacheTime: minutesToMs(minutes),
  });

  /***
   * useQuery docs - https://tanstack.com/query/v4/docs/react/reference/useQuery
   */
  const {
    data: responseStatus,
    isError: apiError,
    refetch: manuallyVerifyAccount,
  } = useQuery({
    queryKey: [...accountVerificationKey, email, auth0AccessToken],
    queryFn: () => verifySiebelAccount(auth0AccessToken),
    enabled: !!auth0AccessToken && !cachedSuccessResponse,
  });

  return {
    siebelVerificationStatus: cachedSuccessResponse ? 204 : responseStatus,
    apiError,
    manuallyVerifyAccount,
  };
};
