// Extension of Yup validation
export const noWhitespaceValidation = function (errorMessage) {
  return this.test("no-whitespace", errorMessage, function (value) {
    if (value === undefined) {
      return true;
    }

    const matches = value.match(/^\s*/g);
    if (!matches) {
      return true;
    }

    return matches[0] === "";
  });
};
