import {
  BorderProps,
  ColorProps,
  LayoutProps,
  SpaceProps,
  border,
  color,
  layout,
  size,
  space,
  typography,
  variant,
} from "styled-system";
import React, { ButtonHTMLAttributes } from "react";

import { Icon } from "../Icon";
import styled from "styled-components";

export interface Props {
  icon: string;
  variant?: "primary" | "secondary" | "transparent";
  magnitude?: "small" | "large";
}

const Wrapper = styled.button`
  ${border}
  ${color}
  ${layout}
  ${space}
  ${size}
  ${typography}
  cursor: pointer;
  text-decoration: none;
  border: none;
  border-radius: 50%;
  appearance: none;
  padding: 0;
  font-family: inherit;
  &:disabled {
    pointer-events: none;
  }
`;

const ActionVariant = styled(Wrapper)(
  variant({
    scale: "actionButton",
    variants: {
      primary: {
        bg: "neutrals.ln20",
        color: "primary",
        ">:only-child": {
          color: "primary",
        },
        "&:hover": {
          bg: "neutrals.ln20",
        },
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
          outline: "none",
        },
        "&:active": {
          bg: "neutrals.ln30",
          borderColor: "none",
          boxShadow: "none",
        },
        "&:disabled": {
          bg: "neutrals.ln10",
          color: "disabledColor",
          ">:only-child": {
            color: "disabledColor",
          },
        },
      },
      secondary: {
        bg: "transparent",
        color: "neutrals.dn40",
        ">:only-child": {
          color: "neutrals.dn40",
        },
        "&:hover": {
          bg: "neutrals.ln20",
        },
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
          outline: "none",
        },
        "&:active": {
          bg: "neutrals.ln30",
          borderColor: "none",
          boxShadow: "none",
        },
        "&:disabled": {
          bg: "transparent",
          color: "disabledColor",
          ">:only-child": {
            color: "disabledColor",
          },
        },
      },
      transparent: {
        bg: "transparent",
        color: "neutrals.dn40",
        ">:only-child": {
          color: "neutrals.dn40",
        },
        "&:focus": {
          borderColor: "hydroDark",
          boxShadow: "inputFocus",
          outline: "none",
        },
        "&:disabled": {
          bg: "transparent",
          color: "disabledColor",
          ">:only-child": {
            color: "disabledColor",
          },
        },
      },
    },
  }),
  variant({
    prop: "magnitude",
    scale: "actionButtonMagnitude",
    variants: {
      small: {
        width: 10,
        height: 10,
        ">:only-child": {
          fontSize: 4,
        },
      },
      large: {
        width: 12,
        height: 12,
        ">:only-child": {
          fontSize: 7,
        },
      },
    },
  }),
);

const IconWrapper = styled(Icon)`
  padding: 1px 6px;
`;

export const ActionButton: React.FC<
  Props &
    ColorProps &
    SpaceProps &
    LayoutProps &
    BorderProps &
    ButtonHTMLAttributes<HTMLButtonElement>
> = ({ icon, ...props }) => (
  <ActionVariant type="button" {...props}>
    <IconWrapper icon={icon} m="auto" data-testid="icon" />
  </ActionVariant>
);

ActionButton.defaultProps = {
  variant: "primary",
  magnitude: "small",
  disabled: false,
};
