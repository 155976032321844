import React from "react";
import { LiveningIcp } from "../../components/LiveningIcp";
import { Page } from "../../components/Page";

export interface LiveningIcpProps {
  tabTitle: string;
}

export const LiveningIcpPage: React.FC<LiveningIcpProps> = ({ tabTitle }) => {
  return (
    <Page tabTitle={tabTitle}>
      <LiveningIcp />
    </Page>
  );
};
