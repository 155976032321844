import TagManager from "react-gtm-module";

export interface GAEvent {
  event: string;
  category: string;
  action: string;
  label: string;
}

export const sendGAEvent = (event: GAEvent) => {
  TagManager.dataLayer({
    dataLayer: event
  });
};
