import { apiClient, apiClientConfig } from "../client/ApiClient";
import { CompleteProfileFormValues } from "../types/CompleteProfileFormValues";
import { UserFullDetails } from "../types/UserFullDetails";
import { UserBasicDetails } from "../types/UserBasicDetails";
import { includeAccessToken } from "../client/AxiosConfigObjectUtil";

export const createUser = async (
  value: CompleteProfileFormValues,
  auth0AccessToken?: string,
): Promise<boolean> => {
  try {
    const response = await apiClient.post("users", value.profile, {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    });

    return response.status === 201;
  } catch (error) {
    return false;
  }
};

export const retrieveUser = async (
  auth0AccessToken?: string,
): Promise<UserFullDetails> => {
  return await apiClient
    .get<UserFullDetails>("users", {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => {
      const data = response.data;

      if (!data.alternatePhone) {
        data.alternatePhone = "";
      }
      if (!data.businessName) {
        data.businessName = "";
      }

      return data;
    });
};

export const verifySiebelAccount = async (auth0AccessToken?: string) => {
  return await apiClient
    .get("users/account", {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => response.status);
};

export const updateUser = async (
  value: CompleteProfileFormValues,
  auth0AccessToken?: string,
): Promise<UserFullDetails> => {
  return await apiClient
    .put("users", value.profile, {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => response.data);
};

export const retrieveUserProfile = async (
  auth0AccessToken?: string,
): Promise<UserBasicDetails> => {
  return await apiClient
    .get<UserBasicDetails>("users/profile", {
      headers: includeAccessToken(apiClientConfig, auth0AccessToken),
    })
    .then((response) => {
      const data = response.data;

      if (!data.alternatePhone) {
        data.alternatePhone = "";
      }
      if (!data.businessName) {
        data.businessName = "";
      }

      return data;
    });
};
