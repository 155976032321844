import styled from "styled-components";
import { border, color, layout, space, typography } from "styled-system";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const TableHead: any = styled.th(
  color,
  space,
  typography,
  layout,
  border,
);
