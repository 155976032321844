import React, { useRef } from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { VectorLogo } from "../VectorLogo";
import { mediaQueries } from "../../theme";
import { IconTooltipLink } from "../IconTooltipLink";
import { UserLogo } from "../UserLogo";
import { Menu } from "../Menu";
import { Button } from "../Button";
import { LogoutButton } from "../LogoutButton";
import { Line } from "../Line";
import { Icon } from "../Icon";
import { useHistory } from "react-router-dom";

interface Props {
  userInitials: string;
  userFullName: string;
  userEmail: string;
  showUserLogo?: boolean;
  hasMobileNavMenu?: boolean;
  onMobileNavMenuClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  showMobileNav?: boolean;
  userLogoDisabled?: boolean;
}

const TopBarWrapper = styled(Flex)`
  z-index: 99999;

  ${mediaQueries.largeDevice} {
    position: fixed;
    width: 100%;
    top: 0;
  }
`;

const MenuIconWrapper = styled(Icon)`
  display: inline-flex;
  align-items: center;

  ${mediaQueries.largeDevice} {
    display: none;
  }
`;

const MobileVectorLogo = styled(VectorLogo)`
  display: inline-block;

  ${mediaQueries.mediumDevice} {
    display: none;
  }
`;

const DesktopVectorLogo = styled(VectorLogo)`
  display: none;

  ${mediaQueries.mediumDevice} {
    display: inline-block;
  }
`;

const UserLogoWrapper = styled(Flex)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "default")};
`;

export const MainTopBar: React.FC<Props> = ({
  userInitials,
  userFullName,
  userEmail,
  showUserLogo,
  hasMobileNavMenu,
  onMobileNavMenuClick,
  showMobileNav,
  userLogoDisabled,
}) => {
  const userLogoRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const handleMenuSettingsClick = () => {
    history.push("/account-settings");
  };

  return (
    <>
      <TopBarWrapper
        justifyContent="space-between"
        alignItems="center"
        border="none"
        borderBottom="2px solid"
        borderBottomColor="neutrals.ln30"
        backgroundColor="white"
        height={[13, 13, 16]}
      >
        <Flex>
          {hasMobileNavMenu && (
            <MenuIconWrapper
              icon={showMobileNav ? "clear" : "menu"}
              color="neutrals.mn80"
              ml={3}
              verticalAlign="middle"
              fontSize={6}
              onClick={onMobileNavMenuClick}
            />
          )}

          <Flex>
            <MobileVectorLogo
              height={11}
              ml={3}
              src={"/assets/images/vector-logo-mobile.svg"}
              verticalAlign="middle"
            />

            <DesktopVectorLogo
              height={12}
              ml={5}
              src={"/assets/images/vector-logo-desktop.svg"}
            />
          </Flex>
        </Flex>

        <Flex alignItems="center" position="relative" mr={[3, 3, 5]}>
          <IconTooltipLink href="/help" icon="help" mr={[1, 2, 3]}>
            Help
          </IconTooltipLink>

          {showUserLogo && (
            <UserLogoWrapper disabled={userLogoDisabled}>
              <UserLogo
                userInitials={userInitials}
                userFullName={userFullName}
                userEmail={userEmail}
                ref={userLogoRef}
                ml={[1, 2, 3]}
                height={[10, 10, 12]}
                width={[10, 10, 12]}
              />

              <Menu
                id="account-settings-menu"
                top={[11, 11, 55]}
                right={0}
                width={["250px", "300px"]}
                triggerRef={userLogoRef}
              >
                <Flex flexDirection="column" justifyContent="flex-start">
                  <Button
                    variant="lowAffordance"
                    iconLeft="settings"
                    magnitude="medium"
                    width="100%"
                    textAlign="left"
                    onClick={handleMenuSettingsClick}
                    data-testid="menu-account-settings"
                  >
                    Settings
                  </Button>

                  <Line
                    width="100%"
                    my={4}
                    border="1px solid"
                    borderColor="neutrals.ln30"
                    bg="neutrals.ln30"
                    opacity="1"
                    height={0}
                  />

                  <LogoutButton textAlign="left" width="100%" />
                </Flex>
              </Menu>
            </UserLogoWrapper>
          )}
        </Flex>
      </TopBarWrapper>
    </>
  );
};

MainTopBar.defaultProps = {
  showUserLogo: true,
  hasMobileNavMenu: false,
  showMobileNav: false,
};
