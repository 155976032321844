export interface FaqTypes {
  category: string;
  qa: QuestionAnswer[];
}

export interface QuestionAnswer {
  question: string;
  answer: string;
}

export const FaqValues: FaqTypes[] = [
  {
    category: "Online requests",
    qa: [
      {
        question:
          "When searching for a site address, I am told the address is not found. How can I let you know the address on the form?",
        answer:
          "An address can be manually added to the form after selecting Add Manual Address.",
      },
      {
        question: "Why do I need to consult an electrician for my request?",
        answer:
          "An electrician provides specialised information such as the phase and fuse (amp) sizes that are required and installed at the site. This information determines the capacity to be made available on the network to meet the site load requirements.",
      },
      {
        question: "Why do I need to provide so many contacts for my request?",
        answer:
          "We need to have contact details for specific roles because at various times during the job we may need to contact them to coordinate works or obtain information. We will also use the supplied email or mobile numbers to provide progress updates " +
          "(if these have been requested).",
      },
      {
        question:
          "Can my electrician be the livening inspector for my ICP order?",
        answer:
          "No, only Vector pre-approved electricians with the necessary qualifications for working on our network can liven a connection. These pre-approved electricians confirm a site is electrically safe, liven the supply and provide the necessary electrical " +
          "compliance certification. They must provide Vector with the livening information for the site." +
          " Click <a href='https://www.vector.co.nz/personal/electricity/approved-livening-inspector'" +
          " data-new-window='true' target='_blank' rel='noopener noreferrer'>here</a>" +
          " to view the list of livening inspectors.",
      },
      {
        question: "Can I receive progress updates on my request?",
        answer:
          "Yes. When completing the order form, you will need to let us know: " +
          "<ul> " +
          "<li>what contacts are to receive progress updates</li>" +
          "<li>their email and/or mobile phone number</li>" +
          "<li>how they would like to receive them, for example by email or SMS</li>" +
          "</ul>" +
          "Once the order form has been submitted, we will send the relevant progress updates to those identified on the order form.",
      },
      {
        question:
          "Can I order multiple livening requests for different sites at the same time?",
        answer:
          "Yes. If the contact details, retailer and livening inspector are the same across all sites, then you can add multiple sites on the one order. You will need to specify the details for each ICP required for each site, or, if you have a large number," +
          " upload our template file for each site.<br/>" +
          "<br/>If the contact details, retailer or livening inspector are different across the sites, then separate requests will need to be submitted.<br/>" +
          "<br/>Once a request which has multiple sites has been submitted, each site will become an individual request and we will provide you with different reference number for each site.",
      },
      {
        question: "Can I save my order and return to it later?",
        answer:
          "This functionality is only accessible if you are logged into your registered account. Unfortunately, when placing an order as a guest, the ability to save your form and continue later is not available.",
      },
      {
        question: "Can I edit my request before I submit it?",
        answer:
          "Yes. When you are reviewing your request, you have the option to edit it. Select the “Edit” button next the relevant section and update. When completed, select the next button to return to the “Review and Submit” page.",
      },
      {
        question: "What are the next steps after I submit my request?",
        answer:
          "You will receive a confirmation of receipt of your request, including a summary of your request details, and a reference number. Our team will assess the request. We will keep you updated with automated emails informing you about the request progress.",
      },
      {
        question:
          "How do I request a new connection and the livening of it at the same time?",
        answer:
          "A separate request will need to be submitted for each service as, usually, the livening takes place months after the connection supply point installation.",
      },
      {
        question: "How do I request a new gas connection?",
        answer:
          "If the connection is for a residential property, you can use the " +
          "<a href='https://css.vector.co.nz/' > gas self-service-portal</a>." +
          " If the connection is for a commercial property or a gas subdivision, then you will need to contact us on " +
          "<a href='tel:+640508832867'>0508 832 867</a>.",
      },
      {
        question:
          "The order form is not working, what do I do and who do I contact?",
        answer:
          "Email <a href='mailto:tech.support@vector.co.nz?subject=ECSS%20Service%20Query' >tech.support@vector.co.nz</a>" +
          " and let us know as much detail as you can on what happened. Please provide screenshots, if possible, of any error messages.",
      },
      {
        question: "I have trouble uploading a file, what do I do?",
        answer:
          "There are several reasons why a file cannot be uploaded: " +
          "<ul> " +
          "<li>The file is too big (the maximum size is 10MB)</li>" +
          "<li>The file format is not accepted. We only accept Excel or Google Sheet files for ICP orders, and pdf, dwg, jpg, doc or docx files for new connection orders</li> " +
          "<li>The file you uploaded is corrupt – please create your file again and upload again</li>" +
          "<li>Something is wrong on our side: please try again in a couple of minutes and do not refresh your page in the meantime</li>" +
          "</ul>",
      },
    ],
  },
  {
    category: "Account management",
    qa: [
      {
        question:
          "Do I have to register before using the customer self-service portal?",
        answer:
          "You do not need to register to submit a request, but you will need to register if you want to use the portal to save your request before submitting or track its progress." +
          " We recommend ordering as a guest user if you are making a one-off request. We recommend you register if you will be submitting multiple requests, as this will make ordering and tracking simpler for you.",
      },
      {
        question: "Can I have one account for all employees in my company?",
        answer:
          "Yes, you can. The first person who registers a business account becomes the admin for that account. This admin can add additional admins or users by contacting us.<br/> " +
          "<br/>Send an email from your registered email account " +
          "<a href='mailto:vectorconnections@vector.co.nz?subject=Request%20changes%20to%20<registered%20account%20name>%20'>  " +
          "vectorconnections@vector.co.nz</a> and in the subject line type: 'Request changes to &lt;registered account name&gt;'. " +
          "You will need to let us know the name and email address of the person you would like added or removed. If a new user is to also be an admin, you will need to include this information.",
      },
      {
        question:
          "What are the differences between ordering as a guest or a registered user?",
        answer:
          "Both guest and registered users can:" +
          "<ol type='a'> " +
          "<li>Receive updates on request progress (via email or SMS)</li>" +
          "<li>Pay online by clicking ‘Pay Now’ in your email (for new simple electricity connections only).</li>" +
          "</ol> " +
          "Guest users can:" +
          "<ul> " +
          "<li>Order online but cannot save their request</li>" +
          "</ul> " +
          "Registered users can: " +
          "<ul> " +
          "<li>Order online and save their request if they do not want to submit straight away</li>" +
          "<li>View and track the progress of all their requests online</li>" +
          "<li>Add other users to the same account (used by companies with multiple employees) so all company requests are visible to all users</li>" +
          "</ul>",
      },
    ],
  },
  {
    category: "Billing and payment",
    qa: [
      {
        question:
          "Can you confirm if my online payment has been successfully processed?",
        answer:
          "Once you complete a payment online, you will see a ‘transaction approved’ reference number displayed on the screen.<br/>" +
          "<br/>The payment will be allocated within two business days. You will receive a notification once we have received payment.",
      },
      {
        question: "I cannot pay my invoice.",
        answer:
          "After clicking on the link within your email notification or account, this will revert you to the Windcave payment page and enable you to " +
          "insert your credit card details to process your payment. If the payment page does not appear, refresh your page, and return to the page again.",
      },
    ],
  },
  {
    category: "Technical issues",
    qa: [
      {
        question: "Why can’t I see all my orders in the electricity portal?",
        answer:
          "Unfortunately, if you have submitted a complex request on the portal, this will not show up in the order list. A complex request is:" +
          "<ul> " +
          "<li>A subdivision with more than 10 lots</li>" +
          "<li>A connection with a fuse size of over 100 amps</li>" +
          "</ul>" +
          "If you cannot see your livening (ICP number) or simple connections, try logging out and logging back in again.<br/>" +
          "<br/>If you still cannot see your requests, please email " +
          "<a href='mailto:tech.support@vector.co.nz?subject=ECSS%20Service%20Query'>tech.support@vector.co.nz</a> and let us know as much detail as you can on what requests are missing. Please provide screenshots, if possible, of any error messages.",
      },
      {
        question: "Why can’t I submit an order?",
        answer:
          "If the submit button is greyed out, make sure you have ticked the privacy policy statement to accept our terms and conditions relating to privacy. Once you’ve accepted the policy terms, the submit button will be active.<br/>" +
          "<br/>If you have clicked on submit and there are problems creating your request, you will receive a message that something went wrong. Please try again in a few minutes. If it still does not work, please email " +
          "<a href='mailto:tech.support@vector.co.nz?subject=ECSS%20Service%20Query'>tech.support@vector.co.nz</a> and let us know as much detail as you can on what happened. Please provide screenshots, if possible, of any error messages.",
      },
    ],
  },
];
