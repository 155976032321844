import { Field, ErrorMessage } from "formik";
import React from "react";
import { UnmeteredFeatureTypeValues } from "../../../../constants/Unmetered";
import { Flex } from "../../../Flex";
import { FormikDropdown } from "../../../FormikDropdown";
import { FormInputErrorMessage } from "../../../FormInputErrorMessage";

export const FeatureType: React.FC = () => {
  return (
    <Flex
      flexDirection="column"
      flexGrow={0.3}
      flexBasis={0}
      minWidth={["100%", "100%", "100%", 0]}
      mr={[0, 0, 0, 3]}
      mb={[3, 3, 3, 0]}
    >
      <Field
        id="unmetered.featureType"
        name="unmetered.featureType"
        items={UnmeteredFeatureTypeValues}
        visibleItems={4}
        label="Feature type"
        placeholder="Select"
        component={FormikDropdown}
      />
      <ErrorMessage
        name="unmetered.featureType"
        className="field-error"
        component={FormInputErrorMessage}
      />
    </Flex>
  );
};
