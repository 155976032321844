import React from "react";
import styled from "styled-components";
import { Flex } from "../../../../../Flex";
import { Span } from "../../../../../Span";
import {
  getFileNameWithoutExtension,
  getFileExtension,
} from "../../../../../FileUpload/_/FileUtil/FileUtil";
import { UploadUnitValues } from "../../../../../../types/UploadUnitValues";

interface Props {
  uploads: UploadUnitValues[];
  uploadTitle: string;
}

const LabelWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.neutrals.dn40};
  min-width: 0;
  flex-basis: 0;
`;

const UploadUnitWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.neutrals.ln10};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const FileTypeWrapper = styled(Span)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.neutrals.mn80};
  text-transform: uppercase;
`;

export const ReviewUploadsFormMeteredDetails: React.FC<Props> = ({
  uploads,
  uploadTitle,
}) => {
  return (
    <>
      {uploads.length !== 0 && (
        <Span mb={3}>
          <LabelWrapper
            lineHeight={2}
            flexGrow={[0.5, 0.5, 0.5, 0.4, 0.3]}
            mt={3}
            mb={3}
            data-testid={"uploadReviewTitle"}
          >
            {uploadTitle}
          </LabelWrapper>
          {uploads.map((site, index) => {
            return (
              <UploadUnitWrapper
                key={index}
                mb={3}
                borderRadius="radio"
                padding={[2, 2, 2, 3]}
              >
                <Span
                  data-testid={`review-form-site-${index + 1}-filename`}
                  fontSize={2}
                  lineHeight={2}
                >
                  {getFileNameWithoutExtension(site.fileName)}
                </Span>
                <FileTypeWrapper
                  data-testid={`review-form-site-${index + 1}-filetype`}
                  ml={3}
                  padding={1}
                  borderRadius="fileType"
                  fontSize={1}
                  lineHeight={0}
                >
                  {getFileExtension(site.fileName)}
                </FileTypeWrapper>
              </UploadUnitWrapper>
            );
          })}
        </Span>
      )}
    </>
  );
};
