import { PAYMENT_METHOD_CREDIT_CARD } from "../../../../constants/PaymentMethods";
import { PayNowOrderPayment } from "../../../../types/PayNowOrderPayment";
import moment from "moment";

export const amountToString = (amount: number) => {
  return (Math.round(amount * 100) / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });
};

export const setPayNowFormDetails = (
  values: PayNowOrderPayment,
  payNowOrderPayment: PayNowOrderPayment,
): PayNowOrderPayment => {
  values = { ...payNowOrderPayment };
  values.billingDetails.billingAddressId = "";
  values.paymentMethod = PAYMENT_METHOD_CREDIT_CARD;
  return values;
};

export const setFormattedTotalPrice = (totalPrice: number): string => {
  return "$" + amountToString(totalPrice);
};

export const formattedQuoteExpiry = (quoteExpiry: string): string => {
  return "Payment due by " + moment(quoteExpiry).local().format("D MMMM YYYY");
};
