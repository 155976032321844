import React from "react";
import { UnsubmittedOrder } from "../../../../types/Order";
import { TableData } from "../../../TableData";
import { OrderStatusIcon } from "../../../OrderStatusIcon";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { ActionLink } from "../../../ActionLink";
import {
  getExpiryDaysStringUtil,
  getOrderTypeUtil,
  getUnsubmittedAddressUtil,
  isDeleteOrderButton,
} from "../UnsubmittedOrdersUtil/UnsubmittedOrdersUtil";
import { deleteModalAction } from "../../../../constants/DeleteReducerAction";
import { useHistory } from "react-router-dom";

interface Props {
  index: number;
  order: UnsubmittedOrder;
  userFullName: string;
  onDelete: (action: deleteModalAction) => void;
}

const TrWrapper = styled.tr`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutrals.ln20};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.hydroDark};
    box-shadow: ${({ theme }) => theme.shadows.inputFocus};
    outline: none;
  }
`;

const ActionLinkWrapper = styled(ActionLink)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

export const UnsubmittedOrderRow: React.FC<Props> = ({
  index,
  order,
  userFullName,
  onDelete,
}) => {
  const days = getExpiryDaysStringUtil(order.expiryDay);
  const address = getUnsubmittedAddressUtil(order);
  const orderType = getOrderTypeUtil(order.orderType);
  const handleDelete = () => {
    onDelete({ type: "Select", payload: order });
  };

  const history = useHistory();

  const handleRowOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const isDelete = isDeleteOrderButton(event);

    if (!isDelete) {
      if (order.orderType === "LiveningIcpOrder") {
        history.push("/icp-request", order);
      } else {
        history.push("/new-connection", order);
      }
    }
  };

  return (
    <TrWrapper tabIndex={index} onClick={handleRowOnClick}>
      <TableData
        data-testid="order-row-site"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {address}
      </TableData>

      <TableData
        data-testid="order-row-job-type"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {orderType}
      </TableData>

      <TableData
        data-testid="order-row-requested-by"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {userFullName}
      </TableData>

      <TableData
        data-testid="order-row-status"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        <OrderStatusIcon color={theme.colors.orangeAlert} status={"draft"} />
      </TableData>

      <TableData
        data-testid="order-row-created-date"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {order.createdDate}
      </TableData>

      <TableData
        data-testid="order-row-expires-date"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
        fontSize={1}
      >
        {days}
      </TableData>

      <TableData
        fontSize={1}
        data-testid="order-delete"
        height={15}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="neutrals.ln30"
      >
        <ActionLinkWrapper
          data-testid="order-delete-button"
          onClick={handleDelete}
        >
          Delete
        </ActionLinkWrapper>
      </TableData>
    </TrWrapper>
  );
};
