import React, { useState } from "react";
import { LiveningIcpFormValues } from "../../types/LiveningIcpFormValues";
import { LiveningIcpForm } from "../LiveningIcpForm";
import { Flex } from "../Flex";
import { Grid } from "../Grid";
import styled from "styled-components";
import { mediaQueries } from "../../theme";
import { LiveningIcpProgressStepper } from "../LiveningIcpProgressStepper";
import { submitLiveningIcpOrder } from "../../services/SubmitOrderService";
import { Redirect, useLocation } from "react-router-dom";
import {
  getGaSaveDraftFailedEvent,
  getGaSaveDraftSuccessEvent,
  getGaSubmitFailedEvent,
  getGaSubmitSuccessEvent,
} from "../../utils/ga/GaLiveningIcpEvents";
import { EMPTY_UNSUBMITTED_ORDER } from "../../constants/Orders";
import {
  deleteUnSubmittedOrder,
  putUnSubmittedOrder,
} from "../../services/OrderService";
import { FloatingBanner } from "../FloatingBanner";
import { BasicNotificationBannerProps } from "../BasicNotificationBanner/BasicNotificationBanner";
import { addUnsubmittedOrderId } from "../AddDraftOrderIdUtil/AddDraftOrderIdUtil";
import lodash from "lodash";
import { EMPTY_LIVENING_ICP_ORDER } from "../../constants/LiveningIcpOrder";
import { setRequesterDetailsLiveningIcp } from "../SetRequesterFormDetailsUtil/SetRequesterFormDetailsUtil";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { sendGAEvent } from "../../utils/ga/GaEventSender";
import { useInvalidateOrderList } from "../../hooks/cachedQueries/useInvalidateOrderList/useInvalidateOrderList";

const FlexWrapper = styled(Flex)`
  ${mediaQueries.extraLargeDevice} {
    grid-column-start: 2;
  }
`;

const GridWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  ${mediaQueries.extraLargeDevice} {
    display: grid;
    grid-template-columns: 25vw auto 10vw;
  }
`;

interface NotificationBannerProps extends BasicNotificationBannerProps {
  show: boolean;
}

const WrappedNotificationBanner = styled.div`
  position: fixed;
  left: 2.2vh;
  bottom: 14.7vh;
`;

export const LiveningIcp: React.FC = () => {
  const location = useLocation();
  const { state } = location;

  const {
    authenticatedUserContext: { userAuth0Details, userBasicDetails },
  } = useUserAuthentication();
  const { accessToken } = userAuth0Details;
  const { invalidateOrdersCache } = useInvalidateOrderList();

  const formValues: LiveningIcpFormValues = lodash.isEmpty(state)
    ? lodash.cloneDeep(EMPTY_LIVENING_ICP_ORDER)
    : state.liveningIcpOrder;

  const initialState: NotificationBannerProps = {
    show: false,
    title: "",
    message: "",
    variant: "success",
    icon: "check_circle",
  };

  const [saveOrderResetBanner, setSaveOrderResetBanner] =
    useState(initialState);

  const [unsubmittedOrderId, setUnsubmittedOrderId] = useState(
    lodash.isEmpty(state) ? "" : state.orderId,
  );
  const [activeStep, setActiveStep] = useState(2);

  const [orderDetails, setOrderDetails] = useState<
    LiveningIcpFormValues | undefined
  >(undefined);

  const handleStepChange = (activeStep: number) => {
    setActiveStep(activeStep);
  };

  const handleSubmit = async (values: LiveningIcpFormValues) => {
    try {
      values.contactId = userBasicDetails?.contactId;

      await submitLiveningIcpOrder(values, accessToken);
      await invalidateOrdersCache();
      await deleteUnSubmittedOrder(unsubmittedOrderId, accessToken);

      setOrderDetails(values);
      sendGAEvent(getGaSubmitSuccessEvent());
    } catch (err: any) {
      setActiveStep(4);
      sendGAEvent(getGaSubmitFailedEvent());
    }
  };

  const handleSave = async (values: LiveningIcpFormValues) => {
    let icpOrderSaved;
    try {
      const draftOrder = lodash.cloneDeep(EMPTY_UNSUBMITTED_ORDER);
      draftOrder.orderType = "LiveningIcpOrder";
      draftOrder.liveningIcpOrder = values;

      addUnsubmittedOrderId(unsubmittedOrderId, draftOrder);

      const result = await putUnSubmittedOrder(draftOrder, accessToken);
      setUnsubmittedOrderId(result.orderId);

      icpOrderSaved = true;
      sendGAEvent(getGaSaveDraftSuccessEvent());
    } catch (error) {
      icpOrderSaved = false;
      sendGAEvent(getGaSaveDraftFailedEvent());
    }

    setSaveOrderResetBanner({
      show: true,
      title: icpOrderSaved
        ? "Order saved"
        : "Oops, looks like something went wrong",
      message: icpOrderSaved
        ? "Your order has been successfully saved and can be found in your Draft orders list"
        : "Please wait a few minutes and try again. If the problem persists, contact us at info@vector.co.nz.",
      variant: icpOrderSaved ? "success" : "error",
      icon: icpOrderSaved ? "check_circle" : "warning",
    });
  };

  const handleOnSaveBannerClose = () => {
    setSaveOrderResetBanner({
      show: false,
      title: saveOrderResetBanner.title,
      message: saveOrderResetBanner.message,
      variant: saveOrderResetBanner.variant,
      icon: saveOrderResetBanner.icon,
    });
  };

  return (
    <>
      <LiveningIcpProgressStepper currentStep={activeStep} />
      <WrappedNotificationBanner>
        <FloatingBanner
          title={saveOrderResetBanner.title}
          message={saveOrderResetBanner.message}
          variant={saveOrderResetBanner.variant}
          icon={saveOrderResetBanner.icon}
          visible={saveOrderResetBanner.show}
          onBannerClose={handleOnSaveBannerClose}
        />
      </WrappedNotificationBanner>
      <GridWrapper mx={[3, 3, 4, 4, 0]}>
        <FlexWrapper flexDirection="column">
          {!orderDetails ? (
            <LiveningIcpForm
              initialValues={setRequesterDetailsLiveningIcp(
                formValues,
                userBasicDetails,
              )}
              onSubmit={handleSubmit}
              onStepChange={handleStepChange}
              activeStep={activeStep}
              handleSave={handleSave}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/thank-you/liveningIcp",
                state: orderDetails,
              }}
            />
          )}
        </FlexWrapper>
      </GridWrapper>
    </>
  );
};
