import moment from "moment";
import { isOrderCancelledOrComplete } from "../../../OrderDetailsList/_/OrderStatusUtil/OrderStatusUtil";
import { DuePayment } from "../../../../types/DuePayment";

const DATE_FORMAT = "D MMM YYYY";

export const shouldShowNextActivity = (
  nextActivity: string,
  progressStatus: OrderProgressStatus,
) => {
  return nextActivity && !isOrderCancelledOrComplete(progressStatus);
};

export const formatActivityAndDate = (
  activity: string,
  activityDate?: Date,
) => {
  if (!activityDate) {
    return activity;
  }

  const currentActivityDate = moment(activityDate).format(DATE_FORMAT);
  return `${activity} (${currentActivityDate})`;
};

export const isPaymentDue = (duePayment: DuePayment): boolean => {
  if (!duePayment) {
    return false;
  }

  const { dueDate } = duePayment;
  const paymentDueDate = moment(dueDate).format(DATE_FORMAT);

  return moment().isSameOrBefore(paymentDueDate, "day");
};
