import React, { useEffect, useRef, useState } from "react";
import { LayoutProps, PositionProps, SpaceProps } from "styled-system";
import { useClickedOutsideElement } from "../../hooks/useClickedOutsideElement/useClickedOutsideElement";
import { Menu } from "./Menu";

interface Props {
  id: string;
  triggerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

export const MenuContainer: React.FC<
  Props & LayoutProps & PositionProps & SpaceProps
> = ({ id, triggerRef, children, ...props }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClickedOutsideElement = () => {
    setShowMenu(false);
  };

  const menuRef = useRef<HTMLDivElement>(null);
  useClickedOutsideElement<HTMLDivElement>(
    menuRef,
    handleClickedOutsideElement,
    () => !showMenu,
  );

  useEffect(() => {
    const handleOnTriggerClick = () => {
      setShowMenu((prevState) => !prevState);
    };

    const triggerRefCurrent = triggerRef.current;
    if (!triggerRefCurrent) {
      return;
    }

    triggerRefCurrent.onclick = handleOnTriggerClick;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showMenu && (
        <Menu id={id} ref={menuRef} {...props}>
          {children}
        </Menu>
      )}
    </>
  );
};
