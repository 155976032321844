import React from "react";
import { NewConnectionPage, NewConnectionProps } from "./NewConnectionPage";

export const DEFAULT_NEW_CONNECTION_CONTENT: NewConnectionProps = {
  tabTitle: "",
};

export const NewConnectionPageContainer: React.FC = () => {
  return <NewConnectionPage {...DEFAULT_NEW_CONNECTION_CONTENT} />;
};
