import React, { useState } from "react";
import { EMPTY_CANCEL_ORDER_FORM } from "../../../../constants/CancelOrderFormValues";
import { OrderDetailCancelForm } from "../OrderDetailCancelForm";
import { ActionLink } from "../../../ActionLink";
import { Modal } from "../../../Modal";
import { isOrderCancelled } from "../../../OrderDetailsList/_/OrderStatusUtil/OrderStatusUtil";
import { putCancelOrder } from "../../../../services/OrderService";
import { OrderDetailsCancelFormValues } from "../../../../types/OrderDetailsCancelFormValues";
import lodash from "lodash";
import { useUserAuthentication } from "../../../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { useInvalidateOrderList } from "../../../../hooks/cachedQueries/useInvalidateOrderList/useInvalidateOrderList";

interface Props {
  orderStatus: OrderProgressStatus;
  orderId: string;
  handleOnCancelOrderNotification: (cancelResponse: boolean) => void;
}

export const OrderDetailCancelModal: React.FC<Props> = ({
  orderStatus,
  orderId,
  handleOnCancelOrderNotification,
}) => {
  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();

  const [showLoading, setShowLoading] = useState(false);

  const { invalidateOrdersCache } = useInvalidateOrderList();

  const cancelOrderForm = lodash.cloneDeep(EMPTY_CANCEL_ORDER_FORM);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [showCancelLink, setShowCancelLink] = useState(
    isOrderCancelled(orderStatus),
  );

  const toggleFormVisibility = () => {
    if (!showLoading) {
      setShowCancelForm((prevState) => !prevState);
    }
  };

  const handleOnCancel = () => {
    toggleFormVisibility();
  };

  const handleOnSubmit = async (values: OrderDetailsCancelFormValues) => {
    setShowLoading(true);
    const { accessToken } = userAuth0Details;
    values.orderId = orderId;
    const submitResponse = await putCancelOrder(values, accessToken);

    setShowCancelLink(submitResponse);
    handleOnCancel();
    setShowLoading(submitResponse);
    handleOnCancelOrderNotification(submitResponse);

    if (submitResponse) {
      await invalidateOrdersCache();
    }
  };

  return (
    <>
      {!showCancelLink && (
        <ActionLink onClick={toggleFormVisibility}>Cancel order</ActionLink>
      )}

      <Modal
        title="You are about to cancel your order"
        showModal={showCancelForm}
        onCloseModal={toggleFormVisibility}
        showButtons={false}
      >
        <OrderDetailCancelForm
          initialValues={cancelOrderForm}
          onSubmitForm={handleOnSubmit}
          onCancelForm={handleOnCancel}
          data-testid={"cancel-form"}
          loading={showLoading}
        />
      </Modal>
    </>
  );
};
