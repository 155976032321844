import React from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import env from "../../environment";
import { UserAuthenticationProvider } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";

export const AuthenticationProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const handleAuth0Redirect = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      redirectUri={env.auth0RedirectUri}
      onRedirectCallback={handleAuth0Redirect}
      useRefreshTokens
      audience={`${env.auth0ApiBaseUrl}/api/v2/`}
    >
      <UserAuthenticationProvider>{children}</UserAuthenticationProvider>
    </Auth0Provider>
  );
};
