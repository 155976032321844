import React, { ReactElement } from "react";
import { SpaceProps } from "styled-system";
import { FormikInputText } from "../FormikInputText";
import { FormInputErrorMessage } from "../FormInputErrorMessage";
import { ErrorMessage, Field } from "formik";
import { Flex } from "../Flex";

interface Props {
  prefix: string;
  propertyName?: string;
  label?: string;
  focusable?: boolean;
  maxLength?: number;
  flexGrow?: number;
  optional?: boolean;
  disabled?: boolean;
  message?: ReactElement | string;
}

export const CompanyNzbn: React.FC<Props & SpaceProps> = ({
  prefix,
  propertyName = "companyNzbn",
  ...props
}) => {
  const baseOptions = {
    id: `${prefix}.${propertyName}`,
    name: `${prefix}.${propertyName}`,
    label: "NZBN (New Zealand Business Number)",
    focusable: true,
    maxLength: 13,
    flexGrow: 1,
  };

  props = { ...baseOptions, ...props };

  return (
    <Flex flexDirection={"column"} mb={4}>
      <Field {...props} component={FormikInputText} />
      <ErrorMessage
        name={`${prefix}.${propertyName}`}
        className="field-error"
        component={FormInputErrorMessage}
      />
    </Flex>
  );
};
