import React from "react";
import styled from "styled-components";
import { NewConnectionLotValues } from "../../../../../../types/NewConnectionLotValues";
import { Span } from "../../../../../Span";
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import { mediaQueries } from "../../../../../../theme";

interface Props {
  lots: NewConnectionLotValues[];
}

const Table = styled.table<LayoutProps>`
  ${layout}
  display: none;

  ${mediaQueries.mediumDevice} {
    display: table;
    text-align: left;
  }
`;

const TableCell = styled.td<SpaceProps>`
  ${space}
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutrals.ln30};
`;

const TableHeadCell = styled.th<TypographyProps>`
  ${typography}
  color: ${({ theme }) => theme.colors.neutrals.mn80};
`;

export const ReviewFormDesktopLots: React.FC<Props> = ({ lots }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Supply type
          </TableHeadCell>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Phase
          </TableHeadCell>
          <TableHeadCell lineHeight={4} fontWeight="medium">
            Fuse (Amp)
          </TableHeadCell>
        </tr>
      </thead>
      <tbody>
        {lots.map((lot, index) => {
          return (
            <tr data-testid={`desktop-lot-row-${index + 1}`} key={index}>
              <TableCell py={3}>
                <Span data-testid={`lot-${index + 1}-type`}>{lot.type}</Span>
              </TableCell>
              <TableCell py={3}>
                <Span data-testid={`lot-${index + 1}-phase`}>{lot.phase}</Span>
              </TableCell>
              <TableCell py={3}>
                <Span data-testid={`lot-${index + 1}-fuse`}>{lot.fuseAmp}</Span>
              </TableCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
