import React from "react";
import { ProgressStepper } from "../ProgressStepper";

interface Props {
  currentStep: number;
}

const LIVENING_ICP_FORM_STEPS = ["Service Type", "Order Details", "Review"];

export const LiveningIcpProgressStepper: React.FC<Props> = ({ ...props }) => {
  return <ProgressStepper steps={LIVENING_ICP_FORM_STEPS} {...props} />;
};

export { LIVENING_ICP_FORM_STEPS };
