import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, PositionProps, SpaceProps } from "styled-system";
import { Box } from "../Box";

interface Props {
  id: string;
  children: React.ReactNode;
}

const StyledMenu = styled(Box)<LayoutProps>`
  ${layout}

  position: absolute;
  z-index: 1000;
`;

export const Menu = React.forwardRef<
  HTMLDivElement,
  Props & LayoutProps & PositionProps & SpaceProps
>(({ id, top, left, right, p, width, children }, ref) => {
  return (
    <StyledMenu
      id={id}
      data-testid="menu"
      p={p || 4}
      top={top}
      left={left}
      right={right}
      width={width}
      boxShadow="menu"
      borderRadius="menu"
      backgroundColor="neutrals.ln0"
      ref={ref}
    >
      {children}
    </StyledMenu>
  );
});

Menu.displayName = "Menu";

StyledMenu.defaultProps = {
  top: 0,
  right: 0,
};
