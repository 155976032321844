import React, { useState } from "react";
import styled from "styled-components";
import { Field, Form, Formik, FormikState } from "formik";
import { space, SpaceProps } from "styled-system";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { scrollToTop } from "../../utils/handleScroll/HandleScroll";
import { PAY_NOW_FORM_STEPS } from "../PayNowProgressStepper/PayNowProgressStepper";
import { useUserAuthentication } from "../../contexts/UserAuthenticationProvider/UserAuthenticationProvider";
import { Title } from "../Title";
import { PayNowOrderPayment } from "../../types/PayNowOrderPayment";
import { PayNowReviewQuote } from "./_/PayNowReviewQuote";
import { PayNowBillingDetailsForm } from "./_/PayNowBillingDetailsForm";
import { PayNowFormSchema } from "./PayNowFormSchema";
import { useHistory } from "react-router-dom";
import { amountToString } from "./_/PayNowDetailsUtil/PayNowDetailsUtil";
import { Span } from "../Span";
import { BasicNotificationBanner } from "../BasicNotificationBanner";
import { FormikRadioButtonDescriptive } from "../FormikRadioButtonDescriptive";
import {
  PAYMENT_METHOD_ACCOUNT_2_ACCOUNT,
  PAYMENT_METHOD_CREDIT_CARD,
} from "../../constants/PaymentMethods";

interface Props {
  paymentId: string;
  onSubmit: (values: PayNowOrderPayment) => void;
  onStepChange: (activeStep: number) => void;
  activeStep: number;
  initialValues: PayNowOrderPayment;
}

const validationSchemas = [undefined, PayNowFormSchema, undefined];

const FormWrapper = styled(Form)<SpaceProps>`
  ${space}
`;

export const PayNowForm: React.FC<Props> = ({
  paymentId,
  initialValues,
  onSubmit,
  onStepChange,
  activeStep,
}) => {
  const showLoading = false;
  const [editMode, setEditMode] = useState(false);
  const [selectedAcknowledged, setSelectedAcknowledged] = useState(false);

  const history = useHistory();

  const {
    authenticatedUserContext: { userAuth0Details },
  } = useUserAuthentication();

  const isLastStep = activeStep === PAY_NOW_FORM_STEPS.length - 1;

  const handleSubmit = (values: PayNowOrderPayment) => {
    onSubmit(values);
    setEditMode(false);
  };

  const handlePay = (values: PayNowOrderPayment) => {
    if (isLastStep) {
      history.push("/create-payment-session", {
        userId: userAuth0Details.auth0Id,
        orderId: values.orderId,
        paymentId,
        srNumber: values.orderNumber,
        amount: amountToString(values.totalPrice).replace(/,/g, ""),
        customerName: `${values.billingDetails.firstName} ${values.billingDetails.lastName}`,
        paymentMethod: values.paymentMethod,
      });
      return;
    }

    let nextStep = 1;

    nextStep = activeStep + 1;
    onStepChange(nextStep);
    scrollToTop();
  };

  const handleOnBackClick = () => {
    setSelectedAcknowledged(false);

    const nextStep = activeStep - 1;
    onStepChange(nextStep);
  };

  const handleBillingDetailsFormEdit = () => {
    setEditMode(true);
    setSelectedAcknowledged(false);
  };

  const handleBillingDetailsFormCancel = (
    resetForm: (
      nextState?: Partial<FormikState<PayNowOrderPayment>> | undefined,
    ) => void,
  ) => {
    resetForm && resetForm();
    setEditMode(false);
  };

  const onSelectAcknowledged = () => {
    setSelectedAcknowledged((prevState) => !prevState);
  };

  return (
    <>
      <Formik
        validateOnChange={false}
        /* eslint-disable  @typescript-eslint/no-non-null-assertion */
        initialValues={initialValues!}
        enableReinitialize={true}
        validationSchema={validationSchemas[activeStep - 1]}
        onSubmit={handleSubmit}
      >
        {({ values, resetForm, validateField }) => (
          <FormWrapper mb={8}>
            {activeStep === 1 && (
              <Flex flexDirection="column">
                <Title as="h1" lineHeight={7} fontWeight="medium" my={5}>
                  Review
                </Title>

                <PayNowReviewQuote
                  id="pay-now-review-quote"
                  orderPayment={initialValues}
                />
              </Flex>
            )}

            {activeStep === 2 && (
              <>
                <PayNowBillingDetailsForm
                  onEdit={handleBillingDetailsFormEdit}
                  editMode={editMode}
                  selectedAcknowledged={selectedAcknowledged}
                  onSelectAcknowledged={onSelectAcknowledged}
                  handleSubmit={handleSubmit}
                  handleCancel={() => handleBillingDetailsFormCancel(resetForm)}
                  validateField={validateField}
                  values={values}
                  initialValues={initialValues}
                />

                <Flex flexDirection="column" mt={7}>
                  <Flex>
                    <Span
                      display="inline-block"
                      fontSize={4}
                      lineHeight={4}
                      fontWeight="medium"
                    >
                      Select a payment method
                    </Span>
                  </Flex>

                  <BasicNotificationBanner
                    title="Fast & Secure"
                    message="Our payment partner Windcave is trusted, and provides safe and secure payment methods."
                    variant="information"
                    icon="info"
                    my={5}
                    fontSize={1}
                  />

                  <Flex flexDirection="column" mb={4}>
                    <Field
                      magnitude="medium"
                      id="creditcard"
                      name="paymentMethod"
                      label="Credit card or debit card"
                      description={`Note: a service fee of 1.15% per transaction is applied by our service provider to offer online payments through this site. 
                        Vector does not receive any part of this fee. This fee will appear as a separate transaction on your credit card or debit card statement and is referred to as a "convenience fee".`}
                      value={PAYMENT_METHOD_CREDIT_CARD}
                      currentValue={values.paymentMethod}
                      component={FormikRadioButtonDescriptive}
                      mb={3}
                    />

                    <Field
                      magnitude="medium"
                      id="account2account"
                      name="paymentMethod"
                      label="Account2Account"
                      description=""
                      value={PAYMENT_METHOD_ACCOUNT_2_ACCOUNT}
                      currentValue={values.paymentMethod}
                      component={FormikRadioButtonDescriptive}
                    />
                  </Flex>
                </Flex>
              </>
            )}

            <Flex
              justifyContent={activeStep > 1 ? "space-between" : "flex-end"}
              mt={5}
            >
              {activeStep === 2 && (
                <Button
                  variant="secondary"
                  alignSelf="flex-start"
                  onClick={handleOnBackClick}
                  data-testid="back-button"
                >
                  Back
                </Button>
              )}

              {activeStep < 3 && (
                <Button
                  type="button"
                  disabled={
                    (isLastStep && !selectedAcknowledged) ||
                    editMode ||
                    showLoading
                  }
                  alignSelf="flex-end"
                  loading={showLoading}
                  onClick={() => handlePay(values)}
                  data-testid={isLastStep ? "pay-button" : "next-button"}
                >
                  {isLastStep ? "Pay now" : "Next"}
                </Button>
              )}
            </Flex>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
