import React from "react";
import { Form, Formik } from "formik";
import { OrderDetailsCancelFormValues } from "../../../../types/OrderDetailsCancelFormValues";
import { CancelOrderSchema } from "./CancelOrderSchema";
import { OrderDetailCancelFormFields } from "../OrderDetailCancelFormFields";
import { Button } from "../../../Button";
import { Flex } from "../../../Flex";

interface Props {
  initialValues: OrderDetailsCancelFormValues;
  onSubmitForm: (values: OrderDetailsCancelFormValues) => void;
  onCancelForm: () => void;
  loading: boolean;
}

export const OrderDetailCancelForm: React.FC<Props> = ({
  initialValues,
  onSubmitForm,
  onCancelForm,
  loading,
}) => {
  return (
    <Formik
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      initialValues={initialValues!}
      validationSchema={CancelOrderSchema}
      onSubmit={(values) => {
        onSubmitForm(values);
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <OrderDetailCancelFormFields id="cancel-order-form" values={values} />

          <Flex flexDirection="row" justifyContent={"flex-end"} mt={5}>
            <Button
              variant="ghost"
              type="reset"
              marginRight={3}
              data-testid="cancelButton"
              disabled={loading}
              onClick={onCancelForm}
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              type="submit"
              data-testid="submitButton"
              loading={loading}
              disabled={loading}
            >
              Confirm
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
