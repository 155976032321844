import React from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { Text } from "../Text";
import { Icon } from "../Icon";
import { LayoutProps, SpaceProps, variant } from "styled-system";
import { Grid } from "../Grid";
import { Title } from "../Title";
import { Box } from "../Box";

export interface BasicNotificationBannerProps {
  title?: string;
  message: string | (() => void);
  variant: NotificationBannerVariant;
  icon: NotificationBannerIcon;
  actionButton?: React.ReactNode;
  closeButton?: React.ReactNode;
  fontSize?: number;
}

const IconStyle = styled(Icon)<{ variant: string }>(
  variant({
    variants: {
      default: {
        color: "hydro",
      },
      information: {
        color: "hydro",
      },
      success: {
        color: "newEnergy",
      },
      error: {
        color: "urgentRed",
      },
      warning: {
        color: "sunshine",
      },
    },
  }),
);

const BannerStyle = styled(Flex)(
  variant({
    scale: "notificationBanner",
    variants: {
      default: {
        bg: "neutrals.ln20",
      },
      information: {
        bg: "hydro25",
      },
      informationGray: {
        bg: "neutrals.ln20",
      },
      success: {
        bg: "newEnergy25",
      },
      error: {
        bg: "urgentRed25",
      },
      warning: {
        bg: "optimism25",
      },
    },
  }),
);

export const BasicNotificationBanner: React.FC<
  BasicNotificationBannerProps & SpaceProps & LayoutProps
> = ({
  title,
  message,
  variant,
  icon,
  actionButton,
  closeButton,
  mb,
  mt,
  my,
  fontSize = 2,
  width,
}) => {
  return (
    <BannerStyle
      borderRadius="notificationBanner"
      justifyContent="flex-start"
      variant={variant}
      p={3}
      mb={mb}
      mt={mt}
      my={my}
    >
      <IconStyle variant={variant} icon={icon} data-testid="icon" />

      <Grid gridTemplateColumns={["auto", "auto auto"]} width={width}>
        <Flex flexDirection="column" ml={2}>
          {title && (
            <Title
              as="h4"
              mt={0}
              mb={2}
              fontWeight="bold"
              lineHeight="body"
              fontSize="body"
              color="nightSky"
              textAlign="left"
            >
              {title}
            </Title>
          )}

          <Text fontSize={fontSize} color="neutrals.dn40" mt={0} mb={0}>
            {typeof message === "string" && message}
            {typeof message === "function" && message()}
          </Text>
        </Flex>

        {actionButton && actionButton}
      </Grid>

      {closeButton && (
        <Box data-testid="close-button-wrapper">{closeButton}</Box>
      )}
    </BannerStyle>
  );
};
