import { LiveningIcpSite } from "../../types/LiveningIcpSite";
import { UploadUnitValues } from "../../types/UploadUnitValues";

export const getFileSelectUploadStatus = (
  selectedUnits: CssFile[],
  uploadedUnits: UploadUnitValues[],
): FileSelectUploadStatus => {
  if (selectedUnits.length === 0) {
    return "";
  }

  for (let index = 0; index < selectedUnits.length; index++) {
    const selectedUnit = selectedUnits[index];

    const indexInUploadUnits = uploadedUnits.findIndex(
      (uploadedUnit) => uploadedUnit.fileName === selectedUnit.name,
    );

    if (indexInUploadUnits === -1) {
      return "UPLOADING";
    }
  }

  return "DONE";
};

export const filesStillUploading = (sites: LiveningIcpSite[]): boolean => {
  return sites.some((site) => site.filesUploadStatus === "UPLOADING");
};

export const getFileNameFromPath = (filePath: string) => {
  let index = -1;
  const separators = ["/", "\\"];
  separators.some((item) => {
    index = filePath.lastIndexOf(item);
    return index !== -1;
  });

  if (index === -1) {
    return filePath;
  }

  return filePath.substring(index + 1);
};
